import React, { useState, useEffect } from "react";
// import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
// import usePost from "../..//Hooks/usePost";
// import useCustomMutation from "../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../../Buttons/ButtonSubmit";

const AddPayment = ({ eventID, token, toggleAddPayment }) => {
  const [inputs, setInputs] = useState({});
  //   const queryClient = useQueryClient();
  const key = ["eventPayment", eventID];
  const mutation = useCustomMutation(key);
  //   const { status, execute, pending, data } = usePost();
  // const mutation = useCustomMutation(key);

  const handleCreateFaq = (event) => {
    event.preventDefault();
    const Method = "POST",
      endPoint = `faq/${eventID}`;
    const raw = JSON.stringify({
      accountHolderName: inputs?.accountHolderName,
      bankName: inputs?.bankName,
      accountNumber: inputs?.accountNumber,
      accountType: inputs?.accountType,
      branchCode: inputs?.branchCode,
      reference: inputs?.reference,
    });
    //   execute(endPoint, raw, Method, "FAQ successfully added", token);
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Payment successful added",
      token: token,
    };
    mutation.mutate(mutation?.isSuccess);
  };

  useEffect(() => {
    if (mutation?.isSuccess === "success") {
      //   queryClient.invalidateQueries(key);
      toggleAddPayment();
    }
  }, [mutation?.isSuccess]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  return (
    <div>
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={handleCreateFaq}
      >
        <Row>
          <Col md={6}>
            <label>Account Holder Name</label>
            <input
              name="accountHolderName"
              type="text"
              value={inputs?.accountHolderName}
              onChange={handleChange}
              required
              className="mb-3 form-control fromInput"
            />
          </Col>
          <Col md={6}>
            <label>Bank Name</label>
            <input
              name="bankName"
              type="text"
              value={inputs?.bankName}
              onChange={handleChange}
              required
              className="mb-3 form-control fromInput"
            />
          </Col>
          <Col md={6}>
            <label>Account Number</label>
            <input
              name="accountNumber"
              type="number"
              value={inputs?.accountHolderName}
              onChange={handleChange}
              required
              className="mb-3 form-control fromInput"
            />
          </Col>
          <Col md={6}>
            <label>Account Type</label>
            <input
              name="accountType"
              type="text"
              value={inputs?.accountType}
              onChange={handleChange}
              required
              className="mb-3 form-control fromInput"
            />
          </Col>
          <Col md={6}>
            <label>Branch Code</label>
            <input
              name="branchCode"
              type="text"
              value={inputs?.branchCode}
              onChange={handleChange}
              required
              className="mb-3 form-control fromInput"
            />
          </Col>
          <Col md={6}>
            <label>Reference</label>
            <input
              name="reference"
              type="text"
              value={inputs?.reference}
              onChange={handleChange}
              required
              className="mb-3 form-control fromInput"
            />
          </Col>
          <div className="w-100 mt-2 d-flex justify-content-center">
            <ButtonSubmit
              Title="Add Payment"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              type="submit"
              pending={mutation?.isLoading}
              handleOnclick={handleCreateFaq}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddPayment;

import React from "react";
import { Spinner } from "reactstrap";

function ButtonIcon({
  Title,
  BackgroundColor,
  ColorText,
  BorderColor,
  borderRadius,
  handleOnclick,
  Icon,
  pending,
}) {
  return (
    <buton
      className="btn"
      style={{
        fontWeight: "500",
        color: ColorText,
        borderColor: BorderColor,
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: borderRadius,
        backgroundColor: BackgroundColor,
      }}
      onClick={handleOnclick}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {!pending ? (
          <>
            <div>{Icon}</div>
            <div className="mx-1">{Title}</div>
          </>
        ) : null}

        {!pending ? null : (
          <span>
            <Spinner as="span" animation="border" size="sm" /> Loading
          </span>
        )}
      </div>
    </buton>
  );
}

export default ButtonIcon;

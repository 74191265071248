import React, { useRef } from "react";
// import Lottie from "lottie-react";
// import conference from "../../../assets/animations/conference.json";
import conference from "../../../assets/images/banners/login/conference.png";
import "./login-lotties.scss";

function Conference() {
  // const conferenceRef = useRef();
  return (
    <div className="lotties-login-container">
      <div className="w-100 d-flex justify-content-center align-items-center" style={{userSelect: "none"}}>
        <div className="rounded-4 lotties-login-container-conference"  style={{ width: "17em", height: "17em" }}>
          <img src={conference} alt="conference" className="w-100 h-100" />
          {/* <Lottie lottieRef={conferenceRef} animationData={conference} /> */}
        </div>
      </div>
      <div className="lotties-login-conference-caption">
        <div className="w-100 d-flex justify-content-center lotties-login-text">
          Connect. Collaborate. Conquer
        </div>
        <div className="w-100 d-flex justify-content-center lotties-login-text">
          with Kago Events Virtual Conference
        </div>
      </div>
    </div>
  );
}

export default Conference;

import React from "react";
import extractSrcFromIframe from "../../../../utils/extractSrcFromIframe";
import Divider from "./Divider";
const EventMap = ({ venueMap }) => {
  const embedMapURL = extractSrcFromIframe(venueMap);
  return (
    <>
      <h1
        id="location"
        className="d-flex justify-content-center"
        style={{ color: "black", fontWeight: "bold" }}
      >
        Event Location
      </h1>
      <Divider />
      <div className="w-100 mt-4" style={{ height: "400px" }}>
        <iframe
          src={embedMapURL}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Event Location Map"
        ></iframe>
      </div>
    </>
  );
};

export default EventMap;

import React from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../Hooks/useCustomQuery";
import HeaderNavigation from "../../Layout/HorizontalLayout/CommunityHeader";
import logo from "../../assets/images/community/Logo.png";
// import HeaderNa
import LobbyComponents from "../../components/Webinar/Participants/Lobby";

const WebinarLobby = () => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userName = obj?.data?.data?.data?.firstName;
  //   const userId = obj?.data?.data?.data?.id;
  const token = obj?.data?.token;
  const params = useParams();

  const eventID = params.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");
  document.title = `${myEventName} | Welcome`;

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );
  return (
    <div className="w-100 webinar-backstage">
      <HeaderNavigation logoImg={logo} />
      <LobbyComponents
        userName={userName}
        token={token}
        eventID={eventID}
        eventDetail={getMyEventDetail?.data}
      />
    </div>
  );
};

export default WebinarLobby;

import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import DataTable from "react-data-table-component";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ViewInvites from "./ViewInvites";
import ModalDelete from "../../../Modal/ModalDelete";
import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";

const TableInvites = ({ userID, token, eventID, search }) => {
  const [attendeeID, setAttendeeID] = useState(false);
  const [attendEmail, setAttendeeEmail] = useState(null);
  const [attendDetails, setAttendeeDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["myInvites", eventID];

  const mutationDelete = useCustomMutation(key);

  const {
    data: getInvitees,
    error: errorGetInvitees,
    isLoading: isLoadingGetInvitees,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}event-invitations/invite/${eventID}`,
    token,
    {
      enabled: !!eventID,
    }
  );

  // console.log("getInvitees....", getInvitees);

  const tog_viewParticipant = (details) => {
    setAttendeeDetails(details);
    setIsToggleView(!isToggleView);
  };
  const tog_deleteParticipant = (id, email) => {
    setAttendeeID(id);
    setAttendeeEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `attendees/${attendeeID}/?eventId=${eventID}&email=${attendEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Attendee successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => {
        return (
          <span
            className="d-flex justify-content-center align-items-center"
            style={{
              width: 120,
              height: 30,
              borderRadius: 15,
              fontWeight: "bolder",
              backgroundColor: "#59CE96",
              color: "white",
            }}
          >
            Invitation sent
            {/* <div style={{ width: 90, height: 50, padding: 5 }}>
              <img
                src={row?.eventBanner}
                className="w-100 h-100"
                style={{ borderRadius: 10 }}
              />
            </div> */}
          </span>
        );
      },
    },
  ];

  const filterParticipants = (participants, searchQuery) => {
    if (!searchQuery) return participants;
    return participants?.filter((item) =>
      [item?.firstName, item?.lastName, item?.email, item?.jobTitle].some(
        (field) => field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredAttendees = filterParticipants(getInvitees?.data, search);

  const data = filteredAttendees;
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Invites"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            DeleteParticitant={DeleteParticitant}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Invite"
        body={<ViewInvites details={attendDetails} />}
        toggleSaveChanges={() => tog_viewParticipant()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableInvites;

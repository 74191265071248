import React from "react";
import LandingPicture from "../../../../../../../assets/images/event/landing1.png";
function LandingForm({}) {
  return (
    <div>
      <img src={LandingPicture} />
      LandingForm
    </div>
  );
}

export default LandingForm;

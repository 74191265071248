import { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTypography,
  MDBInputGroup,
  // MDBScrollbar,
} from "mdb-react-ui-kit";
import SimpleBar from "simplebar-react";
import "./community.scss";
import userImg from "../../../../assets/images/users/User.png";
import users from "./components/data";

// component
// import ChatUsers from "./components/ChatUsers";
// import ChatArea from "./components/ChatArea";

// dummy data
// import users from "./components/data";

const ChatApp = ({ userId, token, eventID, eventDetail }) => {
  document.title = "Kago Events | Community Chat";

  const [selectedUser, setSelectedUser] = useState(users[1]);
  const attendee = users?.users;
  // `${process.env.REACT_APP_API}attendees/${eventID}/event`,
  const {
    data: getMyCommunityChat,
    error: errorGetMyCommunityChat,
    isLoading: isLoadingGetMyCommunityChat,
  } = useCustomQuery(
    ["myCommunityChat", eventID],
    `${process.env.REACT_APP_API}attendees/${eventID}`,
    token
  );

  console.log("getMyCommunityChat...", getMyCommunityChat);

  // console.log("eventDetail..", eventDetail);
  // On user change

  const onUserChange = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="page-content  community---container">
      <MDBContainer fluid className="py-0 chat---general---container">
        <MDBRow>
          <MDBCol md="12">
            <MDBCard id="chat3" style={{ borderRadius: "15px" }}>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0">
                    <div className="p-3">
                      <MDBInputGroup className="rounded mb-3">
                        <input
                          className="form-control rounded"
                          placeholder="Search"
                          type="search"
                        />
                        <span
                          className="input-group-text border-0"
                          id="search-addon"
                        >
                          <MDBIcon fas icon="search" />
                        </span>
                      </MDBInputGroup>

                      {/* <MDBScrollbar
                        suppressScrollX
                        style={{ position: "relative", height: "400px" }}
                      > */}
                      <SimpleBar
                        style={{
                          position: "relative",
                          height: "300px",
                          width: "100%",
                        }}
                      >
                        <MDBTypography listUnStyled className="mb-0">
                          <li className="p-2 border-bottom">
                            <a
                              href="#!"
                              className="d-flex justify-content-between"
                            >
                              <div className="d-flex flex-row">
                                <div>
                                  <img
                                    src={eventDetail?.bannerImage}
                                    alt="event-image"
                                    className="d-flex align-self-center me-3"
                                    width="60"
                                    height="60"
                                    style={{
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <span className="badge bg-success badge-dot"></span>
                                </div>
                                <div className="pt-1">
                                  <p className="fw-bold mb-0">
                                    {eventDetail?.eventName}
                                  </p>
                                  <div></div>
                                  {/* <p className="small text-muted">
                                    Hello, Are you there?
                                  </p> */}
                                </div>
                              </div>
                              <div className="pt-3">
                                {/* <p className="small text-muted mb-1">live</p> */}
                                <span className="badge bg-danger rounded-pill float-end">
                                  3
                                </span>
                              </div>
                            </a>
                          </li>
                          {attendee?.map((contact, i) => {
                            return (
                              <li className="p-2 border-bottom" key={i}>
                                <a
                                  href="#!"
                                  className="d-flex justify-content-between"
                                >
                                  <div className="d-flex flex-row">
                                    <div>
                                      <img
                                        src={
                                          contact?.avatar
                                            ? contact?.avatar
                                            : userImg
                                        }
                                        alt="avatar"
                                        className="d-flex align-self-center me-3"
                                        width="60"
                                        height="60"
                                        style={{ borderRadius: "50%" }}
                                      />
                                      <span className="badge bg-warning badge-dot"></span>
                                    </div>
                                    <div className="pt-1">
                                      <p className="fw-bold mb-0">
                                        {contact?.name}
                                      </p>
                                      <p className="small text-muted">
                                        {contact?.lastMessage}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="pt-1">
                                    <p className="small text-muted mb-1">
                                      {contact?.lastMessageOn}
                                    </p>
                                    {contact?.number ? (
                                      <span className="badge bg-danger rounded-pill float-end">
                                        {contact?.number}
                                      </span>
                                    ) : null}
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </MDBTypography>
                      </SimpleBar>
                    </div>
                  </MDBCol>
                  <MDBCol md="6" lg="7" xl="8">
                    {/* <MDBScrollbar
                      suppressScrollX
                      style={{ position: "relative", height: "400px" }}
                      className="pt-3 pe-3"
                    > */}
                    <SimpleBar
                      style={{
                        position: "relative",
                        height: "300px",
                        width: "100%",
                      }}
                    >
                      <div className="d-flex flex-row justify-content-start">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                        <div>
                          <p
                            className="small p-2 ms-3 mb-1 rounded-3"
                            style={{ backgroundColor: "#f5f6f7" }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                          <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-end">
                        <div>
                          <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                            Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo
                            consequat.
                          </p>
                          <p className="small me-3 mb-3 rounded-3 text-muted">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                      </div>

                      <div className="d-flex flex-row justify-content-start">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                        <div>
                          <p
                            className="small p-2 ms-3 mb-1 rounded-3"
                            style={{ backgroundColor: "#f5f6f7" }}
                          >
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                          </p>
                          <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-end">
                        <div>
                          <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.
                          </p>
                          <p className="small me-3 mb-3 rounded-3 text-muted">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                      </div>

                      <div className="d-flex flex-row justify-content-start">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                        <div>
                          <p
                            className="small p-2 ms-3 mb-1 rounded-3"
                            style={{ backgroundColor: "#f5f6f7" }}
                          >
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo.
                          </p>
                          <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-end">
                        <div>
                          <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                            Nemo enim ipsam voluptatem quia voluptas sit
                            aspernatur aut odit aut fugit, sed quia consequuntur
                            magni dolores eos qui ratione voluptatem sequi
                            nesciunt.
                          </p>
                          <p className="small me-3 mb-3 rounded-3 text-muted">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                      </div>

                      <div className="d-flex flex-row justify-content-start">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                        <div>
                          <p
                            className="small p-2 ms-3 mb-1 rounded-3"
                            style={{ backgroundColor: "#f5f6f7" }}
                          >
                            Neque porro quisquam est, qui dolorem ipsum quia
                            dolor sit amet, consectetur, adipisci velit, sed
                            quia non numquam eius modi tempora incidunt ut
                            labore et dolore magnam aliquam quaerat voluptatem.
                          </p>
                          <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-end">
                        <div>
                          <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                            Ut enim ad minima veniam, quis nostrum
                            exercitationem ullam corporis suscipit laboriosam,
                            nisi ut aliquid ex ea commodi consequatur?
                          </p>
                          <p className="small me-3 mb-3 rounded-3 text-muted">
                            12:00 PM | Aug 13
                          </p>
                        </div>
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                          alt="avatar 1"
                          style={{ width: "45px", height: "100%" }}
                        />
                      </div>
                    </SimpleBar>
                    <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                        alt="avatar 3"
                        style={{ width: "40px", height: "100%" }}
                      />
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="exampleFormControlInput2"
                        placeholder="Type message"
                      />
                      <a className="ms-1 text-muted" href="#!">
                        <MDBIcon fas icon="paperclip" />
                      </a>
                      <a className="ms-3 text-muted" href="#!">
                        <MDBIcon fas icon="smile" />
                      </a>
                      <a className="ms-3" href="#!">
                        <MDBIcon fas icon="paper-plane" />
                      </a>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default ChatApp;

import React from "react";

import logosm from "../../assets/images/logo-sm.png";
import kagoLogo from "../../assets/images/Kago-main-logo.png";
// import logodark from "../../assets/images/Kago-main-logo.png";
// import logolight from "../../assets/images/Kago-main-logo.png";
import { Link } from "react-router-dom";

const CustomTopBar = () => {
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="customize---topBar"
        style={{ userSelect: "none" }}
      >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/home" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosm} alt="logo-sm-dark" height="27" />
                </span>
                <div style={{ width: 120, height: 60 }}>
                  <img
                    src={kagoLogo}
                    alt="kago-logo"
                    className="w-100 h-100"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Link>
            </div>
          </div>

          <div className="d-flex">{/* <LanguageDropdown /> */}</div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default CustomTopBar;

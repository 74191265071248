import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Spinner, Row, Col, Alert } from "reactstrap";
import formatDate from "../../../../utils/formatDate";

const AddTicket = ({ token, eventID, eventDetails, setIsOffcanvasOpen }) => {
  const [inputs, setInputs] = useState({
    tier: "",
    price: "",
    quantity: "",
    discount: "",
    closingDate: "",
    earlyBirdStartDate: "",
    earlyBirdEndDate: "",
    ticketDescription: "",
    ticketTerms: "",
  });
  const [isEarlyBird, setIsEarlyBird] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const key = ["EventDetails", eventDetails?._id];

  const mutation = useCustomMutation(key);

  const validateDates = () => {
    const errors = [];
    const { earlyBirdStartDate, earlyBirdEndDate, closingDate } = inputs;

    if (isEarlyBird) {
      if (new Date(earlyBirdStartDate) >= new Date(earlyBirdEndDate)) {
        errors.push("Early bird start date must be before the end date.");
      }
      if (new Date(earlyBirdStartDate) >= new Date(closingDate)) {
        errors.push("Early bird start date must be before the closing date.");
      }
      if (new Date(earlyBirdEndDate) > new Date(closingDate)) {
        errors.push("Early bird end date must not be after the closing date.");
      }
    }

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    if (!validateDates()) {
      return;
    }

    const url = `ticket-settings?eid=${eventDetails?._id}`;
    const raw = JSON.stringify({
      ...inputs,
      price: parseInt(inputs.price),
      quantity: parseInt(inputs.quantity),
      discount: parseInt(inputs.discount),
      allowEarlyBird: isEarlyBird,
      earlyBirdStartDate: formatDate(inputs.earlyBirdStartDate),
      earlyBirdEndDate: formatDate(inputs.earlyBirdEndDate),
      closingDate: formatDate(inputs.closingDate),
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: "POST",
      displayMessage: "Ticket Successfully Created",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess, setIsOffcanvasOpen]);

  useEffect(() => {
    if (isEarlyBird) {
      validateDates();
    } else {
      setValidationErrors([]);
    }
  }, [
    inputs.earlyBirdStartDate,
    inputs.earlyBirdEndDate,
    inputs.closingDate,
    isEarlyBird,
  ]);

  const renderInput = (label, name, type = "text", required = false) => (
    <Col md={4}>
      <label>{label}</label>
      <input
        type={type}
        name={name}
        value={inputs[name]}
        onChange={handleChange}
        className="mb-3 form-control fromInput"
        required={required}
        style={{ width: "100%" }}
      />
    </Col>
  );

  return (
    <div className="w-100">
      <form onSubmit={handleSubmitForm}>
        <Row>
          {renderInput("Ticket Name", "tier")}
          {renderInput("Price", "price", "number")}
          {renderInput("Quantity", "quantity", "number")}
        </Row>
        <Row>
          {renderInput("Ticket Closing Date", "closingDate", "date", true)}
        </Row>

        <div>
          <input
            className="form-check-input"
            type="checkbox"
            id="earlyBirdCheckbox"
            checked={isEarlyBird}
            onChange={() => setIsEarlyBird(!isEarlyBird)}
          />
          <label className="mx-2" htmlFor="earlyBirdCheckbox">
            Include Early Bird Price
          </label>
        </div>

        {isEarlyBird && (
          <div className="mt-2">
            <Row>
              {renderInput(
                "Early Bird Start Date",
                "earlyBirdStartDate",
                "date",
                true
              )}
              {renderInput(
                "Early Bird End Date",
                "earlyBirdEndDate",
                "date",
                true
              )}
              {renderInput("Discount (%)", "discount", "number", true)}
            </Row>
          </div>
        )}

        {validationErrors.length > 0 && (
          <Alert color="warning" className="mt-3">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}

        <Row>
          <Col md={12}>
            <label className="mt-2 mb-2">Ticket description</label>
            <textarea
              rows={5}
              className="form-control"
              name="ticketDescription"
              value={inputs.ticketDescription}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <label className="mt-2 mb-2">Tickets Terms</label>
            <textarea
              rows={5}
              className="form-control"
              name="ticketTerms"
              value={inputs.ticketTerms}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <div className="d-flex mt-2 justify-content-center align-item-center">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={mutation.isLoading || validationErrors.length > 0}
          >
            {mutation.isLoading ? (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading
              </span>
            ) : (
              "Create"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTicket;

import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";

function EventVisibility({ userID, token, eventDetails }) {
  const [inputs, setInputs] = useState({});

  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Visibility successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  useEffect(() => {
    if (eventDetails) {
      const { visibility } = eventDetails;
      setInputs({
        visibility,
      });
    }
  }, [eventDetails]);

  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={12} lg={12}>
            <div className="mt-2">
              <label>Set Event</label>
              <div className="d-flex">
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="visibility"
                    id="exampleRadios1"
                    value="Public"
                    onChange={handleChange}
                    defaultChecked={
                      inputs.visibility === "Public" ? true : null
                    }
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Public
                  </label>
                </div>
                <div className="px-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="exampleRadios2"
                      value={"Private"}
                      onChange={handleChange}
                      defaultChecked={
                        inputs.visibility === "Private" ? true : null
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Private
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="mt-2 d-flex align-item-center">
          <button className="btn btn-primary rounded-5" type="submit">
            <div className="d-flex justify-content-start align-items-center">
              {!mutation.isLoading ? <span>Update Visibility</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

export default EventVisibility;

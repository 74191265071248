import React, { useState } from "react";
import DataTable from "react-data-table-component";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import { AiFillEye } from "react-icons/ai";

const TableEmail = ({ myEmail }) => {
  const [emailDetails, setEmailDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);

  const toggleViewEmail = (details) => {
    setEmailDetails(details);
    setIsToggleView(!isToggleView);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Audience</span>,
      selector: (row) => row?.audience,
    },
    {
      name: <span className="font-weight-bold fs-13">Message</span>,
      selector: (row) => row?.body,
    },
    {
      name: <span className="font-weight-bold fs-13">View</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className=""
                onClick={() => toggleViewEmail(row)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const data = myEmail;

  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card-mb-none">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>

      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Email"
        body={
          <div>
            <div>
              <span style={{ fontWeight: "bold" }}>Subject :</span>{" "}
              <span>{emailDetails?.subject}</span>
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>To :</span>{" "}
              <span>{emailDetails?.audience}</span>
            </div>

            <div
              className="mt-2 rounded-3 p-2 text-justify"
              style={{
                border: "0.5px solid #CACACA",
                backgroundColor: "#F0F0F0",
                minHeight: 200,
              }}
            >
              {emailDetails?.body}
            </div>
          </div>
        }
        toggleSaveChanges={() => toggleViewEmail()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableEmail;

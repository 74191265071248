import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box, Collapse } from "@mui/material";
import ButtonSubmit from "../../../../Buttons/ButtonSubmit";
import ButtonGradient from "../../../../Buttons/ButtonGradient";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});

const formatLabel = (label) =>
  `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

const Sessions = ({ sessions = [], eventID, eventName, eventDetails }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const email = obj?.data?.data?.data?.email;

  const [selectedTab, setSelectedTab] = useState("0");
  const [expandedDays, setExpandedDays] = useState({});

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const toggleViewMore = (day) => {
    setExpandedDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const getSessionsByDay = () => {
    if (!sessions || !Array.isArray(sessions)) {
      return {};
    }

    const sessionsByDay = sessions.reduce((acc, session) => {
      const day = session?.sessionDate;
      if (!acc[day]) acc[day] = [];
      acc[day].push(session);
      return acc;
    }, {});

    Object.keys(sessionsByDay).forEach((day) => {
      sessionsByDay[day]?.sort((a, b) =>
        a?.startTime?.localeCompare(b?.startTime)
      );
    });

    return sessionsByDay;
  };

  const sessionsByDay = getSessionsByDay();
  const days = Object.keys(sessionsByDay)?.sort();

  // console.log("email...", email);
  // console.log("eventDetails...", eventDetails);
  // eventDetails;

  const isHost = (email, userArray) => {
    return userArray.some((user) => user?.email === email);
  };

  const handleJoinNow = (sessionName, sessionID) => {
    const host = isHost(email, eventDetails?.personnel);
    if (host) {
      Navigate(
        `/${eventName?.replace(/ /g, "-")}/host/${sessionName?.replace(
          / /g,
          "-"
        )}/${eventID}/${sessionID}`
      );
    }
    if (eventDetails?.attendees?.includes(email)) {
      Navigate(
        `/${eventName?.replace(/ /g, "-")}/${sessionName?.replace(
          / /g,
          "-"
        )}/${eventID}/${sessionID}`
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="w-100 d-flex justify-content-center mt-2 ">
        {/* <Box sx={{ backgroundColor: "white", width: "90%" }}> */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="session navigation tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {days.map((day, index) => (
            <Tab key={day} value={String(index)} label={`Day ${index + 1}`} />
          ))}
        </Tabs>
        {/* </Box> */}
      </div>
      <div className="w-100 d-flex justify-content-center py-4">
        {days?.map((day, index) => (
          <div key={day} hidden={selectedTab !== String(index)}>
            {sessionsByDay[day]?.slice(0, 3)?.map((session) => (
              <div
                className="mb-2 d-flex align-items-center"
                style={{
                  padding: 9,
                  width: 650,
                  height: 80,
                  borderRadius: 10,
                  backgroundColor: "#fff",
                }}
                key={session._id}
              >
                <div
                  className="mx-2 h-100"
                  style={{ width: 5, backgroundColor: "#f68a20" }}
                ></div>
                <div className="w-75">
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "bolder",
                      color: "black",
                    }}
                  >
                    {session.sessionName} : {session.sessionDate} |{" "}
                    {session.startTime} -{session.endTime}
                  </div>
                  {/* <div>
                    {session.startTime} - {session.endTime}
                  </div> */}
                  <div>{session.sessionLocation}</div>
                </div>

                <div className="w-25 d-flex justify-content-end">
                  <ButtonSubmit
                    Title="Join Now"
                    BackgroundColor="#f68a20"
                    ColorText="white"
                    BorderColor="#f68a20"
                    borderRadius={50}
                    handleOnclick={() =>
                      handleJoinNow(session?.sessionName, session?._id)
                    }
                    pending={false}
                  />
                </div>
              </div>
            ))}
            {sessionsByDay[day]?.length > 3 && (
              <div>
                <Collapse in={expandedDays[day]}>
                  {sessionsByDay[day]?.slice(3)?.map((session) => (
                    <div
                      className="mb-2 d-flex align-items-center"
                      style={{
                        padding: 9,
                        width: 650,
                        height: 80,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                      }}
                      key={session._id}
                    >
                      <div
                        className="mx-2 h-100"
                        style={{ width: 5, backgroundColor: "#f68a20" }}
                      ></div>
                      <div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "bolder",
                            color: "black",
                          }}
                        >
                          {session.sessionName} : {session.sessionDate} |{" "}
                          {session.startTime} -{session.endTime}
                        </div>
                        {/* <div>
                    {session.startTime} - {session.endTime}
                  </div> */}
                        <div>{session.sessionLocation}</div>
                      </div>
                    </div>
                  ))}
                </Collapse>
                <div className="w-100 mt-3 d-flex justify-content-center">
                  <ButtonGradient
                    Title={expandedDays[day] ? "View Less" : "View More"}
                    leftColor="#f7983c"
                    rightColor="#ff7c6e"
                    ColorText="white"
                    BorderColor="transparent"
                    borderRadius={50}
                    handleOnclick={() => toggleViewMore(day)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </ThemeProvider>
  );
};

export default Sessions;

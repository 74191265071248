import React from "react";
import CustomHeader from "../../components/Common/CustomTopBar";
import OrganizerForm from "./components/Organizer/OrganizerForm";
// import AttendeeStepper from "./components/Attendee/AttendeeStepper";

const AttendeeReg = () => {
  document.title = "Kago Events | Organizer Registration";

  return (
    <>
      <CustomHeader />
      <OrganizerForm />
    </>
  );
};

export default AttendeeReg;

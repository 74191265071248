import React from "react";

const Header = ({ activeLink, handleNavigation, eventDetail }) => {
  return (
    <React.Fragment>
      <header className="reception-header">
        <nav className="reception-navbar">
          <ul
            className="reception-navbar__menu"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 0 ? "reception-navbar__menu--link--active" : ""
                }`}
                style={{
                  color: activeLink === 0 ? "#f68a20" : "white",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
                onClick={() => handleNavigation(0)}
              >
                Reception
              </a>
            </li>
            {eventDetail?.eventType?.includes("Conference") ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 2
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  style={{
                    color: activeLink === 2 ? "#f68a20" : "white",
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                  onClick={() => handleNavigation(2)}
                >
                  Sessions
                </a>
              </li>
            ) : null}
            {eventDetail?.eventType?.includes("Expo") ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 2
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleNavigation(2)}
                >
                  Sessions
                </a>
              </li>
            ) : null}

            {eventDetail?.eventType?.includes("Exhibition") &&
            eventDetail?.eventType?.length > 1 ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 4
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleNavigation(4)}
                >
                  Exhibition
                </a>
              </li>
            ) : null}

            {eventDetail?.eventType?.includes("Expo") && (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 4
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleNavigation(4)}
                >
                  Exhibition
                </a>
              </li>
            )}
            {/* <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 6 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleNavigation(6)}
              >
                Community Chat
              </a>
            </li> */}
            {/* <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 8 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleNavigation(8)}
              >
                Sponsors
              </a>
            </li> */}
          </ul>
        </nav>
        <div className="reception-header__extended"></div>
      </header>
    </React.Fragment>
  );
};

export default Header;

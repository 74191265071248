import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  //   ButtonDropdown,
} from "reactstrap";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import FormNotification from "./FormNotification";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import TableNotification from "./TableNotification";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import { MdOutlineNotificationsNone } from "react-icons/md";

const PushNotification = ({ eventDetails, token, userID, isLoading }) => {
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const key = ["allPushNotification", eventDetails?._id];
  // const mutation = useCustomMutation(key);
  const {
    data: getAllSMS,
    error: errorGetAllSMS,
    isLoading: isLoadingGetAllSMS,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}sms/${eventDetails?._id}`,
    token,
    {
      enabled: !!eventDetails?._id,
    }
  );

  // console.log("getAllSMS.....", getAllSMS);
  // console.log("eventDetails.....", eventDetails);
  const togglePushNotification = () => {
    setIsEmailOpen(!isEmailOpen);
  };

  return (
    <div className="w-100 px-5 py-4 ">
      <div className="w-100 mb-2  d-flex justify-content-between">
        <div className="mb-2" style={{ fontSize: 18, fontWeight: "600" }}>
          {/* Push Notification */}
        </div>
        <div>
          <ButtonIcon
            Title="New Notification"
            Icon={<MdOutlineNotificationsNone size={15} color="white" />}
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={() => togglePushNotification()}
            pending={false}
          />
        </div>
      </div>

      <div className="w-100">
        <Row>
          <Col md={12}>
            <div className="w-100 mt-2">
              <TableNotification
                userID={userID}
                token={token}
                eventID={eventDetails?._id}
                mySMS={getAllSMS?.data}
              />
            </div>
          </Col>
        </Row>
      </div>
      <OffCanvas
        isOffcanvasOpen={isEmailOpen}
        title="New Notification"
        body={
          <FormNotification
            setIsSMSOpen={setIsEmailOpen}
            userId={userID}
            token={token}
            eventID={eventDetails?._id}
            eventDetails={eventDetails}
          />
        }
        toggleSaveChanges={togglePushNotification}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default PushNotification;

import React from "react";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import { Row, Col } from "reactstrap";
import { MdOutlineDocumentScanner, MdRecommend } from "react-icons/md";
import * as XLSX from "xlsx";
// import { FaCalendarAlt, FaPersonBooth } from "react-icons/fa";
import {
  FaUser,
  FaStar,
  FaUsersViewfinder,
  FaUsersLine,
} from "react-icons/fa6";
import { GoDownload } from "react-icons/go";
import ButtonIcon from "../../Buttons/ButtonIcon";
import DayEventAttendance from "./DayEventAttendance";
import TableScannedAttendee from "./TableScannedAttendee";
import TableRegistrationList from "./TableRegistrationList";

import { exportRegisteredAttendee } from "../../../utils/exportRegisteredAttendee";

const ExpoAnalytics = ({ token, eventID }) => {
  const {
    data: getMyReports,
    error: errorGetMyReports,
    isLoading: isLoadingGetMyReports,
  } = useCustomQuery(
    ["eventRe", eventID],
    `${process.env.REACT_APP_API}reports/${eventID}`,
    token
  );

  const {
    data: getMyScannedAttendees,
    error: errorGetMyScannedAttendees,
    isLoading: isLoadingGetMyScannedAttendees,
  } = useCustomFecthQuery(
    ["eventScanned", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getRegisteredAttendees,
    error: errorGetRegisteredAttendees,
    isLoading: isLoadingGetRegisteredAttendees,
  } = useCustomFecthQuery(
    ["eventRegisteredAttendees", eventID],
    `${process.env.REACT_APP_API}attendees/${eventID}/event`,
    token,
    { enabled: !!eventID }
  );

  const handleExportScannedAttendees = () => {
    // const sheetData =
    // );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      getMyScannedAttendees?.data?.badgesPrinted?.map(
        ({
          firstName,
          lastName,
          email,
          phoneNumber,
          jobTitle,
          organization,
          type,
        }) => ({
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          PhoneNumber: phoneNumber,
          JobTitle: jobTitle,
          Organization: organization,
          Type: type,
        })
      )
    );

    XLSX.utils.book_append_sheet(workbook, worksheet, "TotalAttendance");
    XLSX.writeFile(workbook, "TotalAttendance.xlsx");
  };

  return (
    <Row>
      <Row>
        <Col md={8}>
          <Row>
            <Col sm={6} md={6}>
              <div
                className="d-flex align-items-center p-3 rounded-4"
                style={{ backgroundColor: "white", height: 120 }}
              >
                <div
                  className="d-flex align-items-center justify-content-center p-2"
                  style={{
                    backgroundColor: "#FFE7D0",
                    borderRadius: ".9em",
                    width: 65,
                    height: 65,
                  }}
                >
                  <FaUser size={30} color="#F68A20" />
                </div>
                <div className="mx-1">
                  <div style={{ fontSize: 16, fontWeight: "bolder" }}>
                    Registration
                  </div>
                  <div
                    className="w-100"
                    style={{ fontSize: 18, fontWeight: "bolder" }}
                  >
                    {getMyReports?.data?.invitedAttendees}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} md={6}>
              <div
                className="d-flex align-items-center p-3 rounded-4"
                style={{ backgroundColor: "white", height: 120 }}
              >
                <div
                  className="d-flex align-items-center justify-content-center p-2"
                  style={{
                    backgroundColor: "#d7f5f1",
                    borderRadius: ".9em",
                    width: 65,
                    height: 65,
                  }}
                >
                  <FaUsersLine size={30} color="#00B075" />
                </div>
                <div className="mx-1">
                  <div style={{ fontSize: 18, fontWeight: "bolder" }}>
                    {/* Total Check-ins */}
                    Total Attendance
                  </div>
                  <div
                    className="w-100"
                    style={{ fontSize: 18, fontWeight: "bolder" }}
                  >
                    {getMyReports?.data?.attendance}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} md={6}>
              <div
                className="mt-3 d-flex align-items-center p-3 rounded-4"
                style={{ backgroundColor: "white", height: 120 }}
              >
                <div
                  className="d-flex align-items-center justify-content-center p-2"
                  style={{
                    backgroundColor: "#FFE7D0",
                    borderRadius: ".9em",
                    width: 65,
                    height: 65,
                  }}
                >
                  <FaStar size={30} color="#F68A20" />
                </div>
                <div className="mx-1">
                  <div style={{ fontSize: 18, fontWeight: "bolder" }}>
                    {/* Total Check-ins */}
                    Average Event Rate
                  </div>
                  <div
                    className="w-100"
                    style={{ fontSize: 18, fontWeight: "bolder" }}
                  >
                    {getMyReports?.data?.eventRating}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} md={6}>
              <div
                className="mt-3 d-flex align-items-center p-3 rounded-4"
                style={{ backgroundColor: "white", height: 120 }}
              >
                <div
                  className="d-flex align-items-center justify-content-center p-2"
                  style={{
                    backgroundColor: "#d7f5f1",
                    borderRadius: ".9em",
                    width: 65,
                    minWidth: 65,
                    maxWidth: 65,
                    height: 65,
                  }}
                >
                  <MdRecommend size={30} color="#00B075" />
                </div>
                <div className="mx-1" style={{ flexWrap: "wrap" }}>
                  <div style={{ fontSize: 16, fontWeight: "bolder" }}>
                    50% would recommend this event to someone else
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <div
                className="d-flex p-3 rounded-4"
                style={{ backgroundColor: "white", height: 255 }}
              >
                <div className="mx-1">
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: "bolder",
                      color: "black",
                    }}
                  >
                    Insights
                  </div>
                  <div
                    className="w-100"
                    style={{
                      fontSize: 12,
                      fontWeight: "500",
                      textAlign: "justify",
                      marginTop: 8,
                    }}
                  >
                    The Insights tab is your gateway to understanding the
                    heartbeat of our events platform. Here, you'll delve into a
                    treasure trove of attendee feedback, thoughts, and
                    suggestions, providing invaluable insights into the pulse of
                    each event.
                  </div>
                  <div className="d-flex mt-3 justify-content-center">
                    <ButtonIcon
                      Title="Export"
                      BackgroundColor="#F68A20"
                      ColorText="white"
                      BorderColor="#F68A20"
                      borderRadius={25}
                      handleOnclick={() => {}}
                      Icon={<GoDownload size={25} color="white" />}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row className="mt-3">
        <Col md={12}>
          <div
            className="w-100 p-3 rounded-4"
            style={{ backgroundColor: "white" }}
          >
            <div className="w-100 d-flex justify-content-between aligns-item-center">
              <h4>Daily Attendance</h4>
              <ButtonIcon
                Title="Export"
                BackgroundColor="#F68A20"
                ColorText="white"
                BorderColor="#F68A20"
                borderRadius={25}
                handleOnclick={() => {}}
                Icon={<GoDownload size={25} color="white" />}
              />
            </div>
            <div className="mt-2 d-flex align-items-center">
              <DayEventAttendance />
            </div>
          </div>
        </Col>
      </Row> */}
      <Row>
        {/* <Col md={12}>
          <div
            className="w-100 mt-3  p-3 rounded-4"
            style={{ backgroundColor: "white" }}
          >
            <div className="w-100 d-flex justify-content-between aligns-item-center">
              <h4>Total Attendance</h4>
              <ButtonIcon
                Title="Export"
                BackgroundColor="#F68A20"
                ColorText="white"
                BorderColor="#F68A20"
                borderRadius={25}
                handleOnclick={handleExportScannedAttendees}
                Icon={<GoDownload size={25} color="white" />}
              />
            </div>
            <div className="w-100 d-flex align-items-center">
              <TableScannedAttendee
                scannedAttendees={getMyScannedAttendees?.data?.badgesPrinted}
              />
            </div>
          </div>
        </Col> */}
        <Col md={12}>
          <div
            className="w-100 mt-3  p-3 rounded-4"
            style={{ backgroundColor: "white" }}
          >
            <div className="w-100 d-flex justify-content-between aligns-item-center">
              <h4>Registration List</h4>
              <ButtonIcon
                Title="Export"
                BackgroundColor="#F68A20"
                ColorText="white"
                BorderColor="#F68A20"
                borderRadius={25}
                handleOnclick={() =>
                  exportRegisteredAttendee(getRegisteredAttendees?.data)
                }
                Icon={<GoDownload size={25} color="white" />}
              />
            </div>
            <div className="w-100 d-flex align-items-center">
              <TableRegistrationList
                registrationAttendees={getRegisteredAttendees?.data}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default ExpoAnalytics;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ViewAttendee from "./ViewAttendee";
import SendSMSForm from "./SendSMSForm";
import EmailForm from "./EmailForm";
import WhatsAppForm from "./WhatsAppForm";
import ModalDelete from "../../../Modal/ModalDelete";
import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineSms } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaUserLock } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
// import ReactTooltip from "react-tooltip";
import Tooltip from "../../../../utils/toolTip";

const TableAttendee = ({ token, eventID, search }) => {
  const [attendeeID, setAttendeeID] = useState(null);
  const [attendEmail, setAttendeeEmail] = useState(null);
  const [attendDetails, setAttendeeDetails] = useState(null);
  const [isToggleViewSMS, setIsToggleViewSMS] = useState(false);
  const [isToggleViewEmail, setIsToggleViewEmail] = useState(false);
  const [isToggleViewWhatsApp, setIsToggleViewWhatsApp] = useState(false);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["myAttendees", eventID];

  const mutationDelete = useCustomMutation(key);

  const {
    data: getAttendees,
    error: errorGetAttendees,
    isLoading: isLoadingGeAttendees,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}attendees/${eventID}/invited`,
    token,
    {
      enabled: !!eventID,
    }
  );

  const tog_viewParticipant = (details) => {
    setAttendeeDetails(details);
    setIsToggleView(!isToggleView);
  };
  const tog_sendSMS = (id) => {
    setAttendeeID(id);
    setIsToggleViewSMS(!isToggleViewSMS);
  };
  const tog_sendEmail = (id) => {
    setAttendeeID(id);
    setIsToggleViewEmail(!isToggleViewEmail);
  };
  const tog_sendWhatasApp = (id) => {
    setAttendeeID(id);
    setIsToggleViewWhatsApp(!isToggleViewWhatsApp);
  };
  const tog_deleteParticipant = (id, email) => {
    setAttendeeID(id);
    setAttendeeEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `attendees/${attendeeID}/?eventId=${eventID}&email=${attendEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Attendee successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">First Name</span>,
      selector: (row) => row?.firstName,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Name</span>,
      selector: (row) => row?.lastName,
    },

    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone number</span>,
      selector: (row) => row?.phoneNumber,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip text="View Attendee details">
              <IoEyeOutline
                size={18}
                className="mx-1"
                onClick={() => tog_viewParticipant(row)}
              />
            </Tooltip>

            {/* <div data-tip="Send SMS"> */}
            <Tooltip text="Send SMS">
              <MdOutlineSms
                size={16}
                className="mx-1"
                onClick={() => tog_sendSMS(row?._id)}
              />
            </Tooltip>
            {/* </div> */}
            {/* <div data-tip="Send Email to this attendee"> */}
            <Tooltip text="Send Email">
              <MdOutlineMailOutline
                size={16}
                className="mx-1"
                onClick={() => tog_sendEmail(row?._id)}
              />
            </Tooltip>
            {/* </div> */}
            {/* <div data-tip="Send WhatsApp Message to this attendee"> */}
            <Tooltip text="Send WhatsApp Message">
              <IoLogoWhatsapp
                size={16}
                className="mx-1"
                onClick={() => tog_sendWhatasApp(row?._id)}
              />
            </Tooltip>
            {/* </div> */}
            {/* <div data-tip="Suspend this Attendee"> */}
            <Tooltip text="Suspend Attendee">
              <FaUserLock size={14} className="mx-1" onClick={() => {}} />
            </Tooltip>
            {/* </div> */}
            {/* <div data-tip="Delete Participant"> */}
            <Tooltip text="Delete Attendee">
              <MdDelete
                size={16}
                color="red"
                className="mx-1"
                onClick={() => tog_deleteParticipant(row?._id, row?.email)}
              />
            </Tooltip>
            {/* </div> */}
          </div>
        );
      },
    },
  ];

  const filterParticipants = (participants, searchQuery) => {
    if (!searchQuery) return participants;
    return participants?.filter((item) =>
      [item?.firstName, item?.lastName, item?.email, item?.jobTitle].some(
        (field) => field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredAttendees = filterParticipants(getAttendees?.data, search);

  const data = filteredAttendees;
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <div className="mt-3 w-100">
      {/* <ReactTooltip effect="solid" place="top" /> */}
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Attendee"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            DeleteParticitant={DeleteParticitant}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Attendees"
        body={<ViewAttendee details={attendDetails} />}
        toggleSaveChanges={() => tog_viewParticipant()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />

      <OffCanvas
        isOffcanvasOpen={isToggleViewSMS}
        title="Send SMS"
        body={
          <SendSMSForm
            attendeeID={attendeeID}
            eventID={eventID}
            token={token}
            setIsToggleViewSMS={setIsToggleViewSMS}
          />
        }
        toggleSaveChanges={() => tog_sendSMS()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />

      <OffCanvas
        isOffcanvasOpen={isToggleViewEmail}
        title="Send Email"
        body={
          <EmailForm
            attendeeID={attendeeID}
            eventID={eventID}
            token={token}
            setIsToggleViewEmail={setIsToggleViewEmail}
          />
        }
        toggleSaveChanges={() => tog_sendEmail()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
      <OffCanvas
        isOffcanvasOpen={isToggleViewWhatsApp}
        title="Send WhatsApp"
        body={
          <WhatsAppForm
            attendeeID={attendeeID}
            eventID={eventID}
            token={token}
            setIsToggleViewWhatsApp={setIsToggleViewWhatsApp}
          />
        }
        toggleSaveChanges={() => tog_sendWhatasApp()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableAttendee;

import React from "react";
import Picture from "./Picture";
import { MdEmail, MdPhoneIphone } from "react-icons/md";
import { FaCity, FaGlobeAfrica } from "react-icons/fa";

function ShowSpeaker({ speaker, showContacts }) {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div
        style={{
          width: 400,
          borderRadius: 10,
          backgroundColor: "#fbfbfb",
          userSelect: "none",
        }}
      >
        <div className="w-100 d-flex">
          <div>
            <Picture
              showImage={speaker?.photo}
              iconSize={50}
              iconColor="#F68A20"
              imgHeight={90}
              imgWidth={90}
              imgBorder="50%"
            />
          </div>
          <div
            style={{
              height: 100,
            }}
          >
            <div
              className="w-100 mt-2 mx-3 d-flex align-items-center"
              style={{
                fontSize: 16,
                fontWeight: "700",
                color: "black",
              }}
            >
              {speaker?.title ? speaker?.title : null} {speaker?.firstName}{" "}
              {speaker?.lastName}
            </div>
            {speaker?.jobTitle ? (
              <div
                className="w-100 mx-3 d-flex align-items-center"
                style={{
                  fontSize: 13,
                  fontWeight: "700",
                  color: "#909090",
                }}
              >
                {speaker?.jobTitle}
              </div>
            ) : null}

            {speaker?.organization ? (
              <div
                className="w-100 mx-3 d-flex align-items-center"
                style={{
                  fontSize: 13,
                  fontWeight: "700",
                  color: "#F68A20",
                }}
              >
                {speaker?.organization}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-100 mt-2">
          <div className="w-100 mt-2 mb-2 d-flex ">
            <div
              style={{
                height: 0.1,
                width: 380,
                background: "#F68A20",
              }}
            ></div>
          </div>
          <div>
            {showContacts ? (
              <div className="w-100 mt-2 d-flex  align-items-center">
                <MdEmail color="black" size={16} />
                <div className="mx-1"> {speaker?.email}</div>
              </div>
            ) : null}

            {showContacts ? (
              <>
                {speaker?.phoneNumber ? (
                  <div className="w-100 mt-2 d-flex  align-items-center">
                    <MdPhoneIphone color="black" size={16} />
                    <div className="mx-1"> {speaker?.phoneNumber}</div>
                  </div>
                ) : null}
              </>
            ) : null}

            {speaker?.city ? (
              <div className="w-100 mt-2 d-flex align-items-center">
                <FaCity color="black" size={16} />
                <div className="mx-1"> {speaker?.city}</div>
              </div>
            ) : null}

            {speaker?.country ? (
              <div className="w-100 mt-2 d-flex align-items-center">
                <FaGlobeAfrica color="black" size={16} />
                <div className="mx-1"> {speaker?.country}</div>
              </div>
            ) : null}

            {speaker?.speakerBiography ? (
              <div className="w-100 mt-2 d-flex align-items-center text-justify">
                <div
                  className="mx-1 text-justify"
                  style={{ fontSize: 11, textAlign: "justify" }}
                >
                  {speaker?.speakerBiography}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowSpeaker;

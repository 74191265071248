import React, { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Spinner } from "reactstrap";
import { MdEmail } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";
import { FaFax } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

// import { useStore1Selector } from "../../../..";
// import { userdetails } from "../../../../Redux/Slices/userSlice";

// import { Link, useParams } from "react-router-dom";

const Profile = ({ eventID, boothID }) => {
  // const { eventID } = useParams();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const [ViewShedule, setViewShedule] = useState();

  const {
    data: getMyProfileBooth,
    error: errorGetMyProfileBooth,
    isLoading: isLoadingGetMyProfileBooth,
  } = useCustomQuery(
    ["myExProfile", eventID],
    `${process.env.REACT_APP_API}company-profile/booth/${boothID}`,
    token
  );

  if (isLoadingGetMyProfileBooth) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // console.log("getMyProfileBooth....", getMyProfileBooth);
  return (
    <React.Fragment>
      <div>
        <Container>
          <div className="d-flex w-100 ">
            <div className="w-25">
              <div
                style={{
                  width: "141px",
                  height: "141px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={getMyProfileBooth?.companyLogo}
                  className="w-100 h-100 rounded-2"
                />
              </div>
            </div>
            <div className="w-75">
              <div>{getMyProfileBooth?.companyName}</div>
              <div>
                <span>
                  <MdEmail color="#fff" size={14} />
                </span>
                <span className="mx-0 mt-4"></span>
                {getMyProfileBooth?.companyAddress}
              </div>
              <div>
                <span>
                  <FaGlobe color="#fff" size={14} />
                </span>
                <span className="mx-0 mt-4"></span>
                {getMyProfileBooth?.companyWebsite}
              </div>
              <div className="d-flex w-100">
                <div className="mx-0 mt-1">
                  <FaPhoneAlt color="#fff" size={14} />
                </div>
                <div className="mx-0 mt-1" style={{ marginRight: 1 }}>
                  {getMyProfileBooth?.companyPhone}
                </div>
              </div>
              <div className="d-flex w-100">
                <div className="mx-0 mt-1">
                  <FaFax color="#fff" size={14} />
                </div>
                <div className="mx-0 mt-1" style={{ marginRight: 1 }}>
                  {getMyProfileBooth?.companyFax}
                </div>
              </div>
              <div className="d-flex w-100">
                {getMyProfileBooth?.companyFacebook ? (
                  <div className="mt-1">
                    <FaFacebook color="#fff" size={14} />
                  </div>
                ) : null}
                {getMyProfileBooth?.companyInstagram ? (
                  <div className="mx-0 mt-1">
                    <FaInstagramSquare color="#fff" size={14} />
                  </div>
                ) : null}
                {getMyProfileBooth?.companyLinkdIn ? (
                  <div className="mx-0 mt-1">
                    <FaLinkedin color="#fff" size={14} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-100">
            <p>{getMyProfileBooth?.companyDescription}</p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Profile;

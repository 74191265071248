import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Card, CardBody, CardImg, CardText } from "reactstrap";
import CustomHeader from "../../components/Common/CustomTopBar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./components/RegisterAs.scss";
import hostImg from "../../assets/images/banners/register/host.png";
import AttendeeImg from "../../assets/images/banners/register/attendee.png";

const Register = () => {
  const Navigate = useNavigate();
  const [btnContinue, setBtnContinue] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  document.title = "Kago Events | Register As";

  const handleCardSelection = (cardType) => {
    setSelectedCard(cardType);
    setBtnContinue(true);
  };

  const handleContinue = () => {
    if (selectedCard) {
      const redirectPath =
        selectedCard === "host" ? "/register-organizer" : "/register-attendee";
      Navigate(redirectPath);
      // const redirectPath =
      //   selectedCard === "host" ? "/talk-to-us" : "/register-attendee";
      // Navigate(redirectPath);
    } else {
      console.log("No card selected");
    }
  };

  const cardStyle = (cardType) => ({
    paddingTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
    border: selectedCard === cardType ? "2px solid #f68a20" : "none",
    borderRadius: selectedCard === cardType ? "10px" : "none",
    transition: "margin 0.3s ease",
    cursor: "pointer",
  });

  return (
    <>
      <CustomHeader />
      <Row className="text-center">
        <h2
          style={{
            marginTop: 90,
            marginBottom: 38,
            textTransform: "capitalize",
          }}
        >
          Thank you for visiting
        </h2>
        <span
          style={{ fontSize: 16, fontWeight: 500, textTransform: "capitalize" }}
        >
          Continue your journey to
        </span>
      </Row>
      <div className="w-100 d-flex justify-content-center mt-5">
        <div
          className="HostCardRedirect"
          onClick={() => handleCardSelection("host")}
          style={cardStyle("host")}
        >
          <Card>
            <CardImg top className="img-fluid" src={hostImg} alt="Host" />
            <CardBody>
              <CardText>
                <div className="d-flex justify-content-center">
                  <span className="Title">Host An Event</span>
                </div>
              </CardText>
            </CardBody>
          </Card>
        </div>

        <div
          className="HostCardRedirect"
          onClick={() => handleCardSelection("attendee")}
          style={cardStyle("attendee")}
        >
          <Card>
            <CardImg
              top
              className="img-fluid"
              src={AttendeeImg}
              alt="Attendee"
            />
            <CardBody>
              <CardText>
                <div className="d-flex justify-content-center">
                  <span className="Title">Attend An Event</span>
                </div>
              </CardText>
            </CardBody>
          </Card>
        </div>
      </div>

      <Row className="text-center" style={{ marginTop: 12 }}>
        <div>
          <button
            style={{
              horizoAlign: "center",
              width: "520px",
              color: btnContinue ? "#fff" : "#495057",
              fontSize: 16,
              fontWeight: 500,
              border: "1px solid rgba(73,80,87,.2)",
              padding: "11px 20px",
              borderRadius: 4,
              background: btnContinue
                ? "#f68a20"
                : "linear-gradient(to right bottom, rgb(190,190,190), rgb(184,184,184))",
              cursor: btnContinue ? "pointer" : "not-allowed",
            }}
            onClick={handleContinue}
            disabled={!btnContinue}
          >
            Continue
          </button>
        </div>
        <p style={{ marginTop: 16 }}>
          <span>{AiOutlineArrowLeft}</span>
          Already have an account ?
          <a
            href="/login"
            className="fw-medium text-primary"
            style={{
              color: "rgba(38,166,128,1)",
              display: "block",
              textAlign: "center",
            }}
          >
            Login
          </a>
        </p>
      </Row>
    </>
  );
};

export default Register;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import "../../../assets/scss/custom/pages/_thankYou.scss";
import kagoBanner from "../../../assets/images/kago_logo_placeholder.png"; 

const DemoResponse = () => {
  return (
    <div className="thank-you-page">
      <div className="thank-you-content">
        <h2>Thank You for Requesting a Demo!</h2>
        <p>
          Our team will be in touch with you shortly. In the meantime, feel free
          to explore more of our services and offerings.
        </p>
      </div>

      {/* Side-by-side section */}
      <div className="side-by-side-blocks">
        <div className="block">
          <h3>Need Assistance?</h3>
          <p>
            For any further questions, feel free to email us at{" "}
            <a href="mailto:support@kago.digital" className="email-highlight">
              support@kago.digital
            </a>
            . Our support team is ready to assist you.
          </p>
          <p>
            Explore our{" "}
            <a
              href="https://kago.digital"
              target="_blank"
              rel="noopener noreferrer"
              className="email-highlight"
            >
              Help Center
            </a>{" "}
            for FAQs and guides on how to make the most of our platform.
          </p>
        </div>

        <div className="block">
          <h3>Stay Connected</h3>
          {/* Add image before social media info */}
          <img
            src={kagoBanner} 
            alt="Kago Banner"
            className="kago-banner-image"
          />
          <p>Follow us on social media to get the latest updates and tips:</p>
          <div className="social-links">
            <a
              href="https://linkedin.com/company/kago-digital/"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={24} /> {/* LinkedIn icon */}
            </a>
            <a
              href="https://kago.digital"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>🌐</span> {/* Website icon */}
            </a>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="authentication-brandMark">
        <Link
          to="https://www.tngsolutions.co.za/"
          target="_blank"
          rel="noopener noreferrer"
          className="brand-mark text-dark"
        >
          By TNG Solutions | Developed in South Africa
        </Link>
      </div>
    </div>
  );
};

DemoResponse.propTypes = {
  history: PropTypes.object,
};

export default DemoResponse;

import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import ButtonSubmit from "../../../../Buttons/ButtonSubmit";

const WithdrawalCard = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader style={{ backgroundColor: "#f68a20" }}>
          <div style={{ fontWeight: "bold", fontSize: 18, color: "#fff" }}>
            Balance
          </div>
        </CardHeader>
        <CardBody>
          <div className="w-100 d-flex justify-content-between align-items-center rounded-4">
            <div>
              <div style={{ fontWeight: "bold", fontSize: 15 }}>
                Your Balance :{" "}
                <span
                  style={{ fontWeight: "bolder", color: "#000", fontSize: 16 }}
                >
                  R 10000
                </span>
              </div>
              <div style={{ fontWeight: "bold", fontSize: 15 }}>
                Withdraw Threshold :{" "}
                <span
                  style={{ fontWeight: "bolder", color: "#000", fontSize: 16 }}
                >
                  20 days
                </span>
              </div>
            </div>
            <div>
              <ButtonSubmit
                Title="Request Withdrawal"
                BackgroundColor="#f68a20"
                ColorText="#fff"
                BorderColor="#f68a20"
                borderRadius={10}
                handleOnclick={() => {}}
                pending={false}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WithdrawalCard;

import React from "react";
import DataTable from "react-data-table-component";

const TableRegistrationList = ({ registrationAttendees }) => {
  // console.log("scannedAttendees....", registrationAttendees);
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">First Name</span>,
      selector: (row) => row?.firstName,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Name</span>,
      selector: (row) => row?.lastName,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone Number</span>,
      selector: (row) => row?.phoneNumber,
    },
    {
      name: <span className="font-weight-bold fs-13">Type</span>,
      selector: (row) => row?.roles,
    },
  ];
  const data = registrationAttendees;
  return (
    <div className="mt-3 w-100">
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="300px"
        columns={columns}
        responsive
        data={data}
        pagination
      />
    </div>
  );
};

export default TableRegistrationList;

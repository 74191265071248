import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import { Tabs, Tab, Box } from "@mui/material";
import HeaderNavigation from "../../Layout/HorizontalLayout/CommunityHeader";
import Footer from "../../Layout/HorizontalLayout/Footer";
import Events from "../../components/Community/Events";
import CommunityInfo from "../../components/Community/CommunityInfo";
import Members from "../../components/Community/Members";
import Settings from "../../components/Community/Settings";
import Analystics from "../../components/Community/Analystics";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});

// Helper function to customize Tab label
const formatLabel = (label) =>
  `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

function MyCommunity({}) {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.data?.id;
  const userEmail = obj?.data?.data?.data?.email;
  const userRole = obj?.data?.data?.data?.roles;
  const token = obj?.data?.token;
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState("1");
  // const [companyLogo, setCompanyLogo] = useState(null);

  const communityID = params.communityID;
  // const communityName = params.communityName;
  // const myBoothName = boothName.replace(/-/g, " ");

  const {
    data: getCommunityDetails,
    error: errorGetCommunities,
    isLoading: isLoadingGetCommunityDetails,
  } = useCustomFecthQuery(
    ["CommunityDetails", communityID],
    `${process.env.REACT_APP_API}communities/${communityID}`,
    token,
    { enabled: !!communityID }
  );

  // console.log(
  //   "getCommunityDetails...",
  //   JSON.stringify(getCommunityDetails, null, 2)
  // );

  const {
    data: getBranding,
    error: errorGetBranding,
    isLoading: isLoadingGetBranding,
  } = useCustomFecthQuery(
    ["CommunityBranding,", communityID],
    `${process.env.REACT_APP_API}communities/brand/${communityID}`,
    token,
    { enabled: !!communityID }
  );
  // Community Manager Admin
  const isLogo =
    getBranding?.data?.length === 0 && getBranding?.status === "ok";

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {}, []);

  const canEditCommunity = () => {
    // Check if user's email is found in the members array with role "Admin" or "Event Manager"
    return getCommunityDetails?.data?.members?.some(
      (member) =>
        member?.email === userEmail &&
        (member?.role === "Admin" || member?.role === "Community Manager")
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderNavigation
        // logoImg={isLogo ? null : getBranding?.data}
        communityID={communityID}
      />
      <Box sx={{ backgroundColor: "#282e3f", mt: 0, p: 4 }} />
      <Box sx={{ backgroundColor: "white", width: "100%" }}>
        <div className="d-flex justify-content-center pt-3">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="community navigation tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab value="1" label={formatLabel("Events")} />
            <Tab value="2" label={formatLabel("Community Info")} />
            {canEditCommunity() && (
              <Tab value="3" label={formatLabel("Members")} />
            )}
            {canEditCommunity() && (
              <Tab value="4" label={formatLabel("Settings")} />
            )}

            {/* <Tab value="5" label={formatLabel("Analytics")} /> */}
          </Tabs>
        </div>
      </Box>
      {selectedTab === "1" && (
        <Events
          communityID={communityID}
          getCommunityDetails={getCommunityDetails?.data}
          userEmail={userEmail}
          userRole={userRole}
          token={token}
        />
      )}
      {selectedTab === "2" && (
        <CommunityInfo
          userRole={userRole}
          token={token}
          getCommunityDetails={getCommunityDetails?.data}
        />
      )}
      {selectedTab === "3" && (
        <Members userRole={userRole} communityID={communityID} token={token} />
      )}
      {selectedTab === "4" && (
        <Settings
          userRole={userRole}
          token={token}
          communityID={communityID}
          getCommunityDetails={getCommunityDetails?.data}
          userEmail={userEmail}
        />
      )}
      {selectedTab === "5" && <Analystics userRole={userRole} token={token} />}
      <Footer />
    </ThemeProvider>
  );
}

export default MyCommunity;

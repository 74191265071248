import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import AssignSpeaker from "./AssignSpeaker";
import AssignSessionHost from "./AssignSessionHost";
import "./AssignUser.css";

function AssignUser({
  userID,
  token,
  eventDetails,
  eventID,
  sessionID,
  setIsOffcanvasOpen,
}) {
  const [selectedRole, setSelectedRole] = useState("host");

  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };

  const buttonStyles = (role) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "25px",
    height: "25px",
    border: `2px solid ${selectedRole === role ? "#f68a20" : "#000"}  `,
    color: selectedRole === role ? "#fff" : "#000",
    borderRadius: "25px",
    cursor: "pointer",
  });

  const InnerbuttonStyles = (role) => ({
    width: "15px",
    height: "15px",
    backgroundColor: selectedRole === role ? "#f68a20" : "#fff",
    color: selectedRole === role ? "#fff" : "#000",
    cursor: "pointer",
    borderRadius: "50%",
  });

  return (
    <div className="w-100">
      <div
        className="w-100 d-flex justify-content-center"
        style={{ userSelect: "none" }}
      >
        <div className="d-flex justify-content-center gap-3 align-items-center mb-3">
          <div className="d-flex justify-content-center gap-1 align-items-center">
            <div
              style={buttonStyles("host")}
              onClick={() => handleRoleChange("host")}
            >
              <div
                style={InnerbuttonStyles("host")}
                onClick={() => handleRoleChange("host")}
              ></div>
            </div>
            <div
              style={{
                fontWeight: selectedRole === "host" ? "bold" : "normal",
                color: selectedRole === "host" ? "#f68a20" : "#000",
              }}
            >
              Assign as Host
            </div>
          </div>
          <div className="d-flex justify-content-center gap-1 align-items-center">
            <div
              style={buttonStyles("speaker")}
              onClick={() => handleRoleChange("speaker")}
            >
              <div
                style={InnerbuttonStyles("speaker")}
                onClick={() => handleRoleChange("speaker")}
              ></div>
            </div>
            <div
              style={{
                fontWeight: selectedRole === "speaker" ? "bold" : "normal",
                color: selectedRole === "speaker" ? "#f68a20" : "#000",
              }}
            >
              Assign as Speaker
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex justify-content-center">
        {selectedRole === "host" ? (
          <AssignSessionHost
            token={token}
            eventDetails={eventDetails}
            eventID={eventID}
            sessionID={sessionID}
            setIsOffcanvasOpen={setIsOffcanvasOpen}
          />
        ) : null}
        {selectedRole === "speaker" ? (
          <AssignSpeaker
            token={token}
            eventID={eventID}
            sessionID={sessionID}
            setIsOffcanvasOpen={setIsOffcanvasOpen}
          />
        ) : null}
      </div>

      <Row>
        <Col md={12}></Col>
      </Row>
    </div>
  );
}

export default AssignUser;

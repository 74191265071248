import React, { useState, useEffect } from "react";
import useCustomMutation from "../../Hooks/useCustomMutation";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../Buttons/ButtonSubmit";

const EditFaq = ({ eventID, faqID, token, toggleEditFAQ }) => {
  const key = ["EventDetails", eventID];
  const keyFaq = ["faqDetails", faqID];
  const [inputs, setInputs] = useState({});
  const mutation = useCustomMutation(key);

  const {
    data: getFaq,
    error: errorGetFaq,
    isLoading: isLoadingGetFaq,
  } = useCustomFecthQuery(
    keyFaq,
    `${process.env.REACT_APP_API}faq/${faqID}`,
    token,
    { enabled: !!faqID }
  );

  // console.log("....getFaq....", getFaq);
  // console.log("....faqDetails....", faqDetails);
  // console.log("....inputs....faq....", faqDetails);

  const handleEditFaq = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `faq/${getFaq?.data?._id}`,
      isJSON = true;
    const raw = JSON.stringify({
      question: inputs?.question,
      answer: inputs?.answer,
    });
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "FAQ successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      toggleEditFAQ();
    }
    if (getFaq?.status === "ok") {
      setInputs(getFaq?.data);
    }
  }, [mutation?.isSuccess, getFaq?.status]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  return (
    <div>
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={handleEditFaq}
      >
        <Row>
          <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Question
            </label>

            <textarea
              name="question"
              value={inputs?.question}
              onChange={handleChange}
              placeholder="type your question here..."
              className="mb-3 form-control fromInput"
              rows={3}
              required
            ></textarea>
          </Col>
          <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Answer
            </label>
            <textarea
              name="answer"
              value={inputs?.answer}
              onChange={handleChange}
              placeholder="Write here..."
              className="mb-3 form-control fromInput"
              rows={4}
              required
            ></textarea>
          </Col>
          <div className="w-100 MT-2 d-flex justify-content-center">
            <ButtonSubmit
              Title="Edit FAQ"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={handleEditFaq}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default EditFaq;

const readDate = (isoDate) => {
  // convert  data from "2024-08-05T22:00:00.000Z" to 2024-08-05
  if (!isoDate) return "";
  const date = new Date(isoDate);
  if (isNaN(date.getTime())) return "";
  return date.toISOString().split("T")[0];
  // return new Date(isoDate)?.toISOString()?.split("T")[0];
};

export default readDate;

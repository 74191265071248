import React, { useState, useEffect } from "react";
// import useCustomMutation from "../../Hooks/useCustomMutation";
import BannerCommunity from "./BannerCommunity";

const CommunityDetails = ({
  getCommunityDetails,
  communityID,
  inputs,
  setInputs,
  setCommunityBannerSever,
}) => {
  //   const key = ["CommunityDetails", communityID];
  //   const mutation = useCustomMutation(key);
  const [communityBanner, setCommunityBanner] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  useEffect(() => {
    if (getCommunityDetails) {
      const { banner, communityName, communityDescription } =
        getCommunityDetails;
      setInputs({
        banner,
        communityName,
        communityDescription,
      });
      setCommunityBanner(banner);
    }
  }, [getCommunityDetails]);

  // console.log("getCommunityDetails...", getCommunityDetails);

  return (
    <div className="w-100 d-flex">
      <div className="" style={{ width: "30%" }}>
        <div>
          <p style={{ fontSize: 14, fontWeight: "600", color: "black" }}>
            Community home banner
          </p>
          <p
            style={{
              fontSize: 11,
              fontWeight: "400",
              textAlign: "justify",
            }}
          >
            The banner is displayed on your Community home page above your event
            list. Make sure to personalise it with your information.Import a
            rectangular image (4:1 ratio), size of 1024x256px and no larger than
            2MB is perfect.
          </p>
        </div>
      </div>
      <div className="mx-2" style={{ width: "70%" }}>
        <div>Community Details</div>
        <BannerCommunity
          communityBanner={communityBanner}
          setCommunityBanner={setCommunityBanner}
          setCommunityBannerSever={setCommunityBannerSever}
        />
        <label>Community Name </label>
        <input
          type="text"
          name="communityName"
          value={inputs?.communityName}
          onChange={handleChange}
          // onChange={handleChange}
          style={{ width: 400, borderRadius: 7 }}
          className="mb-3 form-control fromInput"
          required
        />
        <label className="event__input__form__Creation">
          Community Description
          <textarea
            name="communityDescription"
            value={inputs?.communityDescription}
            onChange={handleChange}
            style={{ width: 400, borderRadius: 7 }}
            className="mt-1 form-control fromInput"
            rows={5}
            required
          ></textarea>
        </label>
      </div>
    </div>
  );
};

export default CommunityDetails;

import React, { useState, useEffect } from "react";
import SwitchButton from "../../../../components/Buttons/SwitchButton";

import usePost from "../../../../Hooks/usePost";
import { Spinner, Row } from "reactstrap";

function DeleteAccount({ userId, token }) {
  const { execute, pending, data, status } = usePost();
  const [isOn, setIsOn] = useState(false);
  //   const userId = userDetails?.id;

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "DELETE",
      url = `users/${userId}`;
    const raw = null;

    execute(url, raw, Method, "Account Successfully Deleted", token);
  };

  useEffect(() => {
    if (status === "success") {
      const user = data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authEventKagoUser", JSON.stringify(user));
      }
    }
  }, [status]);

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <div className="d-flex justify-content-between">
            <div>
              <p>
                Once you delete your account, there is no going back. Please be
                certain.
              </p>
              <div className="d-flex">
                <SwitchButton isOn={isOn} handleToggle={() => toggleSwitch()} />

                <div className="mx-2">
                  <h5>Confirm</h5>
                  <p>I want to delete my account.</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-end">
              <div className="">
                <button
                  className={`btn ${
                    isOn ? "btn-danger rounded-5" : "btn-secondary rounded-5"
                  }`}
                  type="submit"
                  disabled={!isOn}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    {!pending ? <span>Delete Account</span> : null}
                    {!pending ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default DeleteAccount;

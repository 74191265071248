import React, { useState } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import ButtonSubmit from "../../../../Buttons/ButtonSubmit";
import OffCanvas from "../../../../OffCanvas/OffCanvas";
import AddPayment from "./AddPayment";
import EditdPayment from "./EditPayment";

const PaymentDetails = ({ userID, token, eventID }) => {
  const [isAddPaymentOffcanvasOpen, setIsAddPaymentOffcanvasOpen] =
    useState(false);
  const [isEditPaymentOffcanvasOpen, setIsEditPaymentOffcanvasOpen] =
    useState(false);
  const toggleAddPayment = () => {
    setIsAddPaymentOffcanvasOpen(!isAddPaymentOffcanvasOpen);
  };
  const toggleEditPayment = () => {
    setIsEditPaymentOffcanvasOpen(!isEditPaymentOffcanvasOpen);
  };
  return (
    <React.Fragment>
      <Card>
        <CardHeader style={{ backgroundColor: "#f68a20" }}>
          <div style={{ fontWeight: "bold", fontSize: 18, color: "#fff" }}>
            Payment Details
          </div>
        </CardHeader>
        <CardBody>
          <div
            className="w-100 d-flex justify-content-between align-items-center rounded-4"
            style={{ backgroundColor: "white" }}
          >
            <div className="w-100">
              <div className="w-100 d-flex justify-content-between">
                <div
                  className="w-75"
                  style={{ fontWeight: "bold", fontSize: 14 }}
                >
                  <Row>
                    <Col md={6}>
                      Account Holder Name :{" "}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Jed Kazadi
                      </span>
                    </Col>
                    <Col md={6}>
                      Bank Name :{" "}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        FNB
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      Account Number :{" "}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        *********242
                      </span>
                    </Col>
                    <Col md={6}>
                      Account Type :{" "}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Cheque
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      Branch Code :{" "}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        25555
                      </span>
                    </Col>
                    <Col md={6}>
                      Reference :{" "}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Beyond Vitiligo Conference
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <span style={{ fontWeight: "lighter", color: "red" }}>
                        Payment hasn't been made to this account yet.
                      </span>
                    </Col>
                  </Row>
                </div>

                {/* <div>
                  <ButtonSubmit
                    Title="Add Payment"
                    BackgroundColor="#f68a20"
                    ColorText="#fff"
                    BorderColor="#f68a20"
                    borderRadius={10}
                    handleOnclick={() => toggleAddPayment()}
                    pending={false}
                  />
                </div> */}
                <div>
                  <ButtonSubmit
                    Title="Edit Payment"
                    BackgroundColor="#f68a20"
                    ColorText="#fff"
                    BorderColor="#f68a20"
                    borderRadius={10}
                    handleOnclick={() => toggleEditPayment()}
                    pending={false}
                  />
                </div>
              </div>
              <div className="w-100"></div>
            </div>
            <div></div>
          </div>
        </CardBody>
      </Card>
      <OffCanvas
        isOffcanvasOpen={isAddPaymentOffcanvasOpen}
        title="Add Payment Details"
        body={
          <AddPayment
            eventID={eventID}
            token={token}
            toggleAddPayment={toggleAddPayment}
          />
        }
        toggleSaveChanges={toggleAddPayment}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
      <OffCanvas
        isOffcanvasOpen={isEditPaymentOffcanvasOpen}
        title="Edit Payment Details"
        body={
          <EditdPayment
            eventID={eventID}
            token={token}
            toggleAddPayment={toggleEditPayment}
          />
        }
        toggleSaveChanges={toggleEditPayment}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </React.Fragment>
  );
};
export default PaymentDetails;

import React from "react";
import LogoImg from "../../assets/images/Kago-main-logo.jpeg";

const Logo = () => {
  return (
    <div className="login-form-logo-size">
      <img src={LogoImg} className="w-100 h-100" />
    </div>
  );
};

export default Logo;

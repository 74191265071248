import React, { useRef, useState, useCallback, useEffect } from "react";
import { Spinner } from "reactstrap";
import { FaPlus } from "react-icons/fa";
import Cropper from "react-easy-crop";
import useCustomMutation from "../../Hooks/useCustomMutation";
import ModalImage from "../Modal/ModalImage";
import ImagePlaceHolder from "../../assets/images/placeholder/ImagePlaceHolder.png";

const AddCompanyLogo = ({ communityID, token }) => {
  const [imageURL, setImageURL] = useState(ImagePlaceHolder);
  const [imageServer, setImageServer] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [isImage, setIsImage] = useState(false);
  const [showAddIcon, setShowAddIcon] = useState(true);

  // const key = ["communityBranding", communityID];
  const key = ["CommunityDetails", communityID];
  const mutation = useCustomMutation(key);

  const fileInputRef = useRef(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showSaveImage = () => {
    const Method = "POST",
      url = `communities/brand/${communityID}`,
      isJSON = true;
    const formdata = new FormData();
    if (imageServer) {
      formdata.append("logo", imageServer);
    }
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Company Logo successfully created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
    // setIsImage(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageServer(file);
      const reader = new FileReader();
      reader.onloadstart = () => {
        // You could also implement a loading state here to give feedback to the user
      };
      reader.onload = (e) => {
        setImageURL(e.target.result);
        setShowAddIcon(false);
        setIsImage(true); // Open modal after image is loaded
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const closeModal = () => {
    setImageURL(ImagePlaceHolder);
    setImageServer(false);
    setShowAddIcon(true);
    setIsImage(false);
  };

  const cancelModal = () => {
    setImageURL(ImagePlaceHolder);
    setImageServer(false);
    setIsImage(false);
    setShowAddIcon(true);
  };
  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsImage(false);
    }
  }, [mutation?.isSuccess]);
  return (
    <div className="d-flex" style={{ width: "68%" }}>
      <div className="" style={{ width: "47%" }}>
        <div>
          <p style={{ fontSize: 14, fontWeight: "600", color: "black" }}>
            Basics
          </p>
          <p
            style={{
              fontSize: 11,
              fontWeight: "400",
              textAlign: "justify",
            }}
          >
            For the community logo, we recommend using at least a 200x200px (2:1
            ratio) image, no larger than 1MB.
          </p>
        </div>
      </div>
      <div>
        <div>Logo</div>
        <div className="" style={{ width: "35%" }}>
          <div
            style={{
              width: 200,
              height: 120,
              position: "relative",
              userSelect: "none",
            }}
            onClick={handleDivClick}
          >
            <img
              src={imageURL}
              className="w-100 h-100 rounded-3"
              alt="Company logo"
              style={{
                position: "absolute",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#ececec",
              }}
            />
            {showAddIcon && (
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "40%",
                  backgroundColor: "white",
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                  cursor: "pointer",
                }}
              >
                <FaPlus size={20} color="black" />
              </div>
            )}

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />
          </div>
          {isImage && (
            <ModalImage
              size="md"
              isOpen={isImage}
              toggle={closeModal}
              title="Upload Logo"
              body={
                <>
                  <div
                    style={{ width: "100%", height: 300, position: "relative" }}
                  >
                    <Cropper
                      image={imageURL}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: 20,
                    }}
                  >
                    <button
                      onClick={cancelModal}
                      className="btn btn-danger rounded-5"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={showSaveImage}
                      className="btn btn-primary rounded-5"
                    >
                      {mutation?.isLoading ? (
                        <span>
                          <Spinner as="span" animation="border" size="sm" />{" "}
                          Loading
                        </span>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCompanyLogo;

import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { detectDeviceType } from "../../utils/deviceType";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import Header from "../../components/Common/EventLanding/One/Header";
import About from "../../components/Common/EventRegistration/One/About";
import RegisterNow from "../../components/Common/EventRegistration/One/RegisterNow";
import CustomFooter from "../../Layout/HorizontalLayout/CustomFooter";
const TicketPaymentConfirmation = () => {
  const deviceType = detectDeviceType();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({
    name_first: "",
    name_last: "",
    email: "",
    phone: "",
    eventID: "",
  });

  useEffect(() => {
    // Create an instance of URLSearchParams using the current location's search
    const queryParams = new URLSearchParams(location.search);

    // Extract values from the query parameters and decode them
    const name_first = decodeURIComponent(queryParams.get("name_first") || "");
    const name_last = decodeURIComponent(queryParams.get("name_last") || "");
    const email = decodeURIComponent(queryParams.get("email") || "");
    const phone = decodeURIComponent(queryParams.get("phone") || "");
    const eventID = decodeURIComponent(queryParams.get("ev") || "");

    // Update state with the decoded values
    setUserDetails({
      name_first,
      name_last,
      email,
      phone,
      eventID,
    });
  }, [location.search]); // Dependency array includes location.search to trigger on change

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomFecthQuery(
    ["myPublicEventDetail", userDetails?.eventID],
    `${process.env.REACT_APP_API}guest/events/${userDetails?.eventID}`,
    null,
    { enabled: !!userDetails?.eventID }
  );

  return (
    <>
      <div className="w-100">
        <Header
          eventDetails={getMyEventDetail}
          deviceType={deviceType}
          isBtnShow={false}
        />
        <div className="w-100">
          <SimpleBar style={{ height: "83vh" }}>
            <div
              className="w-100 mt-2"
              style={{
                position: "relative",
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={getMyEventDetail?.data?.bannerImage}
                alt="Event Banner"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="w-100 pt-3 pb-3">
              <About eventDetails={getMyEventDetail?.data} />
            </div>

            <div className="mt-4">
              <RegisterNow
                eventID={userDetails?.eventID}
                firstName={userDetails.name_first}
                lastName={userDetails.name_last}
                email={userDetails.email}
                phone={userDetails.phone}
              />
            </div>
          </SimpleBar>
        </div>
      </div>
      <div className="w-100">
        <CustomFooter />
      </div>
    </>
  );
};

export default TicketPaymentConfirmation;

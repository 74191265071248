import React from "react";
import { Container } from "reactstrap";
import SelectOption from "./Components/SelectOption";

const Index = () => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.data?.id;
  const token = obj?.data?.token;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <SelectOption userId={userId} token={token} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ViewSponsor from "./ViewSponsor";
import EditSponsor from "./EditSponsor";
import ModalDelete from "../../../Modal/ModalDelete";
import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import { FaEdit } from "react-icons/fa";
import { AiFillEye, AiFillDelete } from "react-icons/ai";

const TableSponsor = ({ userID, token, eventID, search }) => {
  const [sponsorID, setSponsorID] = useState(false);
  const [sponsorDetails, setSponsorDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isToggleEdit, setIsToggleEdit] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["mySponsors", eventID];

  const mutationDelete = useCustomMutation(key);

  const {
    data: getSponsors,
    error: errorGetSponsors,
    isLoading: isLoadingGetSponsors,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token,
    {
      enabled: !!eventID,
    }
  );

  const tog_viewSponsor = (details) => {
    setSponsorDetails(details);
    setIsToggleView(!isToggleView);
  };
  const tog_editSponsor = (details) => {
    setSponsorDetails(details);
    setIsToggleEdit(!isToggleEdit);
  };
  const tog_deleteSponsor = (id, email) => {
    setSponsorID(id);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteSponsor = () => {
    const Method = "DELETE",
      url = `sponsors/${sponsorID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Sponsor successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 80, height: 80, borderRadius: 10 }}>
              <img
                src={row?.logo}
                alt="sponsor-image"
                className="w-100 h-100"
              />
            </div>
          </div>
        );
      },
    },
    ,
    {
      name: <span className="font-weight-bold fs-13">Sponsor Name</span>,
      selector: (row) => row?.sponsorName,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        // logo;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewSponsor(row)}
              />
            </div>
            <div>
              <FaEdit
                size={18}
                className="mx-1"
                onClick={() => tog_editSponsor(row)}
              />
            </div>

            <div>
              <AiFillDelete
                size={20}
                onClick={() => tog_deleteSponsor(row?._id)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterParticipants = (sponsors, searchQuery) => {
    if (!searchQuery) return sponsors;
    return sponsors?.filter((item) =>
      [item?.sponsorName].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredSponsors = filterParticipants(getSponsors?.data, search);

  const data = filteredSponsors;
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);

  // console.log("getSponsors....", getSponsors);

  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Sponsor"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            DeleteParticitant={DeleteSponsor}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Sponsor"
        body={<ViewSponsor details={sponsorDetails} />}
        toggleSaveChanges={() => tog_viewSponsor()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
      <OffCanvas
        isOffcanvasOpen={isToggleEdit}
        title="Edit Sponsor"
        body={
          <EditSponsor
            details={sponsorDetails}
            setIsOffcanvasOpen={setIsToggleEdit}
            eventID={eventID}
            token={token}
          />
        }
        toggleSaveChanges={() => tog_editSponsor()}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default TableSponsor;

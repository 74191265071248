import React from "react";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import Divider from "./Divider";
import "./sponsorsMarquee.scss";

import Marquee from "react-fast-marquee";

const Sponsors = ({ token, eventID }) => {
  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomFecthQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMyBooths,
    error: errorGetMyBooths,
    isLoading: isLoadingGetMyBooths,
  } = useCustomFecthQuery(
    ["myBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  if (getMySponsors?.status === "ok" && getMySponsors?.data?.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <h1
        id="sponsors"
        className="d-flex justify-content-center"
        style={{ color: "black", fontWeight: "bold" }}
      >
        Official Sponsors
      </h1>
      <Divider />
      <div className="w-100 mt-5 d-flex justify-content-center">
        <div className="w-50 d-flex justify-content-center">
          <Marquee autoFill={true} speed={50} loop={0}>
            {[
              ...(Array.isArray(getMySponsors?.data) ? getMySponsors.data : []),
              ...(Array.isArray(getMyBooths?.data) ? getMyBooths.data : []),
            ].map((sponsor) => (
              <div className="mx-2" style={{ width: 70, height: 60 }}>
                <img
                  src={sponsor?.logo}
                  alt="sponsor-image"
                  className="w-100 h-100"
                />
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sponsors;

import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";

import { useNavigate } from "react-router-dom";

import usePost from "../../../../Hooks/usePost";

import "./AttendeeForm.scss";
import SuccessMessage from "./SuccessMessage";

const OrganizerForm = () => {
  const { execute, pending, data } = usePost();

  const Navigate = useNavigate();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `auth/attendee`,
      isJSON = true;
    var formdata = new FormData();
    // formdata.append("bannerImage", BannerServer);
    formdata.append("firstName", inputs.firstName);
    formdata.append("lastName", inputs.lastName);
    formdata.append("email", inputs.email);
    formdata.append("password", inputs.password);
    formdata.append("passwordConfirm", inputs.passwordConfirm);
    execute(url, formdata, Method, "Registered successfully", null, isJSON);
  };

  useEffect(() => {
    // if (data?.status === "success") {
    //   Navigate("/login");
    // }
  }, [data?.status]);

  const handleMessage = () => {
    Navigate("/login");
  };
  return (
    <>
      <div className="page-content">
        <div className="form-container">
          {/* {data?.status === "success" ? (
            <p className="w-80 mt-3 h5 text-success d-flex justify-content-center align-items-center border border-success">
              You have successfully registered, please verify your email !!!
            </p>
          ) : null} */}
          {data?.status === "success" ? (
            <SuccessMessage handleMessage={handleMessage} />
          ) : (
            <form className="form" onSubmit={handleSubmitForm}>
              <h1>Complete your profile</h1>
              <div className="mt-0">
                <div className="booth__body--form-input-container field-col-half">
                  <label
                    htmlFor="firstName"
                    className="booth__body--form-input-container__label"
                  >
                    <p
                      className="input-heading"
                      style={{ marginBottom: "4px" }}
                    >
                      First Name *
                    </p>
                  </label>
                  <div className="single-attendee__body--form-input-container gzIdbH">
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      required
                      placeholder="Enter first name"
                      className="single-attendee__body--form-input-container-field"
                      onFocus={(e) => e.target.classList.add("active")}
                      onBlur={(e) =>
                        e.target.value === "" &&
                        e.target.classList.remove("active")
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="booth__body--form-input-container field-col-half">
                  <label
                    htmlFor="lastName"
                    className="booth__body--form-input-container__label"
                  >
                    <p
                      className="input-heading"
                      style={{ marginBottom: "4px" }}
                    >
                      Last Name *
                    </p>
                  </label>
                  <div className="single-attendee__body--form-input-container gzIdbH">
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      required
                      placeholder="Enter last name"
                      className="single-attendee__body--form-input-container-field"
                      onFocus={(e) => e.target.classList.add("active")}
                      onBlur={(e) =>
                        e.target.value === "" &&
                        e.target.classList.remove("active")
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="booth__body--form-input-container">
                  <label
                    htmlFor="email"
                    className="booth__body--form-input-container__label"
                  >
                    <p
                      className="input-heading"
                      style={{ marginBottom: "4px" }}
                    >
                      Email Address *
                    </p>
                    <p className="password-description">
                      The email address where you can receive invitations and
                      other updates
                    </p>
                  </label>
                  <div className="single-attendee__body--form-input-container gzIdbH">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      placeholder="Enter email"
                      className="single-attendee__body--form-input-container-field"
                      onFocus={(e) => e.target.classList.add("active")}
                      onBlur={(e) =>
                        e.target.value === "" &&
                        e.target.classList.remove("active")
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="booth__body--form-input-container">
                  <label
                    htmlFor="password"
                    className="booth__body--form-input-container__label"
                  >
                    <p
                      className="input-heading"
                      style={{ marginBottom: "4px" }}
                    >
                      Create password *
                    </p>
                    <p className="password-description">
                      At least 8 character with upper, lower case, letter,
                      numbers, symbols
                    </p>
                  </label>
                  <div className="single-attendee__body--form-input-container gzIdbH">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      placeholder="Enter password"
                      className="single-attendee__body--form-input-container-field"
                      onFocus={(e) => e.target.classList.add("active")}
                      onBlur={(e) =>
                        e.target.value === "" &&
                        e.target.classList.remove("active")
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="single-attendee__body--form-input-container gzIdbH">
                    <input
                      id="passwordConfirm"
                      name="passwordConfirm"
                      type="password"
                      required
                      placeholder="Confirm Password"
                      className="single-attendee__body--form-input-container-field"
                      onFocus={(e) => e.target.classList.add("active")}
                      onBlur={(e) =>
                        e.target.value === "" &&
                        e.target.classList.remove("active")
                      }
                      onChange={handleChange}
                    />
                  </div>
                  {/*  */}
                </div>
                <button
                  className="btn-continue"
                  type="submit"
                  id="logInAttempt"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    {!pending ? <span>Contiue</span> : null}
                    {!pending ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
                <p className="PpTou password-description">
                  By continuing, you agree to the{" "}
                  <a
                    href="https://showbay24.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://showbay24.com/terms-of-use/" target="_blank">
                    Terms of Use
                  </a>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizerForm;

import React from "react";
import logosm from "../../assets/images/logo-sm.png";
import kagoLogo from "../../assets/images/Kago-main-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import ButtonSubmit from "../Buttons/ButtonStyle";

const HomeNav = ({ search, setSearch }) => {
  const Navigate = useNavigate();
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="customize---topBar"
        style={{ userSelect: "none" }}
      >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link
                to="https://kago.digital/events-management-software/"
                className="logo logo-dark"
              >
                <span className="logo-sm">
                  <img src={logosm} alt="logo-sm-dark" height="27" />
                </span>
                <div style={{ width: 120, height: 60 }}>
                  <img
                    src={kagoLogo}
                    alt="kago-logo"
                    className="w-100 h-100"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Link>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "transparent",
                paddingTop: ".58rem",
                paddingBottom: ".5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                border: "solid",
                borderWidth: 0.1,
                borderRadius: 30,
              }}
            >
              <AiOutlineSearch size={24} />
              <input
                type="text"
                // placeholder="Search event name"
                value={search}
                onChange={handleSearch}
                style={{
                  width: "100%",
                  border: "none",
                  marginLeft: 8,
                  paddingRight: 24,
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>

          <div className="d-flex  align-items-center gap-2">
            <ButtonSubmit
              Title="Login"
              BackgroundColor="#f68a20"
              ColorText="#fff"
              BorderColor="#f68a20"
              borderRadius={10}
              handleOnclick={() => {
                Navigate("/login");
              }}
              pending={false}
            />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default HomeNav;

import React from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonEventBanner = () => (
  <Box
    sx={{
      backgroundColor: "#fff",
      borderRadius: 2,
      mb: 4,
      overflow: "hidden",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Skeleton
      variant="rectangular"
      width="90%"
      height={300} // Adjust height to match your banner size
      sx={{
        mt: 0.3,
        mb: 1.5,
        borderRadius: 2,
      }}
    />
  </Box>
);

const SkeletonEventText = ({ width, height, sx = {} }) => (
  <Skeleton variant="text" width={width} height={height} sx={sx} />
);

const SkeletonEventDetails = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 2,
      mb: 4,
    }}
  >
    <SkeletonEventText width="80%" height={30} sx={{ mb: 1 }} />
    <SkeletonEventText width="60%" height={20} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" width="90%" height={150} sx={{ borderRadius: 2 }} />
  </Box>
);

const SkeletonEventRegistration = () => {
  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      {/* Banner skeleton */}
      <SkeletonEventBanner />

      {/* Event details skeleton */}
      <SkeletonEventDetails />

      {/* Additional text skeletons */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <SkeletonEventText width="60%" height={40} sx={{ mx: "auto", mb: 1 }} />
        <SkeletonEventText width="40%" height={20} sx={{ mx: "auto", mb: 2 }} />
      </Box>
    </Box>
  );
};

export default SkeletonEventRegistration;

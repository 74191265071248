import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";

function EditVideoLink({ userID, token, eventDetails }) {
  const [inputs, setInputs] = useState({});

  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (eventDetails) {
      const { videoLink } = eventDetails;
      setInputs({
        videoLink: videoLink || "",
      });
      // setInputs({
      //   videoLink: videoLink || "",
      // });
    }
  }, [eventDetails]);

  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row></Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <label className="event__form__Creation">Video Link </label>
            <input
              type="text"
              name="videoLink"
              placeholder="Enter a video link"
              value={inputs.videoLink}
              onChange={handleChange}
              className="form-control"
              required
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-start align-item-center mt-2">
          <div className="d-flex justify-content-start align-item-center">
            <button className="btn btn-primary rounded-5" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Update Video Link </span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditVideoLink;

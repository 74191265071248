import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ backgroundColor: "black" }}>
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              <div className="d-flex">
                <Link
                  to="https://www.kago.digital/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                  }}
                >
                  {new Date().getFullYear()} ©Kago Events
                </Link>
                <div className="mx-1">|</div>
                <Link
                  to="https://www.tngsolutions.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                  }}
                >
                  by TNG Solutions
                </Link>
              </div>
            </Col>
            <Col sm={6}>
              <div className=" d-flex justify-content-end">
                <Link
                  to="https://www.kago.digital/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                  }}
                >
                  Privacy
                </Link>
                <div className="mx-1">|</div>
                <Link
                  to="https://www.kago.digital/terms-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                  }}
                >
                  Cookies
                </Link>
                <div className="mx-1">|</div>
                <Link
                  to="https://www.kago.digital/terms-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                  }}
                >
                  Terms & Conditions
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

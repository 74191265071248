import React from "react";
import DataTable from "react-data-table-component";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";

import { reviews } from "./attendee";

function AllAttendeeReviews() {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  // const userEmail = obj?.data?.data?.data?.email;
  const token = obj?.data?.token;
  const { eventID } = useParams();

  // --------------------------------------- get Reviews --------------------------------get Reviews ----------------------
  const {
    data: getReviews,
    error: errorGetReviews,
    isLoading: isLoadingGetReviews,
  } = useCustomQuery(
    ["myReviews", eventID],
    `${process.env.REACT_APP_API}reviews/?event=${eventID}`,
    token
  );
  // console.log("reviewsssssssss", getReviews);

  // --------------------------------------- get Reviews --------------------------------get Reviews ----------------------

  const columns = [
    {
      name: <span className="font-weight-bold fs-13"></span>,
      selector: (row) => (
        <img
          src={row.userId.photo}
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Full Name</span>,
      selector: (row) => row.userId.firstName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.userId.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Comment</span>,
      selector: (row) => <Link to="#!">{row.comment}</Link>,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Rating</span>,

      selector: (row) => (
        <div className="rating-star">
          <Rating
            emptySymbol="mdi mdi-star-outline text-warning fa-1x"
            fullSymbol="mdi mdi-star text-warning fa-1x"
            fractions={2}
            initialRating={row?.rating}
          />
        </div>
      ),
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: () => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#!">
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>
              <DropdownItem className="edit-item-btn">
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>
              <DropdownItem className="remove-item-btn">
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
  const data = getReviews?.data;
  return (
    <div className="analystic-table-card">
      <div className="w-100">
        <div className="d-flex = h2">Attendees Reviews</div>
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="300px"
          columns={columns}
          responsive
          data={data}
          pagination
        />
      </div>
    </div>
  );
}

export default AllAttendeeReviews;

import React from "react";
import { Spinner } from "reactstrap";

const DeleteForm = ({ setIsToggleDelete, DeleteParticitant, pending }) => {
  return (
    <div className="w-100">
      <h5 className="text-danger">
        Are you sure you want to delete this Security ?
      </h5>
      <div className="modal-footer d-flex justify-content-between">
        <button
          onClick={() => {
            setIsToggleDelete(false);
          }}
          type="button"
          className="btn btn-dark"
        >
          Cancel
        </button>
        <button
          onClick={DeleteParticitant}
          type="button"
          className="btn btn-danger"
        >
          {!pending ? (
            <span className="px-2">Sure</span>
          ) : (
            <span>
              <Spinner as="span" animation="border" size="sm" />
              Loading
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteForm;

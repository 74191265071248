import React, { useState, useRef, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Alert } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import usePost from "../../../Hooks/usePost";
import PackageList from "./PackageList";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ButtonGradient from "../../../components/Buttons/ButtonGradient";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { countryList } from "../../../utils/Countries";
import "../../../assets/scss/custom/pages/_requestDemo.scss";
import CustomFooter from "../../../Layout/HorizontalLayout/CustomFooter";

const productData = [
  "RSVP",
  "RSVP & Ticketing",
  "RSVP, Ticketing, Event Check In, Badging Solution & Mobile App Access",
  "Exhibition",
  "Virtual Event",
];

const RequestForm = () => {
  const Navigate = useNavigate();
  const formRef = useRef(null);
  const { status, execute, pending, data } = usePost();

  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    organizationEmail: "",
    organizationPhoneNumber: "",
    organizationCountry: "",
    organizationName: "",
    organizationType: "",
    otherOrganizationType: "",
    product: [],
    eventType: "",
    otherEventType: "",
    eventFormat: "",
    startDate: null,
    endDate: null,
    attendeesPerEvent: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  // Updated handleChange function to take name and value directly
  const handleChange = (name, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSelect = (selectedList) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      product: selectedList,
    }));
  };

  const handleRemove = (selectedList) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      product: selectedList,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !inputs.firstName ||
      !inputs.lastName ||
      !inputs.organizationEmail ||
      !inputs.organizationPhoneNumber ||
      !inputs.organizationCountry ||
      !inputs.organizationName ||
      !inputs.organizationType ||
      (inputs.organizationType === "other" && !inputs.otherOrganizationType) ||
      !inputs.eventType ||
      // (inputs.eventType === "other" && !inputs.otherEventType) ||
      !inputs.eventFormat ||
      !inputs.startDate ||
      !inputs.endDate ||
      !inputs.attendeesPerEvent ||
      inputs.product.length === 0
    ) {
      setErrorMessage("Please fill in all required fields marked with a star.");
      return;
    }

    if (inputs.endDate <= inputs.startDate) {
      setErrorMessage("End Date and Time must be after Start Date and Time.");
      return;
    }

    setErrorMessage("");

    const Method = "POST",
      endPoint = "leads",
      token = null;
    const raw = JSON.stringify({
      firstName: inputs?.firstName,
      lastName: inputs?.lastName,
      organizationName: inputs?.organizationName,
      organizationType:
        inputs?.organizationType === "Other"
          ? inputs?.otherOrganizationType
          : inputs?.organizationType,
      organizationCountry: inputs?.organizationCountry,
      organizationEmail: inputs?.organizationEmail,
      organizationPhoneNumber: inputs?.organizationPhoneNumber,
      attendeesPerEvent: inputs?.attendeesPerEvent,
      product: inputs?.product,
      eventFormat: inputs?.eventFormat,
      eventType: inputs?.otherEventType,
    });

    execute(endPoint, raw, Method, true, token);
  };

  useEffect(() => {
    if (status === "success") {
      Navigate("/sent");
    }
    if (status === "fail") {
      Navigate("/failed");
    }
    if (status === "error") {
      Navigate("/error");
    }
  }, [status]);

  const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f68a20" : "white",
      color: state.isFocused ? "white" : "black",
    }),
    control: (provided) => ({
      ...provided,
      borderColor: "#ccc",
      "&:hover": {
        borderColor: "#f68a20",
      },
    }),
  };

  console.log("data.....", JSON.stringify(data, null, 2));
  console.log("status.....", JSON.stringify(status, null, 2));
  console.log("inputs.....", JSON.stringify(inputs, null, 2));

  return (
    <>
      <div className="request-demo-form-container">
        <PackageList />

        <div className="request-demo-form-container-right" ref={formRef}>
          <h4>Request a Demo</h4>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    First name <span className="required-star"> *</span>
                  </Label>
                  <Input
                    name="firstName"
                    value={inputs.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Last name <span className="required-star"> *</span>
                  </Label>
                  <Input
                    name="lastName"
                    value={inputs.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>
                Email <span className="required-star"> *</span>
              </Label>
              <Input
                type="email"
                name="organizationEmail"
                value={inputs.organizationEmail}
                onChange={(e) =>
                  handleChange("organizationEmail", e.target.value)
                }
                // onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Contact Number <span className="required-star"> *</span>
              </Label>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.organizationPhoneNumber}
                onChange={(value) =>
                  setInputs({ ...inputs, organizationPhoneNumber: value })
                }
                className="custom-phone-input-request-demo"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Country <span className="required-star"> *</span>
              </Label>
              <Select
                options={countryList.map((country) => ({
                  value: country,
                  label: country,
                }))}
                styles={customSelectStyles}
                placeholder="Select..."
                onChange={(option) =>
                  handleChange("organizationCountry", option.value)
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Company Name <span className="required-star"> *</span>
              </Label>
              <Input
                name="organizationName"
                value={inputs.organizationName}
                onChange={(e) =>
                  handleChange("organizationName", e.target.value)
                }
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Organisation Type <span className="required-star"> *</span>
              </Label>
              <Select
                options={[
                  { value: "Corporate", label: "Corporate" },
                  { value: "Non-Profit", label: "Non-Profit" },
                  { value: "Government", label: "Government" },
                  { value: "Government", label: "Education" },
                  { value: "Other", label: "Other" },
                ]}
                styles={customSelectStyles}
                placeholder="Select..."
                onChange={(option) =>
                  handleChange("organizationType", option.value)
                }
              />
            </FormGroup>

            {inputs.organizationType === "Other" && (
              <FormGroup>
                <Label>Please specify:</Label>
                <Input
                  name="otherOrganizationType"
                  value={inputs.otherOrganizationType}
                  // onChange={handleChange}
                  onChange={(e) =>
                    handleChange("otherOrganizationType", e.target.value)
                  }
                  required
                />
              </FormGroup>
            )}

            {/* MultiSelect Component for Products */}
            <FormGroup>
              <Label>
                What product are you interested in?{" "}
                <span className="required-star"> *</span>
              </Label>
              <Multiselect
                options={productData}
                isObject={false}
                selectedValues={inputs.product}
                onSelect={handleSelect}
                onRemove={handleRemove}
                placeholder="Select products"
                className="custom-multiselect"
              />
            </FormGroup>

            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Event Type <span className="required-star"> *</span>
                  </Label>
                  <Select
                    options={[
                      { value: "Webinar", label: "Webinar" },
                      { value: "Exhibition", label: "Exhibition" },
                      { value: "Conference", label: "Conference" },
                    ]}
                    styles={customSelectStyles}
                    placeholder="Select..."
                    onChange={(option) =>
                      handleChange("eventType", option.value)
                    }
                  />
                </FormGroup>
                {/* {inputs.eventType === "other" && (
                  <FormGroup>
                    <Label>Please specify:</Label>
                    <Input
                      name="otherEventType"
                      value={inputs.otherEventType}
                      // onChange={handleChange}
                      onChange={(e) =>
                        handleChange("otherEventType", e.target.value)
                      }
                      required
                    />
                  </FormGroup>
                )} */}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Event Format <span className="required-star"> *</span>
                  </Label>
                  <Select
                    options={[
                      { value: "inPerson", label: "In Person" },
                      { value: "hybrid", label: "Hybrid" },
                      { value: "virtual", label: "Virtual" },
                    ]}
                    styles={customSelectStyles}
                    placeholder="Select..."
                    onChange={(option) =>
                      handleChange("eventFormat", option.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <Label>
                    Start Date <span className="required-star"> *</span>
                  </Label>
                  <Input
                    name="startDate"
                    value={inputs.startDate}
                    onChange={(e) => handleChange("startDate", e.target.value)}
                    type="date"
                    // required
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <Label>
                    End Date <span className="required-star"> *</span>
                  </Label>
                  <Input
                    type="date"
                    name="endDate"
                    value={inputs.endDate}
                    onChange={(e) => handleChange("endDate", e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <Label>
                    Number of Attendees{" "}
                    <span className="required-star"> *</span>
                  </Label>
                  <Input
                    type="number"
                    name="attendeesPerEvent"
                    value={inputs.attendeesPerEvent}
                    onChange={(e) =>
                      handleChange("attendeesPerEvent", e.target.value)
                    }
                    min="1"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="w-100 d-flex justify-content-center">
              <ButtonGradient
                Title="Request Demo"
                leftColor="#f68a20"
                rightColor="#ffc444"
                ColorText="#fff"
                BorderColor="#fff"
                borderRadius={5}
                handleOnclick={handleSubmit}
                pending={pending}
              />
            </div>
          </form>
        </div>
      </div>

      <CustomFooter style={{ marginTop: "40px" }} />
    </>
  );
};

export default RequestForm;

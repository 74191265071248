import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import OffCanvas from "../../../OffCanvas/OffCanvas";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import { FaRegEdit } from "react-icons/fa";
import EditRegistrationForm from "./EditRegistrationForm";

const ViewRegistrationForm = ({ formContent, token, eventID }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleEditForm = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const customFields = formContent[0]?.customFields || [];

  console.log("formContent....", formContent);

  return (
    <React.Fragment>
      <div className="w-100 mt-4 mb-3 d-flex justify-content-center">
        <div
          style={{
            width: "75%",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
          }}
        >
          <div className="d-flex justify-content-end mx-2">
            <ButtonIcon
              Title="Edit Form"
              BackgroundColor="#F68A20"
              ColorText="white"
              BorderColor="#F68A20"
              borderRadius={25}
              handleOnclick={toggleEditForm}
              Icon={<FaRegEdit size={15} color="white" />}
            />
          </div>
          <div className="w-100 px-4 mb-2 mt-2">
            <form noValidate autoComplete="off" data-testid="form">
              <Row>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      First name<span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Last name<span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input name="email" type="email" className="form-control" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Phone number<span className="text-danger">*</span>
                    </label>
                    <input
                      name="phoneNumber"
                      type="email"
                      className="form-control"
                    />
                  </div>
                </Col>
                {customFields.slice(4).map((field) => (
                  <Col md={12} key={field._id}>
                    <div className="">
                      {field.question_type === "text" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <input
                            type="text"
                            className="w-100 form-control"
                            placeholder={field.label}
                            name={field.name}
                          />
                        </div>
                      )}
                      {field.question_type === "short_answer" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <input
                            type="text"
                            className="w-100 form-control"
                            placeholder={field.label}
                            name={field.name}
                          />
                        </div>
                      )}
                      {field.question_type === "paragraph" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <textarea
                            rows={5}
                            className="form-control"
                            placeholder={field.label}
                            name={field.name}
                          />
                        </div>
                      )}
                      {field.question_type === "options" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <div className="w-25">
                            <select
                              className="px-2 form-control"
                              name={field.name}
                            >
                              <option>Select...</option>
                              {field.list.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </form>
          </div>
        </div>
      </div>
      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Update Registration form"
        body={
          <EditRegistrationForm
            token={token}
            eventID={eventID}
            setIsOffcanvasOpen={setIsOffcanvasOpen}
            dataForm={customFields} // Use the filtered customFields here
            formID={formContent[0]?._id}
          />
        }
        toggleSaveChanges={toggleEditForm}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      />
    </React.Fragment>
  );
};

export default ViewRegistrationForm;

import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import FormWhatsApp from "./FormWhatsApp";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import TableWhatsApp from "./TableWhatsApp";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import { BsWhatsapp } from "react-icons/bs";

const WhatsApp = ({ eventDetails, token, userID, isLoading }) => {
  const [isWhatsAppOpen, setIsWhatsAppOpen] = useState(false);
  const key = ["allWhatsApp", eventDetails?._id];
  // const mutation = useCustomMutation(key);
  const {
    data: getWhatsApp,
    error: errorGetWhatsApp,
    isLoading: isLoadingGetWhatsApp,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}communication/whatsapp/${eventDetails?._id}`,
    token,
    {
      enabled: !!eventDetails?._id,
    }
  );

  // console.log("getAllSMS.....", getAllSMS);
  // console.log("eventDetails.....", eventDetails);
  const toggleWhatsApp = () => {
    setIsWhatsAppOpen(!isWhatsAppOpen);
  };

  return (
    <div className="w-100 px-5 py-4 ">
      <div className="w-100 mb-2  d-flex justify-content-between">
        <div className="mb-2" style={{ fontSize: 18, fontWeight: "600" }}>
          {/* WhatsApp */}
        </div>
        <div>
          <ButtonIcon
            Title="Send WhatsApp"
            Icon={<BsWhatsapp size={14} color="white" />}
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={() => toggleWhatsApp()}
            pending={false}
          />
        </div>
      </div>

      <div className="w-100">
        <Row>
          <Col md={12}>
            <div className="w-100 mt-2">
              <TableWhatsApp
                userID={userID}
                token={token}
                eventID={eventDetails?._id}
                mySMS={getWhatsApp?.data}
              />
            </div>
          </Col>
        </Row>
      </div>
      <OffCanvas
        isOffcanvasOpen={isWhatsAppOpen}
        title="Send WhatsApp"
        body={
          <FormWhatsApp
            setIsWhatsAppOpen={setIsWhatsAppOpen}
            userId={userID}
            token={token}
            eventID={eventDetails?._id}
            eventDetails={eventDetails}
          />
        }
        toggleSaveChanges={toggleWhatsApp}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default WhatsApp;

import React from "react";
import useCustomQuery from "../../Hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import EventOne from "../../components/Common/EventRegistration/EventOne";
import SkeletonEventRegistration from "../../components/Loader/LoaderEventRegistration";

const EventRegistration = () => {
  document.title = "Kago Events | Registration";
  const params = useParams();
  const eventID = params?.eventID;

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myPublicEventDetail", eventID],
    `${process.env.REACT_APP_API}guest/events/${eventID}`,
    null
  );

  // Show skeleton while fetching data
  if (isLoadingGetMyEventDetail) {
    return <SkeletonEventRegistration />;
  }

  // Show error message if there's an error
  if (errorGetMyEventDetail) {
    return <div>Error loading event details. Please try again later.</div>;
  }

  return (
    <React.Fragment>
      <EventOne eventDetails={getMyEventDetail?.data} />
    </React.Fragment>
  );
};

export default EventRegistration;

import React, { useState, useEffect, useRef } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import { Spinner } from "reactstrap";

import Reception from "../reception/reception.component";
import Community from "../community/Community";
import Sessions from "../sessions/sessions.component";
import ExhibitionLobby from "../../../../components/Common/BoothLobby/BoothLobby";
// import ExhibitionLobby from "../BoothLobby/BoothLobby";
// import Booths from "../booth/Index";
import Sponsors from "../sponsors/Sponsors";

// import DropDown from "./components/drop-down/drop-down.component";

const ReceptionHeader = ({ userId, token, eventID }) => {
  const [activeLink, setActiveLink] = useState(0);

  const peopleDropdownRef = useRef(null);
  const messagesDropdownRef = useRef(null);
  const alertsDropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        peopleDropdownRef.current &&
        !peopleDropdownRef.current.contains(event.target) &&
        messagesDropdownRef.current &&
        !messagesDropdownRef.current.contains(event.target) &&
        alertsDropdownRef.current &&
        !alertsDropdownRef.current.contains(event.target)
      ) {
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetails", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // console.log("getMyEventDetail...", getMyEventDetail);

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // console.log("getMyEventDetail...", getMyEventDetail);

  return (
    <React.Fragment>
      <header className="reception-header">
        <nav className="reception-navbar">
          <ul className="reception-navbar__menu">
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 0 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Reception
              </a>
            </li>
            {getMyEventDetail?.data?.eventType?.includes("Conference") ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 2
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick(2)}
                >
                  Sessions
                </a>
              </li>
            ) : null}

            {getMyEventDetail?.data?.eventType?.includes("Exhibition") &&
            getMyEventDetail?.data?.eventType?.length > 1 ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 4
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick(4)}
                >
                  Exhibition
                </a>
              </li>
            ) : null}
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 6 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(6)}
              >
                Community Chat
              </a>
            </li>
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 8 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(8)}
              >
                Sponsors
              </a>
            </li>
          </ul>
        </nav>
        <div className="reception-header__extended"></div>
      </header>
      <div
        className="pt-5"
        style={{ background: "#131216", height: "max-content" }}
      >
        {activeLink === 0 && (
          <Reception
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 2 && (
          <Sessions
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {
          activeLink === 4 && (
            <ExhibitionLobby
              userId={userId}
              token={token}
              eventID={eventID}
              eventDetail={getMyEventDetail}
            />
          )
          // <Booths
          // userId={userId}
          // token={token}
          // eventID={eventID}
          // eventDetail={getMyEventDetail}
          // />
        }
        {activeLink === 6 && (
          <Community
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 8 && (
          <Sponsors
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ReceptionHeader;

import React from "react";
import { Progress } from "reactstrap";
import ImgPic from "../../../../../../../assets/images/users/avatar-1.jpg";

function Standard({ title, price, totalQuantity, totalSold, totalPercentageSold, totalRevenue, closingDate, user }) {
  return (
    <div className="ticketing-card-container">
      <div className="ticketing-card-divider">
        <div className="ticketing-card-divider-left"></div>
        <div className="ticketing-card-divider-right"></div>
      </div>
      <div className="ticketing-card mb-3">
        <div className="ticketing-card-top">
          <div className="ticketing-card-top-title">
            <p className="ticketing-card-top-title-type">{title ?? 'STANDARD'}</p>
            <p className="ticketing-card-top-title-price">R{price ?? 100}</p>
          </div>
          <div className="ticketing-card-top-title">
            <div>
              <span className="ticketing-title-percentage">{totalPercentageSold ?? 10}%</span>
              <br></br>
              {/* <span className="ticketing-title-Sold">SOLD</span> */}
            </div>

            <div className="ticketing-card-top-title-progress">
              <Progress
                color="primary"
                value={totalPercentageSold ?? 10}
                // className="ticket-progress-bar"
              ></Progress>
            </div>
          </div>
          <div className="ticketing-status-container">
            <button className="ticketing-card-top-status-status text-primary ">
              OPEN
            </button>
            <span className="ticketing-card-days">2 days remaining</span>
            {/* <div></div> */}
          </div>
        </div>

        <div
          className="ticketing-card-bottom 
            "
        >
          <div className="ticketing-card-bottom-container p-2">
            <div className="ticketing-card-bottom-first">
              <div>
                <span className="ticketing-bottom-title">Tickets sold</span>
                <br></br>
                <span className="ticketing-bottom-value">{totalSold ?? 10}/{totalQuantity ?? 50}</span>
              </div>
              <div className="mt-3">
                <span className="ticketing-bottom-title">End Date</span>
                <br></br>
                <span className="ticketing-bottom-value">{closingDate ?? '15 Feb'}</span>
              </div>
            </div>
            <div className="ticketing-card-bottom-second">
              <div className="">
                <span className="ticketing-bottom-title">Revenue</span>
                <br></br>
                <span className="ticketing-bottom-value">R{totalRevenue ?? 100}</span>
              </div>
              <div className="mt-3">
                <span className="ticketing-bottom-title">Last Modified</span>
                <br></br>
                <img
                  src={user?.photo ?? ImgPic}
                  alt="last-pic"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="ticketing-card-bottom-circle-main bg-primary"></div>
        </div>
      </div>
    </div>
  );
}

export default Standard;

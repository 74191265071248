import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import SponsorImage from "./SponsorImage";

function AddSponsor({ userId, eventID, token, setIsOffcanvasOpen }) {
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const key = ["mySponsors", eventID];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    // console.log("uploadImg....", uploadImg);
    const Method = "POST",
      url = `sponsors/${eventID}`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("logo", uploadImg);
    Object.entries(inputs).forEach(([key, value]) => {
      formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Sponsor successfully added",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <Row>
          <Col md={12}>
            <div className="d-flex mb-3 justify-content-center">
              <SponsorImage setUploadImg={setUploadImg} />
            </div>
          </Col>

          <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Sponsor name
            </label>
            <input
              type="text"
              name="sponsorName"
              value={inputs?.sponsorName}
              placeholder="Enter sponsor name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title=" Upload"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default AddSponsor;

import React, { useState, useEffect } from "react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import Nothing from "../../Loader/Nothing";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

function AssignSessionHost({
  eventDetails,
  sessionID,
  eventID,
  token,
  setIsOffcanvasOpen,
}) {
  // const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({ speaker: "" });
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `sessionHost/assign/${sessionID}?sessionCordinator=${
        inputs?.speaker ? inputs?.speaker : null
      }`,
      isJSON = true;
    var formdata = null;

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Host successfully assigned",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  // maximum duration 480
  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  if (eventDetails?.data?.personnel) {
    return (
      <Nothing title="You have no session Host at the moment please go to participants  create session host under Organizer team" />
    );
  }

  return (
    <div className="w-100 mt-4 d-flex justify-content-center">
      <div style={{ width: 400 }}>
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          onSubmit={toggleSaveChanges}
        >
          <Row>
            <Col md={12}>
              <label>
                Assign Session Host <span className="text-danger">*</span>
              </label>
              <select
                name="speaker"
                value={inputs.speaker}
                onChange={handleChange}
                className="mb-3 form-control"
                required
              >
                <option value={null}>Select... </option>
                {eventDetails?.personnel?.map((host, i) => {
                  if (host?.role === "session_coordinator") {
                    return (
                      <option value={host?.email} key={i}>
                        {host?.email}
                        {/* {host?.title} {host?.firstName} {host?.lastName} */}
                      </option>
                    );
                  }
                })}
              </select>
            </Col>

            <div className="mb-3 mt-4"></div>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Assign host"
                ColorText="white"
                BorderColor="#F68A20"
                BackgroundColor="#F68A20"
                borderRadius="25px"
                handleOnclick={toggleSaveChanges}
                pending={mutation?.isLoading}
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default AssignSessionHost;

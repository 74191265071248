import React from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "./attendeeCard.scss";
import { FaTrophy } from "react-icons/fa";

function LiveSessions({ token, eventID }) {
  const {
    data: getMyEventSessions,
    error: errorgetMyEventSessions,
    isLoading: isLoadinggetMyEventSessions,
  } = useCustomQuery(
    ["myEventSessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  return (
    <div className="registered-user-card">
      <div className="d-flex">
        <div className="attendee-analistics-icons-container mt-1">
          <FaTrophy size={30} color="white" />
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div style={{ color: "black", fontSize: 16, fontWeight: "900" }}>
              Live Sessions
            </div>
            <div style={{ color: "#1a87a3", fontSize: 28, fontWeight: "900" }}>
              {Array.isArray(getMyEventSessions)
                ? getMyEventSessions.length
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveSessions;

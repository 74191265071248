import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";

function EditTime({ userID, token, eventDetails }) {
  const [inputs, setInputs] = useState({});
  const [warning, setWarning] = useState(""); // State for warning message

  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setWarning(""); // Clear warning message when user makes changes
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    // Validate that Start Date is not greater than End Date
    if (new Date(inputs.startDate) > new Date(inputs.endDate)) {
      setWarning(
        "The Start Date cannot be later than the End Date. Please select a valid date range."
      );
      return; // Prevent form submission if dates are invalid
    }

    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (eventDetails && eventDetails.startDate && eventDetails.endDate) {
      const start = new Date(eventDetails.startDate);
      const end = new Date(eventDetails.endDate);

      const formattedStartDate = start.toISOString().substring(0, 10);
      const formattedEndDate = end.toISOString().substring(0, 10);

      setInputs((inputs) => ({
        ...inputs,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        startTime: eventDetails.startTime,
        endTime: eventDetails.endTime,
      }));
    }
  }, [eventDetails]);

  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col sm={12} md={4} lg={3}>
            <label className="event__form__Creation">
              Start Date
              <input
                type="date"
                name="startDate"
                placeholder="dd-mm-yyyy"
                value={inputs.startDate || ""}
                onChange={handleChange}
                className="form-control"
                style={{ width: 145 }}
                required
              />
            </label>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <label className="event__form__Creation">
              End Date
              <input
                type="date"
                name="endDate"
                value={inputs.endDate || ""}
                onChange={handleChange}
                className="mb-3 form-control"
                style={{ width: 145 }}
                required
              />
            </label>
          </Col>

          <Col sm={12} md={4} lg={3}>
            <label>
              Start Time
              <input
                type="time"
                name="startTime"
                value={inputs.startTime || ""}
                onChange={handleChange}
                className="mb-3 form-control"
                style={{ width: 145 }}
                required
              />
            </label>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <label>
              End Time
              <input
                type="time"
                name="endTime"
                value={inputs.endTime || ""}
                onChange={handleChange}
                className="mb-3 form-control"
                style={{ width: 145 }}
                required
              />
            </label>
          </Col>
        </Row>

        {/* Display warning message if dates are invalid */}
        {warning && <p className="text-danger mt-2">{warning}</p>}

        <div className="d-flex justify-content-start align-item-center">
          <div className="d-flex justify-content-start align-item-center">
            <button className="btn btn-primary rounded-5" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Update Time</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditTime;

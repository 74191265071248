import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { RiErrorWarningLine } from "react-icons/ri";

const GeneralNotice = ({ userID, token, eventID }) => {
  return (
    <Card>
      <CardHeader style={{ backgroundColor: "#f68a20" }}>
        <div
          className="w-100 d-flex align-items-center"
          style={{ fontWeight: "bold", fontSize: 18, color: "#fff" }}
        >
          <span>
            <RiErrorWarningLine size={20} color="#fff" />
          </span>
          <span className="mx-1">Notice</span>
        </div>
      </CardHeader>
      <CardBody>
        <div
          className="w-100 d-flex justify-content-between align-items-center rounded-4"
          style={{ backgroundColor: "white" }}
        >
          <div className="w-100">
            <div className="w-100 d-flex justify-content-between">
              <div
                className="w-100"
                style={{ fontWeight: "bold", fontSize: 14 }}
              >
                We take our commition of 5 % on the widrawal transaction
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </CardBody>
    </Card>
  );
};
export default GeneralNotice;

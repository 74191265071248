import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import FormSMS from "./FormSMS";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import TableSms from "./TableSms";
import { BsQuestionCircleFill } from "react-icons/bs";
import { BsChatLeftText } from "react-icons/bs";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import Tooltip from "../../../../utils/toolTip";
import AIChat from "./AIChat";

const Sms = ({ eventDetails, token, userID, isLoading }) => {
  const [isSMSOpen, setIsSMSOpen] = useState(false);
  const [isChatAiOpen, setIsChatAiOpen] = useState(false);
  const key = ["allSMS", eventDetails?._id];
  const {
    data: getAllSMS,
    error: errorGetAllSMS,
    isLoading: isLoadingGetAllSMS,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}sms/${eventDetails?._id}`,
    token,
    {
      enabled: !!eventDetails?._id,
    }
  );

  const toggleSMS = () => {
    setIsSMSOpen(!isSMSOpen);
  };

  const toggleAIChat = () => {
    setIsChatAiOpen(!isChatAiOpen);
  };

  return (
    <div className="w-100 px-5 py-4 ">
      <div className="w-100 mb-2  d-flex justify-content-between">
        <div className="mb-2" style={{ fontSize: 18, fontWeight: "600" }}></div>
        <div>
          <ButtonIcon
            Title="New SMS"
            Icon={<BsChatLeftText size={15} color="white" />}
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={() => toggleSMS()}
            pending={false}
          />
        </div>
      </div>

      <div className="w-100">
        <Row>
          <Col md={4}>
            <div
              className="p-3 rounded-3 d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "white" }}
            >
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end">
                  <Tooltip text="Total SMS">
                    <BsQuestionCircleFill size={16} />
                  </Tooltip>
                </div>
                <div className="w-100">
                  <div style={{ fontSize: 16, fontWeight: "600" }}>
                    Total SMS
                  </div>
                  <div
                    style={{ fontSize: 28, fontWeight: "600", color: "black" }}
                  >
                    {eventDetails?.smsCredits}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div
              className="p-3 rounded-3 d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "white" }}
            >
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end">
                  <Tooltip text="Remaining SMS">
                    <BsQuestionCircleFill size={16} />
                  </Tooltip>
                </div>
                <div className="w-100">
                  <div style={{ fontSize: 16, fontWeight: "600" }}>
                    Remaining SMS
                  </div>
                  <div
                    style={{ fontSize: 28, fontWeight: "600", color: "black" }}
                  >
                    {eventDetails?.smsCreditsRemaining}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="w-100 mt-2">
              <TableSms
                userID={userID}
                token={token}
                eventID={eventDetails?._id}
                mySMS={getAllSMS?.data}
              />
            </div>
          </Col>
        </Row>
      </div>
      <OffCanvas
        isOffcanvasOpen={isSMSOpen}
        title="New SMS"
        body={
          <FormSMS
            setIsSMSOpen={setIsSMSOpen}
            userId={userID}
            token={token}
            eventID={eventDetails?._id}
            eventDetails={eventDetails}
            isChatAiOpen={isChatAiOpen}
            setIsChatAiOpen={setIsChatAiOpen}
          />
        }
        toggleSaveChanges={toggleSMS}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />

      <OffCanvas
        isOffcanvasOpen={isChatAiOpen}
        title="KagoBot"
        body={<AIChat />}
        toggleSaveChanges={toggleAIChat}
        directionOffCanvas="start"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default Sms;

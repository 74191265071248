import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_USER,
  CUSTOM_LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  customLoginSuccess,
  logoutUserSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  // postFakeLogin,
  // postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  localStorage.setItem("authEventKagoUser", JSON.stringify(user));
  yield put(loginSuccess(user));
  // loginSuccess(user);
  history("/dashboard");

  // console.log("is sucess...", response);

  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const response = yield call(
  //       fireBaseBackend.loginUser,
  //       user.email,
  //       user.password
  //     );
  //     console.log("is sucess...firebase....", response);
  //     yield put(loginSuccess(response));
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
  //     const response = yield call(postJwtLogin, {
  //       email: user.email,
  //       password: user.password,
  //     });
  //     localStorage.setItem("authEventKagoUser", JSON.stringify(response));
  //     console.log("is sucess...jwt....", response);
  //     yield put(loginSuccess(response));
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
  //     const response = yield call(postFakeLogin, {
  //       email: user.email,
  //       password: user.password,
  //     });

  //     localStorage.setItem("authEventKagoUser", JSON.stringify(response));
  //     console.log("is sucess...fake....", response);
  //     yield put(loginSuccess(response));
  //   }
  //   history("/dashboard");
  // } catch (error) {
  //   yield put(apiError(error));
  // }
}
function* customLoginUser({ payload: { user, history, eventId } }) {
  localStorage.setItem("authEventKagoUser", JSON.stringify(user));
  yield put(customLoginSuccess(user));
  history(`/event-landing/${eventId}`);
}

function* logoutUser() {
  try {
    localStorage.removeItem("authEventKagoUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authEventKagoUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authEventKagoUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(CUSTOM_LOGIN_USER, customLoginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

import React, { useState } from "react";
import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import classnames from "classnames";
import Divider from "./Divider";

const Faq = ({ eventDetails }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <React.Fragment>
      <h1
        id="faq"
        className="d-flex justify-content-center"
        style={{ color: "black", fontWeight: "bold" }}
      >
        FAQ
      </h1>
      <Divider />
      <div className="w-100 mt-3 d-flex justify-content-center">
        <div className="w-50">
          <Card>
            <CardBody
              style={{
                borderRadius: 5,
                boxShadow: "1px -1px 5px 0px #F3F3F3",
              }}
            >
              <Row>
                <Col lg={12}>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    {eventDetails?.faq?.map(
                      (faq, index) =>
                        faq.question &&
                        faq.answer && (
                          <div className="accordion-item" key={faq._id}>
                            <h2
                              className="accordion-header"
                              id={`headingFlush${index}`}
                            >
                              <button
                                className={classnames(
                                  "accordion-button",
                                  "fw-medium",
                                  {
                                    collapsed: openIndex !== index,
                                  }
                                )}
                                type="button"
                                onClick={() => toggle(index)}
                                style={{ cursor: "pointer" }}
                              >
                                {faq.question}
                              </button>
                            </h2>
                            <Collapse
                              isOpen={openIndex === index}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body">
                                <div className="text-muted">{faq.answer}</div>
                              </div>
                            </Collapse>
                          </div>
                        )
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;

import React from "react";
import useCustomQuery from "../../../Hooks/useCustomQuery";
// import useCustomMutation from "../../../../../hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";

// import { Spinner } from "reactstrap";
//icons

import { useNavigate } from "react-router-dom";

const Lobby = ({ userName, token, eventID, eventDetail }) => {
  const Navigate = useNavigate();
  //   console.log("eventDetail...", eventDetail);
  const {
    data: getReceptionBanner,
    error: errorGetReceptionBanner,
    isLoading: isLoadingGetReceptionBanner,
  } = useCustomQuery(
    ["myEventReception", eventID],
    `${process.env.REACT_APP_API}event-reception/${eventID}`,
    token
  );
  let receptionImage = Array.isArray(getReceptionBanner);

  const isBannerReception =
    getReceptionBanner && getReceptionBanner[0]?.bannerImage;
  return (
    <div className="reception-content">
      <div className="community">
        <div
          className="community-banner"
          style={{
            backgroundImage: eventDetail?.bannerImage
              ? `url(${eventDetail?.bannerImage})`
              : null,
          }}
        >
          <div className="community-content">
            <div className="community-content__wrapper">
              {isBannerReception ? null : <h6>{eventDetail?.eventName}</h6>}
            </div>
          </div>
        </div>
      </div>

      <div className="event-details">
        <div className="event-details__welcome">
          <h2 style={{ color: "black", margin: "24px 0" }}>
            Welcome, {userName}
          </h2>
          <p style={{ color: "#757575", marginTop: "-12px", fontSize: "13px" }}>
            {eventDetail?.eventDescription}
          </p>
          {eventDetail?.videoLink ? (
            <iframe
              width="560"
              height="387"
              src={eventDetail?.videoLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style={{ border: "0.4px solid #8f8f90", borderRadius: "12px" }}
            ></iframe>
          ) : null}
        </div>
        <div className="event-details__upcoming">
          <h2 style={{ color: "black", margin: "24px 0" }}>Initiate Webinar</h2>
          <p style={{ color: "#757575", marginTop: "-12px", fontSize: "13px" }}>
            Click below to Join the live session
          </p>
          <div className="event-details__upcoming__sessions">
            <button
              className="btn btn-primary rounded-5"
              onClick={() => {
                Navigate(
                  `/${eventDetail?.eventName?.replace(
                    / /g,
                    "-"
                  )}/s/live/${eventID}`
                );
              }}
            >
              Join now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Lobby;

import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import Picture from "../../../Profile/Picture";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import { countryList } from "../../../Countries/countries";
// import { title } from "../../../Common/Title/Title";

function AddSecurity({ userId, eventID, token, setIsOffcanvasOpen }) {
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const key = ["mySecurityTeam", eventID];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `auth/security/${eventID}`,
      isJSON = null;
    const raw = JSON.stringify(inputs);
    // const raw = JSON.stringify({
    //   firstName: inputs.firstName,
    //   lastName: inputs.lastName,
    //   email: inputs.email,
    //   password: inputs.password,
    //   passwordConfirm: inputs.passwordConfirm,
    // });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Team member successfully invited",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);
  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <Row>
          <Col md={12}>
            <div className="d-flex mb-3 justify-content-center">
              <Picture
                userImage={true}
                uploadImg={true}
                setUploadImg={setUploadImg}
                iconSize={80}
                iconColor="#F68A20"
                imgHeight={80}
                imgWidth={80}
                imgBorder="50%"
              />
            </div>
          </Col>
          {/* <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Title
            </label>
            <select
              name="title"
              value={inputs.title}
              onChange={handleChange}
              className="mb-3 form-control"
              style={{ width: 300, borderRadius: 7 }}
              required
            >
              {title?.map((title, i) => (
                <option key={i} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </Col> */}
          <Col md={6}>
            <label>
              <span className="text-danger">*</span>First name
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs?.firstName}
              placeholder="Enter First name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6}>
            <label>
              <span className="text-danger">*</span>Last name
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs?.lastName}
              placeholder="Enter Last name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6}>
            <label>
              <span className="text-danger">*</span>Email
            </label>
            <input
              type="email"
              name="email"
              value={inputs?.email}
              placeholder="Enter email"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6}>
            <label>
              <span className="text-danger">*</span>Phone Number
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <Col md={6}>
            <label>
              <span className="text-danger">*</span>Password
            </label>
            <input
              type="password"
              name="password"
              value={inputs?.password}
              // placeholder="Enter Password"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6}>
            <label>
              <span className="text-danger">*</span>Confirm Password
            </label>
            <input
              type="password"
              name="passwordConfirm"
              value={inputs?.passwordConfirm}
              // placeholder=""
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>

          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Submit"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default AddSecurity;

import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../../../Toast/Toast";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const FormWhatsApp = ({
  setIsWhatsAppOpen,
  userId,
  eventID,
  eventDetails,
  token,
}) => {
  const [inputs, setInputs] = useState({});
  const [userRole, setUserRole] = useState("all");
  const [btnLoad, setBtnLoad] = useState(false);
  const queryClient = useQueryClient();
  const key = ["allWhatsApp", eventID];
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const allParticipantsFunc = (e) => {
    setUserRole("all");
  };
  const attendeesFunc = (e) => {
    setUserRole("attendees");
  };
  const speakersFunc = (e) => {
    setUserRole("speakers");
  };
  const teamMembersFunc = (e) => {
    setUserRole("security");
  };

  const handleSend = (e) => {
    e.preventDefault();
    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const raw = JSON.stringify({
      body: inputs?.message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("eventID...", eventID);
    fetch(
      `${process.env.REACT_APP_API}communication/whatsapp/${eventID}?audience=${userRole}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("..................", res);
        setIsWhatsAppOpen(false);
        setBtnLoad(false);
        if (res?.status === "success") {
          queryClient.invalidateQueries(key);
          successMessage("SMS sucessfully sent");
        } else {
          warningMessage("No phone number found");
        }
      })
      .catch((error) => {
        setIsWhatsAppOpen(false);
        setBtnLoad(false);
        dangerMessage("No phone number found");
        console.error(error);
      });
  };

  return (
    <div>
      <form onSubmit={handleSend}>
        {/* <label>
          Title<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="title"
          value={inputs.title}
          onChange={handleChange}
          style={{ borderRadius: 7 }}
          className="mb-3 form-control fromInput"
          required
        /> */}
        <label className="event__input__form__Creation">
          Message<span className="text-danger">*</span>
          <textarea
            name="message"
            value={inputs.message}
            onChange={handleChange}
            // placeholder="Write here..."
            style={{ borderRadius: 7 }}
            className=" mt-1 form-control fromInput"
            rows={5}
            required
          ></textarea>
        </label>

        <div className="mt-2 mb-3">
          <label>
            Select your audience<span className="text-danger">*</span>
          </label>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleAdmin"
              value="allParticipants"
              onChange={(e) => allParticipantsFunc(e.target.value)}
              defaultChecked
            />
            <label className="form-check-label" htmlFor="exampleAdmin">
              All Participants
            </label>
          </div>
          <div className="">
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="formatRadio"
                id="exampleManager"
                value={"Attendees"}
                onChange={(e) => attendeesFunc(e.target.value)}
              />
              <label className="form-check-label" htmlFor="exampleManager">
                Attendees
              </label>
            </div>
          </div>
          <div className="">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="formatRadio"
                id="exampleEvent"
                value={"Speakers"}
                onChange={(e) => speakersFunc(e.target.value)}
              />
              <label className="form-check-label" htmlFor="exampleEvent">
                Speakers
              </label>
            </div>
          </div>
          <div className="">
            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="radio"
                name="formatRadio"
                id="exampleMember"
                value={"teamMembers"}
                onChange={(e) => teamMembersFunc(e.target.value)}
              />
              <label className="form-check-label" htmlFor="examplMember">
                Event Security
              </label>
            </div>
          </div>
        </div>
        <div className="w-100 mt-2 d-flex justify-content-center">
          <ButtonSubmit
            Title="Send SMS"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={handleSend}
            pending={btnLoad}
          />
        </div>
      </form>
    </div>
  );
};

export default FormWhatsApp;

import React from "react";
import { Row, Col } from "reactstrap";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";

import "./speakerCardStyle.scss";
import Divider from "./Divider";
import SpeakerCard from "./SpeakersCard";

const Speakers = ({ token, eventID }) => {
  const {
    data: getMySpeakers,
    error: errorGetMySpeakers,
    isLoading: isLoadingGetMySpeakers,
  } = useCustomFecthQuery(
    ["mySpeakers", eventID],
    `${process.env.REACT_APP_API}speakers/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  if (getMySpeakers?.status === "ok" && getMySpeakers?.data?.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <h1
        id="speakers"
        className="d-flex justify-content-center"
        style={{ color: "black", fontWeight: "bold" }}
      >
        Our Speakers
      </h1>
      <Divider />

      <Row className="d-flex justify-content-center">
        {getMySpeakers?.data?.map((speaker, index) => (
          <Col md={6} lg={4} xl={3} key={index}>
            <div
              className="w-100 d-flex justify-content-center"
              style={{ marginTop: "6%" }}
            >
              <SpeakerCard speaker={speaker} />
            </div>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default Speakers;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import "./ToggleSwitch.scss";
import { pricing } from "../../../components/Common/PricingPackage/WebinarPrice";
import logoLight from "../../../assets/images/Kago-color-logo.png";
import ModalConctactUs from "../../../components/Modal/ModalConctactUs";

const Index = () => {
  const navigation = useNavigate();
  const [isMonth, setIsMonth] = useState(true);
  const toggleSelection = () => {
    setIsMonth(!isMonth);
  };

  //   console.log("pricing....", pricing);

  const toggleBuyPackage = () => {
    navigation("/buy-webinar");
    // payment gateaway
  };

  const toggleContactSale = () => {
    navigation("/contact-sales/webinar");
    // payment gateaway
  };

  return (
    <div className="w-100">
      <div className="w-100 mb-3" style={{ backgroundColor: "white" }}>
        <section className="header-section ps">
          <div className="d-flex justify-content-center align-items-center p-2 mx-2">
            <img
              //   className="header-section--brand"
              // src={`${eventDetails?.logo}`}
              src={logoLight}
              alt="Company Logo"
            />
          </div>
        </section>
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ fontSize: 30, fontWeight: "bolder" }}
      >
        Webinar Pricing
      </div>
      <div className="mb-2 d-flex justify-content-center align-items-center">
        <div className="toggle-switch">
          <div
            className={`toggle-container ${isMonth ? "month" : "year"}`}
            onClick={toggleSelection}
          >
            <div
              className={`${
                isMonth ? "toggle-option light" : "toggle-option text"
              }`}
            >
              Month
            </div>
            <div
              className={`${
                isMonth ? "toggle-option text" : "toggle-option light"
              }`}
            >
              Year
            </div>
            <div className="toggle-button"></div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <Row>
          {pricing?.map((item, i) => {
            return (
              <Col sm={12} md={3} lg={3}>
                <Card className="" style={{ width: 250 }}>
                  <CardBody className="p-4">
                    <div className="d-flex mb-1">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={item.icon + " font-size-20"}></i>
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">{item.eventType}</h5>
                        <p className="text-muted">{item.eventFormat}</p>
                      </div>
                    </div>
                    <div className="py-4 border-bottom">
                      <div className="w-100 d-flex d-flex justify-content-center align-itmes-center">
                        {item.isContactSales ? null : (
                          <h4>
                            {/* <sup> */}
                            <small>From</small>
                            {/* </sup> */}
                            <span className="mx-1">R</span>
                            {isMonth ? (
                              <>
                                {item?.monthlyPrice}/
                                <span className="font-size-16">m</span>
                              </>
                            ) : (
                              <>
                                {item?.yearlyPrice}/
                                <span className="font-size-16">y</span>
                              </>
                            )}
                          </h4>
                        )}
                      </div>

                      <div className="w-100 justify-content-center align-itmes-center">
                        <div
                          className={
                            item.isContactSales
                              ? "w-100 d-flex justify-content-center align-itmes-center"
                              : "w-100 d-flex justify-content-center align-itmes-center"
                          }
                        >
                          <button
                            className="btn btn-primary btn-sm waves-effect waves-light"
                            onClick={() => {
                              if (item.isContactSales) {
                                toggleContactSale();
                                // navigation("/Talk-To-Us");
                              } else {
                                if (isMonth) {
                                  toggleBuyPackage(item.monthlyPrice);
                                } else {
                                  toggleBuyPackage(item.yearlyPrice);
                                }
                                // PushManager("/contacts");
                              }
                            }}
                          >
                            {item.isContactSales ? "Contact Sales" : "Buy Now"}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="plan-features mt-4">
                      <h5 className="text-center font-size-15 mb-4">
                        Plan Features :
                      </h5>
                      {item?.isChild?.map((subitem, key) => (
                        <p key={key}>
                          <i className="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i>{" "}
                          {subitem.features}
                        </p>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default Index;

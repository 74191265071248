import React, { useState } from "react";
// import faqImage from "../../../../src/assets/images/FAQ.png";
import { data } from "../../../components/Authentication/RequestDemo/faqData";
import "./Faq.css";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <div className="faq-demoSection">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-container-main p-2">
          <div className="faq-container-left">
            <div className="faq-demo-container">
              {data?.map((faq, index) => (
                <div
                  key={index}
                  className={`faq-demo-item ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div
                    className="faq-demo-question"
                    onClick={() => toggleFaq(index)}
                  >
                    {faq.question}
                    <span className="faq-demo-icon">
                      {activeIndex === index ? "−" : "+"}
                    </span>
                  </div>
                  <div
                    className="faq-demo-answer"
                    style={{
                      maxHeight: activeIndex === index ? "300px" : "0",
                      opacity: activeIndex === index ? "1" : "0",
                      transition: "max-height 0.5s ease, opacity 0.5s ease",
                    }}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="faq-container-right">
            <img src={faqImage} alt="FAQ" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default FAQs;

import React, { useState, useContext } from "react";
import userImg from "../../../../../assets/images/users/User.png";
import users from "./data";

import { useParams } from "react-router-dom";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import { ContactContext } from "../../../../../contexts/contact-context/contact.context";

//call here all the attenddees that have already accepted your invitation
function ContactList() {
  const attendee = users?.users;
  const { selectContact } = useContext(ContactContext);
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.data?._id;
  const token = obj?.data?.token;
  const { eventID } = useParams();

  // --------------------------------------- get  attendees --------------------------------get attendees ----------------------

  const {
    data: getAttendees,
    error: errorGetAttendees,
    isLoading: isLoadingGetAttendees,
  } = useCustomQuery(
    ["myAttendees", eventID],
    `${process.env.REACT_APP_API}attendees/${eventID}/event`,
    token
  );
  console.log("attttttttttttttttttttttttt", getAttendees);

  // user accepted requests
  const {
    data: getAcceptedRequests,
    error: errorGetAcceptedRequests,
    isLoading: isLoadingGetAcceptedRequests,
  } = useCustomQuery(
    ["myAcceptedRequests", eventID],
    `${process.env.REACT_APP_API}requests/requests/accepted/${userId}`,
    token
  );
  console.log("reeeeeeeeeeeee", getAcceptedRequests);

  const filteredAttendees = getAttendees?.filter(
    (att) => att?._id === "65b9f32a83bd66f0312c2fb1"
  );
  // console.log("filsssssssssss", filteredAttendees);

  // --------------------------------------- get  attendees --------------------------------get attendees ----------------------

  return (
    <>
      {getAttendees?.map((contact, i) => {
        return (
          <li
            className="p-2 border-bottom"
            key={i}
            onClick={() => selectContact(contact._id)}
          >
            <a href="#!" className="d-flex justify-content-between">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src={contact?.photo ? contact?.photo : userImg}
                    alt="avatar"
                    className="d-flex align-self-center me-3"
                    width="60"
                    height="60"
                    style={{ borderRadius: "50%" }}
                  />
                  <span className="badge bg-warning badge-dot"></span>
                </div>
                <div className="pt-1">
                  <p className="fw-bold mb-0">{`${contact?.firstName} ${contact?.lastName}`}</p>
                  <p className="small text-muted">{contact?.lastMessage}</p>
                </div>
              </div>
              <div className="pt-1">
                <p className="small text-muted mb-1">
                  {contact?.lastMessageOn}
                </p>
                {contact?.number ? (
                  <span className="badge bg-danger rounded-pill float-end">
                    {contact?.number}
                  </span>
                ) : null}
              </div>
            </a>
          </li>
        );
      })}
    </>
  );
}

export default ContactList;

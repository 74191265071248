import React, { useState, useRef, useEffect } from "react";
import { Spinner, Row, Col } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";
import { countryList } from "../Countries/countries";

function EditTime({ userID, token, eventDetails, eventID, eventFormat }) {
  const [inputs, setInputs] = useState({});

  const key = ["EventDetails", eventID];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (eventDetails) {
      const { venue, location, city, province, zipCode, country } =
        eventDetails;
      setInputs({
        venue,
        location,
        city,
        province,
        zipCode,
        country,
      });
    }
  }, [eventDetails]);

  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        {eventFormat === "In Person" ? (
          <div>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Venue Name </label>
                <input
                  type="text"
                  name="venue"
                  placeholder="Venue Name"
                  value={inputs.venue}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">
                  Physical Address
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Physical Address"
                  value={inputs.location}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">City </label>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={inputs.city}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Province</label>
                <input
                  type="text"
                  name="province"
                  value={inputs.province}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Zip </label>
                <input
                  type="text"
                  name="zipCode"
                  value={inputs.zipCode}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Country</label>
                <select
                  name="country"
                  value={inputs.country}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                >
                  {countryList.map((country, i) => (
                    <option key={i}> {country} </option>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
        ) : null}

        <div className="d-flex justify-content-start align-item-center">
          <div className="d-flex justify-content-start align-item-center">
            <button className="btn btn-primary rounded-5" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Update Format</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditTime;

export const pricing = [
  {
    eventType: "Basic",
    eventFormat: "Webinar",
    icon: "fas fa-cube",
    monthlyPrice: 100,
    yearlyPrice: 1200,
    isContactSales: false,
    isChild: [
      { id: "1", features: "100 Participants" },
      { id: "2", features: "Recording" },
      { id: "3", features: "10 speakers" },
      { id: "4", features: "Up to 8 hours" },
    ],
  },
  {
    eventType: "Standard",
    eventFormat: "Webinar",
    icon: "fas fa-cube",
    monthlyPrice: 300,
    yearlyPrice: 3600,
    isContactSales: false,
    isChild: [
      { id: "1", features: "250 Participants" },
      { id: "2", features: "Recording" },
      { id: "3", features: "10 speakers" },
      { id: "4", features: "Up to 8 hours" },
    ],
  },
  {
    eventType: "Premium",
    eventFormat: "Webinar",
    icon: "fas fa-cube",
    monthlyPrice: 600,
    yearlyPrice: 7200,
    isContactSales: false,
    isChild: [
      { id: "1", features: "500 Participants" },
      { id: "2", features: "Recording" },
      { id: "3", features: "10 speakers" },
      { id: "4", features: "Up to 8 hours" },
    ],
  },
  {
    eventType: "Custom",
    eventFormat: "Webinar",
    icon: "fas fa-cube",
    isContactSales: true,
    isChild: [
      { id: "1", features: "500+ Participants" },
      { id: "2", features: "Recording" },
      { id: "3", features: "10+ speakers" },
      { id: "4", features: "Up to 8 hours or more" },
    ],
  },
];

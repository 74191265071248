export const title = [
  "Mr",
  "Mrs",
  "Ms",
  "Sir",
  "Lord",
  "Lady",
  "Dr",
  "Professor",
];

import React, { useState } from "react";
// import useCustomQuery from "../../Hooks/useCustomQuery";
import { Container, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import OffCanvas from "../OffCanvas/OffCanvas";
import Picture from "../Profile/Picture";
import Contact from "./Contact";
// import Img from "../../assets/images/users/avatar-9.jpg";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";

const TableContacts = ({ myContacts, userID, token }) => {
  const [contactID, setContactID] = useState(false);
  const [contactDetails, setContactDetails] = useState(null);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const [isToggleView, setIsToggleView] = useState(false);
  const tog_viewContact = (detail) => {
    setContactDetails(detail);
    setIsToggleView(true);
  };
  const tog_closeContact = () => {
    setIsToggleView(!isToggleView);
  };
  const toggleDeleteMember = () => {};
  const tog_deleteMember = (id, email) => {
    setContactID(id);
    setContactDetails(email);
    setIsToggleDelete(!isToggleDelete);
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13"></span>,
      cell: (row) => {
        return (
          <>
            {row?.photo ? (
              <Picture
                showImage={row?.photo}
                iconSize={15}
                iconColor="#F68A20"
                imgHeight={40}
                imgWidth={40}
                imgBorder={10}
              />
            ) : (
              <Picture
                showImage={false}
                iconSize={15}
                iconColor="#F68A20"
                imgHeight={40}
                imgWidth={40}
                imgBorder="50%"
              />
            )}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">First name</span>,
      selector: (row) => row?.firstName,
    },
    {
      name: <span className="font-weight-bold fs-13">Last name</span>,
      selector: (row) => row?.lastName,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone Number</span>,
      selector: (row) => row?.phoneNumber,
    },

    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row?.jobTitle,
    },
    {
      name: <span className="font-weight-bold fs-13">Organization Name</span>,
      selector: (row) => row?.organization,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div>
            <span>
              <AiFillEye size={20} onClick={() => tog_viewContact(row)} />
            </span>
            <span className="mx-2">
              <AiFillDelete
                size={20}
                onClick={() => tog_deleteMember(row?._id, row?.email)}
              />
            </span>
          </div>
        );
      },
    },
  ];
  // console.log("userID...", userID);
  // console.log("token...", token);
  // const data = getCommunityMembers?.data;
  // console.log("getMyContacts...", myContacts);
  // console.log("contactDetails...", contactDetails);

  const data = myContacts;
  return (
    <div className="w-100">
      <Container>
        <Card>
          <CardBody className="w-100 rounded-4">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="300px"
              columns={columns}
              responsive
              data={data}
              pagination
            />
          </CardBody>
        </Card>
      </Container>
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Contact Details"
        body={<Contact details={contactDetails} />}
        toggleSaveChanges={() => tog_closeContact()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableContacts;

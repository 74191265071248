import React, { useState, useEffect } from "react";
import usePost from "../../../../Hooks/usePost";
// import useCustomMutation from "../../../../Hooks/useCustomMutation";
import EditProfilePicture from "../../../../components/Profile/EditProfilePicture";
import ButtonSubmit from "../../../../components/Buttons/ButtonSubmit";

const Profile = ({ userId, isEventOrganizer, userData, token }) => {
  const { execute, pending, data, status } = usePost();

  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});

  const SaveBtn = () => {
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    if (uploadImg) {
      formdata.append("photo", uploadImg);
    }
    execute(
      url,
      formdata,
      Method,
      "Profile successfully updated",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (status === "success") {
      const user = data?.data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authEventKagoUser", JSON.stringify(user));
      }
    }
    const { photo, firstName, lastName } = userData;
    setShowImage(photo);
    setInputs({
      firstName,
      lastName,
    });
  }, [status, userData, data?.data]);

  return (
    <div className="d-flex justify-content-between">
      <div className="w-100 d-flex">
        <div className="profile-img-container">
          <EditProfilePicture
            showImage={showImage}
            setShowImage={setShowImage}
            setUploadImg={setUploadImg}
            iconSize={80}
            iconColor="white"
            imgHeight={80}
            imgWidth={80}
            imgBorder={10}
            bgColor="#F68A20"
          />
        </div>

        <div className="d-flex justify-content-between align-items-center mx-3 profile-container-main">
          <div>
            <div className="profile-container-Title">
              {inputs?.firstName} {inputs?.lastName}
            </div>
            <div className="profile-container-subtitle">
              {isEventOrganizer ? "Event Organizer" : "Participant"}
            </div>
          </div>
          <ButtonSubmit
            Title="Save Change"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={SaveBtn}
            pending={pending}
          />
        </div>
      </div>
    </div>
  );
};
export default Profile;

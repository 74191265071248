import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function EventEmail({ token, eventDetails }) {
  const [inputs, setInputs] = useState({});

  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Email successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (eventDetails?._id) {
      setInputs((inputs) => ({
        ...inputs,
        supportMail: eventDetails?.supportMail,
        supportPhoneNumber: eventDetails?.supportPhoneNumber,
      }));
    }
  }, [eventDetails?._id]);
  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <label>Contact Details</label>
        </Row>
        <Row>
          <Col md={6}>
            <label className="event__form__Creation">Phone number</label>
            <div
              className="phone-input-container"
              style={{ backgroundColor: "white" }}
            >
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={
                  inputs.supportPhoneNumber ? inputs.supportPhoneNumber : null
                }
                onChange={(value) =>
                  setInputs({ ...inputs, supportPhoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <Col md={6}>
            <label className="event__form__Creation">Email </label>
            <input
              type="email"
              name="supportMail"
              value={inputs?.supportMail}
              onChange={handleChange}
              className="mb-3 form-control"
              required
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-start align-item-center">
          <div className="d-flex justify-content-start align-item-center">
            <button className="btn btn-primary rounded-5" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Update Contacts</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EventEmail;

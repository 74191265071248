import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import { truncate } from "lodash";

const StartConferenceSession = () => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userFullName = `${obj?.data?.data?.data?.firstName} ${obj?.data?.data?.data?.lastName}`;
  const userId = obj?.data?.data?.data?.id;
  const params = useParams();
  const eventID = params.eventID;
  const sessionID = params.sessionID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");
  document.title = `${myEventName} | Conference`;

  // console.log(".....eventID....", eventID);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_VIDEOSDK_API;
    const meetingId = `${sessionID}`;
    const name = `${userFullName}`;

    const config = {
      name: name,
      meetingId: meetingId,
      apiKey: apiKey,
      participantId: userId,
      region: "sg001", // region for new meeting

      // containerId: null,
      redirectOnLeave: `https://events.kago.digital/${eventName}/c/${eventID}`,
      theme: "DARK",
      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible

      chatEnabled: true,
      screenShareEnabled: true,
      pollEnabled: true,
      whiteboardEnabled: true,
      raiseHandEnabled: true,

      recording: {
        autoStart: false, // auto start recording on participant joined
        enabled: true,
        webhookUrl: "https://www.videosdk.live/callback",
        awsDirPath: `/kago/conference/${meetingId}/`, // automatically save recording in this s3 path
      },

      livestream: {
        autoStart: true,
        enabled: true,
      },

      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "SPEAKER", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },

      branding: {
        enabled: false,
        logoURL: null,
        name: myEventName,
        poweredBy: false,
      },

      permissions: {
        pin: true,
        askToJoin: false, // Permission to join
        canCreatePoll: true,
        toggleParticipantMic: true,
        toggleParticipantWebcam: true,
        drawOnWhiteboard: true,
        toggleWhiteboard: true,
        toggleVirtualBackground: true,
        toggleRecording: true,
        toggleLivestream: true,
        removeParticipant: true,
        endMeeting: true,
        changeLayout: true,
      },

      joinScreen: {
        visible: true, // Show the join screen ?
        title: myEventName, // Meeting title
        // meetingUrl: window.location.href, // Meeting joining url
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: "Kago Events", // action button label
          href: `https://events.kago.digital/${eventName}/c/${eventID}`, // action button href
        },
      },

      notificationSoundEnabled: true,

      debug: true, // pop up error during invalid config or netwrok error

      maxResolution: "hd",
      mode: "CONFERENCE",
      // For more features check: /prebuilt/guide/prebuilt-video-and-audio-calling/getting-started

      //Set Session Close And Time
      autoCloseConfig: {
        type: "session-end-and-deactivate",
        duration: 2,
      },
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
  }, []);

  return <div></div>;
};

export default StartConferenceSession;

import React from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonBanner = () => (
  <Box
    sx={{
      backgroundColor: "#fff",
      borderRadius: 2,
      mb: 4,
      overflow: "hidden",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Skeleton
      variant="rectangular"
      width="90%"
      height={400} // Adjust height to match the banner size
      sx={{
        mt: 0.3,
        mb: 1.5,
        borderRadius: 2,
      }}
    />
  </Box>
);

const SkeletonText = ({ width, height, sx = {} }) => (
  <Skeleton variant="text" width={width} height={height} sx={sx} />
);

const SkeletonCountdown = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      gap: 2,
      mb: 4,
    }}
  >
    {[...Array(4)].map((_, index) => (
      <Box
        key={index}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Skeleton variant="rectangular" width={80} height={80} />
        <Skeleton variant="text" width={50} height={20} />
      </Box>
    ))}
  </Box>
);

const SkeletonEventLanding = () => {
  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      {/* Large banner skeleton */}
      <SkeletonBanner />

      {/* Text for "About Event" */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <SkeletonText width="60%" height={40} sx={{ mx: "auto", mb: 1 }} />
        <SkeletonText width="40%" height={20} sx={{ mx: "auto", mb: 2 }} />
      </Box>

      {/* Countdown skeleton */}
      <SkeletonCountdown />

      {/* Footer skeleton */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <SkeletonText width="40%" height={20} sx={{ mx: "auto", mb: 1 }} />
        <SkeletonText width="30%" height={20} sx={{ mx: "auto", mb: 2 }} />
      </Box>
    </Box>
  );
};

export default SkeletonEventLanding;

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import Slide from "../../components/Authentication/Slide";
import OtpCustomForm from "../../components/Authentication/OtpCustomForm";

const OtpCustom = (props) => {
  document.title = "Kago Events| OTP";

  return (
    <div
      className="authentication-container"
      style={{ backgroundColor: "#f9c393" }}
    >
      <div className="authentication--wrapper">
        <div className="login--slider-container">
          <div className="login--slider-wrapper">
            <Slide />
          </div>
        </div>
        <div className="p-2 login--form--slider-container d-flex">
          <div className="login--form--slider-container--first"></div>

          <div
            className="login--form--slider-container--second d-flex align-items-center"
            style={{
              userSelect: "none",
            }}
          >
            <div className="h-100 d-flex align-items-center">
              <div
                className="w-100"
                style={{
                  userSelect: "none",

                  // backgroundColor: "yellow",
                }}
              >
                <div className="w-100 d-flex justify-content-center ">
                  <OtpCustomForm props={props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="authentication-brandMark">
        <Link
          to="https://www.tngsolutions.co.za/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "black",
          }}
        >
          By TNG Solutions | Developed in South Africa
        </Link>
      </div>
    </div>
  );
};

export default withRouter(OtpCustom);

OtpCustom.propTypes = {
  history: PropTypes.object,
};

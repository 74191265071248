import React from "react";
import ReactApexChart from "react-apexcharts";

const LineColumnAreaData = {
  series: [
    {
      name: "Event",
      type: "area",
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    },
    {
      name: "Attendee(s) per day",
      type: "area",
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    },
  ],
  options: {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
      background: "#fff", // Set the background color to white
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
      colors: ["#f68a20"], // Set the line color to orange
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    fill: {
      colors: ["#fce9d8"], // Set the fill color under the line
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    colors: ["#fce9d8", "#f68a20"],
    labels: [
      "01/01/2003",
      "02/01/2003",
      "03/01/2003",
      "04/01/2003",
      "05/01/2003",
      "06/01/2003",
      "07/01/2003",
      "08/01/2003",
      "09/01/2003",
      "10/01/2003",
      "11/01/2003",
    ],
    markers: {
      size: 0,
    },
    legend: {
      offsetY: -10,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Attendees",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Attendees";
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  },
};

const DayEventAttendance = () => {
  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart
        options={LineColumnAreaData.options}
        series={LineColumnAreaData.series}
        type="line"
        height="350"
      />
    </div>
  );
};

export default DayEventAttendance;

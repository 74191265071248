import React, { useEffect, useState } from "react";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import { useParams } from "react-router-dom";

export default function JoinConference() {
  const params = useParams();
  const eventID = params.eventID;
  const sessionID = params.sessionID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");
  const sessionName = params.sessionName;
  const mySessionName = sessionName.replace(/-/g, " ");
  const firstName = params.firstName;
  const lastName = params.lastName;
  const userFullName = `${firstName} ${lastName}`;
  useEffect(() => {
    const apiKey = "b6d0b019-fd1d-4773-ae33-547dd07381d6";
    // const apiKey = process.env.REACT_APP_VIDEOSDK_API_KEY;
    // const meetingId = `Conference/${eventName}/${sessionName}/${eventID}/${sessionID}`;
    const meetingId = `Conference-${eventID}-${sessionID}`;
    const name = `${userFullName}`;
    const config = {
      name: name,
      meetingId: meetingId,
      apiKey: apiKey,

      region: "sg001", // region for new meeting

      containerId: null,
      redirectOnLeave: `sessions/${eventID}`,

      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible

      chatEnabled: true,
      screenShareEnabled: false,
      pollEnabled: true,
      whiteboardEnabled: true,
      raiseHandEnabled: true,

      recording: {
        autoStart: false, // auto start recording on participant joined
        enabled: false,
        webhookUrl: "https://www.videosdk.live/callback",
        awsDirPath: `/meeting-recordings/conference/${meetingId}`, // automatically save recording in this s3 path
      },

      livestream: {
        autoStart: true,
        enabled: true,
      },

      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "SPEAKER", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },

      branding: {
        enabled: false,
        logoURL: null,
        name: mySessionName,
        poweredBy: false,
      },

      permissions: {
        pin: true,
        askToJoin: true, // Ask joined participants for entry in meeting
        canCreatePoll: false,
        toggleParticipantMic: false, // Can toggle other participant's mic
        toggleParticipantWebcam: false, // Can toggle other participant's webcam
        drawOnWhiteboard: false, // Can draw on whiteboard
        toggleWhiteboard: false, // Can toggle whiteboard
        toggleRecording: false, // Can toggle meeting recording
        toggleLivestream: false, //can toggle live stream
        removeParticipant: false, // Can remove participant
        endMeeting: false, // Can end meeting
        changeLayout: false, //can change layout
      },

      joinScreen: {
        visible: false, // Show the join screen ?
        title: sessionName, // Meeting title
        // meetingUrl: window.location.href, // Meeting joining url
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: "Kago Events", // action button label
          href: `sessions/${eventID}`, // action button href
        },
      },

      notificationSoundEnabled: true,

      debug: true, // pop up error during invalid config or netwrok error

      maxResolution: "hd", // "hd" or "sd"
      // you can change made to conference or viewer
      mode: "CONFERENCE",
      // For more features check: /prebuilt/guide/prebuilt-video-and-audio-calling/getting-started

      //seseeion set time
      autoCloseConfig: {
        type: "session-end-and-deactivate",
        duration: 1,
      },
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
  }, []);

  return <div></div>;
}

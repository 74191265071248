import React, { useState, useEffect } from "react";
import { Card, CardBody, Spinner, Collapse } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";
import classnames from "classnames";
import ButtonIcon from "../Buttons/ButtonIcon";
import OffCanvas from "../OffCanvas/OffCanvas";
import ModalDelete from "../Modal/ModalDelete";
import DeleteFaqForm from "./DeleteFaqForm";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import AddFAQ from "./AddFAQ";
import EditFaq from "./EditFaq";

function Faq({ token, eventDetails }) {
  const [faqID, setFaqID] = useState(null);
  const [collapseID, setCollapseID] = useState(null);

  const [isFaqOffcanvasOpen, setFaqIsOffcanvasOpen] = useState(false);
  const [isFaqEditOffcanvasOpen, setFaqEditIsOffcanvasOpen] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);

  const toggleAddFAQ = () => setFaqIsOffcanvasOpen(!isFaqOffcanvasOpen);
  const toggleEditFAQ = () =>
    setFaqEditIsOffcanvasOpen(!isFaqEditOffcanvasOpen);
  const key = ["EventDetails", eventDetails?._id];

  const handleDeleteFaq = (id) => {
    setFaqID(id);
    setIsToggleDelete(!isToggleDelete);
  };

  const handleEditFaq = (id) => {
    setFaqID(id);
    setFaqEditIsOffcanvasOpen(true);
  };

  const handleCollapse = (id) => {
    setCollapseID(collapseID === id ? null : id);
  };

  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <div className="w-100">
        <div className="m-2 d-flex justify-content-end">
          <ButtonIcon
            Title="Add FAQ"
            BackgroundColor="#f68a20"
            ColorText="#fff"
            BorderColor="#f68a20"
            borderRadius={50}
            handleOnclick={toggleAddFAQ}
            Icon={<></>}
            pending={false}
          />
        </div>

        <div
          className="accordion accordion-flush p-2"
          id="accordionFlushExample"
        >
          {eventDetails?.faq?.map((faq, index) => (
            <Card key={index}>
              <div className="accordion-item px-2 py-2">
                <h2 className="accordion-header" id={`headingFlush${faq?._id}`}>
                  <div className="d-flex gap-1">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: faq?._id !== collapseID,
                      })}
                      type="button"
                      onClick={() => handleCollapse(faq?._id)}
                      style={{ cursor: "pointer" }}
                    >
                      {faq?.question}
                    </button>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => handleEditFaq(faq?._id)}
                    >
                      <BiEdit color="black" size={18} />
                    </div>
                    <div
                      className="mx-1 d-flex justify-content-center align-items-center"
                      onClick={() => handleDeleteFaq(faq?._id)}
                    >
                      <MdDelete color="red" size={18} />
                    </div>
                  </div>
                </h2>

                <Collapse
                  isOpen={faq?._id === collapseID}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="text-muted">{faq?.answer}</div>
                  </div>
                </Collapse>
              </div>
            </Card>
          ))}
        </div>
      </div>

      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Message"
        body={
          <DeleteFaqForm
            isToggleDelete={isToggleDelete}
            setIsToggleDelete={setIsToggleDelete}
            faqID={faqID}
            token={token}
            eventID={eventDetails?._id}
          />
        }
      />

      <OffCanvas
        isOffcanvasOpen={isFaqOffcanvasOpen}
        title="Add FAQ"
        body={
          <AddFAQ
            eventID={eventDetails?._id}
            token={token}
            toggleAddFAQ={toggleAddFAQ}
          />
        }
        toggleSaveChanges={toggleAddFAQ}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
      <OffCanvas
        isOffcanvasOpen={isFaqEditOffcanvasOpen}
        title="Edit FAQ"
        body={
          <EditFaq
            eventID={eventDetails?._id}
            faqID={faqID}
            token={token}
            toggleEditFAQ={toggleEditFAQ}
          />
        }
        toggleSaveChanges={toggleEditFAQ}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
}

export default Faq;

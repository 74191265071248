import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./buyWebinar.scss";
import logoLight from "../../../../assets/images/Kago-color-logo.png";
import ButtonSubmit from "../../../../components/Buttons/ButtonStyle";

const ZoomWebinar = () => {
  const navigation = useNavigate();
  const [participants, setParticipants] = useState(500);
  const [licenses, setLicenses] = useState(1);
  const [billingCycle, setBillingCycle] = useState("annual");

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    showPassword: false,
    rememberMe: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleParticipantsChange = (event) => {
    setParticipants(event.target.value);
  };

  const handleLicensesChange = (delta) => {
    setLicenses(licenses + delta);
  };

  const handleBillingCycleChange = (cycle) => {
    setBillingCycle(cycle);
  };

  const handleRegister = () => {
    navigation("/login");
  };
  return (
    <div className="w-100">
      <div className="w-100 mb-3" style={{ backgroundColor: "white" }}>
        <section className="header-section ps">
          <div className="d-flex justify-content-center align-items-center p-2 mx-2">
            <img
              //   className="header-section--brand"
              // src={`${eventDetails?.logo}`}
              src={logoLight}
              alt="Company Logo"
            />
          </div>
        </section>
      </div>
      <div
        className="w-100 p-3"
        style={{ backgroundColor: "white", borderRadius: 20, margin: 5 }}
      >
        <Row>
          <Col md={8}>
            <div
              className="w-100 p-3"
              style={{
                borderWidth: 1,
                borderRadius: 10,
                borderStyle: "solid",
                borderColor: "black",
              }}
            >
              <Row>
                <div
                  style={{ fontSize: 26, fontWeight: "bolder", color: "black" }}
                >
                  Customer Info
                </div>
                <Col sm={12} md={4}>
                  <div className="form-field">
                    <label>First Name</label>
                    <input
                      type="text"
                      value={values.firstName}
                      onChange={handleChange("firstName")}
                      className=""
                      name="firstName" // Unique name for the email input
                    />
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="form-field">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={values.lastName}
                      onChange={handleChange("lastName")}
                      className=""
                      name="lastName" // Unique name for the email input
                    />
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="form-field">
                    <label>Email</label>
                    <input
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      className=""
                      name="email" // Unique name for the email input
                    />
                  </div>
                </Col>
                <Col sm={12} md={8}>
                  <div className="form-field">
                    <label>Address</label>
                    <input
                      type="text"
                      value={values.email}
                      onChange={handleChange("email")}
                      className=""
                      name="email" // Unique name for the email input
                    />
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="form-field">
                    <label>Select Country</label>
                    <input
                      type="text"
                      value={values.lastName}
                      onChange={handleChange("lastName")}
                      className=""
                      name="lastName" // Unique name for the email input
                    />
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="form-field">
                    <label>City</label>
                    <input
                      type="text"
                      value={values.email}
                      onChange={handleChange("email")}
                      className=""
                      name="email" // Unique name for the email input
                    />
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="form-field">
                    <label>Province</label>
                    <input
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      className=""
                      name="email" // Unique name for the email input
                    />
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="form-field">
                    <label>Zip/Postal</label>
                    <input
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      className=""
                      name="email" // Unique name for the email input
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <div
                  style={{ fontSize: 26, fontWeight: "bolder", color: "black" }}
                >
                  Payment Info
                </div>
                <Col sm={12} md={8}>
                  <Row>
                    <Col sm={12} md={6}>
                      <div className="form-field">
                        <label>Account Name</label>
                        <input
                          type="text"
                          value={values.email}
                          onChange={handleChange("email")}
                          className=""
                          name="email" // Unique name for the email input
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="form-field">
                        <label>Credit Card Number</label>
                        <input
                          type="text"
                          value={values.email}
                          onChange={handleChange("email")}
                          className=""
                          name="email" // Unique name for the email input
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="form-field">
                        <label>Month</label>
                        <input
                          type="text"
                          value={values.email}
                          onChange={handleChange("email")}
                          className=""
                          name="email" // Unique name for the email input
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="form-field">
                        <label>Year</label>
                        <input
                          type="text"
                          value={values.email}
                          onChange={handleChange("email")}
                          className=""
                          name="email" // Unique name for the email input
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="form-field">
                        <label>CVC</label>
                        <input
                          type="text"
                          value={values.email}
                          onChange={handleChange("email")}
                          className=""
                          name="email" // Unique name for the email input
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="d-flex justify-content-center">
                <ButtonSubmit
                  Title="Complete Checkout and Pay"
                  BackgroundColor="#f68a20"
                  ColorText="#fff"
                  BorderColor="#f68a20"
                  borderRadius={15}
                  handleOnclick={handleRegister}
                  pending={false}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="zoom-webinar">
              <h1>Kago Webinars</h1>
              <p>
                Engage your audiences and broadcast your message with
                high-quality video broadcast from Kago. Up to 10,000
                participants and 100 panelists.
              </p>
              <div className="participants-selection">
                <select
                  value={participants}
                  onChange={handleParticipantsChange}
                >
                  <option value="100">100 Participants</option>
                  <option value="500">500 Participants</option>
                  <option value="1000">1000 Participants</option>
                  <option value="5000">5000 Participants</option>
                  <option value="10000">10000 Participants</option>
                </select>
                <div className="license-controls">
                  <button
                    onClick={() => handleLicensesChange(-1)}
                    disabled={licenses <= 1}
                  >
                    -
                  </button>
                  <span>{licenses}</span>
                  <button onClick={() => handleLicensesChange(1)}>+</button>
                  <span>Event(s)</span>
                </div>
              </div>
              <div className="billing-cycle">
                <button
                  className={billingCycle === "monthly" ? "active" : ""}
                  onClick={() => handleBillingCycleChange("monthly")}
                >
                  Monthly
                  <br />R {300 * licenses}/month/license
                </button>
                <button
                  className={billingCycle === "annual" ? "active" : ""}
                  onClick={() => handleBillingCycleChange("annual")}
                >
                  Annual
                  <br />R {1200 * licenses}/ year / license
                  <span className="save-tag">Save 27%</span>
                </button>
              </div>
              {/* <a href="#">+ Add Another Webinar Plan</a> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ZoomWebinar;

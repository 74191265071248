import React, { useState } from "react";
import useCustomMutation from "../../Hooks/useCustomMutation";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import EditCompanyLogo from "./EditCompanyLogo";
import AddCompanyLogo from "./AddCompanyLogo";

import ButtonSubmit from "../Buttons/ButtonSubmit";
import ModalDelete from "../Modal/ModalDelete";
import DeleteCommunity from "./DeleteCommunity";
import CommunityDetails from "./CommunityDetails";

function Settings({ getCommunityDetails, communityID, token, userEmail }) {
  const [inputs, setInputs] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [communityBannerSever, setCommunityBannerSever] = useState(null);
  const key = ["CommunityDetails", communityID];
  const keyDelete = ["MyCommunities", userEmail];
  const mutation = useCustomMutation(key);

  // getCommunityDetails;
  //  const {
  //    data: getCommunityDetails,
  //    error: errorGetCommunities,
  //    isLoading: isLoadingGetCommunityDetails,
  //  } = useCustomQuery(
  //    ["CommunityDetails", communityID],
  //    `${process.env.REACT_APP_API}communities/${communityID}`,
  //    token
  //   );

  //updating company Logo
  //   const hangeLogo = (e) => {
  //    const Method = "POST",
  //      url = `communities/brand/${communityID}`,
  //      isJSON = true;
  //     const formdata = new FormData();
  //     formdata.append("logo", logorSever);
  // }
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `communities/${communityID}`,
      isJSON = true;
    const formdata = new FormData();

    if (communityBannerSever) {
      formdata.append("banner", communityBannerSever);
    }
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Community successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const toggleDelete = () => {
    setIsDelete(!isDelete);
  };

  const {
    data: getLogo,
    error: errorGetLogo,
    isLoading: isLoadingGetLogo,
  } = useCustomFecthQuery(
    ["CommunityBranding", communityID],
    `${process.env.REACT_APP_API}communities/brand/${communityID}`,
    token,
    { enabled: !!communityID }
  );

  // console.log("Logo ........###....", getLogo);

  const isLogo = getLogo?.data?.length === 0 && getLogo?.status === "ok";
  // console.log("getCommunityDetails...", getCommunityDetails);

  return (
    <div className="w-100 mt-3 d-flex justify-content-center">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <div className="w-100 mt-3 d-flex justify-content-center">
          <div
            className="w-75  p-3 rounded-3"
            style={{ backgroundColor: "white" }}
          >
            <div className="w-100 d-flex">
              {isLogo ? (
                <AddCompanyLogo communityID={communityID} token={token} />
              ) : (
                <EditCompanyLogo
                  communityID={communityID}
                  token={token}
                  getLogo={getLogo?.data}
                />
              )}

              <div
                className="d-flex justify-content-end align-items-center"
                style={{ width: "35%", height: 35, gap: 4 }}
              >
                <ButtonSubmit
                  Title="Delete Community"
                  ColorText="white"
                  BorderColor="black"
                  BackgroundColor="black"
                  borderRadius="25px"
                  handleOnclick={() => toggleDelete()}
                  pending={false}
                />
                <ButtonSubmit
                  Title="Save Now"
                  ColorText="white"
                  BorderColor="#F68A20"
                  BackgroundColor="#F68A20"
                  borderRadius="25px"
                  handleOnclick={(e) => toggleSaveChanges(e)}
                  pending={mutation?.isLoading}
                />
              </div>
            </div>
            <hr></hr>
            <CommunityDetails
              getCommunityDetails={getCommunityDetails}
              inputs={inputs}
              setInputs={setInputs}
              communityID={communityID}
              communityBannerSever={communityBannerSever}
              setCommunityBannerSever={setCommunityBannerSever}
            />
          </div>
        </div>
      </form>

      <ModalDelete
        size="md"
        isOpen={isDelete}
        toggle={toggleDelete}
        title="Delete Community"
        body={
          <DeleteCommunity
            communityID={communityID}
            userEmail={userEmail}
            token={token}
            setIsDelete={setIsDelete}
          />
        }
      />
    </div>
  );
}

export default Settings;

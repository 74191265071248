import React, { useRef, useState } from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  Modal,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";

import { IoArrowBack } from "react-icons/io5";
import { IoArrowForwardOutline } from "react-icons/io5";
import { BsFillShareFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

import classnames from "classnames";

import SummaryTab from "./TabSummary/Summary";
// import SummarySocialTab from "./SummarySocialTab";
// import TicketingTab from "./TicketingTab";
import TicketingTab from "./TabTicket/TicketingTab";
import SessionsTab from "./SessionsTab";
import ActivityTab from "./ActivityTab";
import BrandingTab from "./BrandingTab";
import ExhibitionTab from "./TabExhibition/TabExhibition";
// import AnalyticsTab from "./AnalyticsTab";
import AnalyticsTab from "./TabAnalytics/Analytics";

const SummaryNavigation = ({ userId, token, eventID }) => {
  const Navigate = useNavigate();
  const queryClient = useQueryClient();

  const [activeEventMenu, setactiveEventMenu] = useState("5");

  // link
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }

  const toggle2 = (tab) => {
    if (activeEventMenu !== tab) {
      setactiveEventMenu(tab);
    }
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const key = ["myEvents", userId];
  const mutationPublish = useCustomMutation(key);
  const mutationUnPublish = useCustomMutation(key);
  const mutationDelete = useCustomMutation(key);

  // modal
  const [isEventDelete, setIsEventDelete] = useState(false);
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [modal_center, setmodal_center] = useState(false);

  const handleCheckEventName = (e) => {
    if (
      getMyEventDetail?.eventName?.toLowerCase() ===
      e.target.value.toLowerCase()
    ) {
      setIsEventDelete(true);
    } else {
      setIsEventDelete(false);
    }
  };
  function tog_deleteEvent() {
    setDeleteEventModal(!deleteEventModal);
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_center1() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  const Publish = () => {
    const Method = "PATCH",
      url = `events/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("state", "Published");
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Successfully Published",
      token: token,
      isJSON: isJSON,
    };
    mutationPublish.mutate(data);
  };

  const Unpublish = () => {
    const Method = "PATCH",
      url = `events/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("state", "Not Published");
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Successfully Unpublished",
      token: token,
      isJSON: isJSON,
    };
    mutationUnPublish.mutate(data);
  };

  const DeleteEvent = () => {
    const Method = "DELETE",
      url = `events/${eventID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Event Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  // Usage

  const getStartDate = getMyEventDetail?.startDate;
  // Convert event start date
  const date = new Date(getStartDate);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const startDate = date.toLocaleDateString("en-US", options);
  const getEndDate = getMyEventDetail?.endDate;
  const endDate = new Date(getEndDate);
  const optionEndDate = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const endDateEvent = endDate.toLocaleDateString("en-US", optionEndDate);

  if (mutationPublish.isSuccess) {
    // Navigate("/dashboard");
    queryClient.invalidateQueries(["myEventDetail", eventID]);
  }
  if (mutationUnPublish.isSuccess) {
    // Navigate("/dashboard");
    queryClient.invalidateQueries(["myEventDetail", eventID]);
  }

  if (mutationDelete.isSuccess) {
    Navigate("/dashboard");
  }

  // console.log("event......@@@@@@......#########...", getMyEventDetail);

  return (
    <div>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <NavbarBrand href="/dashboard">
          <div className="d-flex">
            <div style={{ paddingLeft: 36, paddingRight: 18 }}>
              <IoArrowBack size={30} />
            </div>
            <div>
              <h3 style={{ color: "#000" }}>
                {getMyEventDetail?.data?.eventName}

                <sup
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    color:
                      getMyEventDetail?.data?.state === "Published"
                        ? "#45cb85"
                        : "red",
                    marginLeft: 8,
                  }}
                >
                  &#8226;
                  {getMyEventDetail?.data?.state}
                </sup>
              </h3>
              <p style={{ fontSize: 12 }}>
                <i
                  className="fas fa-calendar"
                  style={{ marginLeft: 4, marginRight: 8 }}
                ></i>
                {startDate}, {getMyEventDetail?.data?.startTime} -{" "}
                {endDateEvent}, {getMyEventDetail?.data?.endTime} SAST
              </p>
            </div>
          </div>
        </NavbarBrand>

        <Nav className="ml-auto px-4" navbar>
          <div className="d-flex justify-content-center">
            <NavItem className="px-2">
              <NavLink href="#">
                <button
                  type="button"
                  className="btn btn-dark waves-effect waves-light"
                  onClick={() => {
                    tog_center1();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-center"
                >
                  <div className="d-flex justify-content-center align-center ">
                    <span>
                      <BsFillShareFill size={20} />
                    </span>
                    <span className="px-2">Share Event</span>
                  </div>
                </button>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center();
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Copy the link</h5>

                    <button
                      type="button"
                      onClick={() => {
                        setmodal_center(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex justify-content-center align-items-center">
                      <form>
                        <input
                          className="form-control fromInput"
                          ref={textAreaRef}
                          value={`https://www.event.showbay24com/`}
                        />
                      </form>
                      {
                        /* Logical shortcut for only displaying the 
          button if the copy command exists */
                        document.queryCommandSupported("copy") && (
                          <div className="px-3">
                            <button
                              className=" btn btn-primary btn-md"
                              onClick={copyToClipboard}
                            >
                              Copy
                            </button>
                            <span className="px-2 text-success">
                              {copySuccess}
                            </span>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </Modal>
              </NavLink>
            </NavItem>
            {getMyEventDetail?.state === "Not Published" ? (
              <NavItem className="px-2">
                <NavLink href="#">
                  {/* <Link to={`/organizer/join-event/${eventID}`}> */}
                  <button
                    className="btn btn-success waves-effect waves-light Publish__btn__event"
                    onClick={Publish}
                  >
                    {!mutationPublish.isLoading ? (
                      `Publish Event`
                    ) : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </button>
                  {/* </Link> */}
                </NavLink>
              </NavItem>
            ) : null}
            {getMyEventDetail?.state === "Published" ? (
              <NavItem className="px-2">
                <NavLink href="#">
                  <button
                    className="btn btn-dark waves-effect waves-light Publish__btn__event"
                    onClick={Unpublish}
                  >
                    {!mutationUnPublish.isLoading ? (
                      `Unpublish Event`
                    ) : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </button>
                </NavLink>
              </NavItem>
            ) : null}
            <NavItem className="px-2">
              <NavLink href="#">
                <button
                  className="btn btn-success waves-effect waves-light"
                  onClick={() => {
                    Navigate(
                      `/${getMyEventDetail?.data?.eventName?.replace(
                        / /g,
                        "-"
                      )}/event-reception/${eventID}`
                    );
                  }}
                >
                  <div className="d-flex justify-content-center align-center ">
                    <span className="px-2">Enter Event</span>
                    <span>
                      <IoArrowForwardOutline size={20} />
                    </span>
                  </div>
                </button>
              </NavLink>
            </NavItem>
            <NavItem className="px-2">
              <NavLink href="#">
                <Link>
                  <button
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => {
                      setDeleteEventModal(true);
                    }}
                  >
                    <div className="d-flex justify-content-center align-center ">
                      Delete Event
                    </div>
                  </button>
                  <Modal
                    size="md"
                    isOpen={deleteEventModal}
                    toggle={() => {
                      tog_deleteEvent();
                    }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="mySmallModalLabel">
                        Delete Event
                      </h5>
                      <button
                        onClick={() => {
                          setDeleteEventModal(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p className="">
                        Everything in
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: "900",
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        >
                          {getMyEventDetail?.data?.eventName}
                        </span>
                        will be permenantley deleted.
                      </p>
                      <p>To Delete Enter the name of your event</p>
                      <form>
                        <input
                          type="text"
                          placeholder=""
                          onChange={handleCheckEventName}
                          className="mb-3 form-control fromInput w-100"
                        />
                      </form>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        onClick={() => {
                          setDeleteEventModal(false);
                        }}
                        type="button"
                        className="btn btn-dark"
                      >
                        Cancel
                      </button>
                      {isEventDelete ? (
                        <button
                          onClick={() => DeleteEvent()}
                          type="button"
                          className="btn btn-danger"
                          disabled={!isEventDelete}
                        >
                          {!mutationDelete?.isLoading ? (
                            <span className="px-2">Sure</span>
                          ) : (
                            <span>
                              <Spinner as="span" animation="border" size="sm" />
                              Loading
                            </span>
                          )}
                        </button>
                      ) : null}
                    </div>
                  </Modal>
                </Link>
              </NavLink>
            </NavItem>
          </div>
        </Nav>
      </Navbar>
      <Card>
        <Row>
          <Col md={12}>
            <div className="mt-2 mb-2 d-flex justify-content-center">
              <Nav
                pills
                className="navtab-bg nav-justified"
                style={{ width: "60%", marginLeft: "60px" }}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeEventMenu === "5",
                    })}
                    onClick={() => {
                      toggle2("5");
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      {/* <span className="">
                <i className="fas fa-home"></i>
              </span> */}
                      <span className="d-none d-sm-block">Summary</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeEventMenu === "6",
                    })}
                    onClick={() => {
                      toggle2("6");
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span className="d-none d-sm-block">Ticketing</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeEventMenu === "7",
                    })}
                    onClick={() => {
                      toggle2("7");
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span className="d-none d-sm-block">Participants</span>
                    </div>
                  </NavLink>
                </NavItem>
                {getMyEventDetail?.data?.eventType?.includes("Conference") ? (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeEventMenu === "8",
                      })}
                      onClick={() => {
                        toggle2("8");
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <span className="d-none d-sm-block">Sessions</span>
                      </div>
                    </NavLink>
                  </NavItem>
                ) : null}
                {getMyEventDetail?.data?.eventType?.includes("Social") ? (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeEventMenu === "9",
                      })}
                      onClick={() => {
                        toggle2("9");
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <span className="d-none d-sm-block">Activities</span>
                      </div>
                    </NavLink>
                  </NavItem>
                ) : null}
                {getMyEventDetail?.data?.eventType?.includes("Exhibition") ? (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeEventMenu === "10",
                      })}
                      onClick={() => {
                        toggle2("10");
                      }}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{ width: 150 }}
                      >
                        <span className="d-none d-sm-block">
                          Exhibition Lobby
                        </span>
                      </div>
                    </NavLink>
                  </NavItem>
                ) : null}

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeEventMenu === "11",
                    })}
                    onClick={() => {
                      toggle2("11");
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span className="d-none d-sm-block">Branding</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeEventMenu === "12",
                    })}
                    onClick={() => {
                      toggle2("12");
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span className="d-none d-sm-block">Analytics</span>
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
      </Card>
      <TabContent activeTab={activeEventMenu}>
        <TabPane tabId="5">
          <SummaryTab
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetails={getMyEventDetail?.data}
            envetType={getMyEventDetail?.data?.eventType}
          />
        </TabPane>
        <TabPane tabId="6">
          <TicketingTab userId={userId} token={token} eventID={eventID} />
        </TabPane>
        <TabPane tabId="8">
          <SessionsTab userId={userId} token={token} eventID={eventID} />
        </TabPane>
        <TabPane tabId="9">
          <ActivityTab userId={userId} token={token} eventID={eventID} />
        </TabPane>
        <TabPane tabId="10">
          <ExhibitionTab userId={userId} token={token} eventID={eventID} />
        </TabPane>
        <TabPane tabId="11">
          <BrandingTab userId={userId} token={token} eventID={eventID} />
        </TabPane>
        <TabPane tabId="12">
          <AnalyticsTab userId={userId} token={token} eventID={eventID} />
        </TabPane>

        <TabPane tabId="13">
          {/* <AnalyticsTab userId={userId} token={token} eventID={eventID} /> */}
        </TabPane>
      </TabContent>
    </div>
  );
};
export default SummaryNavigation;

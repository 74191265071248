import React from "react";
import ShowbayLogo from "../../../assets/images/showbay-color.png";
import "./InvoiceDetails.scss";

function InvoiceDetails({ invoiceInfo }) {
  console.log("invoiceInfo......", invoiceInfo);
  const date = new Date(invoiceInfo?.created_at);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  let InvoiceDate = `${year}/${month}/${day}`;
  return (
    <div className="w-100">
      <div className="w-100  d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <div className="w-100">
            <div style={{ fontSize: 22, fontWeight: "bold", color: "black" }}>
              Invoice
            </div>
            <div>{invoiceInfo?.invoiceNumber}</div>
            {/* <div style={{ fontWeight: "bold", color: "black" }}>
              {invoiceInfo?.item}
            </div> */}
          </div>
        </div>
        <div style={{ width: 120, height: 30 }}>
          <img src={ShowbayLogo} alt="showbay-logo" className="w-100 h-100" />
        </div>
      </div>
      <div className="w-100 d-flex">
        <div className="w-100 issued--invoice--container">
          <div style={{ fontSize: 14, fontWeight: "bold", color: "black" }}>
            Issued
          </div>
          <div>{InvoiceDate}</div>
        </div>
        <div className="w-100 billed--invoice--container">
          <div style={{ fontSize: 14, fontWeight: "bold", color: "black" }}>
            Billed
          </div>
          <div>{invoiceInfo?.companyAddress?.billingAddress}</div>
          <div>{invoiceInfo?.companyAddress?.city}</div>
        </div>
        <div className="w-100 from--invoice--container">
          <div style={{ fontSize: 14, fontWeight: "bold", color: "black" }}>
            From
          </div>
          <div
            className="text-primary"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            Kago Events
          </div>
          <div>Sales@showbay24.com</div>
        </div>
      </div>
      <div
        className="mt-5 w-100 d-flex table--header--invoice--container"
        style={{ fontWeight: "bold", color: "black" }}
      >
        <div className="invoice--event--name">Event Name</div>
        <div className="invoice--event--type">Type</div>
        <div className="invoice--event--quantity">Qty</div>
        <div className="invoice--event--price">Price</div>
      </div>
      <div className="w-100 d-flex invoice--event--details-container">
        <div
          className="invoice--event--name"
          style={{ fontWeight: "bold", color: "black" }}
        >
          {invoiceInfo?.item}
        </div>
        <div className="invoice--event--type">{invoiceInfo?.details}</div>
        <div className="invoice--event--quantity">{invoiceInfo?.units}</div>
        <div className="invoice--event--price">R {invoiceInfo?.total}</div>
      </div>
      <div className="w-100 d-flex">
        <div className="invoice--event--name"></div>
        <div className="invoice--event--pricing">
          <div className="w-100 d-flex">
            <div
              className="invoice--event--subtotal"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Subtotal
            </div>
            <div className="invoice--event--totalAmount">
              R {invoiceInfo?.total}
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex">
        <div className="invoice--event--name"></div>
        <div className="invoice--event--pricing">
          <div className="w-100 d-flex">
            <div
              className="invoice--event--subtotal"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Tax
            </div>
            <div className="invoice--event--totalAmount">0%</div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex">
        <div className="invoice--event--name"></div>
        <div className="invoice--event--final--price">
          <div className="w-100 d-flex">
            <div
              className="invoice--event--subtotal"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Total
            </div>
            <div
              className="invoice--event--totalAmount"
              style={{ fontWeight: "bold", color: "black" }}
            >
              R {invoiceInfo?.totalAmount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDetails;

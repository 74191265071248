import React from "react";
import { Box, Skeleton } from "@mui/material";

const LoaderImage = ({ WIDTH, HEIGHT, BORDERRADIUS }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={WIDTH}
        height={HEIGHT}
        sx={{
          mt: 0.3,
          mb: 1.5,
          borderRadius: BORDERRADIUS,
        }}
      />
    </Box>
  );
};

export default LoaderImage;

import React, { useState, useEffect } from "react";
import useCustomMutation from "../../Hooks/useCustomMutation";
import ButtonSubmit from "../Buttons/ButtonSubmit";

function AddMember({ token, communityID, setModal }) {
  const [userRole, setEUserRole] = useState("Admin");
  const [inputs, setInputs] = useState({});
  const key = ["CommunityMembers", communityID];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const adminFunc = (e) => {
    setEUserRole("Admin");
  };
  const managerFunc = (e) => {
    setEUserRole("Community Manager");
  };
  const eventFunc = (e) => {
    setEUserRole("Event Manager");
  };
  const memberFunc = (e) => {
    setEUserRole("Regular");
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setModal(false);
    }
  }, [mutation?.isSuccess]);

  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `communities/members/${communityID}`;
    const raw = JSON.stringify({
      email: inputs?.email,
      role: userRole,
    });
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Member successfully invited",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };
  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <div style={{ width: 500 }}>
          <div style={{ fontSize: 18, fontWeight: "600" }}>Invite a member</div>

          <p>
            Invite your team to join your organization so they can manage its
            events.
          </p>
          <div>
            <span className="text-danger">*</span>Email
          </div>
          <input
            type="email"
            name="email"
            value={inputs?.email}
            onChange={handleChange}
            style={{ width: 500, borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
          <label>Select a role</label>
          <div style={{ textAlign: "justify" }}>
            Administrators can create new events and invite members to the
            organization. Event managers can only edit existing events.
          </div>
          <div className="mb-3">
            <div className="form-check mt-2 mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="formatRadio"
                id="exampleAdmin"
                value="Public"
                onChange={(e) => adminFunc(e.target.value)}
                defaultChecked
              />
              <label className="form-check-label" htmlFor="exampleAdmin">
                Admin
              </label>
            </div>
            <div className="">
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formatRadio"
                  id="exampleManager"
                  value={"In Person"}
                  onChange={(e) => managerFunc(e.target.value)}
                />
                <label className="form-check-label" htmlFor="exampleManager">
                  Community Manager
                </label>
              </div>
            </div>
            <div className="">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formatRadio"
                  id="exampleEvent"
                  value={"Virtual"}
                  onChange={(e) => eventFunc(e.target.value)}
                />
                <label className="form-check-label" htmlFor="exampleEvent">
                  Event Manager
                </label>
              </div>
            </div>
            <div className="">
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formatRadio"
                  id="exampleMember"
                  value={"Virtual"}
                  onChange={(e) => memberFunc(e.target.value)}
                />
                <label className="form-check-label" htmlFor="examplMember">
                  Member
                </label>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Add Member"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddMember;

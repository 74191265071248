const formatDate = (dateString) => {
  // data convert to this format    "2024-08-05T22:00:00.000Z";
  if (!dateString) return ""; // Check if dateString is empty or undefined
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    // Invalid date check
    return "";
  }

  return date.toISOString();
  // const date = new Date(dateString);
  // return date.toISOString();
};

export default formatDate;

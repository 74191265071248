import React from "react";
// import PropTypes from "prop-types";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import ShowEvents from "./components/ShowEvents";

const Explore = () => {
  document.title = "Kago Events | Explore Events";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userRole = obj?.data?.data?.data?.roles;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content" style={{ userSelect: "none" }}>
        <Container fluid={true}>
          <ShowEvents t={t} userRole={userRole} />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Explore;

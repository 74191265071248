import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import ProfilePicture from "../../../Profile/ProfilePicture";
import { countryList } from "../../../Countries/countries";

function AddExhibitor({
  userId,
  eventID,
  token,
  setIsOffcanvasOpen,
  eventFormat,
}) {
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const key = ["mySpeakers", eventID];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `exhibitors/exhibitor/${eventID}`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("boothNumber", inputs.boothNumber);
    formdata.append("firstName", inputs.firstName);
    formdata.append("lastName", inputs.lastName);
    formdata.append("email", inputs.email);
    formdata.append("organizationNumber", inputs.organizationNumber);
    formdata.append("organization", inputs.organization);
    formdata.append("duration", inputs.duration);
    formdata.append("city", inputs.city);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Exhibitor successfully invited",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);
  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <Row>
          {/* <Col md={12}>
            <div className="d-flex mb-3 justify-content-center">
              <ProfilePicture
                userImage={true}
                uploadImg={true}
                setUploadImg={setUploadImg}
                iconSize={80}
                iconColor="#F68A20"
                imgHeight={80}
                imgWidth={80}
                imgBorder="50%"
              />
            </div>
          </Col> */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Booth Reference
            </label>
            <input
              type="text"
              name="boothNumber"
              value={inputs?.boothNumber}
              placeholder="Enter Booth Reference"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Booth Duration
            </label>
            <input
              type="number"
              name="duration"
              value={inputs?.duration}
              placeholder="1 - 80 minute(s)"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>First name
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs?.firstName}
              placeholder="Enter First name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Last name
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs?.lastName}
              placeholder="Enter Last name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            {" "}
            <label>
              <span className="text-danger">*</span>Email
            </label>
            <input
              type="email"
              name="email"
              value={inputs?.email}
              placeholder="Enter email"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Job Title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={inputs?.jobTitle}
              placeholder="Enter Job Title"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Organization Name
            </label>
            <input
              type="text"
              name="organization"
              value={inputs?.organization}
              placeholder="Enter organization name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Cellphone Number
            </label>
            <input
              type="text"
              name="organizationNumber"
              value={inputs?.organizationNumber}
              placeholder="Enter Cellphone Number"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>City
            </label>
            <input
              type="text"
              name="city"
              value={inputs?.city}
              placeholder="Enter City"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label className="">Country</label>
            <select
              name="country"
              value={inputs.country}
              onChange={handleChange}
              className="mb-3 form-control fromInput"
              style={{ width: 300, borderRadius: 7 }}
              required
            >
              {countryList?.map((country, i) => (
                <option key={i}> {country} </option>
              ))}
            </select>
          </Col>
          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Submit"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default AddExhibitor;

import React, { useState, useEffect } from "react";
import { Progress } from "reactstrap";
import DataTable from "react-data-table-component";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ModalDelete from "../../../Modal/ModalDelete";
// import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import { AiFillEye } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import DeleteTicketForm from "./DeleteTicketForm";
import ViewTicket from "./ViewTicket";
import EditTicket from "./EditTicket";

import readDate from "../../../../utils/readDate";

const TableTicket = ({ userID, token, eventID, search, eventDetails }) => {
  const [ticketID, setTicketID] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isOffcanvasEditOpen, setIsOffcanvasEditOpen] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["EventDetails", eventID];

  const mutationDelete = useCustomMutation(key);

  const toggleViewTicket = (details) => {
    setTicketDetails(details);
    setIsToggleView(!isToggleView);
  };

  const toggleEditTicket = (details) => {
    setTicketDetails(details);
    setIsOffcanvasEditOpen(!isOffcanvasEditOpen);
  };
  const toggleDeleteTicket = (id) => {
    setTicketID(id);
    setIsToggleDelete(!isToggleDelete);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Ticket Name</span>,
      selector: (row) => row?.tier,
    },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      selector: (row) => row?.price,
    },
    {
      name: <span className="font-weight-bold fs-13">Start Date</span>,
      cell: (row) => {
        return <div className="w-100">{readDate(row?.createdAt)}</div>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Closing Date</span>,
      cell: (row) => {
        return <div className="w-100">{readDate(row?.closingDate)}</div>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Stats</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            <Progress color="success" value={row?.quantitySold}></Progress>
          </div>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => toggleViewTicket(row)}
              />
            </div>
            <div>
              <FaEdit
                size={20}
                className="mx-1"
                onClick={() => toggleEditTicket(row)}
              />
            </div>
            <div>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() => toggleDeleteTicket(row?._id)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterTickets = (settings, searchQuery) => {
    if (!searchQuery) return settings;
    return settings?.filter((item) =>
      [item?.tier]?.some((field) =>
        field?.toLowerCase()?.includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredTicketSettings = filterTickets(
    eventDetails?.ticketSettings,
    search
  );

  const data = filteredTicketSettings || [];
  useEffect(() => {
    if (mutationDelete?.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete?.isSuccess]);

  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Ticket"
        body={
          <DeleteTicketForm
            setIsToggleDelete={setIsToggleDelete}
            eventID={eventID}
            ticketID={ticketID}
            token={token}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="View Ticket"
        body={<ViewTicket details={ticketDetails} />}
        toggleSaveChanges={() => toggleViewTicket()}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
      <OffCanvas
        isOffcanvasOpen={isOffcanvasEditOpen}
        title="Edit Ticket"
        body={
          <EditTicket
            ticketDetails={ticketDetails}
            token={token}
            eventDetails={eventDetails}
            setIsOffcanvasOpen={setIsOffcanvasEditOpen}
          />
        }
        toggleSaveChanges={toggleEditTicket}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default TableTicket;

import React from "react";

const Header = ({ eventDetails }) => {
  return (
    <React.Fragment>
      <div
        className="w-100 d-flex justify-content-between align-items-center px-4"
        style={{ height: 65, backgroundColor: "white", userSelect: "none" }}
      >
        {eventDetails?.logo ? (
          <div className="" style={{ height: 65 }}>
            <img
              className="w-100 h-100"
              src={`${eventDetails?.logo}`}
              alt="Company Logo"
            />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default Header;

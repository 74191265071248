import React, { useState } from "react";
import "./organizerMultiStep.scss";
import ButtonSize from "../../../../components/Buttons/ButtonSize";

const OrganizerReigistration = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <div className="organizer-general-container">
        <div className="multi-step-container">
          <div className="multi-step-sidebar">
            <div
              className={`multi-step-step ${
                currentStep === 1 ? "multi-step-active" : ""
              }`}
              onClick={() => handleStepClick(1)}
            >
              <div className="multi-step-circle">1</div>
              <div className="multi-step-info">
                <h4>STEP 1</h4>
                <p>Personal Info</p>
              </div>
            </div>
            <div
              className={`multi-step-step ${
                currentStep === 2 ? "multi-step-active" : ""
              }`}
              onClick={() => handleStepClick(2)}
            >
              <div className="multi-step-circle">2</div>
              <div className="multi-step-info">
                <h4>STEP 2</h4>
                <p>Company Profile</p>
              </div>
            </div>
            <div
              className={`multi-step-step ${
                currentStep === 3 ? "multi-step-active" : ""
              }`}
              onClick={() => handleStepClick(3)}
            >
              <div className="multi-step-circle">3</div>
              <div className="multi-step-info">
                <h4>STEP 3</h4>
                <p>Payfast Merchant</p>
              </div>
            </div>
            <div
              className={`multi-step-step ${
                currentStep === 4 ? "multi-step-active" : ""
              }`}
              onClick={() => handleStepClick(4)}
            >
              <div className="multi-step-circle">4</div>
              <div className="multi-step-info">
                <h4>STEP 4</h4>
                <p>Submit</p>
              </div>
            </div>
          </div>
          <div className="multi-step-form-container">
            {currentStep === 1 && (
              <>
                <h2>Personal Info</h2>
                <p>Please provide your personal information.</p>
                <div className="multi-step-form-group">
                  <label>Name</label>
                  <input type="text" placeholder="e.g. Stephen King" />
                </div>
                <div className="multi-step-form-group">
                  <label>Email Address</label>
                  <input type="email" placeholder="e.g. stephenking@lorem" />
                </div>
                <div className="multi-step-form-group">
                  <label>Phone Number</label>
                  <input type="tel" placeholder="e.g. +1 234 567 890" />
                </div>
              </>
            )}

            {currentStep === 2 && (
              <>
                <h2>Company Profile</h2>
                <p>Choose the plan that best suits your needs.</p>
                {/* Add form fields for selecting a plan */}
              </>
            )}

            {currentStep === 3 && (
              <>
                <h2>Payfast Merchant</h2>
                <p>Enhance your experience with our optional add-ons.</p>
                {/* Add form fields for add-ons */}
              </>
            )}

            {currentStep === 4 && (
              <>
                <h2>Submit</h2>
                <p>Review and confirm your details before submitting.</p>
                {/* Add form fields for final review */}
              </>
            )}

            <div className="multi-step-navigation">
              {currentStep > 1 && (
                <ButtonSize
                  Title="Previous"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handlePreviousStep}
                  pending={false}
                  width={100}
                  height={40}
                />
              )}
              {currentStep < 4 ? (
                <ButtonSize
                  Title="Next"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handleNextStep}
                  pending={false}
                  width={100}
                  height={40}
                />
              ) : (
                <ButtonSize
                  Title="Submit"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={() => {}}
                  pending={false}
                  width={100}
                  height={40}
                />
              )}
            </div>
            {/* (
                  <button className="multi-step-btn multi-step-submit-btn">
                    Submit
                  </button>) */}
            {/* <button
              className="multi-step-btn multi-step-next-btn"
              onClick={handleNextStep}
            >
              Next
            </button> */}
            {/* <button
              className="multi-step-btn multi-step-prev-btn"
              onClick={handlePreviousStep}
            >
              Previous
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizerReigistration;

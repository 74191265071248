import React from "react";
import { Card, CardBody } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ButtonStyle from "../../Buttons/ButtonSubmit";
import communityImg from "../../../assets/images/community/Community.png";

const UpcommingEvents = ({ getMyUpcommingEvents }) => {
  const Navigate = useNavigate();
  // remember to use media query for slick responsiveness

  // const {
  //   data: getUpcoming,
  //   error: errorGetUpcoming,
  //   isLoading: isLoadingGetUpcoming,
  // } = useCustomFecthQuery(
  //   ["guest-events", communityID],
  //   `${process.env.REACT_APP_API}guest/communities/${communityID}`,
  //   null,
  //   {
  //     enabled: !!communityID,
  //   }
  // );
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const toggleSeeEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <div
        style={{
          width: 800,
        }}
      >
        <div className="">
          <Slider {...settings}>
            {getMyUpcommingEvents?.data?.map((event, i) => {
              let dateValue = new Date(event.startDate);

              let day = dayNames[dateValue.getUTCDay()];
              let month = monthNames[dateValue.getUTCMonth()];
              let year = dateValue.getUTCFullYear();
              let date = dateValue.getUTCDate();

              let formattedDate = `${day} ${date} ${month} ${year}`;
              return (
                <Card
                  key={i}
                  style={{
                    width: "100%",
                    borderRadius: 10,
                  }}
                >
                  <CardBody
                    className="p-3"
                    style={{
                      width: 265,
                      height: 300,
                    }}
                  >
                    <div
                      style={{
                        width: 233,
                        height: 100,
                      }}
                    >
                      <img
                        src={
                          event?.bannerImage ? event?.bannerImage : communityImg
                        }
                        style={{
                          borderRadius: 5,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "#ececec",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: 200,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        color: "black",
                        fontSize: 14,
                        fontWeight: "600",
                        marginTop: 4,
                        marginBottom: 5,
                      }}
                    >
                      {event?.eventName?.length > 51
                        ? `${event?.eventName?.substring(0, 48)}...`
                        : event?.eventName}
                    </div>

                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        color: "#9c9c9c",
                        marginBottom: 5,
                      }}
                    >
                      {formattedDate}
                    </div>
                    <div
                      style={{
                        width: 200,
                        display: "flex",
                        // justifyContent: "center",
                        marginTop: 15,
                      }}
                    >
                      <ButtonStyle
                        Title="See Event"
                        ColorText="#F68A20"
                        BorderColor="#F68A20"
                        BackgroundColor="white"
                        borderRadius="25px"
                        handleOnclick={() =>
                          toggleSeeEvent(event?.eventName, event?._id)
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default UpcommingEvents;

import React, { useRef } from "react";
import Lottie from "lottie-react";
import contacts from "../../assets/animations/contacts.json";

function Contacts({ title }) {
  const contactsRef = useRef();
  return (
    <div className="w-100 d-flex justify-content-center">
      {/* backgroundColor: "white" */}

      <div className="rounded-4" style={{ width: 400, height: 230 }}>
        <div
          className="w-100 mt-1 d-flex justify-content-center"
          style={{ width: 90, height: 160 }}
        >
          <Lottie lottieRef={contactsRef} animationData={contacts} />
        </div>
        <div
          className="w-100 d-flex justify-content-center"
          style={{
            userSelect: "none",
            color: "black",
            fontSize: 16,
            fontWeight: "800",
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
}

export default Contacts;

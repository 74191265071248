import React from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonCard = () => (
  <Box
    sx={{
      backgroundColor: "#fff",
      borderRadius: 2,
      mb: 3,
      p: 2,
      width: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        sx={{
          mt: 0.3,
          mb: 1.5,
          borderRadius: 2,
        }}
      />
    </Box>
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Skeleton variant="text" width="80%" height={40} />
      <Skeleton variant="circular" height={30} width={30} />
    </Box>
  </Box>
);

const LoaderCommunityItem = () => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {[...Array(3)].map((_, index) => (
        <SkeletonCard key={index} />
      ))}
    </Box>
  );
};

export default LoaderCommunityItem;

async function fetcher(url, token) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const result = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      // console.log("res.....res....", res);
      if (res?.status === "success") {
        return { data: res?.data, error: null, status: "ok" };
      }
      if (res?.status === "fail") {
        return { data: [], error: res?.message, status: "ok" };
      }
    })
    .catch((error) => {
      console.log("res.....error....", error);
      return { data: [], error: error, status: "ok" };
    });

  return result;
}
export default fetcher;

import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import { FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";
import { AiFillPlusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { GoDotFill } from "react-icons/go";
import { MdDeleteOutline, MdOutlineFileCopy } from "react-icons/md";
import useCustomMutation from "../../../../Hooks/useCustomMutation";

const EditRegistrationForm = ({
  dataForm,
  token,
  eventID,
  setIsOffcanvasOpen,
  formID,
}) => {
  const [formContent, setFormContent] = useState([]);
  const [editedField, setEditedField] = useState(null);
  const [textField, setTextField] = useState("");
  const [fieldCount, setFieldCount] = useState(1);

  const key = ["eventForm", eventID];
  const mutation = useCustomMutation(key);

  useEffect(() => {
    const additionalFields = dataForm?.map((field) => ({
      id: field._id,
      label: field.label,
      list: field.list,
      name: field.name,
      question_type: field.question_type,
    }));

    setFormContent(additionalFields);
  }, [dataForm]);

  // Add new dynamic question
  const addQuestion = () => {
    const newField = {
      id: `question_${Date.now()}`, // unique id
      name: `question_${formContent.length + 1}`,
      label: `Untitled question #${fieldCount}`,
      question_type: "short_answer",
      list: [],
    };
    setFormContent([...formContent, newField]);
    setFieldCount((prevCount) => prevCount + 1); // Increment question number
  };

  // Duplicate the question
  const duplicateQuestion = (index) => {
    const fieldToDuplicate = formContent[index];
    const newField = {
      ...fieldToDuplicate,
      id: `question_${Date.now()}`, // Ensure unique id for duplicated field
      label: `Untitled question #${fieldCount}`, // Add new label with increment
    };
    const updatedFormContent = [...formContent];
    updatedFormContent.splice(index + 1, 0, newField); // Insert right after the current one
    setFormContent(updatedFormContent);
    setFieldCount((prevCount) => prevCount + 1); // Increment question number
  };

  // Delete the question
  const deleteQuestion = (index) => {
    if (index >= 0) {
      // Adjusted to delete any additional fields
      const updatedFormContent = formContent.filter((_, i) => i !== index);
      setFormContent(updatedFormContent);
    }
  };

  // Edit the label of a field
  const editField = (fieldId, fieldLabel) => {
    const updatedFields = formContent.map((field) =>
      field.id === fieldId ? { ...field, label: fieldLabel } : field
    );
    setFormContent(updatedFields);
  };

  // Edit the question type of a field
  const editFieldType = (fieldId, fieldType) => {
    const updatedFields = formContent.map((field) => {
      if (field.id === fieldId) {
        return {
          ...field,
          question_type: fieldType,
          list: fieldType === "options" ? field.list : [], // Reset list if not options
        };
      }
      return field;
    });
    setFormContent(updatedFields);
  };

  // Add an option to the question (for option-type questions)
  const addFieldOption = (fieldId, option) => {
    const updatedFields = formContent.map((field) =>
      field.id === fieldId && option
        ? { ...field, list: [...field.list, option] }
        : field
    );
    setFormContent(updatedFields);
    setTextField("");
  };

  // Remove an option from the question
  const removeFieldOption = (fieldId, optionIndex) => {
    const updatedFields = formContent.map((field) =>
      field.id === fieldId
        ? { ...field, list: field.list.filter((_, idx) => idx !== optionIndex) }
        : field
    );
    setFormContent(updatedFields);
  };

  // Move the field up
  const moveFieldUp = (index) => {
    if (index <= 0) return; // Prevent moving the first element up
    const updatedFormContent = [...formContent];
    const temp = updatedFormContent[index - 1];
    updatedFormContent[index - 1] = updatedFormContent[index];
    updatedFormContent[index] = temp;
    setFormContent(updatedFormContent);
  };

  // Move the field down
  const moveFieldDown = (index) => {
    if (index >= formContent.length - 1) return; // Prevent moving the last field down
    const updatedFormContent = [...formContent];
    const temp = updatedFormContent[index + 1];
    updatedFormContent[index + 1] = updatedFormContent[index];
    updatedFormContent[index] = temp;
    setFormContent(updatedFormContent);
  };

  const handleUpdate = () => {
    const savedData = formContent?.map(({ id, ...rest }) => rest);
    const Method = "PATCH",
      url = `event-form/${formID}`;
    const raw = JSON.stringify({
      customFields: savedData,
    });
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Registration form successfully updated",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      // queryClient.invalidateQueries(["eventForm", eventID]);
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <>
      <div className="w-100">
        {/* First Part: Fixed Fields */}

        <div
          className="mt-3 rounded-3 px-4 py-2"
          style={{
            width: "75%",
            margin: "0px auto",
            backgroundColor: "white",
            boxShadow: "2px 1px 5px 1px #F3F3F3",
            borderWidth: 1,
          }}
        >
          <div className="w-100 d-flex justify-content-center align-items-center mt-2 ">
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ width: "64%", fontSize: 18, fontWeight: "600" }}
            >
              {/* Create Registration Form */}
            </div>
            <div
              className="d-flex justify-content-end align-items-center px-4 py-2"
              style={{ width: "36%" }}
            >
              <ButtonSubmit
                Title="Update Form"
                BackgroundColor="#f68a20"
                ColorText="#fff"
                BorderColor="#f68a20"
                borderRadius={20}
                handleOnclick={handleUpdate}
                pending={mutation?.isLoading}
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center mb-3">
            <Row>
              {formContent.slice(0, 4).map((field) => (
                <Col key={field.id} md={6}>
                  <div className="w-100">
                    <label className="text-dark">{field.label}</label>
                    <input
                      type="text"
                      // value={field.label}
                      className="form-control"
                      // disabled
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        {/* Second Part: Dynamic Fields (Editable, Removable, Duplicable, Movable) */}

        <div className="w-100 d-flex justify-content-center">
          <div className="w-100 mt-3">
            {formContent?.slice(4)?.map((field, index) => (
              <div
                key={field.id}
                style={{
                  width: "75%",
                  backgroundColor: "white",
                  margin: "0px auto",
                  boxShadow: "2px 1px 5px 1px #F3F3F3",
                  borderWidth: 1,
                }}
                className="rounded-3 px-5 py-3 mb-3"
              >
                <div className="flex justify-content-between align-items-center">
                  <div>
                    {editedField === field.id ? (
                      <input
                        type="text"
                        value={field.label}
                        onChange={(e) => editField(field.id, e.target.value)}
                        onBlur={() => setEditedField(null)}
                        className="form-control"
                      />
                    ) : (
                      <label onClick={() => setEditedField(field.id)}>
                        {field.label}
                      </label>
                    )}
                  </div>
                  <div>
                    <select
                      value={field.question_type}
                      onChange={(e) => editFieldType(field.id, e.target.value)}
                      className="form-control"
                    >
                      <option value="short_answer">Short Answer</option>
                      <option value="text">Text</option>
                      <option value="paragraph">Paragraph</option>
                      <option value="options">Options</option>
                    </select>
                  </div>
                </div>

                <div className="my-4">
                  {field.question_type === "options" && (
                    <>
                      <div className="d-flex">
                        <div>
                          <input
                            type="text"
                            onChange={(e) => setTextField(e.target.value)}
                            value={textField}
                            placeholder="Add an option"
                            className="form-control"
                          />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mx-2">
                          <AiOutlinePlusCircle
                            size={30}
                            color="#6D6D6D"
                            onClick={() => addFieldOption(field.id, textField)}
                          />
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        {field.list.map((item, optionIndex) => (
                          <div
                            className="w-100 mt-2 d-flex align-items-center gap-2"
                            key={optionIndex}
                          >
                            <GoDotFill size={12} color="black" /> {item}
                            <TiDeleteOutline
                              color="black"
                              size={17}
                              onClick={() =>
                                removeFieldOption(field.id, optionIndex)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  <div className="w-100 d-flex justify-content-between align-items-center mt-4">
                    <div className="d-flex gap-2">
                      <MdOutlineFileCopy
                        size={25}
                        onClick={() => duplicateQuestion(index + 4)}
                      />
                      <MdDeleteOutline
                        color="red"
                        size={27}
                        onClick={() => deleteQuestion(index + 4)}
                      />
                    </div>
                    <div className="d-flex gap-2">
                      {index + 4 > 4 && ( // Show up arrow for all but the first dynamic element
                        <FaAngleDoubleUp
                          size={20}
                          onClick={() => moveFieldUp(index + 4)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      {index + 4 < formContent.length - 1 && ( // Hide down arrow for the last dynamic element
                        <FaAngleDoubleDown
                          size={20}
                          onClick={() => moveFieldDown(index + 4)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Add New Dynamic Field */}
        <div style={{ width: "100%" }}>
          <div className="w-100 d-flex justify-content-end px-4">
            <AiFillPlusCircle size={50} color="black" onClick={addQuestion} />
          </div>
        </div>
      </div>

      {/* OffCanvas for Preview */}
      {/* <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Preview Registration Form"
        body={<ViewRegistration formContent={formContent} />}
        toggleSaveChanges={() => toggleSaveChanges()}
        directionOffCanvas="end"
        widthOffCanvas="60%"
      /> */}
    </>
  );
};

export default EditRegistrationForm;

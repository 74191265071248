import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import { Modal, ModalBody } from "reactstrap";
import { MdClose } from "react-icons/md";

import SearchInput from "../SerachInput/SearchInput";
import ButtonStyle from "../Buttons/ButtonStyle";
import AddMember from "./AddMember";
import TableMembers from "./TableMembers";

function Members({ communityID, token }) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const {
    data: getCommunityMembers,
    error: errorGetCommunityMembers,
    isLoading: isLoadingGetCommunityMembers,
  } = useCustomFecthQuery(
    ["CommunityMembers", communityID],
    `${process.env.REACT_APP_API}communities/members/${communityID}`,
    token,
    { enabled: !!communityID }
  );

  // console.log("getCommunityMembers...", getCommunityMembers);

  // const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  // const toggleInviteMember = () => {
  //   function noRefCheck() {}
  // };
  const toggleInviteMember = () => {
    setModal(!modal);
    // setIsOffcanvasOpen(!isOffcanvasOpen); // Toggle the Offcanvas visibility
    // console.log("isOffcanvasOpen...", isOffcanvasOpen);
  };

  // const toggleSearch = (event) => {
  //   setSearch(event.target.value);
  // };

  return (
    <div className="w-100 mt-2 d-flex justify-content-center">
      <div className="w-75 rounded-4 p-3" style={{ backgroundColor: "white" }}>
        <div className="d-flex justify-content-between mb-3">
          <SearchInput
            Title={t("Search by name")}
            search={search}
            setSearch={setSearch}
            radius={20}
            // handleSearch={() => toggleSearch()}
          />
          <ButtonStyle
            Title="Invite a member"
            ColorText="white"
            BorderColor="#F68A20"
            BackgroundColor="#F68A20"
            borderRadius="25px"
            handleOnclick={() => toggleInviteMember()}
            // handleOnclick={() => toggleInviteMember()}
          />
        </div>
        <div>
          <TableMembers getCommunityMembers={getCommunityMembers?.data} />
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggleInviteMember} fullscreen>
        {/* <ModalHeader toggle={toggleInviteMember}>Modal title</ModalHeader> */}
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div
              className="d-flex justify-content-center align-items"
              style={{
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                backgroundColor: "#fcceba",
              }}
              onClick={() => toggleInviteMember()}
            >
              <MdClose size={30} />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <AddMember
              token={token}
              communityID={communityID}
              setModal={setModal}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Members;

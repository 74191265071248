import React, { useRef, useState } from "react";
import ImagePlaceHolder from "../../assets/images/community/Community.png";

import { FaPlus, FaEdit } from "react-icons/fa";

const BannerCommunity = ({
  communityBanner,
  setCommunityBanner,
  setCommunityBannerSever,
}) => {
  const [imageURL, setImageURL] = useState(communityBanner);
  const [showAddIcon, setShowAddIcon] = useState(true);
  const [hovering, setHovering] = useState(false);
  const fileInputRef = useRef(null);
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    //file is the image file
    const file = event.target.files[0];
    if (file) {
      setCommunityBannerSever(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        // Update the imageURL state with the Data URL of the selected file
        setCommunityBanner(e.target.result);
        setShowAddIcon(false);
      };
      reader.readAsDataURL(file);
    }
  };
  // console.log("communityBanner...", communityBanner);
  return (
    <div
      style={{
        width: 400,
        height: 120,
        position: "relative",
        userSelect: "none",
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <img
        src={communityBanner ? communityBanner : ImagePlaceHolder}
        className="w-100 h-100 rounded-3"
        style={{
          position: "absolute",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#ececec",
        }}
      />
      {showAddIcon && (
        <div
          onClick={handleDivClick}
          style={{
            position: "absolute",
            top: "35%",
            left: "40%",
            backgroundColor: "white",
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            cursor: "pointer",
          }}
        >
          <FaPlus size={20} color="black" />
        </div>
      )}
      {hovering && !showAddIcon && (
        <div
          onClick={handleDivClick}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            cursor: "pointer",
          }}
        >
          <div
            className=""
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaEdit size={20} color="black" />
          </div>
        </div>
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept="image/*"
      />
    </div>
  );
};

export default BannerCommunity;

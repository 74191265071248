import React from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonCard = () => (
  <Box
    sx={{
      backgroundColor: "#fff",
      borderRadius: 2,
      mb: 2,
      p: 1,
      width: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <Skeleton
        variant="rectangular"
        width="35%"
        height={140}
        sx={{
          mt: 0.3,
          mb: 1.5,
          borderRadius: 2,
        }}
      />
      <Box width="40%">
        <Skeleton
          variant="text"
          width="100%"
          height={30}
          sx={{ mt: 0.3, mb: 1.5, borderRadius: 2 }}
        />
        <Skeleton variant="text" width="60%" height={24} sx={{ mt: 1 }} />
        <Skeleton
          variant="text"
          width="80%"
          height={30}
          sx={{ mt: 0.3, mb: 1.5, borderRadius: 2 }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={35}
          sx={{ mt: 0.3, mb: 1.5, borderRadius: 3 }}
        />
      </Box>
    </Box>
  </Box>
);

const LoaderEventItem = () => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {[...Array(3)].map((_, index) => (
        <SkeletonCard key={index} />
      ))}
    </Box>
  );
};

export default LoaderEventItem;

import React, { useRef } from "react";
import Lottie from "lottie-react";
import nothing from "../../assets/animations/nothing.json";

function BusinessCard({ title }) {
  const nothingRef = useRef();
  return (
    <div className="w-100 d-flex justify-content-center">
      {/* backgroundColor: "white" */}
      <div className="rounded-4" style={{ width: 400, height: 230 }}>
        <div
          className="w-100 mt-1 d-flex justify-content-center"
          style={{ width: 90, height: 160 }}
        >
          <Lottie lottieRef={nothingRef} animationData={nothing} />
        </div>
        <div
          className="h3 w-100 d-flex justify-content-center"
          style={{ userSelect: "none" }}
        >
          {title}
        </div>
      </div>
    </div>
  );
}

export default BusinessCard;

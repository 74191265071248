import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../../../Toast/Toast";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const EmailForm = ({ setIsToggleViewEmail, attendeeID, eventID, token }) => {
  const [inputs, setInputs] = useState({});
  const [btnLoad, setBtnLoad] = useState(false);
  const queryClient = useQueryClient();
  const key = ["allEmail", eventID];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSend = (e) => {
    e.preventDefault();
    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const raw = JSON.stringify({
      subject: inputs?.title,
      body: inputs?.message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API}communication/email?aid=${attendeeID}&eid=${eventID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setIsToggleViewEmail(false);
        setBtnLoad(false);
        if (res?.status === "success") {
          queryClient.invalidateQueries(key);
          successMessage("Email sucessfully sent");
        } else {
          warningMessage("No phone number found");
        }
      })
      .catch((error) => {
        setIsToggleViewEmail(false);
        setBtnLoad(false);
        dangerMessage("No phone number found");
      });
  };

  return (
    <div>
      <form onSubmit={handleSend}>
        <label>
          Subject<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="title"
          value={inputs.title}
          onChange={handleChange}
          style={{ borderRadius: 7 }}
          className="mb-3 form-control fromInput"
          required
        />

        <label className="event__input__form__Creation">
          Body<span className="text-danger">*</span>
        </label>
        <textarea
          name="message"
          value={inputs.message}
          onChange={handleChange}
          style={{ borderRadius: 7 }}
          className=" mt-1 form-control fromInput"
          rows={5}
          required
        ></textarea>

        <div className="w-100 mt-2 d-flex justify-content-center">
          <ButtonSubmit
            Title="Send Email"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={handleSend}
            pending={btnLoad}
          />
        </div>
      </form>
    </div>
  );
};

export default EmailForm;

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import firstEventImg from "../../assets/images/event/DefaultBanner.jpg";
import ButtonStyle from "../Buttons/ButtonStyle";

const EventStatList = [
  {
    eventBanner: firstEventImg,
    eventName: "Empowering Local Leaders for Sustainable Development",
    typeOfEvent: "Conference",
    eventFormat: "Virtual",
    sesions: 20,
    booths: 15,
    registeredUsers: 10000,
    attendance: 7000,
    totalSales: 900000,
  },
];

function TopEvents() {
  const [memeberID, setMemberID] = useState(false);
  const [memberEmail, setMemberEmail] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const tog_viewMember = (id) => {};
  const toggleDeleteMember = () => {};
  const tog_deleteMember = (id, email) => {
    setMemberID(id);
    setMemberEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const columns = [
    // {
    //   name: <span className="font-weight-bold fs-13"></span>,
    //   cell: (row) => {
    //     return (
    //       <div>
    //         <div style={{ width: 90, height: 50, padding: 5 }}>
    //           <img
    //             src={row?.eventBanner}
    //             className="w-100 h-100"
    //             style={{ borderRadius: 10 }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      name: <span className="font-weight-bold fs-13">Event Name</span>,
      cell: (row) => {
        return (
          <div>
            {row?.eventName?.length > 20
              ? `${row?.eventName?.substring(0, 20)}...`
              : row?.eventName}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Type of event</span>,
      selector: (row) => row?.typeOfEvent,
    },
    {
      name: <span className="font-weight-bold fs-13">Format</span>,
      selector: (row) => row?.eventFormat,
    },
    {
      name: <span className="font-weight-bold fs-13">Attendance</span>,
      selector: (row) => row?.attendance,
    },
  ];
  const data = EventStatList?.data;
  const handleExport = () => {};
  return (
    <div className="w-100 ">
      <div
        className="d-flex justify-content-center"
        style={{ fontSize: 20, fontWeight: "600", color: "black" }}
      >
        Top Best Events
      </div>
      <div className="d-flex justify-content-end">
        <ButtonStyle
          Title="Export Data"
          ColorText="white"
          BorderColor="#F68A20"
          BackgroundColor="#F68A20"
          borderRadius="25px"
          handleOnclick={() => handleExport()}
          // handleOnclick={() => toggleInviteMember()}
        />
      </div>
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="300px"
        columns={columns}
        responsive
        data={data}
        pagination
      />
    </div>
  );
}

export default TopEvents;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import logoLight from "../../../assets/images/Kago-color-logo.png";
import ButtonSubmit from "../../../components/Buttons/ButtonSubmit";
const WebinarSales = () => {
  const navigation = useNavigate();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    participants: "",
    speakers: "",
    duration: "",
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <div className="w-100">
      <div className="w-100 mb-3" style={{ backgroundColor: "white" }}>
        <section className="header-section ps">
          <div className="d-flex justify-content-center align-items-center p-2 mx-2">
            <img src={logoLight} alt="Company Logo" />
          </div>
        </section>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 20,
            padding: 20,
            width: 850,
          }}
        >
          <div className="w-100 d-flex justify-content-center align-items-center">
            <h1 style={{ fontWeight: "bolder" }}>Contact Us</h1>
          </div>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <p style={{ fontSize: 18, textAlign: "center", letterSpacing: 0 }}>
              We'd love to learn about your virtual, hybrid & in-person event
              needs, so that we can craft a custom quote for you.
            </p>
          </div>
          <div className="w-100">
            <Row>
              <Col sm={12} md={4}>
                <div className="form-field">
                  <label>First name *</label>
                  <input
                    type="text"
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    className=""
                    name="firstName"
                  />
                </div>
              </Col>
              <Col sm={12} md={4}>
                <div className="form-field">
                  <label>Last name *</label>
                  <input
                    type="text"
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    className=""
                    name="lastName"
                  />
                </div>
              </Col>
              <Col sm={12} md={4}>
                <div className="form-field">
                  <label>Email *</label>
                  <input
                    type="text"
                    value={values.email}
                    onChange={handleChange("email")}
                    className=""
                    name="email"
                  />
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="form-field">
                  <label>Company name *</label>
                  <input
                    type="text"
                    value={values.companyName}
                    onChange={handleChange("companyName")}
                    className=""
                    name="companyName"
                  />
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="form-field">
                  <label>Phone number *</label>
                  <input
                    type="tel"
                    value={values.phoneNumber}
                    onChange={handleChange("phoneNumber")}
                    className=""
                    name="phoneNumber"
                  />
                </div>
              </Col>
              <Col sm={12} md={4}>
                <div className="form-field">
                  <label>Participants *</label>
                  <input
                    type="text"
                    value={values.participants}
                    onChange={handleChange("participants")}
                    className=""
                    name="participants"
                  />
                </div>
              </Col>
              <Col sm={12} md={4}>
                <div className="form-field">
                  <label>speakers *</label>
                  <input
                    type="number"
                    value={values.speakers}
                    onChange={handleChange("speakers")}
                    className=""
                    name="speakers"
                  />
                </div>
              </Col>
              <Col sm={12} md={4}>
                <div className="form-field">
                  <label>How many Hours *</label>
                  <input
                    type="number"
                    value={values.duration}
                    onChange={handleChange("duration")}
                    className=""
                    name="duration"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <ButtonSubmit
              Title="Submit"
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={() => {
                navigation("/login");
              }}
              pending={false}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4"></div>
      {/* <Row>
        <Col md={6}>
          <div
            className="w-100 p-3"
            style={{
              borderWidth: 1,
              borderRadius: 10,
              borderStyle: "solid",
              borderColor: "black",
            }}
          ></div>
        </Col>
      </Row> */}
    </div>
  );
};

export default WebinarSales;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ViewSecurity from "./ViewSecurity";
import ModalDelete from "../../../Modal/ModalDelete";
import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
// import { FaEdit } from "react-icons/fa";
import { AiFillEye, AiFillDelete } from "react-icons/ai";

const TableSecurity = ({ userID, token, eventID, search }) => {
  const [securityID, setSecurityID] = useState(false);
  const [securityEmail, setSecurityEmail] = useState(null);
  const [securityDetails, setSecurityDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["mySecurity", eventID];

  const mutationDelete = useCustomMutation(key);

  const {
    data: getSecurity,
    error: errorGetSecurity,
    isLoading: isLoadingGetSecurity,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}users/event-security/${eventID}`,
    token,
    {
      enabled: !!eventID,
    }
  );

  // console.log("getSecurity....", getSecurity);

  const tog_viewParticipant = (details) => {
    setSecurityDetails(details);
    setIsToggleView(!isToggleView);
  };
  const tog_deleteParticipant = (id, email) => {
    setSecurityID(id);
    setSecurityEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `attendees/${securityID}/?eventId=${eventID}&email=${securityEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Security successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">First Name</span>,
      selector: (row) => row?.firstName,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Name</span>,
      selector: (row) => row?.lastName,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewParticipant(row)}
              />
            </div>
            {/* <div>
              <FaEdit
                size={18}
                className="mx-1"
                onClick={() => tog_viewParticipant(row)}
              />
            </div> */}

            <div>
              <AiFillDelete
                size={20}
                onClick={() => tog_deleteParticipant(row?._id, row?.email)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterParticipants = (participants, searchQuery) => {
    if (!searchQuery) return participants;
    return participants?.filter((item) =>
      [item?.firstName, item?.lastName, item?.email].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredSpeakers = filterParticipants(getSecurity?.data, search);

  const data = filteredSpeakers;
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Security"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            DeleteParticitant={DeleteParticitant}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Security"
        body={<ViewSecurity details={securityDetails} />}
        toggleSaveChanges={() => tog_viewParticipant()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableSecurity;

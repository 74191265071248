import React from "react";
import useCustomQuery from "../../Hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import EventOne from "../../components/Common/EventLanding/EventOne";
import CustomFooter from "../../Layout/HorizontalLayout/CustomFooter";

const EventLandingCustom = () => {
  document.title = `Kago Events`;
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const params = useParams();
  const eventID = params.eventID;

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // const {
  //   data: getMyEventDetail,
  //   error: errorGetMyEventDetail,
  //   isLoading: isLoadingGetMyEventDetail,
  // } = useCustomQuery(
  //   ["myEventDetail", eventID],
  //   `${process.env.REACT_APP_API}guest/events/${eventID}`,
  //   null
  // );

  if (isLoadingGetMyEventDetail) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  const eventType = getMyEventDetail?.data?.eventType;

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "relative",
        }}
      >
        <div
          className="w-100"
          style={{ position: "relative", height: "100vh" }}
        >
          <EventOne
            eventDetails={getMyEventDetail?.data}
            token={token}
            eventID={eventID}
          />
        </div>
        <div className="w-100">
          <CustomFooter />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventLandingCustom;

import React, { useState, useEffect, useContext, useRef } from "react";
import SimpleBar from "simplebar-react";
// import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import { MDBCol, MDBIcon } from "mdb-react-ui-kit";
import TypingArea from "./TypingArea";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ContactContext } from "../../../../../contexts/contact-context/contact.context";
import { socket } from "../../../../../utils/socketConfig";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";

function ChatArea({}) {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.data?.email;
  const userId = obj?.data?.data?.data?._id;
  const userImg = obj?.data?.data?.data?.photo;
  const token = obj?.data?.token;
  const { selectedContactId } = useContext(ContactContext);
  const { eventID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [contactDetails, setContactDetails] = useState({});
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if selected contact id is available before making the API call
        if (selectedContactId) {
          const response = await axios.post(
            "https://orca-app-65xn9.ondigitalocean.app/api/v1/messages/getmsg",
            {
              from: userId,
              to: selectedContactId,
            }
          );
          setMessages(response);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchData();
  }, [selectedContactId, userId]);
  console.log("messagesssssssssss", selectedContactId);

  const {
    data: getSelectedContactDetails,
    error: errorGetSelectedContactDetails,
    isLoading: isLoadingGetSelectedContactDetails,
  } = useCustomQuery(
    ["getSelectedContactDetails", eventID],
    `${process.env.REACT_APP_API}users/${selectedContactId}`,
    token
  );
  console.log("userrrrrrrrr", getSelectedContactDetails);

  useEffect(() => {
    if (getSelectedContactDetails) {
      setContactDetails(getSelectedContactDetails);
    }
  }, [selectedContactId]);

  const handleSendMsg = async () => {
    try {
      // Generate timestamp for the message
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      socket.emit("send-msg", {
        to: selectedContactId,
        from: userId,
        msg: message,
        timestamp: formattedTime, // Include timestamp in the message object
      });

      await axios.post(
        "https://orca-app-65xn9.ondigitalocean.app/api/v1/messages/addmsg",
        {
          from: userId,
          to: selectedContactId,
          message: message,
          timestamp: formattedTime, // Include timestamp in the message object
        }
      );

      setMessages([
        ...messages,
        { fromSelf: true, message: message, timestamp: formattedTime },
      ]); // Include timestamp in the message object
      setMessage(""); // Clear the input field
    } catch (error) {
      console.error("Error sending message:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // other than 2xx.
        console.log("Error status:", error.response.status);
        console.log("Error data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received.
        console.log("No response received");
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.log("Error message:", error.message);
      }
    }
  };

  // Enter key to send message
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMsg();
    }
  };

  useEffect(() => {
    const handleMsgReceive = (msg) => {
      setArrivalMessage({
        fromSelf: false,
        message: msg.message,
        timestamp: msg.timestamp,
      });
    };

    socket.on("msg-recieve", handleMsgReceive);

    return () => {
      socket.off("msg-recieve", handleMsgReceive);
    };
  }, [socket]);

  useEffect(() => {
    if (arrivalMessage) {
      setMessages((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to bottom on initial render and whenever messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <MDBCol md="12" lg="12" xl="12">
        <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
          <SimpleBar
            style={{
              position: "relative",
              height: "300px",
              width: "100%",
            }}
          >
            {!messages.length === 0 ? (
              <>
                <div className="w-full flex items-center justify-center">
                  <h1>Loading...</h1>
                </div>
              </>
            ) : (
              <>
                {messages?.map((msg, _idx) =>
                  msg?.fromSelf ? (
                    <div
                      className="d-flex flex-row justify-content-end"
                      key={_idx}
                    >
                      <div>
                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                          {msg?.message}
                        </p>
                        <p className="small me-3 mb-3 rounded-3 text-muted">
                          {msg?.timestamp}{" "}
                          {/* Display the received timestamp */}
                        </p>
                      </div>
                      <img
                        src={
                          userImg ??
                          "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                        }
                        alt="avatar 1"
                        style={{
                          width: "45px",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="d-flex flex-row justify-content-start"
                      key={_idx}
                    >
                      <img
                        src={
                          contactDetails?.photo ??
                          "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                        }
                        alt="avatar 1"
                        style={{
                          width: "45px",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                      <div>
                        <p
                          className="small p-2 ms-3 mb-1 rounded-3"
                          style={{ backgroundColor: "#f5f6f7" }}
                        >
                          {msg?.message}
                        </p>
                        <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                          {msg?.timestamp}{" "}
                          {/* Display the received timestamp */}
                        </p>
                      </div>
                    </div>
                  )
                )}
                <div ref={messagesEndRef}></div>
              </>
            )}
          </SimpleBar>
        </div>
      </MDBCol>
      {/* this is where the user Type and send the message  ..... */}
      <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
        <img
          src={
            userImg ??
            "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
          }
          alt="avatar 3"
          style={{ width: "40px", height: "100%", borderRadius: "50%" }}
        />
        <input
          type="text"
          className="form-control form-control-lg"
          id="exampleFormControlInput2"
          placeholder="Type message"
          value={message}
          onChange={(text) => setMessage(text.target.value)}
          onKeyPress={handleKeyPress}
        />
        <a className="ms-1 text-muted" href="#!">
          <MDBIcon fas icon="paperclip" />
        </a>
        <a className="ms-3 text-muted" href="#!">
          <MDBIcon fas icon="smile" />
        </a>
        <a className="ms-3" href="#!" onClick={handleSendMsg}>
          <MDBIcon fas icon="paper-plane" />
        </a>
      </div>
    </>
  );
}

export default ChatArea;

import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";
import ButtonSubmit from "../Buttons/ButtonSubmit";

function EditInfo({ userID, token, eventDetails }) {
  const [inputs, setInputs] = useState({});
  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (eventDetails) {
      const { eventName, eventDescription, eventType } = eventDetails;
      setInputs({
        eventName,
        eventDescription,
        eventType,
      });
    }
  }, [eventDetails]);

  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={12}>
            <label className="">Event Name </label>
            <input
              type="text"
              name="eventName"
              className="mb-3 form-control"
              value={inputs.eventName}
              onChange={handleChange}
              required
              style={{ width: 650 }}
            />
          </Col>
          {/* <Col md={6}>
            <label className="">
              Event Type
              <input
                type="text"
                name="eventType"
                placeholder="Conference"
                className="mb-3 form-control"
                style={{ width: 315, userSelect: "none" }}
                value={inputs.eventType}
                disabled
              />
            </label>
          </Col> */}

          <Col md={12}>
            <label className="event__input__form__Creation">
              Event Description
            </label>
            <textarea
              name="eventDescription"
              value={inputs.eventDescription}
              onChange={handleChange}
              placeholder="Write a short summary about the event..."
              className="mb-3 form-control fromInput"
              rows={5}
              required
            ></textarea>
          </Col>
        </Row>

        <div className="d-flex justify-content-start align-item-center">
          <div className="d-flex justify-content-start align-item-center">
            <ButtonSubmit
              Title="Update Info"
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={25}
              handleOnclick={handleSubmitForm}
              pending={mutation.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditInfo;

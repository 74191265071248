import React from "react";
import ImagePlaceHolder from "../../../../assets/images/placeholder/ImagePlaceHolder.png";

const SponsorPic = ({ showImage }) => {
  return (
    <div
      style={{
        width: 200,
        height: 200,
        position: "relative",
        userSelect: "none",
        backgroundColor: "white",
      }}
    >
      <img
        src={showImage ? showImage : ImagePlaceHolder}
        className="w-100 h-100 rounded-3"
        style={{
          position: "absolute",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: !showImage ? "#F68A20" : "transparent",
        }}
      />
    </div>
  );
};

export default SponsorPic;

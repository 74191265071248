import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import { Button, Modal, Spinner } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";

function SponsorTab({ boothID, token, sponsors, eventID }) {
  const queryClient = useQueryClient();
  const key = ["mySponsors", eventID];
  const [search, setSearch] = useState("");
  const [sponsorID, setSponsorID] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);

  const mutationDelete = useCustomMutation(key);
  const tog_deleteParticipant = (id) => {
    setSponsorID(id);
    setIsToggleDelete(!isToggleDelete);
  };
  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `sponsors/booth/${sponsorID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Sponsor Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Logo</span>,
      selector: (row) => (
        <img
          src={row?.sponsorLogo}
          style={{ width: "40px", height: "40px", borderRadius: 10 }}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Sponsor Name</span>,
      selector: (row) =>
        row?.sponsorName?.length > 25
          ? row?.sponsorName?.substring(0, 25)
          : row?.sponsorName,
    },
    {
      name: <span className="font-weight-bold fs-13">Sponsor Tier</span>,
      selector: (row) => row?.sponsorTier,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div>
            <span>
              <BiEdit size={16} className="mx-1" />
            </span>
            {/* <span>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewSponsor(row)}
              />
            </span> */}
            <span>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() => tog_deleteParticipant(row?._id)}
              />
            </span>
          </div>
        );
      },
    },
  ];
  const data = sponsors?.data;
  // console.log("sponsors...", sponsors);

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      queryClient.invalidateQueries(["exhibtitor-SponsorsBooth", eventID]);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <React.Fragment>
      <div className="people-attendees__heading">
        <h3>Sponsors</h3>
        <span className="text">Manage your booth sponsors from here</span>
      </div>
      <div className="people-attendees__body">
        <div className="d-flex align-items-center mb-3">
          <div
            style={{
              background: "#ffffff",
              paddingTop: ".58rem",
              paddingBottom: ".5rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              marginRight: 32,
              border: "solid",
              borderWidth: 0.1,
              borderRadius: 8,
            }}
          >
            <AiOutlineSearch size={24} />
            <input
              type="text"
              placeholder={"Search Sponsor"}
              value={search}
              onChange={handleSearch}
              style={{
                border: "none",
                marginLeft: 8,
                paddingRight: 24,
              }}
            />
          </div>
        </div>
        <div className="analystic-table-card">
          <div className="w-100">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="300px"
              columns={columns}
              responsive
              data={data}
              pagination
            />
          </div>
        </div>
        <Modal
          size="md"
          isOpen={isToggleDelete}
          toggle={() => {
            tog_deleteParticipant();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Delete Event
            </h5>
            <button
              onClick={() => {
                setIsToggleDelete(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="text-danger">
              Are you sure you want to delete this Sponsor ?
            </h5>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              onClick={() => {
                setIsToggleDelete(false);
              }}
              type="button"
              className="btn btn-dark"
            >
              Cancel
            </button>
            <button
              onClick={() => DeleteParticitant()}
              type="button"
              className="btn btn-danger"
            >
              {!mutationDelete?.isLoading ? (
                <span className="px-2">Sure</span>
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" />
                  Loading
                </span>
              )}
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default SponsorTab;

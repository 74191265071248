import React from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const EventTabs = ({ tabs, selectedTab, handleTabChange, tabContainerRef }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollLeft = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({
        left: -100,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({
        left: 100,
        behavior: "smooth",
      });
    }
  };

  const tabStyle = {
    margin: "0 8px",
    cursor: "pointer",
    padding: "8px 16px",
    borderRadius: "4px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: "#F68A20",
    color: "white",
  };

  const inactiveTabStyle = {
    ...tabStyle,
    backgroundColor: "transparent",
    color: "#56626F",
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        marginBottom: "16px",
      }}
    >
      {isSmallScreen && (
        <IconButton
          onClick={scrollLeft}
          sx={{
            position: "absolute",
            left: 0,
            zIndex: 1,
            backgroundColor: "white",
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            "&:hover": { backgroundColor: "#f0f0f0" },
          }}
        >
          <ArrowBackIos />
        </IconButton>
      )}

      <Box
        ref={tabContainerRef}
        sx={{
          display: "flex",
          overflowX: isSmallScreen ? "auto" : "visible",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
          margin: isSmallScreen ? "0 40px" : "0",
        }}
      >
        {tabs.map((tab) => (
          <Box
            key={tab}
            onClick={() => handleTabChange(tab)}
            sx={selectedTab === tab ? activeTabStyle : inactiveTabStyle}
          >
            {tab}
          </Box>
        ))}
      </Box>

      {isSmallScreen && (
        <IconButton
          onClick={scrollRight}
          sx={{
            position: "absolute",
            right: 0,
            zIndex: 1,
            backgroundColor: "white",
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            "&:hover": { backgroundColor: "#f0f0f0" },
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      )}
    </Box>
  );
};

export default EventTabs;

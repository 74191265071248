import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import Sms from "./MarketingTab/Sms";
import Email from "./MarketingTab/Email";
import PushNotification from "./MarketingTab/PushNotification";
import WhatsApp from "./MarketingTab/WhatsApp";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});

const formatLabel = (label) =>
  `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

const Marketing = ({ eventDetails, token, userID, isLoading }) => {
  const [selectedTab, setSelectedTab] = useState("1");
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ backgroundColor: "white", width: "100%" }}
        className="set-event-rightbar-content-hearder"
      >
        <div className="w-100 d-flex justify-content-center">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="community navigation tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab value="1" label={formatLabel("SMS")} />
            <Tab value="2" label={formatLabel("Email")} />
            <Tab value="3" label={formatLabel("Push Notifications")} />
            <Tab value="4" label={formatLabel("WhatsApp")} />
          </Tabs>
        </div>
      </Box>

      {selectedTab === "1" && (
        <>
          <Sms userID={userID} token={token} eventDetails={eventDetails} />
        </>
      )}
      {selectedTab === "2" && (
        <>
          <Email userID={userID} token={token} eventDetails={eventDetails} />
        </>
      )}

      {selectedTab === "3" && (
        <PushNotification
          userID={userID}
          token={token}
          eventDetails={eventDetails}
        />
      )}

      {selectedTab === "4" && (
        <WhatsApp userID={userID} token={token} eventDetails={eventDetails} />
      )}
    </ThemeProvider>
  );
};

export default Marketing;

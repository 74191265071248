import React, { useRef, useState } from "react";
import { LiaUser } from "react-icons/lia";
import { FaPlus } from "react-icons/fa";

function ProfilePicture({
  showImage,
  setShowImage,
  setUploadImg,
  iconSize,
  iconColor,
  imgHeight,
  imgWidth,
  imgBorder,
}) {
  //   const [showAddIcon, setShowAddIcon] = useState(true);
  // const [hovering, setHovering] = useState(false);
  const fileInputRef = useRef(null);
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleUploadImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadImg(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowImage(e.target.result);
        // setShowAddIcon(false);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="w-100 d-flex justify-content-center">
        <div
          style={{
            position: "relative",
            // backgroundColor: hovering ? "rgba(0, 0, 0, 0.5)" : "#ededed",
            backgroundColor: "#ededed",
            width: imgWidth,
            height: imgHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: imgBorder,
            userSelect: "none",
          }}
          // onMouseEnter={() => setHovering(true)}
          // onMouseLeave={() => setHovering(false)}
        >
          {showImage ? (
            <img
              src={showImage}
              className="w-100 h-100"
              style={{ borderRadius: "50%" }}
              alt="profile"
            />
          ) : (
            <LiaUser size={iconSize} color={iconColor} />
          )}
          {/* {hovering ? ( */}
          <div
            onClick={handleDivClick}
            style={{
              position: "absolute",
              top: "35%",
              left: "35%",
              backgroundColor: "white",
              width: 30,
              height: 30,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              cursor: "pointer",
            }}
          >
            <FaPlus size={15} color="black" />
          </div>
          {/* ) : null} */}
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadImage}
        style={{ display: "none" }}
        accept="image/*"
      />
    </div>
  );
}

export default ProfilePicture;

export const pricing = [
  {
    eventType: "Conference",
    eventFormat: "In Person",
    icon: "fas fa-cube",
    price: "9000",
    isChild: [
      { id: "1", features: "5000 Attendees" },
      { id: "2", features: "20 sessions" },
      { id: "3", features: "10 speakers" },
      { id: "4", features: "Tickets" },
    ],
  },
  {
    eventType: "Exhibition",
    eventFormat: "In Person",
    icon: "fas fa-shield-alt",
    price: "2500",
    isChild: [
      { id: "1", features: "7000 attendees" },
      { id: "2", features: "15 booths" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    eventType: "Expo",
    eventFormat: "In Person",
    icon: "fas fa-shield-alt",
    price: "2500",
    isChild: [
      { id: "1", features: "20000 attendees" },
      { id: "2", features: "40 booths" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    eventType: "Conference",
    eventFormat: "Virtual",
    icon: "fas fa-cube",
    price: "9000",
    isChild: [
      { id: "1", features: "1200 Attendees" },
      { id: "2", features: "20 sessions" },
      { id: "3", features: "10 speakers" },
      { id: "4", features: "Tickets" },
    ],
  },
  {
    eventType: "Webinar",
    eventFormat: "Virtual",
    icon: "fas fa-trophy",
    price: "4000",
    isChild: [
      { id: "1", features: "10000 Attendees" },
      { id: "2", features: "10 Activities" },
      { id: "3", features: "No Recording" },
      { id: "4", features: "Free" },
    ],
  },
  {
    eventType: "Exhibition",
    eventFormat: "Virtual",
    icon: "fas fa-shield-alt",
    price: "2500",
    isChild: [
      { id: "1", features: "8000 attendees" },
      { id: "2", features: "40 booths" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    eventType: "Expo",
    eventFormat: "Virtual",
    icon: "fas fa-headset",
    price: "10000",
    isChild: [
      { id: "1", features: "Free Live Support" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    eventType: "Conference",
    eventFormat: "Hybrid",
    icon: "fas fa-cube",
    price: "9000",
    isChild: [
      { id: "1", features: "7000 Attendees" },
      { id: "2", features: "20 sessions" },
      { id: "3", features: "10 speakers" },
      { id: "4", features: "Tickets" },
    ],
  },
  {
    eventType: "Exhibition",
    eventFormat: "Hybrid",
    icon: "fas fa-shield-alt",
    price: "2500",
    isChild: [
      { id: "1", features: "7000 attendees" },
      { id: "2", features: "10 booths" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    eventType: "Expo",
    eventFormat: "Hybrid",
    icon: "fas fa-headset",
    price: "20000",
    isChild: [
      { id: "1", features: "Free Live Support" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
];

import React from "react";
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { detectDeviceType } from "../../../utils/deviceType";
import Header from "../EventRegistration/One/Header";
import CustomFooter from "../../../Layout/HorizontalLayout/CustomFooter";

import CheckoutTicketForm from "./CheckoutTicketForm";
import TicketDetails from "./TicketDetails";

const CheckoutTicket = () => {
  const params = useParams();
  const eventID = params?.eventID;
  const deviceType = detectDeviceType();
  const ticket = JSON.parse(localStorage.getItem("kagoTicketDetails"));

  // console.log(
  //   "ticket...",
  //   JSON.stringify(ticket?.eventDetails?.merchantDetails, null, 2)
  // );

  return (
    <React.Fragment>
      <Header eventDetails={ticket?.eventDetails} deviceType={deviceType} />
      <div className="w-100 mt-2 d-flex justify-content-center">
        <Row>
          <Col md={8}>
            <div
              className="w-100 py-3 rounded-3"
              style={{ backgroundColor: "white" }}
            >
              <div className="px-4 py-2 h4">Checkout</div>
              <div className="px-4">
                <CheckoutTicketForm ticket={ticket} />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <TicketDetails
              ticket={ticket}
              eventID={eventID}
              mechantInfo={ticket?.eventDetails?.merchantDetails}
            />
          </Col>
        </Row>
      </div>
      <div className="w-100">
        <CustomFooter />
      </div>
    </React.Fragment>
  );
};

export default CheckoutTicket;

import React, { useState } from "react";
import HomeNav from "../../../components/Common/HomeNav";
import EventListing from "../../../components/Authentication/Home/EventListing";

const Home = () => {
  const [search, setSearch] = useState("");
  return (
    <>
      <HomeNav search={search} setSearch={setSearch} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "8%",
        }}
      >
        <h1 style={{ fontWeight: "bolder" }}>Upcoming Events</h1>
      </div>
      <div className="w-100 justify-content-center">
        <EventListing search={search} />
      </div>
    </>
  );
};

export default Home;

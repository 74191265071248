import React, { useState, useRef, useEffect } from "react";
import { Send } from "lucide-react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { GoogleGenerativeAI } from "@google/generative-ai";
import "./AIChat.scss"; // Import the SCSS file

// const genAi = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API);
const genAi = new GoogleGenerativeAI("AIzaSyCmGw8ZlzUyfCAHkKTtBLATTxkhJ4dqBmM");
const model = genAi.getGenerativeModel({ model: "gemini-1.5-pro" });

const AIChat = () => {
  const [messages, setMessages] = useState([
    { sender: "ai", text: "Hello, I'm Kago AI how can I help you ?" },
  ]);
  const [inputs, setInputs] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const messageEndRef = useRef(null);
  const chatSessionRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    if (!chatSessionRef.current) {
      chatSessionRef.current = model.startChat({
        generationConfig: {
          temperature: 0.9,
          topK: 1,
          topP: 1,
          maxOutputTokens: 2048,
        },
        history: [],
      });
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.trim()) return;
    setInputs("");
    setMessages((prev) => [...prev, { sender: "user", text: inputs }]);

    setIsTyping(true);
    try {
      let fullResponse = "";
      const result = await chatSessionRef.current.sendMessageStream(inputs);
      setMessages((prev) => [
        ...prev,
        { sender: "ai", text: "", isGenerating: true },
      ]);
      for await (const chunck of result.stream) {
        const chunckText = chunck.text();
        fullResponse += chunckText;
        setMessages((prev) => [
          ...prev.slice(0, -1),
          { sender: "ai", text: fullResponse, isGenerating: true },
        ]);
      }
      setMessages((prev) => [
        ...prev.slice(0, -1),
        { sender: "ai", text: fullResponse, isGenerating: false },
      ]);
      setIsTyping(false);
    } catch (error) {
      setIsTyping(false);
      setMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          text: "Sorry, there was an error",
          isGenerating: false,
        },
      ]);
    }
  };

  const MarkdownComponent = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={vscDarkPlus}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className}>{children}</code>
      );
    },
    h1: ({ node, ...props }) => (
      <h1 style={{ fontSize: "2em", fontWeight: "bold" }} {...props} />
    ),
    h2: ({ node, ...props }) => (
      <h2 style={{ fontSize: "1.5em", fontWeight: "bold" }} {...props} />
    ),
    h3: ({ node, ...props }) => (
      <h3 style={{ fontSize: "1.17em", fontWeight: "bold" }} {...props} />
    ),
  };

  return (
    <div className="ai-chat">
      {/* <header className="chat-header">
        <h1>Jed AI Chat</h1>
      </header> */}
      <div className="chat-body">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === "user" ? "user" : "ai"}`}
          >
            <div className="message-content">
              {message.sender === "user" ? (
                message.text
              ) : (
                <ReactMarkdown
                  className={`markdown ${
                    message.isGenerating ? "typing-animation" : ""
                  }`}
                  components={MarkdownComponent}
                >
                  {message.text || "Thinking..."}
                </ReactMarkdown>
              )}
            </div>
          </div>
        ))}

        {isTyping && <div className="typing">Typing...</div>}
        <div ref={messageEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="chat-input">
        <div className="input-container">
          <input
            type="text"
            value={inputs}
            placeholder="Type a message..."
            onChange={(e) => setInputs(e.target.value)}
          />
          <button type="submit">
            <Send size={24} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default AIChat;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import EventLogo from "../../Events/EventLogo";
import EditVideoLink from "../../Events/EditVideoLink";
import Faq from "../../Events/Faq";
import BannerEvent from "../../Events/BannerEvent";
// import ButtonSubmit from "../../Buttons/ButtonSubmit";
const EventBranding = ({
  eventDetails,
  token,
  userID,
  isLoading,
  userEmail,
}) => {
  // const params = useParams();
  // const eventName = params.eventName;
  const Navigate = useNavigate();

  const key = ["Events", userEmail];
  const mutationDelete = useCustomMutation(key);

  useEffect(() => {
    if (mutationDelete?.isSuccess) {
      Navigate("/dashboard");
    }
  }, [mutationDelete?.isSuccess]);
  return (
    <div className="p-2">
      <div className="w-100 mt-3 d-flex">
        <div className="w-25">
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "#4a4a4a",
              textAlign: "justify",
            }}
          >
            Event Logo
          </div>
          <div style={{ textAlign: "justify" }}>
            The event logo will be displayed prominently at the top of your
            event landing page header.image size (200 X 500) pixels
          </div>
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-2">
          <EventLogo
            userID={userID}
            token={token}
            eventDetails={eventDetails}
          />
        </div>
      </div>

      <hr style={{ color: "black" }}></hr>
      <div className="w-100 d-flex">
        <div className="w-25">
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "#4a4a4a",
              textAlign: "justify",
            }}
          >
            Event Banner
          </div>
          <div style={{ textAlign: "justify" }}>
            You can also use a video header that will replace the image banner
            on your event page. However, the image will still be required and
            used for thumbnails views.image size (1920 X 800) pixels
          </div>
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-2">
          <BannerEvent
            userID={userID}
            token={token}
            eventDetails={eventDetails}
          />
        </div>
      </div>
      <hr style={{ color: "black" }}></hr>
      <div className="w-100 d-flex">
        <div className="w-25">
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "#4a4a4a",
              textAlign: "justify",
            }}
          >
            Event Video Introduction
          </div>
          <div style={{ textAlign: "justify" }}>
            Add a video by entering a YouTube URL. This video must be public or
            unlisted, ads must be turned off, it must not be age restricted, and
            it should be landscape.
            {/* videoLink */}
          </div>
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-4">
          <EditVideoLink
            userID={userID}
            token={token}
            eventDetails={eventDetails}
          />
        </div>
      </div>
      <hr style={{ color: "black" }}></hr>
      <div className="w-100 d-flex">
        <div className="w-25">
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "#4a4a4a",
              textAlign: "justify",
            }}
          >
            Faq
          </div>
          <div style={{ textAlign: "justify" }}>
            FAQ (Frequently Asked Questions) section provides quick answers to
            common queries, enhancing user experience and reducing the need for
            direct support. It covers topics like product details, service
            policies, and troubleshooting tips.
          </div>
        </div>

        <div className="mx-3 w-75 d-flex justify-content-between mb-4">
          <Faq userID={userID} token={token} eventDetails={eventDetails} />
        </div>
      </div>
    </div>
  );
};

export default EventBranding;

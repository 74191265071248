// Tooltip.js
import React from "react";

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = React.useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#555",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px",
            top: "-40px",
            left: "50%",
            transform: "translateX(-50%)",
            whiteSpace: "nowrap",
            zIndex: 1000,
            pointerEvents: "none",
            textAlign: "center",
          }}
        >
          {text}
          <div
            style={{
              position: "absolute",
              width: "0",
              height: "0",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: "5px solid #555",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "-5px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;

import React, { useState, useEffect } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";

import { IoArrowForwardOutline } from "react-icons/io5";
import bgImg from "../../../../assets/images/event/event-background-one.png";

// import CustomModal from "../../../../components/SlideInComponent/SlideInComponent";

//Rextangle.png
import { useNavigate } from "react-router-dom";
import Schedule from "./Schedule";

const Main = ({ userId, token, eventID, eventName }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userID = obj?.data?.data?.data?._id;
  const userEmail = obj?.data?.data?.data?.email;

  // console.log("userRole........", obj?.data?.data?.data?.roles);

  // console.log("userEmail...", userEmail);

  const [groupedSessions, setGroupedSessions] = useState({});

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isFetching: isFetchingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const {
    data: getMyHosts,
    error: errorGetMyHosts,
    isFetching: isFetchingGetMyHosts,
  } = useCustomQuery(
    ["myHosts", eventID],
    `${process.env.REACT_APP_API}users/event-host/${eventID}`,
    token
  );

  // console.log("getMyHosts....x", getMyHosts);

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  const {
    data: getEventBooths,
    error: errorGetEventBooths,
    isLoading: isLoadingGetEventBooths,
  } = useCustomQuery(
    ["eventsBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token
  );

  // group sessions by date

  const groupSessionsByDate = (getMySessions) => {
    const groups = {};

    getMySessions.forEach((session) => {
      const sessionDate = new Date(session.sessionDate);
      const dateKey = sessionDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }

      groups[dateKey].push(session);
    });

    setGroupedSessions(groups);
  };

  useEffect(() => {
    if (getMySessions) {
      groupSessionsByDate(getMySessions);
    }
  }, [getMySessions]);

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token
  );
  // console.log("eventID..", eventID);
  // console.log("getMySponsors..", getMySponsors);

  if (isFetchingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  if (isLoadingGetMySessions) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const handleBuyTicket = () => {
    Navigate(
      `/${getMyEventDetail?.eventName.replace(/ /g, "-")}/Ticket/${eventID}`
    );
  };

  const buyEventBtn = () => {
    return (
      <button
        className="btn waves-effect waves-light event-landing-btn"
        onClick={() => handleBuyTicket()}
      >
        <div className="d-flex justify-content-center align-center">
          <span
            className="px-2"
            style={{ color: "#fff", fontWeight: "600", fontSize: 16 }}
          >
            Buy Ticket
          </span>
        </div>
      </button>
    );
  };

  const isSponsors = getMySponsors && getMySponsors?.length > 0;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Event date configuration
  let dateValue = new Date(getMyEventDetail?.startDate);

  let day = dayNames[dateValue.getUTCDay()];
  let month = monthNames[dateValue.getUTCMonth()];
  let year = dateValue.getUTCFullYear();
  let date = dateValue.getUTCDate();

  let formattedDate = `${day} ${date} ${month} ${year}`;

  // console.log("getMySessions...", getMySessions);

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#000" }}>
        <nav className="event-landing-navbar">
          <ul className="event-landing-navbar__list">
            <li className="event-landing-navbar__list--item">
              <a
                href="#event-details"
                className="event-landing-navbar__list--item-link"
              >
                Event Details
              </a>
            </li>
            <li className="event-landing-navbar__list--item">
              <a
                href="#overview"
                className="event-landing-navbar__list--item-link"
              >
                Overview
              </a>
            </li>
            {getMyEventDetail?.eventType?.includes("Conference") ? (
              <li className="event-landing-navbar__list--item">
                <a
                  href="#schedule"
                  className="event-landing-navbar__list--item-link"
                >
                  Schedule
                </a>
              </li>
            ) : null}
            {/* {getMyEventDetail?.eventType?.includes("Exhibition") ? (
              <li className="event-landing-navbar__list--item">
                <a
                  href="#booths"
                  className="event-landing-navbar__list--item-link"
                >
                  Booths
                </a>
              </li>
            ) : null} */}

            <li className="event-landing-navbar__list--item">
              <a
                href="#hosts"
                className="event-landing-navbar__list--item-link"
              >
                Hosts
              </a>
            </li>
            <li className="event-landing-navbar__list--item">
              <a
                href="#sponsors"
                className="event-landing-navbar__list--item-link"
              >
                Sponsors
              </a>
            </li>
          </ul>
        </nav>
        <div
          id="event-details"
          className="event-landing-container text-center py-5"
          style={{ backgroundImage: `url(${bgImg})` }}
        >
          <h1 style={{ color: "#000" }}>{getMyEventDetail?.eventName}</h1>
          {getMyHosts && (
            <h5
              className="py-2 "
              style={{ color: "#000", fontWeight: "normal" }}
            >
              Event by {getMyHosts?.firstName} {getMyHosts?.lastName}
            </h5>
          )}
          <span style={{ color: "#000", fontSize: "10px", marginBottom: 8 }}>
            EVENT DATE{" "}
          </span>
          <h5 style={{ color: "#000" }}>{formattedDate}</h5>
          <table
            style={{
              borderCollapse: "collapse",
              width: "300px",
              margin: "0 auto",
              color: "#000",
            }}
          >
            <tr>
              <td style={{ whiteSpace: "nowrap" }}>
                <span style={{ fontSize: "12px" }}>START TIME</span>
                <br />

                <span
                  style={{
                    padding: "8px 12px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {`${getMyEventDetail.startTime} SAST`}
                </span>
              </td>
              <td
                style={{ whiteSpace: "nowrap", borderLeft: "1px solid #fff" }}
              >
                <span style={{ fontSize: "12px" }}>END TIME</span>
                <br />

                <span
                  style={{
                    padding: "8px 12px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {`${getMyEventDetail.endTime} SAST`}
                </span>
              </td>
            </tr>
          </table>

          {buyEventBtn()}

          {/* <div className="shadow-sm mt-4" style={{ overflow: "hidden" }}>
            <img
              // src={defaultImg}
              src={getMyEventDetail?.bannerImage}
              className="Image_Fit event-landing-banner"
              //  className="card-brd"
            />
          </div> */}
          <div className="event-landing-banner-container">
            <div
              className="shadow-sm event-landing-banner-wrapper"
              // style={{ overflow: "hidden" }}
            >
              <img
                src={getMyEventDetail?.bannerImage}
                //  className="card-brd"
              />
            </div>
          </div>
          <div id="overview" className="overview text-dark">
            <h3>Overview</h3>
            <p>{getMyEventDetail.eventDescription}</p>
          </div>
          {getMyEventDetail?.eventType?.includes("Conference") ? (
            <div id="schedule" className="schedule text-dark">
              <h3>Schedule</h3>
              <Schedule mySchedule={getMySessions} />
            </div>
          ) : null}

          {/* {getMyEventDetail?.eventType?.includes("Exhibition") ? (
            <div id="booths" className="schedule">
              <h3>Booths</h3>

              <div>
                <div className="schedule__date">
                  <span>{date}</span>
                  <h4>
                    8 <span>Tue</span>
                  </h4>
                </div>

                <div className="schedule__items">
                  {getEventBooths?.map((booth, i) => (
                    <div key={i}>
                      <div className="schedule__items__item">
                        <p className="schedule__items__item--time">
                          {session?.startTime} - {session?.endTime}
                        </p>
                        <h3 className="schedule__items__item--name">
                          {booth?.boothName}
                        </h3>
                      </div>
                      <p></p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null} */}
          {getMyHosts && (
            <div id="hosts" className="hosts text-dark">
              <h3>Hosts</h3>
              <div className="host">
                {/* <div className="host--profile-img"> */}
                <img
                  src={getMyHosts?.photo}
                  alt="host-pic"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                  }}
                />
                {/* {getMyEventDetail?.eventOrganizerId?.firstName.substring(0, 1)} */}
                {/* </div> */}
                <p className="host--name">
                  {`${getMyHosts?.firstName} ${getMyHosts?.lastName}`}
                </p>
                <p className="host--profile-slider" onClick={toggleSlideIn}>
                  {/* View Profile {"  "} */}
                  {/* <IoArrowForwardOutline size={16} />
                  <CustomModal
                  isOpen={slideInOpen}
                  toggle={toggleSlideIn}
                  title="Shadow Sketch"
                  body={<p className="host--profile-img">S</p>}
                /> */}
                </p>
              </div>
            </div>
          )}
          {isSponsors ? (
            <div id="sponsors" className="event-sponsors text-dark">
              <h3>Sponsors</h3>
              <div className="event-sponsors__container">
                {getMySponsors?.map((sponsor, i) => {
                  return (
                    <div className="event-sponsors__item" key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                      />
                      {/* <h5>{sponsor?.sponsorName}</h5> */}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {/* <div id="support" className="support">
            <p>
              In case you need any support, kindly drop us <br /> a message at{" "}
              <a
                href={`mailto:${getMyEventDetail?.eventOrganizerId?.email}`}
                style={{ color: "#faa200" }}
              >
                {getMyEventDetail?.eventOrganizerId?.email}
              </a>
            </p>
            <p>
              Powered by{" "}
              <a
                href="https://www.showbay24.com"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                showbay24.com
              </a>
            </p>
            <p>
              Powered by{" "}
              <span style={{ textDecoration: "underline" }}>showbay24.com</span>
            </p>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Main;

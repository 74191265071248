import React, { useState } from "react";
import { Modal, Spinner } from "reactstrap";
import { PDFViewer, Document, Page } from "@react-pdf/renderer";
import { useReactToPrint } from "react-to-print";
import InvoiceDetails from "./InvoiceDetails";

function InvoiceList({ invoices }) {
  const [isToggleModal, setIsToggleModal] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleModal = (invoice) => {
    setIsToggleModal(!isToggleModal);
    setInvoiceDetails(invoice);
  };
  const handlePrint = () => {
    // setIsLoading(true);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
    // Print or download functionality here
    // Example: window.print();
  };
  //   console.log("invoices...", invoices);
  return (
    <div className="w-100 d-flex justify-content-center mb-5">
      <div className="w-75" style={{ maxWidth: 800 }}>
        <div className="w-100 d-flex  p-3" style={{ fontWeight: "bold" }}>
          <div className="w-25">Date</div>
          <div className="w-50">Event Name</div>
          <div className="w-25">Receipt</div>
        </div>
        {invoices?.data?.map((invoice, i) => {
          const date = new Date(invoice?.created_at);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          let InvoiceDate = `${year}/${month}/${day}`;
          return (
            <div
              className="w-100 d-flex p-3 align-items-center rounded-4 mb-2"
              style={{ backgroundColor: "white", fontWeight: "bold" }}
              key={i}
            >
              <div className="w-25">{InvoiceDate}</div>
              <div className="w-50">{invoice?.item}</div>
              <div className="w-25">
                <button
                  className="btn btn-outline-primary rounded-5"
                  style={{ width: 100 }}
                  onClick={() => handleModal(invoice)}
                >
                  View
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        size="lg"
        isOpen={isToggleModal}
        toggle={() => {
          setIsToggleModal(!isToggleModal);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Invoice Details
          </h5>
          <button
            onClick={() => {
              setIsToggleModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" id="print-invoice-modal">
          {/* <PDFViewer width="100%" height={600}>
            <Document>
              <Page size="A4"> */}
          <InvoiceDetails invoiceInfo={invoiceDetails} />
          {/* </Page>
            </Document>
          </PDFViewer> */}
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <button
            onClick={() => {
              setIsToggleModal(false);
            }}
            type="button"
            className="btn btn-dark"
          >
            Cancel
          </button>
          <button
            onClick={handlePrint}
            type="button"
            className="btn btn-primary"
          >
            {!isLoading ? (
              <span className="px-2">Print</span>
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" />
                Loading
              </span>
            )}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default InvoiceList;

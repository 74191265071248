import React from "react";
import { useParams } from "react-router-dom";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import { Box } from "@mui/material";
import CommunityLandingHeader from "../../../Layout/HorizontalLayout/CommunityLandingHeader";
import logo from "../../../assets/images/community/Logo.png";
import CommunityInfo from "./CommunityInfo";
import UpcommingEvents from "./UpcommingEvents";

function LandingPage() {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userID = obj?.data?.data?.data?._id;
  const userEmail = obj?.data?.data?.data?.email;
  const token = obj?.data?.token;

  const params = useParams();
  const communityID = params.communityID;
  const {
    data: getCommunityDetails,
    error: errorGetCommunities,
    isLoading: isLoadingGetCommunityDetails,
  } = useCustomFecthQuery(
    ["CommunitySharedLink", communityID],
    `${process.env.REACT_APP_API}guest/communities/${communityID}`,
    null,
    {
      enabled: !!communityID,
    }
  );
  // console.log("getCommunityDetails...", getCommunityDetails);
  const {
    data: getMyUpcommingEvents,
    error: errorGetMyUpcommingEvents,
    isLoading: isLoadingGetMyUpcommingEvents,
  } = useCustomFecthQuery(
    ["guest-events", communityID],
    `${process.env.REACT_APP_API}events/community/guest/${communityID}`,
    null,
    { enabled: !!communityID }
  );

  // console.log("getMyUpcommingEvents...", getMyUpcommingEvents);
  // communityID;
  return (
    <div className="w-100">
      <CommunityLandingHeader communityID={communityID} logoImg={logo} />
      <Box sx={{ backgroundColor: "#282e3f", mt: 0, p: 4 }} />
      <CommunityInfo getCommunityDetails={getCommunityDetails?.data} />
      <div className="mt-2 ">
        <div
          className="mt-2 d-flex justify-content-center"
          style={{ fontSize: 20, fontWeight: "600", color: "black" }}
        >
          Upcoming events
        </div>
        <UpcommingEvents getMyUpcommingEvents={getMyUpcommingEvents} />
      </div>
    </div>
  );
}

export default LandingPage;

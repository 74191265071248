import React, { useEffect, useState } from "react";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import Sessions from "./Sessions/Sessions";
import Divider from "./Divider";

const Schedule = ({ token, eventID, eventName, eventDetails }) => {
  const [groupedSessions, setGroupedSessions] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomFecthQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const groupSessionsByDate = (getMySessions) => {
    const groups = {};
    getMySessions?.data?.forEach((session) => {
      const sessionDate = new Date(session?.sessionDate);
      const dateKey = sessionDate?.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(session);
    });

    setGroupedSessions(groups);
  };

  useEffect(() => {
    if (getMySessions) {
      groupSessionsByDate(getMySessions?.data);
    }
  }, [getMySessions]);

  return (
    <React.Fragment>
      <div className="w-100 mt-2 d-flex justify-content-center">
        <h1
          id="schedule"
          className="d-flex justify-content-center"
          style={{ color: "black", fontWeight: "bold" }}
        >
          Sessions
        </h1>
      </div>
      <Divider />
      <div className="w-100">
        <Sessions
          sessions={getMySessions?.data}
          eventID={eventID}
          eventName={eventName}
          eventDetails={eventDetails}
        />
      </div>
    </React.Fragment>
  );
};

export default Schedule;

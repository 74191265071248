import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
// import ProfilePicture from "../../";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

function EditSession({ sessionDetails, eventID, token, setIsOffcanvasOpen }) {
  // const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `sessions/${sessionDetails?._id}`,
      isJSON = null;
    const filteredInputs = Object.entries(inputs).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    var raw = JSON.stringify(filteredInputs);

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Session successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  // maximum duration 480
  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
    if (sessionDetails) {
      const { sessionName, sessionLocation, sessionDate, startTime, duration } =
        sessionDetails;
      setInputs({
        sessionName,
        sessionLocation,
        sessionDate,
        startTime,
        duration,
      });
    }
  }, [mutation?.isSuccess, sessionDetails]);

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <div style={{ width: 400 }}>
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          onSubmit={toggleSaveChanges}
        >
          <Row>
            <Col md={12}>
              <label>
                <span className="text-danger">*</span>Session Name
              </label>
              <input
                type="text"
                name="sessionName"
                value={inputs?.sessionName}
                placeholder="Enter Session Name"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            {/* {eventFormat === "Virtual" ? null : ( */}
            <Col md={12}>
              <label>
                <span className="text-danger">*</span>Location
              </label>
              <input
                type="text"
                name="sessionLocation"
                value={inputs?.sessionLocation}
                placeholder="Enter Session Location"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            {/* )} */}

            <Col md={12}>
              <label>
                <span className="text-danger">*</span>Session Date
              </label>
              <input
                type="date"
                name="sessionDate"
                value={inputs?.sessionDate}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6}>
              <label>
                <span className="text-danger">*</span>Start Time
              </label>
              <input
                type="time"
                name="startTime"
                value={inputs?.startTime}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6}>
              <label>
                <span className="text-danger">*</span>Duration (minutes)
              </label>
              <input
                type="number"
                name="duration"
                value={inputs?.duration}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <div className="mb-3"></div>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Update"
                ColorText="white"
                BorderColor="#F68A20"
                BackgroundColor="#F68A20"
                borderRadius="25px"
                handleOnclick={toggleSaveChanges}
                pending={mutation?.isLoading}
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default EditSession;

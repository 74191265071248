import React, { useState, useRef } from "react";
import { Modal } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "../../css/Summary.scss";

import "./landingPage.scss";
import ImgPic from "../../../../../../assets/images/event/event-background-one.png";
import LandingForm from "./components/LandingForm";
import UploaderReceptionBanner from "./components/UploaderReceptionBanner";
import EditReceptionBanner from "./components/EditReceptionBanner";

const LandingPage = ({ token, eventID }) => {
  const key = ["myEventLadingPage", eventID];
  const [uploadLandingBanner, setUploadLandingBanner] = useState(false);
  const [selectedBarColor, setSelectedBarColor] = useState("#ffffff");
  const [selectedTitleColor, setSelectedTitleColor] = useState("#000");
  const [selectedTextColor, setSelectedTextColor] = useState("#000");
  const colorPickerBarRef = useRef(null);
  const colorPickerTitleRef = useRef(null);
  const colorPickerTextRef = useRef(null);

  const tog_uploadLandingBanner = () => {
    setUploadLandingBanner(true);
  };

  const handleSelectBarColor = () => {
    colorPickerBarRef.current.click();
  };
  const handleSelectTitleColor = () => {
    colorPickerTitleRef.current.click();
  };
  const handleSelectTextColor = () => {
    colorPickerTextRef.current.click();
  };

  return (
    <React.Fragment>
      <div className="event-branding">
        <div className="event-branding--heading">
          <h3>Event Landing Page</h3>
          <span className="text">Customize Your Event Landing Page</span>
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => setUploadLandingBanner(true)}
          >
            Upload
          </button>
        </div>
        <div className="w-100 d-flex">
          <div
            style={{
              position: "relative",
              borderStyle: "dashed",
              borderWidth: 1,
              borderRadius: 10,
              width: 250,
              height: 400,
            }}
            className="mt-2"
          >
            <img
              src={ImgPic}
              style={{ borderRadius: 10 }}
              className="w-100 h-100"
            />
          </div>
          <div className="w-100">
            <div className="w-100 d-flex">
              <div
                style={{
                  // width: 100,
                  height: 70,
                  position: "relative",
                }}
                className="mt-2 mx-2 d-flex flex-column align-items-center"
              >
                <button
                  className="btn btn-primary"
                  style={{ width: 180, height: 50 }}
                  onClick={handleSelectBarColor}
                >
                  Select Lines Color
                </button>
                <input
                  ref={colorPickerBarRef}
                  type="color"
                  value={selectedBarColor}
                  onChange={(e) => setSelectedBarColor(e.target.value)}
                  style={{
                    width: 180,
                    height: 50,
                    position: "absolute",
                    right: -380,
                    top: 0,

                    transform: "translate(-100%, 0)",
                    borderRadius: 5,
                    // display: "none",
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div
                style={{
                  // width: 100,
                  height: 70,
                  position: "relative",
                }}
                className="mt-2 mx-2 d-flex flex-column align-items-center"
              >
                <button
                  className="btn btn-primary"
                  style={{ width: 180, height: 50 }}
                  onClick={handleSelectTitleColor}
                >
                  Select Title Color
                </button>
                <input
                  ref={colorPickerTitleRef}
                  type="color"
                  value={selectedTitleColor}
                  onChange={(e) => setSelectedTitleColor(e.target.value)}
                  style={{
                    width: 180,
                    height: 50,
                    position: "absolute",
                    right: -380,
                    top: 0,

                    transform: "translate(-100%, 0)",
                    borderRadius: 5,
                    // display: "none",
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div
                style={{
                  // width: 100,
                  height: 70,
                  position: "relative",
                }}
                className="mt-2 mx-2 d-flex flex-column align-items-center"
              >
                <button
                  className="btn btn-primary"
                  style={{ width: 180, height: 50 }}
                  onClick={handleSelectTextColor}
                >
                  Select Text Color
                </button>
                <input
                  ref={colorPickerTextRef}
                  type="color"
                  value={selectedTextColor}
                  onChange={(e) => setSelectedTextColor(e.target.value)}
                  style={{
                    width: 180,
                    height: 50,
                    position: "absolute",
                    right: -380,
                    top: 0,

                    transform: "translate(-100%, 0)",
                    borderRadius: 5,
                    // display: "none",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {Array.isArray(getLandingPage) && getLandingPage?.length > 0 && (
          <EditReceptionBanner
            token={token}
            eventID={eventID}
            eventReception={getLandingPage}
          />
        )} */}
        <Modal
          size="md"
          isOpen={uploadLandingBanner}
          toggle={tog_uploadLandingBanner}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Set Landing Page
            </h5>
            <button
              onClick={() => {
                setUploadLandingBanner(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <LandingForm />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;

import React, { useState } from "react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import OffCanvas from "../../../OffCanvas/OffCanvas";

import SearchInput from "../../../SerachInput/SearchInput";
import AddSpeaker from "./AddSpeaker";
import TableSpeaker from "./TableSpeaker";

const Speakers = ({ userID, token, eventID }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleOpenOffCanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  return (
    <div className="w-100">
      <div className="w-100 mt-3 ">
        <div className="w-100">
          Manage all event speakers and invite speakers. Your invited Speakers
          will not affect this limit until they attend your event. You can also
          download all the registration details of your speakers.
        </div>
        <div className="w-100 mt-3 d-flex">
          <div className="w-75 d-flex">
            <SearchInput
              Title="Search"
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              radius={25}
            />
          </div>
          <div className="w-25 d-flex justify-content-end">
            <ButtonIcon
              Title="Add Speaker"
              Icon={<AiOutlineUsergroupAdd size={20} color="white" />}
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={() => handleOpenOffCanvas()}
              pending={false}
            />
          </div>
        </div>
        <div>
          <TableSpeaker
            userID={userID}
            token={token}
            eventID={eventID}
            search={search}
          />
        </div>
      </div>
      <div className="w-100 mt-3"></div>
      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Add Speaker"
        body={
          <AddSpeaker
            userID={userID}
            token={token}
            eventID={eventID}
            setIsOffcanvasOpen={setIsOffcanvasOpen}
          />
        }
        toggleSaveChanges={handleOpenOffCanvas}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default Speakers;

import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../../../Buttons/ButtonSubmit";
import { countryList } from "../../../../../Countries/countries";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function AddAttendee({ userId, eventID, token, setIsOffcanvasOpen }) {
  const [inputs, setInputs] = useState({});
  const key = ["myAttendees", eventID];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `attendees/add/${eventID}`,
      isJSON = null;
    const raw = JSON.stringify({
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      jobTitle: inputs.jobTitle,
      organization: inputs.organization,
      phoneNumber: inputs.phoneNumber,
      city: inputs.city,
      country: inputs.country,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Attendee successfully invited",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);
  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <Row>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>First name
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs?.firstName}
              placeholder="Enter First name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Last name
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs?.lastName}
              placeholder="Enter Last name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            {" "}
            <label>
              <span className="text-danger">*</span>Email
            </label>
            <input
              type="email"
              name="email"
              value={inputs?.email}
              placeholder="Enter email"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Job Title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={inputs?.jobTitle}
              placeholder="Enter Job Title"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Organization Name
            </label>
            <input
              type="text"
              name="organization"
              value={inputs?.organization}
              placeholder="Enter organization name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          {/* <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Phone Number
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={inputs?.phoneNumber}
              placeholder="+270000000"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col> */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Phone Number
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>

          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>City
            </label>
            <input
              type="text"
              name="city"
              value={inputs?.city}
              placeholder="Enter City"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label className="event__form__Creation">
              Country
              <select
                name="country"
                value={inputs.country}
                onChange={handleChange}
                className="mb-3 form-control"
                style={{ width: 300 }}
                required
              >
                {countryList?.map((country, i) => (
                  <option key={i}> {country} </option>
                ))}
              </select>
            </label>
          </Col>
          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Submit"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default AddAttendee;

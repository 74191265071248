import React, { useState, useEffect } from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import SimpleBar from "simplebar-react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Spinner,
} from "reactstrap";
import "../../css/Summary.scss";

import SessionPreviewSlider from "../../../../../../components/Common/SlideBigModalDark/SlideBigBarDark";
import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";

import { AiOutlinePlusCircle } from "react-icons/ai";
// import NoAttendees from "../../../../../../../assets/images/no-attendees.png";

import "./session-list.styles.scss";
import AddSession from "../../AddSession";
import EditSession from "../../EditSession";
import AddSpeakers from "../../AddSpeaker";
import AttachSessionDocument from "../../AttachSessionDocument";
import SessionPreviewBody from "../../../../../../components/Modal/SessionBody";

const SessionList = ({ userId, token, eventID }) => {
  const [dropdownOpenStates, setDropdownOpenStates] = useState({});
  // add session
  const [slideInOpen, setSlideInOpen] = useState(false);
  const [detatils, setDetatils] = useState(false);
  const [showdetatils, setShowDetatils] = useState(false);
  const [showEditSession, setShowEditSession] = useState(false);
  const [showAddSpeaker, setShowAddSpeaker] = useState(false);
  const [showAttachDocument, setShowAttachDocument] = useState(false);
  const [deleteSessionModal, setDeleteSessionModal] = useState(false);
  const key = ["mySessions", eventID];
  const mutationDelete = useCustomMutation(key);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", userId],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // console.log("getMyEventDetail...#2...", getMyEventDetail);

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );
  // console.log("getMySessions....", getMySessions);
  const {
    data: getMyTeam,
    error: errorGetMyMyTeam,
    isLoading: isLoadingGetMyTeam,
  } = useCustomQuery(
    ["myTeams", eventID],
    `${process.env.REACT_APP_API}auth/team-member/${eventID}`,
    token
  );

  const DeleteSession = () => {
    const Method = "DELETE",
      url = `sessions/${detatils?.id}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Session Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setDetatils(false);
      setDeleteSessionModal(false);
    }
  }, [mutationDelete.isSuccess]);

  // console.log("getMyEventDetail...", getMyEventDetail);
  // console.log("getSession...@@@sessios", getMySessions);

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }
  const handleEditSession = (session) => {
    setDetatils(session);
    setShowEditSession(!showEditSession);
  };
  const handlePreviewSession = (session) => {
    setDetatils(session);
    setShowDetatils(!showdetatils);
  };
  const handleAddSpeaker = (session) => {
    setDetatils(session);
    setShowAddSpeaker(!showAddSpeaker);
  };
  const handleAttachDocument = (session) => {
    setDetatils(session);
    setShowAttachDocument(!showAttachDocument);
  };

  const tog_deleteSession = (session) => {
    setDetatils(session);
    setDeleteSessionModal(!deleteSessionModal);
  };

  const toggleDropdown = (index) => {
    setDropdownOpenStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  // console.log("getMySessions.....", getMySessions);

  return (
    <React.Fragment>
      <div className="sessions-list__heading">
        <div>
          <h3>Sessions</h3>
          <span className="text">
            Choose from the different types of sessions to add to your event
            schedule
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-primary"
            type="button"
            onClick={toggleSlideIn}
          >
            <AiOutlinePlusCircle size={20} />
            <span className="px-2">Add a session </span>
          </button>
          <SlideInRightBar
            isOpen={slideInOpen}
            toggle={toggleSlideIn}
            title="Add Session"
            body={
              <AddSession
                userId={userId}
                token={token}
                eventID={eventID}
                eventFormat={getMyEventDetail?.eventFormat}
                setSlideInOpenSession={setSlideInOpen}
              />
            }
          />
        </div>
      </div>
      <SimpleBar style={{ height: "425px" }}>
        {getMySessions?.data?.map((session, i) => {
          return (
            <div
              className="mt-2 mb-5 activities---container d-flex w-100 "
              key={i}
            >
              <div className="p-3 d-flex justify-content-center ">
                <div>
                  <h5 className="text-primary">
                    {session?.sessionDate?.substring(0, 3)}
                  </h5>
                  <h2 className="text-primary">
                    {session?.sessionDate?.substring(8, 10)}
                  </h2>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="verticalLine"></div>
              </div>
              <div className="p-3 w-100 d-flex">
                <div className="">
                  <div className="activity---time--container">
                    <div className="activity---time">
                      <i
                        className="fas fa-clock"
                        aria-hidden="true"
                        style={{ marginRight: 8, marginTop: 2, fontSize: 14 }}
                      ></i>
                      <span>{session?.startTime}</span>
                      {"-"}
                      <span>{session?.endTime}</span>
                    </div>
                    {session?.sessionFormat === "Hybrid" && (
                      <div className="activity---location">
                        <i
                          className="fas fa-map-marker-alt"
                          aria-hidden="true"
                          style={{ marginRight: 8, marginTop: 2, fontSize: 14 }}
                        ></i>
                        <span>{session?.sessionLocation}</span>
                      </div>
                    )}
                    {session?.sessionFormat === "In Person" && (
                      <div className="activity---location">
                        <i
                          className="fas fa-map-marker-alt"
                          aria-hidden="true"
                          style={{ marginRight: 8, marginTop: 2, fontSize: 14 }}
                        ></i>
                        <span>{session?.sessionLocation}</span>
                      </div>
                    )}
                    {session?.sessionFormat === "Virtual" && (
                      <div className="activity---location">
                        <i
                          className="fas fa-map-marker-alt"
                          aria-hidden="true"
                          style={{ marginRight: 8, marginTop: 2, fontSize: 14 }}
                        ></i>
                        <span>Virtual</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-75 d-flex justify-content-start">
                    <div>
                      <h5>{session?.sessionName}</h5>
                      <div className="d-flex">
                        {session?.speakers?.map((speaker, i) => {
                          // console.log("speaker...", speaker);
                          return (
                            <div
                              className="d-flex justify-content-center align-items-center h5"
                              key={i}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#1a87a3",
                                color: "white",
                                marginLeft: "-7px",
                                zIndex: i,
                                border: "2px solid white",
                              }}
                            >
                              {speaker?.profileImage ? (
                                <img
                                  src={speaker?.profileImage}
                                  alt="speaker-picture"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <>{speaker?.firstName}</>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="mx-4 d-flex ">
                    <div className="btn-group activity--btn-edit" role="group">
                      <ButtonDropdown
                        isOpen={dropdownOpenStates[i]}
                        toggle={() => toggleDropdown(i)}
                      >
                        <DropdownToggle caret color="primary">
                          More {/*  <i className="mdi mdi-chevron-down"></i> */}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleEditSession(session)}
                          >
                            Edit Session
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handlePreviewSession(session)}
                          >
                            Preview Session
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => handleAddSpeaker(session)}
                          >
                            Add Speaker
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleAttachDocument(session)}
                          >
                            Attach Document
                          </DropdownItem>
                          {/* <hr></hr> */}
                          <DropdownItem
                            onClick={() => tog_deleteSession(session)}
                          >
                            Delete Session
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </SimpleBar>
      {/* EditActivity */}
      {showdetatils ? (
        <SessionPreviewSlider
          isOpen={showdetatils}
          toggle={handlePreviewSession}
          title="Preview Session"
          body={<SessionPreviewBody session={detatils} />}
        />
      ) : null}
      {/* EditSession */}
      {showEditSession ? (
        <SlideInRightBar
          isOpen={showEditSession}
          toggle={handleEditSession}
          title="Edit Session"
          body={<EditSession sessionDeatails={detatils} eventID={eventID} />}
        />
      ) : null}
      {showAddSpeaker ? (
        <SlideInRightBar
          isOpen={showAddSpeaker}
          toggle={handleAddSpeaker}
          title="Add Speaker"
          body={
            <AddSpeakers
              eventID={eventID}
              token={token}
              setSlideInOpenSpeaker={setShowAddSpeaker}
              session={detatils}
            />
          }
        />
      ) : null}
      {showAttachDocument ? (
        <SlideInRightBar
          isOpen={showAttachDocument}
          toggle={handleAttachDocument}
          title="Attach Document"
          body={
            <AttachSessionDocument
              eventID={eventID}
              token={token}
              setSlideInOpenDocument={setShowAttachDocument}
              session={detatils}
            />
          }
        />
      ) : null}
      <Modal
        size="md"
        isOpen={deleteSessionModal}
        toggle={() => {
          tog_deleteSession();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Delete Session
          </h5>
          <button
            onClick={() => {
              setDeleteSessionModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5 className="text-danger">
            Are you sure you want to delete this Session ?
          </h5>
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <button
            onClick={() => {
              setDeleteSessionModal(false);
            }}
            type="button"
            className="btn btn-dark"
          >
            Cancel
          </button>
          <button
            onClick={() => DeleteSession(detatils?._id)}
            type="button"
            className="btn btn-danger"
          >
            {!mutationDelete?.isLoading ? (
              <span className="px-2">Sure</span>
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" />
                Loading
              </span>
            )}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default SessionList;

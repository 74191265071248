import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { MdClose } from "react-icons/md";

const FullModal = ({ isOpen, toggleEvent, body }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleEvent} fullscreen>
      <ModalBody>
        <div className="d-flex justify-content-end">
          <div
            className="d-flex justify-content-center align-items"
            style={{
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "#fcceba",
            }}
            onClick={() => toggleEvent()}
          >
            <MdClose size={30} />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center">
          {body}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FullModal;

import React from "react";
// import Picture from "../../../Profile/Picture";
import SponsorPic from "./SponsorPic";

function ViewSponsor({ details }) {
  return (
    <div className="w-100 mt-4 d-flex justify-content-center">
      <div
        style={{
          width: 400,
          borderRadius: 10,
          backgroundColor: "#fbfbfb",
          userSelect: "none",
        }}
      >
        <div className="w-100 d-flex">
          <div className="w-100 d-flex justify-content-center">
            <SponsorPic showImage={details?.logo} />
          </div>
        </div>
        <div className="w-100 mt-3 d-flex justify-content-center">
          <div
            style={{
              fontSize: 18,
              fontWeight: "700",
              color: "black",
            }}
          >
            {details?.sponsorName}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSponsor;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ViewTeam from "./ViewTeam";
import ModalDelete from "../../../Modal/ModalDelete";
import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import { FaEdit } from "react-icons/fa";
import { AiFillEye, AiFillDelete } from "react-icons/ai";

const TableOrganizer = ({ userID, token, eventID, search }) => {
  const [speakerID, setSpeakerID] = useState(false);
  const [speakerEmail, setSpeakerEmail] = useState(null);
  const [speakerDetails, setSpeakerDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["organizerTeam", eventID];

  const mutationDelete = useCustomMutation(key);

  const {
    data: getEventDetails,
    error: errorGetEventDetails,
    isLoading: isLoadingEventDetails,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}events/${eventID}`,
    token,
    {
      enabled: !!eventID,
    }
  );

  const tog_editTeam = (details) => {
    setSpeakerDetails(details);
    setIsToggleView(!isToggleView);
  };
  const tog_deleteTeam = (id, email) => {
    setSpeakerID(id);
    setSpeakerEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteTeam = () => {
    const Method = "DELETE",
      url = `attendees/${speakerID}/?eventId=${eventID}&email=${speakerEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Team successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">First name</span>,
      cell: (row) => {
        return <>{row?.firstName ? row?.firstName : "N/A"}</>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Last name</span>,
      cell: (row) => {
        return <>{row?.lastName ? row?.lastName : "N/A"}</>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Phone number</span>,
      cell: (row) => {
        return <>{row?.phoneNumber ? row?.phoneNumber : "N/A"}</>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      cell: (row) => {
        return (
          <>
            {row?.role === "event_organizer"
              ? "Admin"
              : row?.role === "securityGuard"
              ? "Security"
              : row?.role === "registrationOfficer"
              ? "Support Desk"
              : row?.role === "co_event_organizer"
              ? "Co organizer"
              : row?.role === "event_org_marketing"
              ? "Marketing"
              : row?.role === "event_org_sales"
              ? "Sales"
              : null}
          </>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_editTeam(row)}
              />
            </div>
            <div>
              <FaEdit
                size={18}
                className="mx-1"
                onClick={() => tog_editTeam(row)}
              />
            </div>

            <div>
              <AiFillDelete
                size={20}
                onClick={() => tog_deleteTeam(row?._id, row?.email)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterTeams = (teams, searchQuery) => {
    if (!searchQuery) return teams;
    return teams?.filter((item) =>
      [item?.firstName, item?.lastName, item?.email, item?.role].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredEventTeam = filterTeams(
    getEventDetails?.data?.personnel,
    search
  );

  const data = filteredEventTeam;
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Organizer Team"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            DeleteParticitant={DeleteTeam}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Organizer Team"
        body={<ViewTeam details={speakerDetails} />}
        toggleSaveChanges={() => tog_editTeam()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableOrganizer;

import React from "react";
// import {Button } from "reactstrap";

function ButtonSubmit({
  Title,
  BackgroundColor,
  ColorText,
  BorderColor,
  borderRadius,
  handleOnclick,
}) {
  return (
    <buton
      className="btn"
      style={{
        fontWeight: "600",
        color: ColorText,
        borderColor: BorderColor,
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: borderRadius,
        backgroundColor: BackgroundColor,
      }}
      onClick={handleOnclick}
    >
      {Title}
    </buton>
  );
}

export default ButtonSubmit;

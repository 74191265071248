import React, { useState, useRef } from "react";
import Lottie from "lottie-react";
import { useQueryClient } from "@tanstack/react-query";
// import useCustomMutation from "../../../../Hooks/useCustomMutation";
import aiBot from "../../../../assets/animations/aiBot.json";

import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../../../Toast/Toast";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const FormEmail = ({
  setIsSMSOpen,
  userId,
  eventID,
  eventDetails,
  token,
  isChatOpen,
  setIsChatOpen,
}) => {
  const aiRef = useRef();
  const [inputs, setInputs] = useState({});
  const [userRole, setUserRole] = useState("all");
  const [btnLoad, setBtnLoad] = useState(false);
  const [warning, setWarning] = useState(""); // New state variable for warning message
  const queryClient = useQueryClient();
  const key = ["allEmail", eventID];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    setWarning(""); // Clear warning when user starts typing
  };

  const allParticipantsFunc = () => {
    setUserRole("all");
  };
  const attendeesFunc = () => {
    setUserRole("attendees");
  };
  const speakersFunc = () => {
    setUserRole("speakers");
  };
  const teamMembersFunc = () => {
    setUserRole("security");
  };

  const handleSend = (e) => {
    e.preventDefault();

    // Validate inputs
    if (!inputs.title || !inputs.message) {
      setWarning(
        "Please fill in both the subject and message fields before submitting."
      );
      return; // Prevent submission if fields are empty
    }

    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const raw = JSON.stringify({
      subject: inputs?.title,
      body: inputs?.message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API}communication/email/${eventID}?audience=${userRole}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setIsSMSOpen(false);
        setBtnLoad(false);
        if (res?.status === "success") {
          queryClient.invalidateQueries(key);
          successMessage("Email successfully sent");
        } else {
          warningMessage("No email address found");
        }
      })
      .catch((error) => {
        setIsSMSOpen(false);
        setBtnLoad(false);
        dangerMessage("No email address found");
        console.error(error);
      });
  };

  return (
    <div>
      <form onSubmit={handleSend}>
        <div className="mb-4 d-flex align-items-center gap-2">
          <div
            className="d-flex"
            style={{
              width: 80,
              height: 80,
              borderRadius: "50%",
              borderWidth: 2,
              borderColor: "#F7F7F7",
              backgroundColor: "#FAF8F8",
            }}
          >
            <Lottie lottieRef={aiRef} animationData={aiBot} />
          </div>
          <div style={{ fontWeight: "bold" }}>
            <div>Need help with email please use AI ? </div>
            <div
              className="text-primary"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                setIsChatOpen(!isChatOpen);
              }}
            >
              Click here.
            </div>
          </div>
        </div>
        <label>
          Subject<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="title"
          value={inputs.title || ""}
          onChange={handleChange}
          style={{ borderRadius: 7 }}
          className="mb-3 form-control fromInput"
          required
        />
        <label className="event__input__form__Creation">
          Body<span className="text-danger">*</span>
        </label>
        <textarea
          name="message"
          value={inputs.message || ""}
          onChange={handleChange}
          style={{ borderRadius: 7 }}
          className="mt-1 form-control fromInput"
          rows={5}
          required
        ></textarea>
        {warning && <p className="text-danger mt-2">{warning}</p>}{" "}
        {/* Display warning if fields are empty */}
        <div className="mt-2 mb-3">
          <label>
            Select your audience<span className="text-danger">*</span>
          </label>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleAdmin"
              value="allParticipants"
              onChange={allParticipantsFunc}
              defaultChecked
            />
            <label className="form-check-label" htmlFor="exampleAdmin">
              All Participants
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleManager"
              value={"Attendees"}
              onChange={attendeesFunc}
            />
            <label className="form-check-label" htmlFor="exampleManager">
              Attendees
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleEvent"
              value={"Speakers"}
              onChange={speakersFunc}
            />
            <label className="form-check-label" htmlFor="exampleEvent">
              Speakers
            </label>
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleMember"
              value={"teamMembers"}
              onChange={teamMembersFunc}
            />
            <label className="form-check-label" htmlFor="exampleMember">
              Event Security
            </label>
          </div>
        </div>
        <div className="w-100 mt-2 d-flex justify-content-center">
          <ButtonSubmit
            Title="Send Email"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={handleSend}
            pending={btnLoad}
          />
        </div>
      </form>
    </div>
  );
};

export default FormEmail;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCustomMutation from "../../Hooks/useCustomMutation";
import ButtonSubmit from "../Buttons/ButtonSubmit";

function DeleteCommunity({ communityID, userEmail, setIsDelete, token }) {
  const Navigate = useNavigate();

  const key = ["MyCommunities", userEmail];
  const mutation = useCustomMutation(key);
  const deleteCoommunityFunc = () => {
    const Method = "DELETE",
      url = `communities/${communityID}`;
    const data = {
      endPoint: url,
      bodyContent: null,
      Method: Method,
      displayMessage: "Community successfully deleted",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      Navigate("/dashboard");
    }
  }, [mutation?.isSuccess]);

  return (
    <div>
      <h4 className="text-danger text-center">
        Are sure you want to delete this?
      </h4>
      <div className="d-flex justify-content-between">
        <ButtonSubmit
          Title="Cancel"
          ColorText="white"
          BorderColor="black"
          BackgroundColor="black"
          borderRadius="5px"
          handleOnclick={() => {
            setIsDelete(false);
          }}
          pending={false}
        />
        <ButtonSubmit
          Title="Sure"
          ColorText="white"
          BorderColor="#FF0000"
          BackgroundColor="#FF0000"
          borderRadius="5px"
          handleOnclick={() => deleteCoommunityFunc()}
          pending={mutation?.isLoading}
        />
      </div>
    </div>
  );
}

export default DeleteCommunity;

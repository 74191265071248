import React, { useState } from "react";
import EventStaticTable from "./EventStaticTable";
import TopEvent from "./TopEvents";
import ChartProgress from "../Common/chart/ChartProgress";

function Analystics() {
  const [registeredMember, setRegistered] = useState(400);
  const [activeMember, setActiveMember] = useState(300);
  const TotalMember = 500;
  // #0ac074
  return (
    <div className="w-100 mt-3">
      <div className="w-100 mt-4 d-flex justify-content-center">
        <div
          className="w-75 mb-2 rounded-3 d-flex"
          style={{
            // backgroundColor: "white",
            alignSelf: "center",
          }}
        >
          <div
            className="rounded-4 p-3 d-flex align-items-center"
            style={{
              backgroundColor: "white",
              // alignSelf: "center",
            }}
          >
            <div>
              <ChartProgress
                value={registeredMember}
                fgColor="#F68A20"
                lineCap="round"
                height={90}
                width={90}
                min={0}
                max={TotalMember}
                // onChange={(e) => {
                //   setActiveMember(e);
                // }}
                readOnly={true}
              />
            </div>
            <div className="mx-2">
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Registered users
              </div>
              <div
                className="d-flex justify-content-center"
                style={{
                  fontSize: 14,
                  fontWeight: "500",
                  color: "#F68A20",
                }}
              >
                400
              </div>
            </div>
          </div>
          <div
            className="rounded-4 mx-3 p-3 d-flex align-items-center"
            style={{
              backgroundColor: "white",
              // alignSelf: "center",
            }}
          >
            <div>
              <ChartProgress
                value={activeMember}
                fgColor="#0ac074"
                lineCap="round"
                height={90}
                width={90}
                min={0}
                max={TotalMember}
                // onChange={(e) => {
                //   setActiveMember(e);
                // }}
                readOnly={true}
              />
            </div>
            <div className="mx-2">
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Active users
              </div>
              <div
                className="d-flex justify-content-center"
                style={{
                  fontSize: 14,
                  fontWeight: "500",
                  color: "#0ac074",
                }}
              >
                300
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 mt-3 d-flex justify-content-center">
        <div
          className="w-75 p-2 rounded-3"
          style={{
            backgroundColor: "white",
            alignSelf: "center",
          }}
        >
          <div className="w-100">
            <EventStaticTable />
          </div>
        </div>
      </div>

      <div className="w-100 mb-3 mt-4 d-flex justify-content-center">
        <div
          className="w-75 p-2 rounded-3"
          style={{
            backgroundColor: "white",
            alignSelf: "center",
          }}
        >
          <TopEvent />
        </div>
      </div>
    </div>
  );
}

export default Analystics;

import io from "socket.io-client";

// export const socket = io.connect(`https://api.event.kago.digital/
// `);

export const socket = "";

// export const socket = io.connect(`http://127.0.0.1:4500`);

// {
// path: "/api/v1/",
// autoConnect: false,
// withCredentials: true,
// transports: ["websocket"],
// })

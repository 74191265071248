import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

import ConferenceImg from "../../../assets/images/banners/login/conference.png";
import ExhibitorImg from "../../../assets/images/banners/login/exhibition.png";
import WebinarImg from "../../../assets/images/banners/login/webinar.png";

const cardData = [
  {
    title: "Virtual Events",
    text: "Tap into a vast library of free, beautiful animations or create your own using Adobe After Effects.",
    color: "#FFF",
    colorText: "#000",
    image: ConferenceImg,
  },
  {
    title: "Hybrid Events",
    text: "Build gorgeous megamenus with columns, images, icons, and buttons all through an easy interface.",
    color: "#FFA64D",
    colorText: "#FFF",
    image: ExhibitorImg,
  },
  {
    title: "In-Person Events",
    text: "Advanced AJAX search functionality with multiple layout options that are capable of bringing up results in real-time.",
    color: "#FFCF5C",
    colorText: "#FFF",
    image: WebinarImg,
  },
];

const RequestCard = () => {
  return (
    <div className="req-card">
      <div className="req-card-title">
        One-Stop Shop Event Management Software To Power All Your Event Needs.
      </div>
      <div
        className="w-100 d-flex justify-content-center mt-2 mb-2"
        style={{ fontSize: 18 }}
      >
        From event registration to analytics & from a stunning virtual platform
        to onsite solutions, Kago Events has got you covered.
      </div>
      <Container style={{ maxWidth: "1200px", marginTop: "20px" }}>
        <Row>
          {cardData.map((card, index) => (
            <Col
              md="4"
              sm="6"
              xs="12"
              key={index}
              className="mb-4 d-flex justify-content-center"
            >
              <Card
                style={{
                  backgroundColor: card?.color,
                  maxWidth: "350px",
                  width: "100%",
                  borderRadius: "15px",
                }}
              >
                <CardBody className="text-center">
                  <CardTitle style={{ fontSize: 24, color: card.colorText }}>
                    {card.title}
                  </CardTitle>
                  <CardText
                    style={{
                      fontSize: 16,
                      color: card.colorText,
                      textAlign: "start",
                    }}
                  >
                    {card.text}
                  </CardText>
                  <img
                    src={card.image}
                    alt={card.title}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginBottom: "20px",
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default RequestCard;

import React from "react";

const TermsAndCondition = () => {
  return (
    <>
      <div className="w-100">
        <div className="w-100">{/* <h4>Data Usage Policy </h4> */}</div>
        <div className="w-100 text-justify">
          <b>1. Introduction </b>
          <p>
            By signing up for and using Kago Events, you agree to the
            collection, use, and sharing of your data as described in this Data
            Usage Policy. If you do not agree with this policy, please do not
            register or use the software.{" "}
          </p>
          <b>2. Data Collection </b>
          <p>
            <ul>
              <li>
                Personal Information: We collect personal information that you
                provide during the registration process, such as your name,
                email address, and contact details.
              </li>
              <li>
                Usage Data: We collect data on how you interact with the
                software, including features used, pages visited, and actions
                taken.
              </li>
              <li>
                Device Information: We collect information about the devices you
                use to access the software, including IP address, browser type,
                and operating system.
              </li>
            </ul>
          </p>
          <b>3. Use of Data </b>
          <p>
            <ul>
              <li>
                Service Provision: We use your data to provide, maintain, and
                improve the software and its features.
              </li>
              <li>
                Personalization: We use your data to personalize your
                experience, such as by providing tailored content and
                recommendations.
              </li>
              <li>
                Communication: We use your data to communicate with you,
                including sending updates, notifications, and support messages.
              </li>
              <li>
                Analytics: We use your data to analyze usage patterns and
                improve our services.
              </li>
            </ul>
          </p>
          <b>4. Data Sharing </b>
          <p>
            <ul>
              <li>
                Third-Party Service Providers: We may share your data with
                third-party service providers who assist us in operating the
                software and providing services to you.{" "}
              </li>
              <li>
                Legal Requirements: We may disclose your data if required by law
                or in response to valid requests by public authorities.{" "}
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition, or
                sale of assets, your data may be transferred to the new owner.{" "}
              </li>
            </ul>
          </p>
          <b>5. Data Security </b>
          <p>
            <ul>
              <li>
                We implement appropriate technical and organizational measures
                to protect your data from unauthorized access, disclosure,
                alteration, and destruction.{" "}
              </li>
              <li>
                However, no method of transmission over the internet or
                electronic storage is 100% secure, and we cannot guarantee
                absolute security.{" "}
              </li>
            </ul>
          </p>
          <b>6. Data Retention </b>
          <p>
            <ul>
              <li>
                We retain your data for as long as necessary to fulfill the
                purposes outlined in this policy, unless a longer retention
                period is required or permitted by law. Event data is retained
                for 21 days after the event.{" "}
              </li>
              <li>
                You may request the deletion of your data at any time by
                contacting us at 011 053 7975 or support@kago.digital{" "}
              </li>
            </ul>
          </p>
          <b>7. Your Rights </b>
          <p>
            <ul>
              <li>
                Access: You have the right to access the personal data we hold
                about you.{" "}
              </li>
              <li>
                Correction: You have the right to request the correction of
                inaccurate or incomplete data.{" "}
              </li>
              <li>
                Deletion: You have the right to request the deletion of your
                data, subject to certain conditions.{" "}
              </li>
              <li>
                Objection: You have the right to object to the processing of
                your data in certain circumstances.{" "}
              </li>
            </ul>
          </p>
          <b>8. Changes to This Policy </b>
          <p>
            <ul>
              <li>
                We may update this Data Usage Policy from time to time. We will
                notify you of any changes by posting the new policy on our
                website.
              </li>
              <li>
                Your continued use of the software after any changes indicates
                your acceptance of the new policy.
              </li>
            </ul>
          </p>
          <b>9. Contact Information </b>
          <p>
            If you have any questions or concerns about this Data Usage Policy,
            please contact us at 011 053 7975 or
            <span>support@kago.digital</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;

import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonSubmit from "../Buttons/ButtonSubmit";

const LoaderComingSoon = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div className="coming-soon">
        <div className="coming-soon-wrapper">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <h1 className="coming-soon-title">
              coming soon
              <span
                style={{
                  color: "#000",
                }}
              >
                .
              </span>
            </h1>
          </div>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <ButtonSubmit
              Title="Explore"
              BackgroundColor="#000"
              ColorText="#fff"
              BorderColor="#000"
              borderRadius={5}
              handleOnclick={() => {
                Navigate(`/home`);
              }}
              pending={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoaderComingSoon;

import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import BulkAttendee from "./bulk/BulkAttendee";
import SingleAttendee from "./single/AddAttendee";
const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});

const InvitesTab = ({ userID, token, eventID, setIsOffcanvasOpen }) => {
  const [selectedTab, setSelectedTab] = useState("1");
  const formatLabel = (label) =>
    `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <div>
      {/* <hr></hr> */}
      <div>
        Add attendees to the attendee list. For a seamless one-click
        registration/entry for your attendees, please fill all mandatory form
        fields.
      </div>
      <div>
        <ThemeProvider theme={theme}>
          <div className="w-100 d-flex justify-content-center">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="community navigation tabs"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab value="1" label={formatLabel("Bulk upload attendees")} />
              <Tab value="2" label={formatLabel("Add a single attendee")} />
            </Tabs>
          </div>
          {selectedTab === "1" && (
            <BulkAttendee
              userID={userID}
              token={token}
              eventID={eventID}
              setIsOffcanvasOpen={setIsOffcanvasOpen}
            />
          )}
          {selectedTab === "2" && (
            <SingleAttendee
              userID={userID}
              token={token}
              eventID={eventID}
              setIsOffcanvasOpen={setIsOffcanvasOpen}
            />
          )}
        </ThemeProvider>
      </div>
    </div>
  );
};

export default InvitesTab;

export const packageDescription = [
  {
    name: "RSVP",
    features: [
      " Custom-designed landing page",
      " Custom registration form",
      " Attendee Management Interface",
      " Pre-event / post-event email marketing tools",
      " Detailed Reporting",
      " Custom-branded Domain",
    ],
  },
  {
    name: "RSVP & Ticketing",
    features: [
      " Custom-designed landing page",
      " Custom registration form",
      " Attendee Management Interface",
      " Pre-event / post-event email marketing tools",
      " Detailed Reporting",
      " Custom-branded Domain",
      " Ticket selling",
      " Use your own Payfast account for ticketing",
    ],
  },
  {
    name: "RSVP, Event Check In , Badging Solution & Mobile App Access",
    features: [
      " Custom-designed landing page",
      " Custom registration form",
      " Attendee Management Interface",
      " Pre-event / post-event email marketing tools",
      " Detailed Reporting",
      " Custom-branded Domain",
      " Event check-in and check-out security app",
      " Event QR codes",
      " Event badge solution (doesn't include printer and printing material)",
      " Access to attendee mobile app",
    ],
  },
  {
    name: "RSVP, Ticketing, Event Check In, Badging Solution & Mobile App Access",
    features: [
      " Custom-designed landing page",
      " Custom registration form",
      " Attendee Management Interface",
      " Pre-event / post-event email marketing tools",
      " Detailed Reporting",
      " Custom-branded Domain",
      " Event check-in and check-out security app",
      " Event QR codes",
      " Event badge solution (doesn't include printer and printing material)",
      " Access to attendee mobile app",
      " Ticketing",
    ],
  },
  {
    name: "Exhibition",
    features: [
      " Custom-designed landing page",
      " Custom registration form",
      " Attendee Management Interface",
      " Pre-event / post-event email marketing tools",
      " Detailed Reporting",
      " Custom-branded Domain",
      " Event check-in and check-out security app",
      " Event QR codes",
      " Event badge solution (doesn't include printer and printing material)",
      " Access to attendee mobile app",
      " Ticketing",
      " Exhibitor access",
      " Lead generation list",
      " Access to exhibitor virtual portal",
      " Exhibitor can go live in their booth",
    ],
  },
  {
    name: "Virtual Event",
    features: [
      " Custom-designed landing page",
      " Custom registration form",
      " Attendee Management Interface",
      " Pre-event / post-event email marketing tools",
      " Detailed Reporting",
      " Custom-branded Domain",
      " Access to attendee mobile app",
      " Ticketing",
      " Exhibitor access",
      " Lead generation list",
      " Access to exhibitor virtual portal",
      " Exhibitor can go live in their booth",
      " Live session streaming",
      " In-session engagement",
      " Stream to YouTube from Kago",
    ],
  },
];

import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box, Collapse } from "@mui/material";
import ButtonGradient from "../../../../Buttons/ButtonGradient";
import "./sessionEventStyle.scss";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});

const formatLabel = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "short",
  }).format(date);
};

const Sessions = ({ sessions = [] }) => {
  const [selectedTab, setSelectedTab] = useState("0");
  const [expandedDays, setExpandedDays] = useState({});

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const toggleViewMore = (day) => {
    setExpandedDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const getSessionsByDay = () => {
    if (!sessions || !Array.isArray(sessions)) {
      return {};
    }

    const sessionsByDay = sessions.reduce((acc, session) => {
      const day = session?.sessionDate;
      if (!acc[day]) acc[day] = [];
      acc[day].push(session);
      return acc;
    }, {});

    Object.keys(sessionsByDay).forEach((day) => {
      sessionsByDay[day]?.sort((a, b) =>
        a?.startTime?.localeCompare(b?.startTime)
      );
    });

    return sessionsByDay;
  };

  const sessionsByDay = getSessionsByDay();
  const days = Object.keys(sessionsByDay)?.sort();

  return (
    <ThemeProvider theme={theme}>
      <div className="w-100 d-flex justify-content-center mt-2 ">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="session navigation tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {days.map((day, index) => (
            <Tab
              key={day}
              value={String(index)}
              label={formatLabel(day)} // Format the day here as "1 Feb", "3 March", etc.
            />
          ))}
        </Tabs>
      </div>
      <div className="eventSession-container py-4">
        {days?.map((day, index) => (
          <div key={day} hidden={selectedTab !== String(index)}>
            {sessionsByDay[day]?.slice(0, 3)?.map((session) => (
              <div className="eventSession-card" key={session._id}>
                <div
                  className="mx-2"
                  style={{ width: 5, backgroundColor: "#f68a20" }}
                ></div>
                <div>
                  <div className="eventSession-topic">
                    {session.sessionName}
                  </div>
                  <div className="eventSession-topic mb-2">
                    {session.sessionDate} | {session.startTime} -
                    {session.endTime}
                  </div>
                  <div className="eventSession-speaker">
                    {session?.speakers?.map((speaker, i) => {
                      return (
                        <div className="eventSession-speaker-card" key={i}>
                          <img src={speaker?.photo} alt="speaker-image" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="eventSession-location mt-2">
                    {session.sessionLocation ? session.sessionLocation : null}
                  </div>
                </div>
              </div>
            ))}
            {sessionsByDay[day]?.length > 3 && (
              <div>
                <Collapse in={expandedDays[day]}>
                  {sessionsByDay[day]?.slice(3)?.map((session) => (
                    <div
                      className="mb-2 d-flex align-items-center"
                      style={{
                        padding: 9,
                        width: 650,
                        height: 80,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                      }}
                      key={session._id}
                    >
                      <div
                        className="mx-2 h-100"
                        style={{ width: 5, backgroundColor: "#f68a20" }}
                      ></div>
                      <div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "bolder",
                            color: "black",
                          }}
                        >
                          {session.sessionName} : {session.sessionDate} |{" "}
                          {session.startTime} -{session.endTime}
                        </div>
                        <div>{session.sessionLocation}</div>
                      </div>
                    </div>
                  ))}
                </Collapse>
                <div className="w-100 mt-3 d-flex justify-content-center">
                  <ButtonGradient
                    Title={expandedDays[day] ? "View Less" : "View More"}
                    leftColor="#f7983c"
                    rightColor="#ff7c6e"
                    ColorText="white"
                    BorderColor="transparent"
                    borderRadius={50}
                    handleOnclick={() => toggleViewMore(day)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </ThemeProvider>
  );
};

export default Sessions;

import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import EventCard from "./EventCard";
import EventTabs from "./EventTabs";
import LoaderExploreEvent from "../../Loader/LoaderExploreEvent";

const EventListing = ({ search }) => {
  const [selectedTab, setSelectedTab] = useState("ALL");
  const [page, setPage] = useState(1);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [loading, setLoading] = useState(false);
  const tabContainerRef = useRef(null);

  const tabs = ["ALL", "TODAY", "TOMORROW", "THIS WEEK", "NEXT WEEK"];

  const { data: getPublicEvents, isLoading: isLoadingGetPublicEvents } =
    useCustomQuery(
      ["ExplorePublicEvents", selectedTab, page],
      `${process.env.REACT_APP_API}events/explore?page=${page}&limit=8`,
      null
    );

  useEffect(() => {
    if (getPublicEvents?.data) {
      setLoading(true);
      const events = getPublicEvents?.data;
      const filtered = filterEventsByTab(events);
      setFilteredEvents(filtered);
      setTotalEvents(filtered?.length);
      setLoading(false);
    }
  }, [getPublicEvents, selectedTab, page, search]);

  const filterEventsByTab = (events) => {
    const today = new Date().toISOString().split("T")[0];
    const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0];

    const filteredByTab = events.filter((event) => {
      const eventDate = event.startDate.split("T")[0];

      switch (selectedTab) {
        case "TODAY":
          return eventDate === today;
        case "TOMORROW":
          return eventDate === tomorrow;
        case "THIS WEEK":
          return isInThisWeek(eventDate);
        case "NEXT WEEK":
          return isInNextWeek(eventDate);
        default:
          return true;
      }
    });

    return filteredByTab?.filter((event) => {
      return (
        event?.eventName?.toLowerCase().includes(search?.toLowerCase()) ||
        event?.location?.toLowerCase().includes(search?.toLowerCase())
      );
    });
  };

  const isInThisWeek = (date) => {
    const now = new Date();
    const startOfWeek = now?.setDate(now?.getDate() - now?.getDay());
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek?.getDate() + 7);
    const eventDate = new Date(date);
    return eventDate >= startOfWeek && eventDate <= endOfWeek;
  };

  const isInNextWeek = (date) => {
    const now = new Date();
    const startOfNextWeek = now?.setDate(now?.getDate() - now?.getDay() + 7);
    const endOfNextWeek = new Date(startOfNextWeek);
    endOfNextWeek.setDate(endOfNextWeek?.getDate() + 7);
    const eventDate = new Date(date);
    return eventDate >= startOfNextWeek && eventDate <= endOfNextWeek;
  };

  const handleTabChange = (tab) => {
    setLoading(true);
    setSelectedTab(tab);
    setPage(1);
  };

  return (
    <Box sx={{ p: 3 }}>
      <EventTabs
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabContainerRef={tabContainerRef}
      />

      {loading || isLoadingGetPublicEvents ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <LoaderExploreEvent />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
          >
            {filteredEvents?.length === 0 && (
              <Typography>No events found for {selectedTab}.</Typography>
            )}
            {filteredEvents?.map((event) => (
              <EventCard key={event?.id} event={event} />
            ))}
          </Box>

          {totalEvents > 7 && (
            <div className="w-100 mt-4 d-flex justify-content-center align-items-center">
              <ButtonSubmit
                Title="Load More"
                BackgroundColor="transparent"
                ColorText="#f68a20"
                BorderColor="#f68a20"
                borderRadius={10}
                handleOnclick={() => {
                  setLoading(true);
                  setPage(page + 1);
                }}
                pending={isLoadingGetPublicEvents}
              />
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default EventListing;

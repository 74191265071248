import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Spinner, Row, Col } from "reactstrap";

import { countryList } from "../../../../components/Countries/countries";

function Conference({ eventType, eventFormat, userId, token, getCommunities }) {
  const navigate = useNavigate();
  const [eventStatus, setEventStatus] = useState("Public");
  const [inputs, setInputs] = useState({});

  // connnect to api
  const key = ["myEvents", userId];
  const mutation = useCustomMutation(key);

  const EventStatePublic = (e) => {
    setEventStatus("Public");
  };
  const EventStateFunc2 = (e) => {
    setEventStatus("Private");
  };

  // // handleValidSubmit

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const communityID = inputs.Community
      ? inputs.Community
      : getCommunities[0]._id;

    const Method = "POST",
      url = `events/create/?cid=${communityID}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      formdata.append(key, value);
    });
    formdata.append("eventStatus", "Upcoming");
    formdata.append("eventFormat", eventFormat);
    formdata.append("eventType", eventType);
    formdata.append("visibility", eventStatus);
    formdata.append("state", "Not Published");
    // formdata.append("eventName", inputs.EventName);
    // formdata.append("eventDescription", inputs.eventDescription);
    // formdata.append("startDate", inputs.startDate);
    // formdata.append("endDate", inputs.endDate);
    // formdata.append("startTime", inputs.startTime);
    // formdata.append("endTime", inputs.endTime);
    // formdata.append("venue", inputs.venue);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  // console.log("mutation.isSuccess....", mutation.isSuccess);

  if (mutation.isSuccess) {
    navigate("/dashboard");
    // setSlideInOpenConference(false);
  }

  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          {/* <Col>
            <div
              className="h1 d-flex justify-content-center"
              style={{ width: 650 }}
            >
              Create Event
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col md={12}>
            <label className="">Event Name</label>
            <input
              type="text"
              name="eventName"
              className="mb-3 form-control"
              value={inputs.eventName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="">Event Type </label>
            <input
              type="text"
              name="eventType"
              placeholder="Conference"
              className="mb-3 form-control"
              style={{ userSelect: "none" }}
              value={eventType}
              // onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={6}>
            <label className="event__form__Creation">Community </label>
            <select
              name="Community"
              value={inputs.timeZone__event}
              onChange={handleChange}
              className="mb-3 form-control fromInput"
              required
            >
              {getCommunities?.map((community, index) => (
                <option key={index} value={community?._id}>
                  {community?.communityName}
                </option>
              ))}
            </select>
          </Col>
          <Col md={12}>
            <label className="event__input__form__Creation">
              Event Description
              <textarea
                name="eventDescription"
                value={inputs.eventDescription}
                onChange={handleChange}
                placeholder="Write a short summary about the event..."
                className="mb-3 form-control fromInput"
                rows={5}
                required
              ></textarea>
            </label>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <label className="event__form__Creation">Start Date</label>
            <input
              type="date"
              name="startDate"
              placeholder="dd-mm-yyyy"
              value={inputs.startDate}
              onChange={handleChange}
              className="form-control"
              required
            />
          </Col>
          <Col sm={12} md={4} lg={3}>
            <label className="event__form__Creation">End Date </label>
            <input
              type="date"
              name="endDate"
              value={inputs.endDate}
              onChange={handleChange}
              className="mb-3 form-control"
              required
            />
          </Col>

          <Col sm={12} md={4} lg={3}>
            <label>Start Time</label>
            <input
              type="time"
              name="startTime"
              value={inputs.startTime}
              onChange={handleChange}
              className="mb-3 form-control"
              required
            />
          </Col>
          <Col sm={12} md={4} lg={3}>
            <label>End Time</label>
            <input
              type="time"
              name="endTime"
              value={inputs.endTime}
              onChange={handleChange}
              className="mb-3 form-control"
              required
            />
          </Col>
        </Row>

        {eventFormat === "In Person" ? (
          <div>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Venue Name</label>
                <input
                  type="text"
                  name="venue"
                  placeholder="venue"
                  value={inputs.venue}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">
                  Physical Address
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Address"
                  value={inputs.location}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">City</label>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={inputs.city}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Province</label>
                <input
                  type="text"
                  name="province"
                  value={inputs.province}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Zip Code</label>
                <input
                  type="text"
                  name="zipCode"
                  value={inputs.zipCode}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Country </label>
                <select
                  name="country"
                  value={inputs.country}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                >
                  <option value={null}> Select.... </option>
                  {countryList.map((country, i) => (
                    <option key={i}> {country} </option>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
        ) : null}
        {eventFormat === "Hybrid" ? (
          <div>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Venue Name</label>
                <input
                  type="text"
                  name="venue"
                  placeholder="venue"
                  value={inputs.venue}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">
                  Physical Address
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Address"
                  value={inputs.location}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">City</label>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={inputs.city}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Province</label>
                <input
                  type="text"
                  name="province"
                  value={inputs.province}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Zip Code</label>
                <input
                  type="text"
                  name="zipCode"
                  value={inputs.zipCode}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">Country </label>
                <select
                  name="country"
                  value={inputs.country}
                  onChange={handleChange}
                  className="mb-3 form-control"
                  required
                >
                  <option value={null}> Select.... </option>
                  {countryList.map((country, i) => (
                    <option key={i}> {country} </option>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
        ) : null}

        <Row>
          <Col md={12} lg={12}>
            <div className="mt-2">
              <label>Set Event</label>
              <div className="d-flex">
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Public"
                    onChange={(e) => EventStatePublic(e.target.value)}
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Public
                  </label>
                </div>
                <div className="px-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value={"Private"}
                      onChange={(e) => EventStateFunc2(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Private
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center align-item-center">
          <div className="d-flex justify-content-center align-item-center">
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Submit</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Conference;

import React, { useRef } from "react";
import Lottie from "lottie-react";
import loader from "../../assets/animations/loading.json";

function Loading({ title, size }) {
  const loaderRef = useRef();
  return (
    <div className="w-100 d-flex justify-content-center">
      {/* backgroundColor: "white" */}

      <div className="rounded-4 w-100">
        <div
          className="w-100 d-flex justify-content-center"
          style={{ width: size, height: size }}
        >
          <Lottie lottieRef={loaderRef} animationData={loader} />
        </div>
        <div
          className="w-100 d-flex justify-content-center"
          style={{
            userSelect: "none",
            color: "black",
            fontSize: 16,
            fontWeight: "600",
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
}

export default Loading;

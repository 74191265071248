import React from "react";
import communityImg from "../../../assets/images/community/Community.png";

function CommunityInfo({ getCommunityDetails }) {
  return (
    <div className="w-100 p-3 d-flex justify-content-center align-items-center">
      <div className="p-2 rounded-3 bg-white" style={{ width: 800 }}>
        <div className="w-100" style={{ userSelect: "none" }}>
          <img
            alt="community"
            src={
              getCommunityDetails?.length > 0
                ? getCommunityDetails[0]?.banner
                : communityImg
            }
            className="w-100 h-100 rounded-3"
          />
        </div>
        <div className="w-100">
          <div className="w-100 mt-2 d-flex justify-content-between align-items-center">
            <div
              className="w-100 d-flex justify-content-center"
              style={{
                fontSize: 20,
                fontWeight: "600",
                color: "black",
                textAlign: "justify",
              }}
            >
              {getCommunityDetails?.length > 0
                ? getCommunityDetails[0]?.communityName
                : null}
            </div>
          </div>
          <div
            className="mt-1"
            style={{
              fontSize: 12,
              fontWeight: "400",
              textAlign: "justify",
            }}
          >
            {getCommunityDetails?.length > 0
              ? getCommunityDetails[0]?.communityDescription
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityInfo;

import React, { useState, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Spinner, Button } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomQuery from "../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import "./css/Summary.scss";
import "./css/AddSpeaker.scss";

const AddSpeaker = ({ token, eventID, setSlideInOpenSpeaker }) => {
  const queryClient = useQueryClient();
  const key = ["mySpeakers", eventID];
  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();

  // console.log("getMyEventDetail...", getMyEventDetail);

  // Function to handle link clicks

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `speakers/${eventID}/${inputs.sessionID}`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("profileImage", logoServer);
    formdata.append("firstName", inputs.firstName);
    formdata.append("lastName", inputs.lastName);
    formdata.append("email", inputs.email);
    formdata.append("jobTitle", inputs.jobTitle);
    formdata.append("organization", inputs.organization);
    formdata.append("city", inputs.city);
    formdata.append("country", inputs.country);
    formdata.append("speakerBiography", inputs.biography);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Speaker Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  if (mutation.isSuccess) {
    setSlideInOpenSpeaker(false);
    queryClient.invalidateQueries(["mySessions", eventID]);
  }

  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={!logoServer ? ImgSession : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-16px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 400x200px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>
            {/* ------------------ Form Fields ---------------------- */}

            <div className="single-attendee__body--form-input-container gzIdbH">
              <select
                id="sessionID"
                name="sessionID"
                onChange={handleChange}
                required
                className="single-attendee__body--form-input-container-field"
                onFocus={(e) => e.target.classList.add("active")}
                onBlur={(e) =>
                  e.target.value === "" && e.target.classList.remove("active")
                }
              >
                <option value={null}>Select Session...</option>
                {getMySessions?.data?.map((session, i) => {
                  return (
                    <option value={session?._id} key={i}>
                      {session?.sessionName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="firstName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">First Name*</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="First Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="lastName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Last Name</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Last Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="email"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Email id*</p>
                <p className="input-description">
                  Where speaker will receive further event communication
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="email"
                  name="email"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter email id of new speaker"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="jobTitle"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Job Title</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="jobTitle"
                  name="jobTitle"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter speaker's Job Title"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="organization"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Organization</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="organization"
                  name="organization"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter speaker's organization"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="city"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">City</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="city"
                  name="city"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter speaker's city"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="country"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Country</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="country"
                  name="country"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter speaker's country"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="biography"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Speaker Biography</p>
                <p className="input-description">
                  Bio of the speaker and their achievements.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="biography"
                  name="biography"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder=""
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                  style={{ minHeight: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="next-container" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Submit</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddSpeaker;

// utils.js
export const eventCountDown = (startDateStr, endDateStr) => {
  const now = new Date();
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  let result = {
    status: null,
    count: null,
  };

  if (now >= startDate && now <= endDate) {
    const countStatus = "Ongoing";
    return { status: countStatus, data: null };
  } else if (now >= endDate) {
    const countStatus = "Completed";
    return { status: countStatus, data: null };
  } else if (now < startDate) {
    const totalSeconds = (startDate - now) / 1000;
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    const countStatus = "UpComing";
    const count = { days, hours, minutes, seconds };
    return { status: countStatus, data: count };
  }
  return result;
};

import React from "react";

import { UserProvider } from "./helpers/AuthType/authContext";
import { UserTicketProvider } from "./helpers/UserTicketContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// context
import { ThemeProvider } from "./helpers/ThemeContext";
import "./i18n";
import "./index.css";

// import toastify for notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { configureStore } from "./store/store";
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <Provider store={configureStore({})}>
      <ThemeProvider>
        <UserTicketProvider>
          <QueryClientProvider client={queryClient}>
            <React.Fragment>
              <BrowserRouter>
                <App />
                <ToastContainer />
              </BrowserRouter>
            </React.Fragment>
          </QueryClientProvider>
        </UserTicketProvider>
      </ThemeProvider>
    </Provider>
  </UserProvider>
);
reportWebVitals();
// serviceWorker.unregister();

import React, { useState } from "react";
import Picture from "../Profile/Picture";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
function Contact({ details }) {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [isEditCard, setIsEditCard] = useState(false);
  const toggleSaveChanges = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  const toggleEditChanges = () => {
    setIsEditCard(!isEditCard);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <div
        style={{
          width: 400,
          // height: 370,
          borderRadius: 10,
          backgroundColor: "#fbfbfb",
          userSelect: "none",
        }}
      >
        <div className="w-100 d-flex">
          <div>
            <Picture
              showImage={details?.photo}
              iconSize={50}
              iconColor="#F68A20"
              imgHeight={90}
              imgWidth={90}
              imgBorder="50%"
            />
          </div>
          <div
            style={{
              height: 100,
              // backgroundColor: "red",
            }}
          >
            <div
              className="w-100 mt-2 mx-3 d-flex align-items-center"
              style={{
                fontSize: 16,
                fontWeight: "700",
                color: "black",
              }}
            >
              {details?.firstName} {details?.lastName}
            </div>
            <div
              className="w-100 mx-3 d-flex align-items-center"
              style={{
                fontSize: 13,
                fontWeight: "700",
                color: "#909090",
              }}
            >
              {details?.jobTitle}
            </div>
            <div
              className="w-100 mx-3 d-flex align-items-center"
              style={{
                fontSize: 13,
                fontWeight: "700",
                color: "#F68A20",
              }}
            >
              {details?.organization}
            </div>
          </div>
        </div>
        <div className="w-100 mt-2">
          <div className="w-100 mt-2 mb-2 d-flex ">
            <div
              style={{
                height: 0.1,
                width: 380,
                background: "#F68A20",
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "700",
                color: "black",
              }}
            >
              Contacts
            </div>
            <div className="w-100 mt-2 d-flex  align-items-center">
              <MdEmail color="black" size={16} />
              <div className="mx-1"> {details?.email}</div>
            </div>
            <div className="w-100 mt-2 d-flex align-items-center">
              <FaPhoneAlt color="black" size={16} />
              <div className="mx-1"> {details?.phoneNumber}</div>
            </div>
            <div className="w-100 mt-2 d-flex align-items-center">
              <FaMapMarkerAlt color="black" size={16} />
              <div className="mx-1"> {details?.organizationAddress}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

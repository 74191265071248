import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
// import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ThemeContext } from "../../helpers/ThemeContext";

// import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import EventActions from "../../components/Common/TopbarDropdown/EventActions";
// import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import WhiteLogo from "../../assets/images/kago-color-white.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const SetEventHeader = (props) => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const eventID = props?.eventID;
  const userEmail = props?.userEmail;
  const {
    data: getEventDetails,
    error: errorGetEventDetails,
    isLoading: isLoadingGetEventDetails,
  } = useCustomFecthQuery(
    ["EventDetails", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <header
        id="page-topbar"
        style={{ backgroundColor: "white", color: "black", userSelect: "none" }}
      >
        <div className="navbar-header-community">
          <div
            className="d-flex"
            style={{ width: "20.18%", height: 70, backgroundColor: "#000" }}
          >
            <div>
              <Link to="/dashboard">
                <img
                  src={WhiteLogo}
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 35,
                    width: 130,
                    height: 135,
                    zIndex: 999,
                  }}
                />
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <EventActions
              eventID={eventID}
              userEmail={userEmail}
              token={token}
              getEventDetails={getEventDetails}
            />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

SetEventHeader.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(SetEventHeader));

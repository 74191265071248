import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer-custom">
        <div className="w-100">
          <div style={{ width: "100%" }}>
            <Container fluid={true}>
              <Row>
                <Col md={6}>
                  <div className="footer-right-content">
                    <Link
                      to="https://www.kago.digital/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "white",
                      }}
                    >
                      {new Date().getFullYear()} ©Kago Events
                    </Link>
                    <div className="mx-1">|</div>
                    <Link
                      to="https://www.tngsolutions.co.za/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "white",
                      }}
                    >
                      by TNG Solutions
                    </Link>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="footer-left-content">
                    <Link
                      to="https://www.kago.digital/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "white",
                      }}
                    >
                      Privacy
                    </Link>
                    <div className="mx-1">|</div>
                    <Link
                      to="https://www.kago.digital/terms-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "white",
                      }}
                    >
                      Cookies
                    </Link>
                    <div className="mx-1">|</div>
                    <Link
                      to="https://www.kago.digital/terms-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "white",
                      }}
                    >
                      Terms & Conditions
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
// import useCustomMutation from "../../../../../hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
//icons

import { useNavigate } from "react-router-dom";

import { AiOutlineSearch } from "react-icons/ai";

import SessionBody from "../../../../../components/Modal/SessionBody";
import "./sessions.styles.scss";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import CustomModal from "../../../../../components/Common/SlideBigModalDark/SlideBigBarDark";

const Sessions = ({ userId, token, eventID, eventDetail }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.data?.email;

  const [organizedSessions, setOrganizedSessions] = useState({});
  const [activeSession, setActiveSession] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [search, setSearch] = useState("");

  // view session details
  const [slideInOpenPreview, setSlideInOpenPreview] = useState(false);

  const toggleSlideInPreview = () => {
    setSlideInOpenPreview(!slideInOpenPreview);
  };

  const {
    data: getMyTicketAsAttendee,
    error: errorGetMyTicketAsAttendee,
    isLoading: isLoadingGetMyTicketAsAttendee,
  } = useCustomQuery(
    ["MyTicketAsAttendee", eventID],
    `${process.env.REACT_APP_API}tickets/${eventID}/?email=${userEmail}`,
    token
  );

  const ticketType =
    getMyTicketAsAttendee && getMyTicketAsAttendee.length > 0
      ? getMyTicketAsAttendee[0]?.ticketType.toLowerCase()
      : null;

  //get sessions by access Type
  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/user/${eventID}/?access=${ticketType}`,
    token
  );

  useEffect(() => {
    const sessionsByMonth = {};
    getMySessions?.forEach((session) => {
      const date = new Date(session.sessionDate);
      const monthYear = date.toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
      const day = date.getDate();

      if (!sessionsByMonth[monthYear]) {
        sessionsByMonth[monthYear] = {};
      }
      if (!sessionsByMonth[monthYear][day]) {
        sessionsByMonth[monthYear][day] = [];
      }
      sessionsByMonth[monthYear][day].push(session);
    });
    setOrganizedSessions(sessionsByMonth);

    if (getMySessions?.length > 0) {
      setActiveSession(getMySessions[0]._id);
    }

    if (getMySessions && getMySessions.length > 0) {
      const firstSessionDate = new Date(getMySessions[0].sessionDate);
      const defaultDay = `${firstSessionDate.getDate()}-${firstSessionDate.toLocaleString(
        "default",
        { month: "short", year: "numeric" }
      )}`;
      setSelectedDay(defaultDay);
    }
  }, [getMySessions]);

  const handleDayClick = (day, monthYear) => {
    setSelectedDay(`${day}-${monthYear}`);
  };

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  // console.log("getSession...", getMySessions);

  const handleJoinSession = (sessionName, sessionID) => {
    Navigate(
      `/live-session/attendee/${eventDetail?.eventName.replace(
        / /g,
        "-"
      )}/${sessionName?.replace(/ /g, "-")}/${eventID}/${sessionID}`
    );
  };

  return (
    <div className="sessions-content">
      <div className="sessions-calender">
        <div className="sessions-calender__header">
          <h2>Schedule</h2>
        </div>
        <div className="sessions-calender__body">
          {Object.entries(organizedSessions).map(([monthYear, days]) => {
            return (
              <div className="sessions-calender__body__dates" key={monthYear}>
                <span className="month">{monthYear}</span>
                {Object.entries(days).map(([day, sessions]) => {
                  const dayMonth = `${day}-${monthYear}`;
                  return (
                    <div
                      className="date"
                      key={day}
                      onClick={() => handleDayClick(day, monthYear)}
                      style={{
                        backgroundColor:
                          selectedDay === dayMonth ? "#2dd173" : "transparent",

                        userSelect: "none",
                      }}
                    >
                      <span style={{ fontWeight: 700 }}>{day}</span>
                      <span>{monthYear?.substring(0, 3)}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div className="sessions-calender__body__events ">
            <div
              style={{
                width: "100%",
                background: "transparent",
                paddingTop: ".58rem",
                paddingBottom: ".5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginRight: 32,
                marginBottom: 10,
                border: "solid",
                borderWidth: 0.1,
                borderRadius: 8,
                userSelect: "none",
              }}
            >
              <AiOutlineSearch size={24} />
              <input
                type="text"
                placeholder="Search session name..."
                value={search}
                // onChange={handleSearch}
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
                style={{
                  border: "none",
                  marginLeft: 8,
                  paddingRight: 24,
                  backgroundColor: "transparent",
                  color: "#fff",
                }}
              />
            </div>

            {/*  Sessions list container */}

            <div className="event-details__upcoming__sessions">
              {getMySessions?.data
                ?.filter((session) => {
                  const sessionDate = new Date(session.sessionDate);
                  const sessionDayMonth = `${sessionDate.getDate()}-${sessionDate.toLocaleString(
                    "default",
                    { month: "short", year: "numeric" }
                  )}`;
                  return (
                    selectedDay === sessionDayMonth &&
                    (search === "" ||
                      session.sessionName.toLowerCase().includes(search))
                  );
                })
                .map((session, i) => {
                  // console.log("session", session);
                  return (
                    <div
                      key={i}
                      className="event-details__upcoming__sessions--session"
                      style={{
                        border: "1px solid #828283",
                        borderRadius: "12px",
                        width: "100%",
                        position: "relative",
                        color: "#c2c2c3",
                        padding: "16px 18px",
                        background: "rgba(255, 255, 255, 0.1)",
                        marginBottom: "16px",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          fontWeight: 600,
                          fontSize: "13px",
                        }}
                      >
                        {session?.startTime} - {session?.endTime}
                      </p>
                      <h4
                        style={{
                          color: "white",
                          fontWeight: 600,
                          margin: "-4px 0 10px",
                        }}
                      >
                        {session?.sessionName}
                      </h4>
                      <div className="d-flex">
                        {session?.speakers?.map((speaker, i) => {
                          // console.log("speaker..", speaker);
                          return (
                            <div
                              className="d-flex justify-content-center align-items-center h5"
                              key={i}
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#1a87a3",
                                color: "white",
                                marginLeft: "-7px",
                                zIndex: i,
                                border: "2px solid white",
                              }}
                            >
                              {speaker?.profileImage ? (
                                <img
                                  src={speaker?.profileImage}
                                  alt="speaker-picture"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <>{speaker?.firstName}</>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: "#2d2c32",
                            border: "0.8px solid #5c5c5c",
                            borderRadius: "8px",
                            padding: "4px 8px",
                            fontSize: "12px",
                          }}
                        >
                          Session
                        </span>
                        <span> &#8226;</span>
                        <span style={{ fontSize: "13px" }}>
                          {session?.sessionName}
                        </span>
                      </div>
                      <div className="session-details">
                        <button
                          style={{
                            position: "absolute",
                            zIndex: "999",
                            top: "8%",
                            right: "18%",
                            backgroundColor: "#2dd173",
                            color: "white",
                            fontWeight: "600",
                            letterSpacing: "0.8px",
                            padding: "8px 16px",
                            border: "none",
                            borderRadius: "7px",
                          }}
                          onClick={() =>
                            handleJoinSession(
                              session?.sessionName,
                              session?._id
                            )
                          }
                        >
                          Join now
                        </button>

                        <div>
                          <button
                            onClick={toggleSlideInPreview}
                            style={{
                              position: "absolute",
                              zIndex: "999",
                              top: "8%",
                              right: "3%",
                              backgroundColor: "#2dd173",
                              color: "white",
                              fontWeight: "600",
                              letterSpacing: "0.8px",
                              padding: "8px 16px",
                              border: "none",
                              borderRadius: "7px",
                            }}
                          >
                            Preview
                          </button>
                          <CustomModal
                            isOpen={slideInOpenPreview}
                            toggle={toggleSlideInPreview}
                            title="Session Preview"
                            body={<SessionBody session={session} />}
                          />
                        </div>

                        <div></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sessions;

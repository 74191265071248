import React, { useState, useEffect, useRef } from "react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
// MARQUEE
// import { Marquee } from "@devnomic/marquee";
import "@devnomic/marquee/dist/index.css";
import SessionReception from "./SessionReception";
import EventReceptionBanner from "../../../assets/images/community/Community.png";

const Reception = ({ userName, token, eventID, eventDetail }) => {
  const Navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(0);
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  const getVideoSrc = (videoLink) => {
    if (!videoLink) return null; // Return null if no video link is provided

    // Check for YouTube links
    if (videoLink.includes("youtube.com") || videoLink.includes("youtu.be")) {
      let videoId;
      if (videoLink.includes("youtu.be")) {
        videoId = videoLink.split("/").pop();
      } else {
        const params = new URLSearchParams(new URL(videoLink).search);
        videoId = params.get("v");
      }
      if (!videoId) {
        console.error("No video ID found");
        return null;
      }
      return `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1`;
    }

    // Check for Vimeo links
    if (videoLink.includes("vimeo.com")) {
      let videoId = videoLink.split("/").pop().split("?")[0]; // Remove any query parameters
      if (isNaN(parseInt(videoId, 10))) {
        console.error("Invalid video ID for Vimeo");
        return null;
      }
      return `https://player.vimeo.com/video/${videoId}?autoplay=1&controls=1`;
    }

    // If not a YouTube or Vimeo link, return the videoLink itself
    return videoLink;
  };
  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomFecthQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomFecthQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const handleJoinSession = (sessionName, sessionID) => {
    Navigate(
      `/live-session/organizer/${eventDetail?.data?.eventName?.replace(
        / /g,
        "-"
      )}/${sessionName?.replace(/ /g, "-")}/${eventID}/${sessionID}`
    );
  };

  // console.log("getMySponsors....", getMySponsors);

  return (
    <React.Fragment>
      <div className="reception-content" style={{ marginTop: 60 }}>
        <div className="community">
          <div
            className="community-banner event-reception-banner"
            style={{
              // backgroundImage: eventDetail?.bannerImage
              //   ? `url(${eventDetail.bannerImage})`
              //   : null,
              backgroundImage: `url(${EventReceptionBanner})`,
              // width: "100%",
              // height: "100%",
              // objectFit: "cover",
            }}
          >
            <div className="community-content event-reception-content">
              <div className="community-content__wrapper">
                {/* Display event name if no banner image */}
                {!eventDetail?.bannerImage && <h6>{eventDetail?.eventName}</h6>}
              </div>
            </div>
          </div>
        </div>

        <div className="event-reception-details">
          <div className="event-reception-welcome" style={{ color: "white" }}>
            {/* <h2 style={{ color: "black", margin: "24px 0" }}> */}
            <h2 style={{ color: "black" }}> Welcome, {userName}</h2>
            <p
              className="event-reception-text-details"
              style={{ color: "black" }}
              // style={{ color: "#757575", marginTop: "-12px", fontSize: "13px" }}
            >
              {eventDetail?.eventDescription}
            </p>
            {eventDetail?.videoLink && (
              <iframe
                width="560"
                height="315"
                src={getVideoSrc(eventDetail.videoLink)}
                title="Event Video Player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: "none", borderRadius: "12px" }}
              ></iframe>
            )}
          </div>
          <div className="event-reception-upcoming">
            <h2 style={{ color: "black", margin: "24px 0" }}>
              Upcoming Session
            </h2>
            <p
              style={{
                // color: "#757575",
                color: "black",
                marginTop: "-12px",
                fontSize: "13px",
              }}
            >
              Explore what's about to come next from the session summary below.
            </p>
            <div>
              <SessionReception
                getMySessions={getMySessions}
                eventDetail={eventDetail}
                eventID={eventID}
              />
            </div>

            <div className="event-reception-sessions">
              {/* <button
                className="btn btn-primary rounded-5"
                onClick={() =>
                  navigate(
                    `/${eventDetail?.eventName?.replace(
                      / /g,
                      "-"
                    )}/s/${eventID}`
                  )
                }
              >
                Go backstage
              </button> */}
            </div>
          </div>
        </div>
        <div className="event-reception-bottom-space"></div>
        {/* <div>
          <div
            className="event-reception-marquee-container"
            style={{ marginTop: -80 }}
          >
            <Marquee
              fade={false}
              direction="left"
              reverse={false}
              pauseOnHover={false}
              className="" // pass class to change gap or speed
              innerClassName="" // pass class to change gap or speed
            >
              {getMySponsors?.data?.map((sponsor, i) => (
                <div className="event-reception-marquee-item" key={i}>
                  <img src={`${sponsor?.logo}`} alt="sponsor-image" />
                </div>
              ))}
            </Marquee>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Reception;

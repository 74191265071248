import React from "react";
import { useNavigate } from "react-router-dom";
// import { Button } from "@mui/material";
// import { MdMarkEmailRead } from "react-icons/md";
import SentMail from "../../../../components/Loader/SentMail";
import ButtonSubmit from "../../../../components/Buttons/ButtonStyle";

const ConfirmationMessage = () => {
  const Navigate = useNavigate();
  const handleRedirection = () => {
    Navigate("/request-demo");
  };
  return (
    <div className="successFullMessage-container d-flex justify-content-center align-items-center">
      <div
        style={{
          width: 500,
          backgroundColor: "white",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <div className="mt-4">
          <div
            className="w-100 d-flex justify-content-center"
            style={{
              userSelect: "none",
              color: "#f68a20",
              fontSize: 18,
              fontWeight: "800",
            }}
          >
            Your request has been successfully submitted. .
          </div>
          <div className="w-100 d-flex justify-content-center ">
            <SentMail
              title="Our team will contact
            you shortly"
            />
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          {/* <Link to="/login"> */}
          <ButtonSubmit
            Title="OK"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={15}
            handleOnclick={() => handleRedirection()}
          />
          {/* <Button
              type="submit"
              variant="contained"
              // onClick={() => handleRedirection()}
            >
              Ok
            </Button> */}
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationMessage;

import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import SlideInRightBar from "../../../../../../../components/Common/SlideInComponent/SlideInComponent";
import { Modal, Spinner } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import AddProducts from "../../../AddProducts";

function ProductTab({ boothID, token, products, eventID }) {
  const queryClient = useQueryClient();
  const key = ["exhibtitor-ProductsBooth", eventID];
  const [search, setSearch] = useState("");
  const [productID, setProductID] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const [slideInOpen, setSlideInOpen] = useState(false);
  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };
  const mutationDelete = useCustomMutation(key);
  const tog_deleteParticipant = (id) => {
    setProductID(id);
    setIsToggleDelete(!isToggleDelete);
  };
  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `products/${productID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Product Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Product Picture</span>,
      selector: (row) => (
        <img
          src={row?.productImage}
          style={{ width: "40px", height: "40px", borderRadius: 10 }}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Product Name</span>,
      selector: (row) =>
        row?.productName?.length > 25
          ? row?.productName?.substring(0, 25)
          : row?.productName,
    },
    {
      name: <span className="font-weight-bold fs-13">Product Description</span>,
      selector: (row) =>
        row?.productDescription?.length > 35
          ? row?.productDescription?.substring(0, 35)
          : row?.productDescription,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div>
            <span>
              <BiEdit size={16} className="mx-1" />
            </span>
            {/* <span>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewSponsor(row)}
              />
            </span> */}
            <span>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() => tog_deleteParticipant(row?._id)}
              />
            </span>
          </div>
        );
      },
    },
  ];
  const data = products?.data;
  //   console.log("products...", products);

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      queryClient.invalidateQueries(["exhibtitor-ProductsBooth", eventID]);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <React.Fragment>
      <div className="people-attendees__heading">
        <h3>Products</h3>
        <span className="text">Manage your booth products from here</span>
      </div>
      <div className="people-attendees__body">
        <div className="d-flex justify-content-between align-items-center mb-3 w-100">
          <div
            style={{
              background: "#ffffff",
              paddingTop: ".58rem",
              paddingBottom: ".5rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              marginRight: 32,
              border: "solid",
              borderWidth: 0.1,
              borderRadius: 8,
            }}
          >
            <AiOutlineSearch size={24} />
            <input
              type="text"
              placeholder={"Search Product"}
              value={search}
              onChange={handleSearch}
              style={{
                border: "none",
                marginLeft: 8,
                paddingRight: 24,
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <button
              className="d-flex"
              style={{
                border: "1px solid #21929c",
                borderRadius: "8px",
                fontSize: 14,
                background: "#21929c",
                color: "#fefefe",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 9,
                height: 45,
              }}
              onClick={toggleSlideIn}
              pointer="cursor"
            >
              <p>
                <span pointer="cursor">Add Product</span>
              </p>
            </button>
          </div>
        </div>
        <div className="analystic-table-card">
          <div className="w-100">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="300px"
              columns={columns}
              responsive
              data={data}
              pagination
            />
          </div>
        </div>
        <Modal
          size="md"
          isOpen={isToggleDelete}
          toggle={() => {
            tog_deleteParticipant();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Delete Event
            </h5>
            <button
              onClick={() => {
                setIsToggleDelete(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="text-danger">
              Are you sure you want to delete this Product ?
            </h5>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              onClick={() => {
                setIsToggleDelete(false);
              }}
              type="button"
              className="btn btn-dark"
            >
              Cancel
            </button>
            <button
              onClick={() => DeleteParticitant()}
              type="button"
              className="btn btn-danger"
            >
              {!mutationDelete?.isLoading ? (
                <span className="px-2">Sure</span>
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" />
                  Loading
                </span>
              )}
            </button>
          </div>
        </Modal>
        <SlideInRightBar
          isOpen={slideInOpen}
          title="Add Booth Products"
          toggle={toggleSlideIn}
          body={
            <AddProducts
              token={token}
              boothID={boothID}
              eventID={eventID}
              setSlideInOpenProduct={setSlideInOpen}
            />
          }
        />
      </div>
    </React.Fragment>
  );
}

export default ProductTab;

import React, { useState, useEffect } from "react";
import { UserDetails } from "../../helpers/AuthType/authContext";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loginUser } from "../../store/actions";
import usePost from "../../Hooks/usePost";
import { Spinner } from "reactstrap";
import Logo from "./Logo";
import OtpInput from "./OtpInput";
import ButtonSubmit from "../Buttons/ButtonSubmit";

export default function OtpForm({ props }) {
  const { userInfo } = UserDetails();
  const dispatch = useDispatch();
  const { execute, pending, data } = usePost();
  const { execute: executeResend, pending: pendingResend } = usePost();

  const length = 6;
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const email = userInfo?.email;

  const handleChange = (newOtp) => {
    setOtp(newOtp);
  };

  const handleConfirmOTP = () => {
    if (otp.every((digit) => digit !== "")) {
      const otpValue = parseInt(otp.join(""), 10);
      const Method = "POST";
      const endPoint = "auth/verifyOtp";
      const token = null;
      const raw = JSON.stringify({
        email: email,
        otp: otpValue,
      });
      execute(endPoint, raw, Method, "Successfully logged in", token);
    }
  };

  const handleResendOTP = () => {
    setOtp(new Array(length).fill("")); // Clear the OTP input fields
    const Method = "POST";
    const endPoint = `auth/resend-otp?email=${email}`;
    const token = null;
    const raw = null;
    executeResend(endPoint, raw, Method, "OTP resent", token);
  };

  // Listen for Enter key press

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && otp?.every((digit) => digit !== "")) {
        handleConfirmOTP();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [otp]);

  useEffect(() => {
    if (data?.data?.status === "success") {
      dispatch(loginUser(data, props.router.navigate));
    }
  }, [data?.data?.status]);

  return (
    <div className="login-form-container">
      <div className="login-form-header">
        <Logo />
        <h2 className="login-form-text mb-2">Enter code</h2>
        <p>
          we sent a code to
          <span className="mx-1 text-primary">{email}</span>
        </p>
      </div>

      {pending || pendingResend ? (
        <div style={{ display: "flex", gap: 12 }}>
          <Spinner type="grow" size="md" color="primary" />
          <Spinner type="grow" size="md" color="primary" />
          <Spinner type="grow" size="md" color="primary" />
          <Spinner type="grow" size="md" color="primary" />
          <Spinner type="grow" size="md" color="primary" />
        </div>
      ) : (
        <>
          <div className="w-100 mt-2 mb-2">
            <OtpInput length={length} otp={otp} onChange={handleChange} />
          </div>
          <div className="w-100 mt-3 d-flex justify-content-center">
            <ButtonSubmit
              Title="Confirm"
              BackgroundColor="#f68a20"
              ColorText="#fff"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={handleConfirmOTP}
              pending={false}
            />
          </div>
        </>
      )}
      <div className="d-flex mt-3 justify-content-start">
        Didn't get a code ?
        <span className="mx-1" style={{ fontWeight: "bolder" }}>
          <Link onClick={handleResendOTP}>Click to resend</Link>
        </span>
      </div>
    </div>
  );
}

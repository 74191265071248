import React from "react";
import { Card, CardBody } from "reactstrap";
import { FaCheckCircle } from "react-icons/fa";

const NotificationPayment = () => {
  return (
    <React.Fragment>
      <div
        className="w-100 p-2 d-flex align-items-center rounded-3"
        style={{
          backgroundColor: "#0ac074",
          fontSize: 15,
          color: "#FFFFFF",
        }}
      >
        <div>
          <FaCheckCircle size={20} color="#fff" />
        </div>
        <div className="mx-2">
          Your Request has been sent Successfully our team will contact you
          soon.
        </div>
      </div>
    </React.Fragment>
  );
};
export default NotificationPayment;

import React, { useState, useEffect } from "react";
import useCustomMutation from "../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import EditProfilePicture from "../Profile/EditProfilePicture";
import ButtonSubmit from "../Buttons/ButtonSubmit";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function CreateBusinessCard({ getDetails, userId, token, setIsOffcanvasOpen }) {
  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const key = ["MyCard", userId];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `contacts/${getDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });
    if (uploadImg) {
      formdata.append("photo", uploadImg);
    }
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Business Card successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
    if (getDetails) {
      const {
        photo,
        firstName,
        lastName,
        email,
        phoneNumber,
        organization,
        organizationAddress,
        jobTitle,
      } = getDetails;
      setShowImage(photo);
      setInputs({
        firstName,
        lastName,
        email,
        phoneNumber,
        organization,
        organizationAddress,
        jobTitle,
      });
    }
  }, [mutation?.isSuccess, getDetails]);

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <Row>
          <Col md={12}>
            <div className="d-flex mb-2 justify-content-center">
              {/* <label>Profile Picture</label> */}
              <EditProfilePicture
                showImage={showImage}
                setShowImage={setShowImage}
                setUploadImg={setUploadImg}
                iconSize={80}
                iconColor="#F68A20"
                imgHeight={80}
                imgWidth={80}
                imgBorder="50%"
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>First name
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs?.firstName}
              placeholder="Enter First name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Last name
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs?.lastName}
              placeholder="Enter Last name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            {" "}
            <label>
              <span className="text-danger">*</span>Company Name
            </label>
            <input
              type="text"
              name="organization"
              value={inputs?.organization}
              placeholder="Enter your company name"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Job Title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={inputs?.jobTitle}
              placeholder="Enter Job Title"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Company Address
            </label>
            <input
              type="text"
              name="organizationAddress"
              value={inputs?.organizationAddress}
              placeholder="Enter your company address"
              onChange={handleChange}
              style={{ width: 630, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Email
            </label>
            <input
              type="text"
              name="email"
              value={inputs?.email}
              placeholder="Enter Email"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Phone Number
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Update"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default CreateBusinessCard;

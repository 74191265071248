import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
// import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ThemeContext } from "../../helpers/ThemeContext";

// import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";

import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const CommunityHeader = (props) => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const communityID = props?.communityID;
  const eventID = props?.eventID;

  const {
    data: getBranding,
    error: errorGetBranding,
    isLoading: isLoadingGetBranding,
  } = useCustomFecthQuery(
    ["CommunityBranding,", communityID],
    `${process.env.REACT_APP_API}communities/brand/${communityID}`,
    null,
    { enabled: !!communityID }
  );
  const isLogo =
    getBranding?.data?.length === 0 && getBranding?.status === "ok";
  // console.log("props...", props);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  //theme color
  useEffect(() => {
    // document.body.className = theme === "light" ? "body" : "dark-theme";
  }, [theme]);

  return (
    <React.Fragment>
      <header
        id="page-topbar"
        style={{ backgroundColor: "white", color: "black", userSelect: "none" }}
      >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/" className="logo logo-dark">
                {isLogo ? (
                  <div style={{ fontSize: 22, fontWeight: "600" }}>Logo</div>
                ) : (
                  <>
                    <div
                      className="logo-sm"
                      style={{ backgroundColor: "white", objectFit: "contain" }}
                    >
                      <img
                        src={getBranding?.data[0]?.logo}
                        alt="logo-sm-dark"
                        height="50"
                      />
                    </div>
                    <div
                      className="logo-lg"
                      style={{ backgroundColor: "white", objectFit: "contain" }}
                    >
                      {getBranding?.data[0]?.logo ? (
                        <img
                          src={getBranding?.data[0]?.logo}
                          alt="logo-dark"
                          style={{
                            width: 200,
                            height: 60,
                            backgroundColor: "white",
                            objectFit: "contain",
                          }}
                        />
                      ) : null}
                    </div>
                  </>
                )}
              </Link>

              {/* <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={props.logoImg} alt="logo-sm-light" height="120" />
                </span>
                <span className="logo-lg">
                  <img src={props.logoImg} alt="logo-light" height="120" />
                </span>
              </Link> */}
            </div>
          </div>

          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line text-dark" />
              </button>
            </div>

            <NotificationDropdown color={true} />

            <ProfileMenu props={props} color={true} />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

CommunityHeader.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(CommunityHeader));

import React, { useEffect } from "react";
import { Spinner } from "reactstrap";
// import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../Hooks/useCustomMutation";

const DeleteFaqForm = ({ faqID, token, eventID, setIsToggleDelete }) => {
  //   const queryClient = useQueryClient();
  const key = ["EventDetails", eventID];
  const mutation = useCustomMutation(key);
  const deleteFaq = () => {
    const Method = "DELETE",
      url = `faq/${faqID}`;
    const data = {
      endPoint: url,
      bodyContent: null,
      Method: Method,
      displayMessage: "FAQ successfully deleted",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      //   queryClient.invalidateQueries(key);
      setIsToggleDelete(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <div className="w-100">
      <h5 className="text-danger">
        Are you sure you want to delete this FAQ ?
      </h5>
      <div className="modal-footer d-flex justify-content-between">
        <button
          onClick={() => {
            setIsToggleDelete(false);
          }}
          type="button"
          className="btn btn-dark"
        >
          Cancel
        </button>
        <button onClick={deleteFaq} type="button" className="btn btn-danger">
          {!mutation?.isLoading ? (
            <span className="px-2">Sure</span>
          ) : (
            <span>
              <Spinner as="span" animation="border" size="sm" />
              Loading
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteFaqForm;

import React from "react";

// import logosm from "../../assets/images/logo-sm.png";
import kagoLogo from "../../assets/images/kago-logo-rz.png";
// import logodark from "../../assets/images/Kago-main-logo.png";
// import logolight from "../../assets/images/Kago-main-logo.png";
import { Link } from "react-router-dom";

const TopBarRequestDemo = () => {
  return (
    <React.Fragment>
      <div className="requrstDemo---topBar">
        <div style={{ width: 160, height: 80, marginLeft: 10 }}>
          <Link to="https://kago.digital/" className="logo logo-dark">
            <img
              src={kagoLogo}
              alt="image-logo"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Link>
        </div>
      </div>

      <div></div>
    </React.Fragment>
  );
};

export default TopBarRequestDemo;

import React from "react";
import extractSrcFromIframe from "../../../../utils/extractSrcFromIframe";
import Divider from "./Divider";

const EventMap = ({ venueMap, deviceType }) => {
  const embedMapURL = extractSrcFromIframe(venueMap);
  return (
    <>
      <h1
        id="location"
        className="d-flex justify-content-center"
        style={{ color: "black", fontWeight: "bold" }}
      >
        Event Location
      </h1>
      <Divider />
      <div
        style={{
          height: deviceType === "Mobile" ? "200px" : "400px",
          width: deviceType === "Mobile" ? "100%" : "100%",
          marginTop: "5%",
        }}
      >
        <iframe
          src={embedMapURL}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Event Location Map"
        ></iframe>
      </div>
    </>
  );
};

export default EventMap;

import React, { createContext, useState } from "react";

// Create the context
export const UserTicketContext = createContext();

// Create a provider component
export const UserTicketProvider = ({ children }) => {
  const [customerDetails, setCustomerDetails] = useState({
    name_first: "",
    name_last: "",
    phoneNumber: "",
    email_address: "",
  });

  return (
    <UserTicketContext.Provider value={{ customerDetails, setCustomerDetails }}>
      {children}
    </UserTicketContext.Provider>
  );
};

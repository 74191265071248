import React from "react";
import EditInfo from "../../Events/EditInfo";
import EditTime from "../../Events/EditTime";
import EventFormat from "../../Events/EventFormat";
import MapVenue from "../../Events/MapVenue";
import EventVisibility from "../../Events/EventVisibility";
import EventEmail from "../../Events/EventEmail";
import RedirectionLink from "../../Events/RedirectionLink";
const EventSetup = ({ eventDetails, token, userID }) => {
  // console.log("eventDetails...", eventDetails);
  return (
    <div className="p-2">
      {/* <div className="mb-2">
        <div style={{ fontSize: 18, fontWeight: "600" }}>Event Setup</div>
      </div> */}
      <div className="w-100 d-flex">
        <div className="w-25">
          <div style={{ fontSize: 14, fontWeight: "600", color: "#4a4a4a" }}>
            About Event
          </div>
          <div>
            Write a short event summary or some useful information to access the
            event
          </div>

          {/* <p>
            Update Event Information
             An event cannot exceed 2 weeks after completions the completion day.
            <span
              className="mx-2"
              style={{ textDecoration: "underline", color: "#F68A20" }}
            >
              <a href="https://kago.digital/" target="_blank">
                Click here
              </a>
            </span>
            to find out more.
          </p> */}
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-2">
          <EditInfo userID={userID} token={token} eventDetails={eventDetails} />
        </div>
      </div>

      <hr style={{ color: "black" }}></hr>
      <div className="w-100 d-flex">
        <div className="w-25">
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "#4a4a4a",
            }}
          >
            Event Time
          </div>
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-2">
          <EditTime userID={userID} token={token} eventDetails={eventDetails} />
        </div>
      </div>
      {eventDetails?.eventFormat === "Virtual" ? null : (
        <>
          <hr style={{ color: "black" }}></hr>
          <div className="w-100 d-flex">
            <div className="w-25">
              <div
                style={{ fontSize: 14, fontWeight: "600", color: "#4a4a4a" }}
              >
                Event Format
              </div>
              <div style={{ textAlign: "justify" }}>
                By default, virtual event formats will display all dates
                according to the participant's time zone. However, users can
                display the dates as they wish.
              </div>
            </div>

            <div className="mx-3 w-75 d-flex justify-content-between mb-2">
              <EventFormat
                userID={userID}
                token={token}
                eventDetails={eventDetails}
                eventID={eventDetails?._id}
                eventFormat={eventDetails?.eventFormat}
              />
              <div className="mt-5">
                {/* <button className="btn btn-primary">Update Format</button> */}
              </div>
            </div>
          </div>
        </>
      )}

      {eventDetails?.eventFormat !== "Virtual" ? (
        <>
          <hr style={{ color: "black" }}></hr>
          <div className="w-100 d-flex">
            <div className="w-25">
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color: "#4a4a4a",
                  textAlign: "justify",
                }}
              >
                Event Map Location
              </div>
              <div style={{ textAlign: "justify" }}>
                Add a Embedded Map link HTML URL. Copy your embedded map link
                from google map
              </div>
            </div>
            <div className="mx-3 w-75 d-flex justify-content-between mb-2">
              <MapVenue
                userID={userID}
                token={token}
                eventDetails={eventDetails}
              />
            </div>
          </div>
        </>
      ) : null}

      <hr style={{ color: "black" }}></hr>
      <div className="w-100 d-flex">
        <div className="w-25">
          <div style={{ fontSize: 14, fontWeight: "600", color: "#4a4a4a" }}>
            Event Visibilty
          </div>
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-2">
          <EventVisibility
            userID={userID}
            token={token}
            eventDetails={eventDetails}
          />
        </div>
      </div>
      <hr style={{ color: "black" }}></hr>
      <div className="w-100 d-flex">
        <div className="w-25">
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "#4a4a4a",
            }}
          >
            Event Support
          </div>
          <div style={{ textAlign: "justify", letterSpacing: "1px" }}>
            Event Support Inquiries: Contact information and correspondence for
            event-related support and coordination.
          </div>
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-2">
          <EventEmail
            userID={userID}
            token={token}
            eventDetails={eventDetails}
          />
        </div>
      </div>
      <hr style={{ color: "black" }}></hr>
      <div className="w-100 d-flex">
        <div className="w-25">
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "#4a4a4a",
            }}
          >
            Redirection After Registration
          </div>
          <div style={{ textAlign: "justify", letterSpacing: 0.2 }}>
            Please provide the redirection link to direct users after successful
            registration. If no link is provided, the default redirection will
            be to the Kago login page.
          </div>
        </div>
        <div className="mx-3 w-75 d-flex justify-content-between mb-2">
          <RedirectionLink
            userID={userID}
            token={token}
            eventDetails={eventDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default EventSetup;

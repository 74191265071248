import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import {
//   EmailShareButton,
//   FacebookShareButton,
//   LineShareButton,
//   LinkedinShareButton,
//   TelegramShareButton,
//   TwitterShareButton,
//   WhatsappShareButton,
// } from "react-share";
// import { MdEmail } from "react-icons/md";
// import { FaLinkedin, FaFacebook, FaTelegram } from "react-icons/fa";
// import { FaSquareXTwitter } from "react-icons/fa6";
// import { IoLogoWhatsapp } from "react-icons/io";
// import { RiTwitterXLine } from "react-icons/ri";
import { CiLink } from "react-icons/ci";
// import { BsCopy } from "react-icons/bs";
import communityImg from "../../assets/images/community/Community.png";

function CommunityInfo({ getCommunityDetails }) {
  const [copied, setCopied] = useState(false);
  const CommunityLink = `https://events.kago.digital/c/${getCommunityDetails?.communityName?.replace(
    / /g,
    "-"
  )}/${getCommunityDetails?._id}`;
  // console.log("getCommunityDetails....", getCommunityDetails);
  return (
    <div className="w-100 p-3 d-flex justify-content-center align-items-center">
      <div
        className="p-2 rounded-3 bg-white"
        style={{ width: 800 }}
        onClick={() => {
          // Navigate(`/my-community`);
        }}
      >
        <div className="w-100" style={{ userSelect: "none" }}>
          <img
            alt="community"
            src={
              getCommunityDetails?.banner
                ? getCommunityDetails?.banner
                : communityImg
            }
            className="w-100 h-100 rounded-3"
          />
        </div>
        <div className="w-100">
          <div className="w-100 mt-2 d-flex justify-content-between align-items-center">
            <div
              className="w-100 d-flex justify-content-center"
              style={{
                fontSize: 20,
                fontWeight: "600",
                color: "black",
                textAlign: "justify",
              }}
            >
              {getCommunityDetails?.communityName}
            </div>
          </div>

          <div
            className="mt-1"
            style={{
              fontSize: 12,
              fontWeight: "400",
              textAlign: "justify",
            }}
          >
            {getCommunityDetails?.communityDescription}
          </div>
          <div className="w-100 mt-2">
            {/* <CopyToClipboard
              text={`https://event.kago.digital/c/${getCommunityDetails?.communityName}/${getCommunityDetails?._id}`}
              onCopy={() => setCopied(true)}
            >
              <button className="btn btn-primary rounded-5">Share</button>
            </CopyToClipboard> */}
            <div className="d-flex">
              <div style={{ fontWeight: "700", color: "black" }}>
                Share Link :
              </div>
              <CopyToClipboard
                text={`https://events.kago.digital/c/${getCommunityDetails?.communityName?.replace(
                  / /g,
                  "-"
                )}/${getCommunityDetails?._id}`}
                onCopy={() => setCopied(true)}
              >
                <button
                  style={{
                    backgroundColor: "transparent",

                    borderStyle: "none",
                  }}
                >
                  {CommunityLink?.substring(0, 50)}
                </button>
              </CopyToClipboard>
              <div
                style={{
                  fontWeight: "700",
                  color: "black",
                  userSelect: "none",
                }}
              >
                <CopyToClipboard
                  text={`https://events.kago.digital/c/${getCommunityDetails?.communityName?.replace(
                    / /g,
                    "-"
                  )}/${getCommunityDetails?._id}`}
                  onCopy={() => setCopied(true)}
                >
                  <CiLink size={25} color="black" />
                </CopyToClipboard>
                <span>
                  {copied ? (
                    <span className="mx-2" style={{ color: "#F68A20" }}>
                      Copied
                    </span>
                  ) : null}
                </span>
              </div>
            </div>
            {/* <div>
              <div style={{ fontWeight: "700", color: "black" }}>
                Share on social Media
              </div>
            </div> */}
            {/* <div className="d-flex">
              <LinkedinShareButton url={CommunityLink}>
                <FaLinkedin size={22} round color="#1877F2" />
              </LinkedinShareButton>
              <FacebookShareButton url={CommunityLink}>
                <FaFacebook size={22} round color="#1877F2" />
              </FacebookShareButton>
              <TwitterShareButton url={CommunityLink}>
                <FaSquareXTwitter size={22} round color="#000" />
              </TwitterShareButton>
              <TelegramShareButton url={CommunityLink}>
                <FaTelegram size={22} round color="#1877F2" />
              </TelegramShareButton>
              <WhatsappShareButton url={CommunityLink}>
                <IoLogoWhatsapp size={22} round color="#00A400" />
              </WhatsappShareButton>

            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityInfo;

import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ModalDelete = ({ size, isOpen, toggle, title, body }) => {
  return (
    <div>
      <Modal size={size} isOpen={isOpen} toggle={toggle} backdrop={true}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
      </Modal>
    </div>
  );
};

export default ModalDelete;

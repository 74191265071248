import React from "react";
import { useNavigate } from "react-router-dom";

const SessionReception = ({ getMySessions, eventDetail, eventID }) => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.data?.email;
  const Navigate = useNavigate();
  const handleJoinSession = (sessionName, sessionID) => {
    if (eventDetail?.attendees?.includes(userEmail)) {
      Navigate(
        `/live-session/a/${eventDetail?.eventName?.replace(
          / /g,
          "-"
        )}/${sessionName?.replace(/ /g, "-")}/${eventID}/${sessionID}`
      );
    } else {
      Navigate(
        `/live-session/organizer/${eventDetail?.eventName?.replace(
          / /g,
          "-"
        )}/${sessionName?.replace(/ /g, "-")}/${eventID}/${sessionID}`
      );
    }
  };
  const renderSessions = () => {
    if (getMySessions?.status === "ok" && getMySessions?.data?.length === 0) {
      return null;
    }
    if (getMySessions?.status === "ok" && getMySessions?.data?.length === 1) {
      return (
        <div
          className="event-details__upcoming__sessions--session"
          style={{
            border: "1px solid #828283",
            borderRadius: "12px",
            width: "100%",
            position: "relative",
            color: "#c2c2c3",
            padding: "16px",
            // backgroundColor: "white",
            backgroundColor: "#3C3C3C",
            marginBottom: "16px",
          }}
        >
          <p style={{ color: "#A1A1A1", fontWeight: 600, fontSize: "13px" }}>
            {getMySessions?.data[0]?.startTime} -{" "}
            {getMySessions?.data[0]?.endTime}
          </p>
          <h4
            style={{
              color: "white",
              fontWeight: 600,
              margin: "-4px 0 10px",
            }}
          >
            {getMySessions?.data[0]?.sessionName}
          </h4>
          {getMySessions?.data[0]?.speakers?.map((speaker, i) => {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  gap: "8px",
                  marginBottom: "12px",
                }}
              >
                {speaker?.photo ? (
                  <img
                    src={speaker?.photo}
                    alt="speaker-photo"
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                ) : (
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f68a20",
                      borderRadius: "50%",
                      color: "white",
                    }}
                  >
                    {speaker?.firstName[0]?.toUpperCase()}
                    {speaker?.lastName[0]?.toUpperCase()}
                  </div>
                )}
              </div>
            );
          })}

          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <span
              style={{
                // backgroundColor: "#2d2c32",
                backgroundColor: "#596bff",
                border: "0.8px solid #596bff",
                borderRadius: "8px",
                padding: "4px 8px",
                fontSize: "12px",
                color: "white",
              }}
            >
              Session
            </span>
            <span> &#8226;</span>
            {getMySessions?.data[0]?.tags?.map((tag, i) => {
              return (
                <div key={i} className="d-flex">
                  <span style={{ fontSize: "13px", marginRight: "5px" }}>
                    #{tag}
                  </span>
                </div>
              );
            })}
          </div>
          {eventDetail?.eventFormat === "In Person" ? null : (
            <button
              style={{
                position: "absolute",
                zIndex: "999",
                top: "5%",
                right: "5%",
                backgroundColor: "#596bff",
                // backgroundColor: "#2dd173",
                color: "white",
                fontWeight: "600",
                letterSpacing: "0.8px",
                padding: "8px 16px",
                border: "none",
                borderRadius: "7px",
              }}
              onClick={() =>
                handleJoinSession(
                  getMySessions?.data[0]?.sessionName,
                  getMySessions?.data[0]?._id
                )
              }
            >
              Join now
            </button>
          )}
        </div>
      );
    }

    return getMySessions?.data?.slice(0, 2).map((session, i) => {
      return (
        <div
          key={i}
          className="event-details__upcoming__sessions--session"
          style={{
            border: "1px solid #EFEFEF",
            borderRadius: "12px",
            width: "100%",
            position: "relative",
            color: "#c2c2c3",
            padding: "16px",
            backgroundColor: "white",
            marginBottom: "16px",
          }}
        >
          <p style={{ color: "#A1A1A1", fontWeight: 600, fontSize: "13px" }}>
            {session?.startTime} - {session?.endTime}
          </p>
          <h4
            style={{
              color: "black",
              fontWeight: 600,
              margin: "-4px 0 10px",
            }}
          >
            {session?.sessionName}
          </h4>
          {session?.speakers?.map((speaker, i) => {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  gap: "8px",
                  marginBottom: "12px",
                }}
              >
                {speaker?.photo ? (
                  <img
                    src={speaker?.photo}
                    alt="speaker-photo"
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                ) : (
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      backgroundColor: "#f68a20",
                      borderRadius: "50%",
                      color: "white",
                    }}
                  >
                    {speaker?.firstName[0]?.toUpperCase()}
                    {speaker?.lastName[0]?.toUpperCase()}
                  </div>
                )}
              </div>
            );
          })}

          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <span
              style={{
                // backgroundColor: "#2d2c32",
                backgroundColor: "#596bff",
                border: "0.8px solid #5c5c5c",
                borderRadius: "8px",
                padding: "4px 8px",
                fontSize: "12px",
                color: "white",
              }}
            >
              Session
            </span>
            <span> &#8226;</span>
            {session?.tags?.map((tag, i) => {
              return (
                <div key={i} className="d-flex">
                  <span style={{ fontSize: "13px", marginRight: "5px" }}>
                    #{tag}
                  </span>
                </div>
              );
            })}
          </div>
          {eventDetail?.eventFormat === "In Person" ? null : (
            <button
              style={{
                position: "absolute",
                zIndex: "999",
                top: "5%",
                right: "5%",
                // backgroundColor: "#2dd173",
                backgroundColor: "#596bff",
                color: "white",
                fontWeight: "600",
                letterSpacing: "0.8px",
                padding: "8px 16px",
                border: "none",
                borderRadius: "7px",
              }}
              onClick={() =>
                handleJoinSession(session?.sessionName, session?._id)
              }
            >
              Join now
            </button>
          )}
        </div>
      );
    });
  };
  return (
    <div className="event-details__upcoming__sessions">{renderSessions()}</div>
  );
};

export default SessionReception;

import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import usePost from "../..//Hooks/usePost";
// import useCustomMutation from "../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../Buttons/ButtonSubmit";

const AddFAQ = ({ eventID, token, toggleAddFAQ }) => {
  const key = ["EventDetails", eventID];
  const [inputs, setInputs] = useState({});
  const queryClient = useQueryClient();
  const { status, execute, pending, data } = usePost();
  // const mutation = useCustomMutation(key);

  const handleCreateFaq = (event) => {
    event.preventDefault();
    const Method = "POST",
      endPoint = `faq/${eventID}`;
    const raw = JSON.stringify({
      question: inputs?.question,
      answer: inputs?.answer,
    });
    execute(endPoint, raw, Method, "FAQ successfully added", token);
  };

  useEffect(() => {
    if (status === "success") {
      queryClient.invalidateQueries(key);
      toggleAddFAQ();
    }
  }, [status]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  return (
    <div>
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={handleCreateFaq}
      >
        <Row>
          <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Question
            </label>
            <textarea
              name="question"
              value={inputs?.question || ""}
              onChange={handleChange}
              placeholder="Type your question here..."
              className="mb-3 form-control fromInput"
              rows={3}
              required
            ></textarea>
          </Col>
          <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Answer
            </label>
            <textarea
              name="answer"
              value={inputs?.answer || ""}
              onChange={handleChange}
              placeholder="Write here..."
              className="mb-3 form-control fromInput"
              rows={4}
              required
            ></textarea>
          </Col>
          <div className="w-100 mt-2 d-flex justify-content-center">
            <ButtonSubmit
              Title="Add FAQ"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              type="submit"
              pending={pending}
              handleOnclick={handleCreateFaq}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddFAQ;

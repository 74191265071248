import React, { useState, useEffect, useRef } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import usePost from "../../../../../Hooks/usePost";
import { Spinner } from "reactstrap";

import { BsPeople } from "react-icons/bs";
// import { BiMessageSquareDetail } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { BiBell } from "react-icons/bi";

// import { BiSolidBellRing } from "react-icons/bi";

import Reception from "../reception/reception.component";
import Sessions from "../sessions/sessions.component";
import ExhibitionLobby from "../../../../../components/Common/BoothLobby/BoothLobby";
// import Booths from "../../../../../components/Common/allBooth/AllBooth";
// import Booths from "../booth/Index";
import Community from "../../../../../components/Common/chats/community/community";
import PrivateChat from "../../../../../components/Common/chats/private/PrivateChat";
import Sponsors from "../sponsors/Sponsors";
import { socket } from "../../../../../utils/socketConfig";
import AvatarOne from "../../../../../assets/images/users/avatar-1.jpg";

import { messages, alerts } from "./components/attendees";

const ReceptionHeader = ({ userId, token, eventID }) => {
  const [search, setsearch] = useState(false);
  const [activeLink, setActiveLink] = useState(0);
  const { execute, pending, data, status } = usePost();
  const [isDropdownVisiblePeople, setDropdownVisibilityPeople] =
    useState(false);
  const [isDropdownVisibleMessages, setDropdownVisibilityMessages] =
    useState(false);
  const [isDropdownVisibleAlerts, setDropdownVisibilityAlerts] =
    useState(false);

  const peopleDropdownRef = useRef(null);
  const messagesDropdownRef = useRef(null);
  const alertsDropdownRef = useRef(null);

  useEffect(() => {
    socket.on("connect", () => {
      // console.log("sockettttttttt", socket?.io?.uri);
      // console.log("Connected to server");
    });

    socket.emit("add-user", userId, (result) => {
      // console.log("add-user result:", result);
    });

    socket.emit("join-community", eventID, (result) => {
      // console.log("join-community result:", result);
    });
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        peopleDropdownRef.current &&
        !peopleDropdownRef.current.contains(event.target) &&
        messagesDropdownRef.current &&
        !messagesDropdownRef.current.contains(event.target) &&
        alertsDropdownRef.current &&
        !alertsDropdownRef.current.contains(event.target)
      ) {
        setDropdownVisibilityPeople(false);
        setDropdownVisibilityMessages(false);
        setDropdownVisibilityAlerts(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdownPeople = () => {
    setDropdownVisibilityPeople(!isDropdownVisiblePeople);
  };
  const toggleDropdownMessages = () => {
    setDropdownVisibilityMessages(!isDropdownVisibleMessages);
  };
  const toggleDropdownAlerts = () => {
    setDropdownVisibilityAlerts(!isDropdownVisibleAlerts);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // --------------------------------------- get  attendees --------------------------------get attendees ----------------------

  const {
    data: getAttendees,
    error: errorGetAttendees,
    isLoading: isLoadingGetAttendees,
  } = useCustomQuery(
    ["myAttendees", eventID],
    `${process.env.REACT_APP_API}attendees/${eventID}/event`,
    token
  );

  // --------------------------------------- get  attendees --------------------------------get attendees ----------------------

  // --------------------------------------- requests -------------------------------- requests ----------------------

  // get user requests
  const {
    data: getRequests,
    error: errorGetRequests,
    isLoading: isLoadingGetRequests,
  } = useCustomQuery(
    ["myRequests", eventID],
    `${process.env.REACT_APP_API}requests/requests/${userId}`,
    token
  );

  // handle accept request
  const handleSendRequest = async (attendee) => {
    const Method = "POST",
      endPoint = `requests/request/${eventID}/?requester=${userId}&requestee=${attendee._id}`;
    // token = null;
    const raw = null;
    execute(endPoint, raw, Method, "Request Sent!", token);
  };

  // handle accept request
  const handleAcceptRequest = async (alert) => {
    const status = "accepted";
    const Method = "PATCH",
      endPoint = `requests/respond/${alert?._id}/?response=${status}`;
    // token = null;
    const raw = JSON.stringify({
      response: status,
    });
    execute(endPoint, raw, Method, "Request Accepted!", token);
  };

  // handle decline request
  const handleDeclineRequest = async (alert) => {
    const status = "declined";
    const Method = "PATCH",
      endPoint = `requests/respond/${alert?._id}/?response=${status}`;
    // token = null;
    const raw = JSON.stringify({
      response: status,
    });
    execute(endPoint, raw, Method, "Request Declined!", token);
  };

  // --------------------------------------- requests -------------------------------- requests ----------------------

  // console.log("atttttttt", getAttendees);

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // Capitalize first letters function
  function capitalizeFirstLetters(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <React.Fragment>
      <header className="reception-header">
        <nav className="reception-navbar">
          <ul className="reception-navbar__menu">
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 0 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Reception
              </a>
            </li>
            {getMyEventDetail?.eventType?.includes("Conference") ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 2
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick(2)}
                >
                  Sessions
                </a>
              </li>
            ) : null}
            {getMyEventDetail?.eventType?.includes("Exhibition") &&
            getMyEventDetail?.eventType?.length > 1 ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 4
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick(4)}
                >
                  Exhibition
                </a>
              </li>
            ) : null}
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 6 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(6)}
              >
                Community
              </a>
            </li>
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 8 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(8)}
              >
                Chat
              </a>
            </li>
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 10
                    ? "reception-navbar__menu--link--active"
                    : ""
                }`}
                onClick={() => handleLinkClick(10)}
              >
                Sponsors
              </a>
            </li>
          </ul>
        </nav>
        <div className="reception-header__extended">
          <div className="tooltiping" ref={peopleDropdownRef}>
            <BsPeople
              className="reception-header__extended--icon"
              size={23}
              onClick={toggleDropdownPeople}
            />
            <div
              className="tooltiptext reception-header__extended--icon--tooltip"
              style={{ textAlign: "left", padding: "8px" }}
            >
              <span style={{ fontWeight: 600, marginBottom: "8px" }}>
                People
              </span>
              <br />
              {/* <span style={{ fontSize: "12px" }}>
                Discover and connect with fellow attendees
              </span> */}
            </div>
            {isDropdownVisiblePeople && (
              <div className="tooltiping__content">
                {getAttendees?.data?.slice(0, 4).map((attendee, _idx) => (
                  <div className="tooltiping__content--attendee" key={_idx}>
                    <img
                      className="tooltiping__content--attendee--img"
                      src={attendee.photo ?? AvatarOne}
                      alt="showbay attendee list attendee"
                    />
                    <div className="tooltiping__content--attendee--info">
                      <p className="tooltiping__content--attendee--info-name">
                        {`${capitalizeFirstLetters(
                          attendee.firstName
                        )} ${capitalizeFirstLetters(attendee.lastName)}`}
                      </p>
                      <div
                        className="tooltiping__content--attendee--info-status"
                        onClick={() => handleSendRequest(attendee)}
                      >
                        <div
                          style={{
                            width: "4px",
                            height: "4px",
                            backgroundColor: "#2dd173",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <span style={{ color: "#ffffff" }}>Send Request</span>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="tooltiping__content--show-all">Load more</div> */}
              </div>
            )}
          </div>

          {/* <div className="tooltiping" ref={messagesDropdownRef}>
            <BiMessageSquareDetail
              className="reception-header__extended--icon"
              size={22}
              onClick={toggleDropdownMessages}
            />
            <div
              className="tooltiptext reception-header__extended--icon--tooltip"
              style={{ textAlign: "left", padding: "8px" }}
            >
              <span style={{ fontWeight: 600, marginBottom: "8px" }}>
                Messages
              </span>
              <br />
              <span style={{ fontSize: "12px" }}>
                Reply to your direct messages here
              </span>
            </div>
            {isDropdownVisibleMessages && (
              <div className="tooltiping__content">
                {messages.slice(0, 4).map((message) => (
                  <div className="tooltiping__content--message">
                    <div style={{ width: "15%", height: "15%" }}>
                      <img
                        className="tooltiping__content--message--img"
                        src={message.profileImg}
                        alt="showbay attendee list attendee"
                      />
                      <div className="tooltiping__content--message--activity-track"></div>
                    </div>
                    <div className="tooltiping__content--message--info">
                      <p className="tooltiping__content--message--info-name">
                        {`${message.firstName} ${message.lastName}`}
                      </p>
                      <span className="tooltiping__content--message--info-status">
                        {message.msg}
                      </span>
                    </div>
                  </div>
                ))}
                <div className="tooltiping__content--show-all">
                  See all messages
                </div>
              </div>
            )}
          </div> */}

          <div
            className="tooltiping"
            ref={alertsDropdownRef}
            style={{ cursor: "default" }}
          >
            <IoNotificationsOutline
              className="reception-header__extended--icon"
              size={23}
              onClick={toggleDropdownAlerts}
            />
            <div
              className="tooltiptext reception-header__extended--icon--tooltip"
              style={{ textAlign: "left", padding: "8px" }}
            >
              <span style={{ fontWeight: 600, marginBottom: "8px" }}>
                Alerts
              </span>
              <br />
              <span style={{ fontSize: "12px" }}>
                View event announcements sent by hosts
              </span>
            </div>
            {isDropdownVisibleAlerts && (
              <div className="tooltiping__content">
                {getRequests.slice(0, 4).map((alert) => (
                  <div className="tooltiping__content--alerts">
                    <div style={{ width: "10%", height: "10%" }}>
                      <BiBell className="tooltiping__content--alerts--img" />
                    </div>
                    <div className="tooltiping__content--alerts--info">
                      <p className="tooltiping__content--alerts--info-name">
                        {/* {`${alert.title}`} */}
                        {`${capitalizeFirstLetters(
                          alert?.requester?.firstName
                        )} ${capitalizeFirstLetters(
                          alert?.requester?.lastName
                        )} has sent you a request!`}
                      </p>
                      <span className="tooltiping__content--alerts--info-status">
                        <span
                          style={{
                            cursor: "pointer",
                            padding: "4px",
                            border: "0.4px solid rgba(255, 255, 255, 0.8)",
                            borderRadius: "4px",
                            color: "rgba(255, 255, 255, 0.8)",
                            fontSize: "10px",
                            backgroundColor: "#2dd173",
                          }}
                          onClick={() => handleAcceptRequest(alert)}
                        >
                          Accept
                        </span>{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            padding: "4px",
                            border: "0.4px solid rgba(255, 255, 255, 0.8)",
                            borderRadius: "4px",
                            color: "rgba(255, 255, 255, 0.8)",
                            fontSize: "10px",
                            backgroundColor: "#2dd173",
                          }}
                          onClick={() => handleDeclineRequest(alert)}
                        >
                          Decline
                        </span>
                      </span>
                    </div>
                  </div>
                ))}
                <div className="tooltiping__content--show-all">
                  Show all activity
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
      <div
        className="pt-5"
        style={{ background: "#131216", height: "max-content" }}
      >
        {activeLink === 0 && (
          <Reception
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 2 && (
          <Sessions
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 4 && (
          <ExhibitionLobby
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 6 && <Community />}
        {activeLink === 8 && <PrivateChat />}
        {activeLink === 10 && (
          <Sponsors
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ReceptionHeader;

import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import FormEmail from "./FormEmail";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import TableEmail from "./TableEmail";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import { MdOutlineEmail } from "react-icons/md";
import ChatgptAssistantFom from "./ChatgptAssistantFom";
import AIChat from "./AIChat";

const Email = ({ eventDetails, token, userID, isLoading }) => {
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const key = ["allEmail", eventDetails?._id];
  // const mutation = useCustomMutation(key);
  const {
    data: getAllEmail,
    error: errorGetAllEmail,
    isLoading: isLoadingGetAllEmail,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}communication/email/${eventDetails?._id}`,
    token,
    {
      enabled: !!eventDetails?._id,
    }
  );

  const toggleEmail = () => {
    setIsEmailOpen(!isEmailOpen);
  };

  const toggleEChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className="w-100 px-5 py-4 ">
      <div className="w-100 mb-2  d-flex justify-content-between">
        <div className="mb-2" style={{ fontSize: 18, fontWeight: "600" }}></div>
        <div>
          <ButtonIcon
            Title="New Email"
            Icon={<MdOutlineEmail size={15} color="white" />}
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={() => toggleEmail()}
            pending={false}
          />
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md={12}>
            <div className="w-100 mt-2">
              <TableEmail myEmail={getAllEmail?.data} />
            </div>
          </Col>
        </Row>
      </div>
      <OffCanvas
        isOffcanvasOpen={isEmailOpen}
        title="New Email"
        body={
          <FormEmail
            setIsSMSOpen={setIsEmailOpen}
            userId={userID}
            token={token}
            eventID={eventDetails?._id}
            eventDetails={eventDetails}
            isChatOpen={isChatOpen}
            setIsChatOpen={setIsChatOpen}
          />
        }
        toggleSaveChanges={toggleEmail}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />

      <OffCanvas
        isOffcanvasOpen={isChatOpen}
        title="KagoBot"
        body={<AIChat />}
        toggleSaveChanges={toggleEChat}
        directionOffCanvas="start"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default Email;

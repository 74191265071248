import React from "react";
import { useNavigate } from "react-router-dom";
import { FaRegCalendar } from "react-icons/fa";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import ButtonGradient from "../../Buttons/ButtonGradient";

const BuyTickets = ({ eventDetails, eventID }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const key = ["setTicket", eventID];

  const {
    data: getMyTicketSettings,
    error: errorGetMyTicketSettings,
    isLoading: isLoadingGetMyTicketSettings,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}ticket-settings/${eventID}/event`,
    token,
    {
      enabled: !!eventID,
    }
  );

  const handleBuyTicket = (price, ticketName, ticketTerms, ticketDetail) => {
    localStorage.setItem(
      "kagoTicketDetails",
      JSON.stringify({
        price: price,
        ticketName: ticketName,
        ticketDetail: ticketDetail,
        ticketTerms: ticketTerms,
        eventDetails: eventDetails,
      })
    );
    Navigate(`/checkout/${eventID}`);
  };

  const merchantDetails = eventDetails?.merchantDetails;
  const today = new Date();

  return (
    <>
      {getMyTicketSettings?.data?.map((ticket, i) => {
        const earlyBirdStartDate = new Date(ticket.earlyBirdStartDate);
        const earlyBirdEndDate = new Date(ticket.earlyBirdEndDate);
        const closingDate = new Date(ticket.closingDate);

        const isEarlyBirdActive =
          ticket.allowEarlyBird &&
          today >= earlyBirdStartDate &&
          today <= earlyBirdEndDate;
        const isEventClosed = today >= closingDate;
        const isSoldOut = ticket.quantitySold === ticket.quantity;

        const ticketPrice = isEarlyBirdActive
          ? ticket?.tierEarlyBirdPrice
          : ticket?.price;

        return (
          <div className="w-100 mt-2 d-flex justify-content-center" key={i}>
            <div className="ticket-container">
              <div className="ticket-wrapper">
                <div className="item-right">
                  <h2 className="num">
                    {new Date(ticket?.createdAt)?.getDate()}
                  </h2>
                  <p className="day">
                    {new Date(ticket.createdAt).toLocaleString("default", {
                      month: "short",
                    })}
                  </p>
                  <span className="up-border"></span>
                  <span className="down-border"></span>
                </div>

                <div className="item-left">
                  <h2 className="title">{ticket?.tier}</h2>
                  <div className="sce">
                    <div className="icon">
                      <FaRegCalendar size={14} />
                    </div>
                    <p>
                      Closing by{" "}
                      {new Date(ticket.closingDate).toLocaleString("default", {
                        weekday: "long",
                      })}{" "}
                      {` ${new Date(ticket.closingDate).getDate()}`}{" "}
                      {new Date(ticket.closingDate).toLocaleString("default", {
                        month: "short",
                      })}{" "}
                      {new Date(ticket.closingDate).getFullYear()}
                    </p>
                  </div>
                  <div className="w-100 d-flex justify-content-between">
                    <div className="w-100">
                      <span
                        style={{
                          fontSize: 28,
                          fontFamily: "poppins",
                          fontWeight: "bolder",
                          color: "#4aa3ff",
                          textDecoration: isEarlyBirdActive
                            ? "line-through"
                            : "none",
                        }}
                      >
                        R {ticket?.price}
                      </span>
                      {isEarlyBirdActive && (
                        <sup
                          style={{
                            fontFamily: "poppins",
                            fontSize: 18,
                            fontWeight: "bolder",
                            background:
                              "linear-gradient(to right, #f7983c, #ff7c6e)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        >
                          R{ticket?.tierEarlyBirdPrice}
                        </sup>
                      )}
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                      {isEventClosed || isSoldOut ? (
                        <ButtonGradient
                          Title={isSoldOut ? "Sold Out" : "Not Available"}
                          leftColor="#a9a9a9"
                          rightColor="#a9a9a9"
                          ColorText="white"
                          BorderColor="transparent"
                          borderRadius={2}
                          handleOnclick={() => {}}
                        />
                      ) : (
                        <ButtonGradient
                          Title="Buy Now"
                          leftColor="#f7983c"
                          rightColor="#ff7c6e"
                          ColorText="white"
                          BorderColor="transparent"
                          borderRadius={2}
                          handleOnclick={() =>
                            handleBuyTicket(
                              ticketPrice,
                              ticket?.tier,
                              ticket?.ticketTerms,
                              ticket,
                              merchantDetails
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default BuyTickets;

import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import Invites from "./Invites/Invites";
import Attendees from "./Attendees/Attendee";
import Sponsors from "./Sponsors/Sponsors";
import Speakers from "./Speakers/Speakers";
import Exhibitors from "./Exhibitors/Exhibitors";
// import Volunteers from "./Volunteer/Volunteer";
import OrganizerTeam from "./OrganizerTeam/OrganizerTeam";
import SecurityTeam from "./Security/SecurityTeam";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});
// Helper function to customize Tab label
const formatLabel = (label) =>
  `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

const Participants = ({ userID, token, eventDetails }) => {
  const [selectedTab, setSelectedTab] = useState("0");
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  // console.log("eventDetails?.eventType...", eventDetails?.eventType);
  return (
    <ThemeProvider theme={theme}>
      <div className="set-event-rightbar-content">
        <Box
          sx={{ backgroundColor: "white", width: "100%" }}
          className="set-event-rightbar-content-hearder"
        >
          <div className="w-100 d-flex justify-content-center">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="community navigation tabs"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab value="0" label={formatLabel("Invitations")} />
              <Tab value="1" label={formatLabel("Attendees")} />
              <Tab value="2" label={formatLabel("Sponsors")} />
              {eventDetails?.eventType?.includes("Conference") &&
              eventDetails?.eventType?.length === 1 ? (
                <Tab value="3" label={formatLabel("Speakers")} />
              ) : null}
              {eventDetails?.eventType?.includes("Webinar") &&
              eventDetails?.eventType?.length === 1 ? (
                <Tab value="3" label={formatLabel("Speakers")} />
              ) : null}
              {eventDetails?.eventType?.includes("Expo") &&
              eventDetails?.eventType?.length === 1 ? (
                <Tab value="3" label={formatLabel("Speakers")} />
              ) : null}
              {eventDetails?.eventType?.includes("Exhibition") &&
              eventDetails?.eventType?.length === 1 ? (
                <Tab value="4" label={formatLabel("Exhibitors")} />
              ) : null}
              {eventDetails?.eventType?.includes("Expo") &&
              eventDetails?.eventType?.length === 1 ? (
                <Tab value="4" label={formatLabel("Exhibitors")} />
              ) : null}
              {/* <Tab value="5" label={formatLabel("Volunteer")} /> */}
              <Tab value="6" label={formatLabel("Organizer Team")} />
              <Tab value="7" label={formatLabel("Security Team")} />
            </Tabs>
          </div>
        </Box>

        {selectedTab === "0" && (
          <div className="px-5 py-4">
            <Invites
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
            />
          </div>
        )}

        {selectedTab === "1" && (
          <div className="px-5 py-4">
            <Attendees
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
            />
          </div>
        )}
        {selectedTab === "2" && (
          <div className="px-5 py-4">
            <Sponsors
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
            />
          </div>
        )}
        {selectedTab === "3" && (
          <div className="px-5 py-4">
            <Speakers
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
            />
          </div>
        )}
        {selectedTab === "4" && (
          <div className="px-5 py-4">
            <Exhibitors
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
              eventDetails={eventDetails}
            />
          </div>
        )}
        {selectedTab === "5" && (
          <div className="px-5 py-4">
            {/* <Volunteers
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
              eventDetails={eventDetails}
            /> */}
          </div>
        )}
        {selectedTab === "6" && (
          <div className="px-5 py-4">
            <OrganizerTeam
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
              eventDetails={eventDetails}
            />
          </div>
        )}
        {selectedTab === "7" && (
          <div className="px-5 py-4">
            <SecurityTeam
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
              eventDetails={eventDetails}
            />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Participants;

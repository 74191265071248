import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import LoaderImage from "../../Loader/LoaderImage";

const EventCard = ({ event }) => {
  const Navigate = useNavigate();
  const startDate = event?.startDate?.split("T")[0];
  const [imageLoading, setImageLoading] = useState(true);

  const handleRedirect = (eventName, evID) => {
    const formattedEventName = eventName?.replace(/[^a-zA-Z0-9]/g, "-");
    Navigate(`/${formattedEventName}/p/event-landing/${evID}`);
  };

  return (
    <Card
      key={event.id}
      sx={{ width: 300, m: 1 }}
      onClick={() => {
        handleRedirect(event?.eventName, event?._id);
      }}
    >
      {imageLoading && (
        <LoaderImage WIDTH={"99%"} HEIGHT={140} BORDERRADIUS={2} />
      )}
      <CardMedia
        component="img"
        height="140"
        image={event?.bannerImage}
        alt={event?.eventName}
        onLoad={() => setImageLoading(false)}
        style={{ display: imageLoading ? "none" : "block" }}
      />

      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {event?.eventName}
        </Typography>
        {event?.eventFormat !== "Virtual" && (
          <Typography variant="body2" color="text.secondary">
            {event.location}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          {startDate} {event.startTime}
        </Typography>
        {!event?.ticketsSold && (
          <Typography
            variant="body2"
            color="#f68a20"
            sx={{ fontWeight: "bold", mt: 1 }}
          >
            Free
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default EventCard;

import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
import MyCommunity from "../Pages/MyCommunity/MyCommunity";
import CommunityLandingPage from "../components/Community/LandingPage/LandingPage";

// Import Authentication pages

// import Login from "../Pages/Authentication/Login";

import RequestDemo from "../Pages/Authentication/RequestDemo/Index";
import DemoResponse from "../Pages/Authentication/RequestDemo/DemoResponse";
import Home from "../Pages/Authentication/Home/Home";
import Login from "../Pages/Authentication/Login";
import CustomLogin from "../Pages/Authentication/Login";
import Otp from "../Pages/Authentication/Otp";
import OtpCustom from "../Pages/Authentication/OtpCustom";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import ResetPasswordPage from "../Pages/Authentication/ResetPassword";
import Logout from "../Pages/Authentication/Logout";
import OrganizerReigistration from "../Pages/Authentication/components/Organizer/OrganizerReigistration";
import Register from "../Pages/Authentication/Register";
import ConfirmationMessage from "../Pages/Authentication/components/ConfirmMessage/ConfirmationMessage";
import Verify from "../Pages/Verify/Index";
import TalkToUs from "../Pages/Authentication/TalkToUs";
import WebinarPricingPage from "../Pages/Pricing/Webinar/Index";
import BuyWebinarPage from "../Pages/Pricing/Buy/Webinar/Index";
import ContactWebinarPage from "../Pages/Pricing/ContactSales/WebinarSales";
import OrganizerReg from "../Pages/Authentication/OrganizerReg";
import UserProfile from "../Pages/Authentication/user-profile";
import Merchant from "../Pages/Authentication/Merchant";
import BusinessCard from "../Pages/BusinessCard/BusinessCard";
import MyContacts from "../Pages/Contacts/Contacts";
import CreateEvent from "../Pages/Organizer/CreateEvent/Index";
import EventSetUp from "../Pages/EventSetup/EventSetUp";
import EventOverview from "../components/EventSetUp/Overview/Overview";
// import EventSetUp from "../Pages/Organizer/EventSummary/Index";
import EventSummary from "../Pages/Organizer/EventSummary/Index";
import EventLanding from "../Pages/EventLanding/Index";
import EventLandingCustom from "../Pages/EventLanding/EventLandingCustom";
import EventLandingPublic from "../Pages/EventLanding/EventLandingPublic";
//event Registration
import EventRegistration from "../Pages/EventRegistrattion/EventRegistration";
import TicketPaymentConfirmation from "../Pages/EventRegistrattion/TicketPaymentConfirmation";

//event Landing Page
import EventReception from "../Pages/EventLobby/EventLobby";
import JoinSessionGuest from "../Pages/JoinSession/JoinAsGuest";
import JoinSessionHost from "../Pages/JoinSession/JoinsAsHost";

import JoinConferenceReception from "../Pages/Conference/BackStageConference";
import JoinEventAsOrganizer from "../Pages/JoinEvent/Index";
import JoinEventLobbyAsAttendee from "../Pages/Conference/ConferenceLobby";
import JoinExpoReceptionAsOrganizer from "../Pages/Expo/BackStage/BackStage";
import JoinWebinarReceptionAsOrganizer from "../Pages/Webinar/BackStage/BackStage";
import JoinWebinarSessionAsOrganizer from "../Pages/Webinar/BackStage/StartSession";
import JoinSessionConferenceAsOrganizer from "../Pages/Conference/BackStage/StartConferenceSession";
// import JoinSessionAsOrganizer from "../Pages/Organizer/JoinSession/Index";
import JoinBoothAsOrganizer from "../components/Common/JoinBooth/Index";
import MobileSession from "../Pages/Mobile/JoinSession";
import JoinSessionAsAttendee from "../components/Common/JoinBooth/Index";
// import JoinSessionAsExhibitor from "../Pages/Attendee/JoinSession/Index";
import JoinSessionSocial from "../Pages/Attendee/JoinActivity/Index";

//Speaker

import JoinSessionAsSpeaker from "../Pages/Attendee/JoinSession/Index";

//Attendee
// import AttendeeEventLanding from "../Pages/Attendee/EventLanding/Index";
import ShowQRCode from "../Pages/QRCode/ShowQRCode";
import MyTickets from "../Pages/MyTictets/MyTickets";
import CheckoutTicket from "../components/Common/EventLanding/CheckoutTicket";
import MyInvoices from "../Pages/Invoice/Invoice";
import AttendeeEventReception from "../Pages/Attendee/JoinEvent/Index";
import BuyEvent from "../Pages/InvitePage/Index";
import ExploreEvent from "../Pages/Explore/index";
import JoinWebinarLobbyAsAttendee from "../Pages/Webinar/WebinarLobby";
import JoinWebinarAsAttendee from "../Pages/Webinar/JoinAsAttendee";
import JoinConferenceAsAttendee from "../Pages/Conference/JoinSessionAsAttendee";

// exhibitor

import ExhibitorSetBooth from "../Pages/Exhibitor/BoothSetup/Index";
import ExhibitorEventReception from "../Pages/Exhibitor/JoinEvent/Index";
import ExhibitorJoinBooth from "../Pages/Exhibitor/JoinBooth/Index";

//all users join exhibition lobby
import AllBooth from "../components/Common/allBooth/AllBooth";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/merchant-details", component: <Merchant /> },
  { path: "/my-business-card", component: <BusinessCard /> },
  { path: "/my-contacts", component: <MyContacts /> },
  { path: "/my-tickets", component: <MyTickets /> },
  { path: "/my-invoices", component: <MyInvoices /> },
  { path: "/create-event", component: <CreateEvent /> },
  //see all booths
  { path: "/:eventName/booths/:eventID", component: <AllBooth /> },

  { path: "/:eventName/event-summary/:eventID", component: <EventSummary /> },
  {
    path: "/:eventName/event-reception/:eventID",
    component: <JoinEventAsOrganizer />,
  },

  {
    path: "/Exhibition/:eventName/:boothName/:eventID/:boothID",
    component: <JoinBoothAsOrganizer />,
  },

  {
    path: "/:eventName/Invite/:eventID",
    component: <BuyEvent />,
  },
  {
    path: "/:eventName/exhibitor/event/reception/:eventID",
    component: <ExhibitorEventReception />,
  },

  // JoinSessionAsSpeaker

  //Exhibitor routes

  {
    path: "/:eventName/set-booth/:eventID",
    component: <ExhibitorSetBooth />,
  },
  {
    path: "/:boothName/exhibitor/:eventID/:boothID",
    component: <ExhibitorJoinBooth />,
  },

  //
  {
    path: "/explore-events",
    component: <ExploreEvent />,
  },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/otp", component: <Otp /> },
  { path: "/otp/:eventId", component: <OtpCustom /> },
  { path: "/home", component: <Home /> },
  { path: "/request-demo", component: <RequestDemo /> },
  { path: "/request-message", component: <DemoResponse /> },
  { path: "/login", component: <Login /> },
  { path: "/login/e/:eventId", component: <CustomLogin /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/resetPassword/:token", component: <ResetPasswordPage /> },
  { path: "price/webinar", component: <WebinarPricingPage /> },
  { path: "/buy-webinar", component: <BuyWebinarPage /> },
  { path: "/contact-sales/webinar", component: <ContactWebinarPage /> },
  {
    path: "/organizer/registration",
    component: <OrganizerReigistration />,
  },
  {
    path: "/register-as",
    component: <Register />,
  },
  {
    path: "/message",
    component: <ConfirmationMessage />,
  },
  {
    path: "/sent",
    component: <ConfirmationMessage />,
  },
  {
    path: "/failed",
    component: <ConfirmationMessage />,
  },
  {
    path: "/error",
    component: <ConfirmationMessage />,
  },

  {
    path: "/verify/:ID/:Token",
    component: <Verify />,
  },
  {
    path: "/talk-to-us",
    component: <TalkToUs />,
  },
  { path: "/register-organizer", component: <OrganizerReg /> },
  {
    path: "/c/:communityName/:communityID",
    component: <CommunityLandingPage />,
  },

  //event Registration
  {
    path: "/e/registration/:eventID",
    component: <EventRegistration />,
  },
  //event Registration with custom domain name
  {
    path: "/:evenName/registration/:eventID",
    component: <EventRegistration />,
  },
  //event Registration
  {
    path: "/registrations",
    component: <TicketPaymentConfirmation />,
  },
  {
    path: "/checkout/:eventID",
    component: <CheckoutTicket />,
  },
  //public event Link
  {
    path: ":eventName/p/event-landing/:eventID",
    component: <EventLandingPublic />,
  },

  {
    path: "/live-session/organizer/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinSessionConferenceAsOrganizer />,
  },

  // delete this route and folder

  // {
  //   path: "/live-session/organizer/:eventName/:sessionName/:eventID/:sessionID",
  //   component: <JoinSessionAsOrganizer />,
  // },

  {
    path: "/live-session/attendee/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinSessionAsAttendee />,
  },
  {
    path: "/live-session/a/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinConferenceAsAttendee />,
  },
  {
    path: "/:eventName/:sessionName/:firstName/:lastName/:eventID/:sessionID",
    component: <MobileSession />,
  },

  {
    path: "/barcode/:id",
    component: <ShowQRCode />,
  },
  {
    path: "/live-session/social/attendee/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinSessionSocial />,
  },

  // live-session/attendee/
];
const protectedRoutes = [
  // { path: "/:eventName/set/:eventID", component: <EventSetUp /> },
  { path: "/:eventName/set/:eventID/overview", component: <EventOverview /> },
  //event lobby
  {
    path: "/:eventName/reception/:eventID",
    component: <EventReception />,
  },
];

const customProtectedRoutes = [
  {
    path: "/community/:communityName/:communityID",
    component: <MyCommunity />,
  },
  { path: "/:eventName/set/:eventID", component: <EventSetUp /> },
  { path: "/:eventName/set/:eventID/overview", component: <EventOverview /> },
  { path: "/:eventName/set/:eventID/inbox", component: <EventOverview /> },
  //Enter Event Reception As User
  // {
  //   path: "/:eventName/reception/:eventID",
  //   component: <JoinEventLobbyAsAttendee />,
  // },
  //custom landing page after custom login
  { path: "/event-landing/:eventID", component: <EventLandingCustom /> },

  // landing page after normal login
  { path: "/:eventName/event-landing/:eventID", component: <EventLanding /> },

  {
    path: "/:eventName/host/:sessionName/:eventID/:sessionID",
    component: <JoinSessionHost />,
  },
  {
    path: "/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinSessionGuest />,
  },
  {
    path: "/:eventName/w/stage/:eventID",
    component: <JoinWebinarReceptionAsOrganizer />,
  },
  {
    path: "/:eventName/c/:eventID",
    component: <JoinConferenceReception />,
  },
  {
    path: "/:eventName/expo/stage/:eventID",
    component: <JoinExpoReceptionAsOrganizer />,
  },

  {
    path: "/:eventName/w/live/:eventID",
    component: <JoinWebinarLobbyAsAttendee />,
  },
  // webinar reception page
  {
    path: "/:eventName/event/reception/:eventID",
    component: <AttendeeEventReception />,
  },
  {
    path: "/:eventName/s/:eventID",
    component: <JoinWebinarSessionAsOrganizer />,
  },
  {
    path: "/:eventName/s/live/:eventID",
    component: <JoinWebinarAsAttendee />,
  },

  // JoinAsAttendee,
];

export {
  authProtectedRoutes,
  protectedRoutes,
  publicRoutes,
  customProtectedRoutes,
};

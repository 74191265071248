import React, { useState, useEffect } from "react";
import { UserDetails } from "../../helpers/AuthType/authContext";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import usePost from "../../Hooks/usePost";
import { warningMessage } from "../../components/Toast/Toast";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Logo from "../../components/Authentication/Logo";
import ButtonSubmit from "../Buttons/ButtonSubmit";

export default function LoginForm({ props }) {
  const { setUserInfo } = UserDetails();
  const Navigate = useNavigate();
  const { execute, pending, data } = usePost();
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    rememberMe: false,
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const onChangeRecaptcha = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    const savedCredentials = localStorage.getItem("credentials");
    if (savedCredentials && values.email === "" && values.password === "") {
      const credentials = JSON.parse(savedCredentials);
      setValues((prevValues) => ({
        ...prevValues,
        email: credentials.email,
        password: credentials.password,
        rememberMe: true,
      }));
    } else {
      // If no saved credentials or email/password already provided, ensure rememberMe is set to false
      setValues((prevValues) => ({
        ...prevValues,
        rememberMe: false,
      }));
    }
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    setValues((prevValues) => ({
      ...prevValues,
      rememberMe: event.target.checked,
    }));
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleLogin = () => {
    // if (!captchaValue) {
    //   warningMessage("Please complete the reCAPTCHA");
    //   return;
    // } else {
    const Method = "POST",
      endPoint = "auth/login",
      token = null;
    const raw = JSON.stringify({
      email: values.email,
      password: values.password,
    });

    execute(endPoint, raw, Method, true, token);
    // }

    // if (values.rememberMe) {
    //   localStorage.setItem(
    //     "credentials",
    //     JSON.stringify({ email: values.email, password: values.password })
    //   );
    // } else {
    //   localStorage.removeItem("credentials");
    // }
  };

  useEffect(() => {
    if (data?.data?.status === "success") {
      setUserInfo({ email: values.email });
      // setUserInfo(data?.data);
      Navigate("/otp");
      // dispatch(loginUser(data?.data, props.router.navigate));
    }
  }, [data?.data?.status]);

  return (
    <div className="login-form-container">
      <div className="login-form-header">
        <Logo />
        <h1 className="login-form-text">Sign In</h1>
        <p>Sign In to access your account</p>
      </div>
      <div className="form-field">
        <label>Email</label>
        <input
          type="email"
          value={values.email}
          onChange={handleChange("email")}
          className={!validateEmail(values.email) ? "" : ""}
          name="email" // Unique name for the email input
        />
        {!validateEmail(values.email) && (
          <p className="mt-3 text-primary">Enter a valid email</p>
        )}
      </div>
      <div className="form-field">
        <label>Password</label>
        <div className="password-input">
          <input
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            style={{ borderRadius: "10px" }}
            name="password" // Unique name for the password input
          />
          <button
            className="eye-icon"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {values.showPassword ? <FiEye /> : <FiEyeOff />}
          </button>
        </div>
      </div>
      {/* <div className="form-field remember-me">
        <input
          type="checkbox"
          checked={values.rememberMe}
          onChange={handleCheckboxChange}
          style={{ color: values.rememberMe ? "#F68A20" : "" }} // Change color when checked
        />
        <label>Remember Me</label>
        
      </div> */}
      <div className="d-flex justify-content-end">
        <Link to="/forgot-password">Forgot Password</Link>
      </div>
      <div className="d-flex justify-content-center">
        <ButtonSubmit
          Title="Sign In"
          BackgroundColor="#f68a20"
          ColorText="#fff"
          BorderColor="#f68a20"
          borderRadius={25}
          handleOnclick={handleLogin}
          pending={pending}
        />
        {/* <div className="form-field">
          <button
            className="login-button rounded-5"
            onClick={handleLogin}
            disabled={pending}
          >
            {pending ? <Spinner animation="border" size="sm" /> : "Sign in"}
          </button>
        </div> */}
      </div>
      <div className="authentication-recaptcha">
        <ReCAPTCHA
          sitekey="6LdW6ykqAAAAAI-sRClDPIW2sCAFjz8leO7u7aR8"
          onChange={onChangeRecaptcha}
        />
      </div>

      {/* Uncomment this section if you want to include a signup prompt */}
      {/* <div className="signup-prompt">
        <span>Don't have an account?</span>
        <Link to="/signup">Sign up</Link>
      </div> */}
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../Hooks/useCustomQuery";
import AllEventsCommunity from "./AllEventsCommunity";
import ButtonStyle from "../Buttons/ButtonStyle";

function Events({ communityID, getCommunityDetails, userEmail, token }) {
  const Navigate = useNavigate();

  // Function to check if the user is allowed to create a new event
  const canCreateEvent = () => {
    // Check if user's email is found in the members array with role "Admin" or "Event Manager"
    return getCommunityDetails?.members?.some(
      (member) =>
        member?.email === userEmail &&
        (member?.role === "Admin" || member?.role === "Event Manager")
    );
  };

  const {
    data: getEventsByCommunity,
    error: errorGetEventsByCommunity,
    isLoading: isLoadingGetEventsByCommunity,
  } = useCustomQuery(
    ["EventByCommunity", communityID],
    `${process.env.REACT_APP_API}events/community/${communityID}`,
    token
  );

  return (
    <div className="w-100 mt-3 d-flex justify-content-center">
      <div
        className="rounded-3 p-3"
        style={{ width: 900, backgroundColor: "white" }}
      >
        <div className="mb-3 d-flex justify-content-between">
          <div style={{ fontSize: 14, fontWeight: "600" }}>
            Ongoing and upcoming event(s)
          </div>
          {/* {canCreateEvent() && (
            <ButtonStyle
              Title="New Event"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={() => {
                Navigate("/create-event");
              }}
            />
          )} */}
        </div>
        <AllEventsCommunity
          userEmail={userEmail}
          token={token}
          getEventsByCommunity={getEventsByCommunity?.data}
          getCommunityDetails={getCommunityDetails}
        />
      </div>
    </div>
  );
}

export default Events;

import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "reactstrap";
import useCustomMutation from "../../Hooks/useCustomMutation";

function RedirectionLink({ token, eventDetails }) {
  const [inputs, setInputs] = useState({});

  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Link redirection successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (eventDetails?._id) {
      setInputs((inputs) => ({
        ...inputs,
        redirectUrl: eventDetails?.redirectUrl,
      }));
    }
  }, [eventDetails?._id]);
  return (
    <div className="d-flex justify-content-center" style={{ width: 650 }}>
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        {/* <Row>
          <label>Contact Details</label>
        </Row> */}
        <Row>
          <Col md={6}>
            <label className="event__form__Creation">Redirect to </label>
            <input
              type="text"
              name="redirectUrl"
              value={inputs?.redirectUrl}
              onChange={handleChange}
              className="mb-3 form-control"
              required
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-start align-item-center">
          <div className="d-flex justify-content-start align-item-center">
            <button className="btn btn-primary rounded-5" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Update Redirection</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default RedirectionLink;

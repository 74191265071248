import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

const TableMembers = ({ getCommunityMembers }) => {
  const [memeberID, setMemberID] = useState(false);
  const [memberEmail, setMemberEmail] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const tog_viewMember = (id) => {};
  const toggleDeleteMember = () => {};
  const tog_deleteMember = (id, email) => {
    setMemberID(id);
    setMemberEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">First name</span>,
      selector: (row) => row?.firstName,
    },
    {
      name: <span className="font-weight-bold fs-13">Last name</span>,
      selector: (row) => row?.lastName,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },

    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row?.role,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <AiFillEye
                size={23}
                onClick={() => tog_viewMember(row)}
                className="pb-1 mx-1"
              />
            </div>
            <div>
              <BiEdit size={17} className="" />
            </div>
            <div>
              <AiFillDelete
                size={17}
                onClick={() => tog_deleteMember(row?._id, row?.email)}
                className="mx-2"
              />
            </div>
          </div>
        );
      },
    },
  ];
  // getCommunityDetails;
  const data = getCommunityMembers;
  return (
    <div className="w-100">
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="300px"
        columns={columns}
        responsive
        data={data}
        pagination
      />
    </div>
  );
};

export default TableMembers;

import React from "react";
import Picture from "../../../Profile/Picture";
import { MdEmail } from "react-icons/md";
import { FaCity, FaGlobeAfrica } from "react-icons/fa";
function ViewInvites({ details }) {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div
        style={{
          width: 400,
          borderRadius: 10,
          backgroundColor: "#fbfbfb",
          userSelect: "none",
        }}
      >
        <div className="w-100 d-flex">
          <div>
            <Picture
              showImage={details?.photo}
              iconSize={50}
              iconColor="#F68A20"
              imgHeight={90}
              imgWidth={90}
              imgBorder="50%"
            />
          </div>
          <div
            style={{
              height: 100,
            }}
          >
            <div
              className="w-100 mt-2 mx-3 d-flex align-items-center"
              style={{
                fontSize: 16,
                fontWeight: "700",
                color: "black",
              }}
            >
              {details?.firstName} {details?.lastName}
            </div>
            {details?.jobTitle ? (
              <div
                className="w-100 mx-3 d-flex align-items-center"
                style={{
                  fontSize: 13,
                  fontWeight: "700",
                  color: "#909090",
                }}
              >
                {details?.jobTitle}
              </div>
            ) : null}

            {details?.organization ? (
              <div
                className="w-100 mx-3 d-flex align-items-center"
                style={{
                  fontSize: 13,
                  fontWeight: "700",
                  color: "#F68A20",
                }}
              >
                {details?.organization}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-100 mt-2">
          <div className="w-100 mt-2 mb-2 d-flex ">
            <div
              style={{
                height: 0.1,
                width: 380,
                background: "#F68A20",
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "700",
                color: "black",
              }}
            >
              Details
            </div>
            <div className="w-100 mt-2 d-flex  align-items-center">
              <MdEmail color="black" size={16} />
              <div className="mx-1"> {details?.email}</div>
            </div>
            {details?.city ? (
              <div className="w-100 mt-2 d-flex align-items-center">
                <FaCity color="black" size={16} />
                <div className="mx-1"> {details?.city}</div>
              </div>
            ) : null}

            {details?.country ? (
              <div className="w-100 mt-2 d-flex align-items-center">
                <FaGlobeAfrica color="black" size={16} />
                <div className="mx-1"> {details?.country}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewInvites;

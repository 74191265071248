export const data = [
  {
    question: "What is Kago?",
    answer:
      "Kago is an event management platform that simplifies the process of organizing virtual and in-person events.",
  },
  {
    question: "What types of events can I manage with Kago?",
    answer:
      "You can manage exhibitions, conferences, workshops, and more using Kago.",
  },
  {
    question: "How can I request a demo?",
    answer:
      "Fill out the request form above, and our team will get back to you to schedule a demo.",
  },
  {
    question: "Are there any package options?",
    answer:
      "Yes, we offer various packages tailored to different event needs. Below are the available packages along with their features:",
  },
];

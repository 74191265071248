import React from "react";
import { Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../Hooks/useCustomQuery";
import { FaRegDotCircle } from "react-icons/fa";
import ImagePlaceHolder from "../../assets/images/placeholder/ImagePlaceHolder.png";
import ButtonSubmit from "../Buttons/ButtonStyle";
function AllEventsCommunity({
  search,
  userEmail,
  token,
  t,
  getEventsByCommunity,
  getCommunityDetails,
}) {
  const Navigate = useNavigate();

  const filterEventsByName = (events, searchQuery) => {
    if (!searchQuery) {
      return events;
    } else {
      return events?.filter((event) =>
        event?.eventName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  };
  const filteredEvents = filterEventsByName(getEventsByCommunity, search);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleAttendeeEnterEvent = (event, eventName, evID) => {
    if (event?.eventType?.includes("Webinar")) {
      // Navigate(`/${eventName}/w/stage/${evID}`);
      Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
    } else {
      // Navigate(`/${eventName}/expo/stage/${evID}`);
      Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
    }
    // Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
  };
  const handleEditEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/set/${evID}`);
  };

  const canEditEvent = () => {
    // Check if user's email is found in the members array with role "Admin" or "Event Manager"
    return getCommunityDetails?.members?.some(
      (member) =>
        member?.email === userEmail &&
        (member?.role === "Admin" || member?.role === "Event Manager")
    );
  };

  return (
    <>
      {getEventsByCommunity?.map((event, i) => {
        // Event date configuration
        let dateValue = new Date(event.startDate);

        let day = dayNames[dateValue.getUTCDay()];
        let month = monthNames[dateValue.getUTCMonth()];
        let year = dateValue.getUTCFullYear();
        let date = dateValue.getUTCDate();

        let formattedDate = `${day} ${date} ${month} ${year}`;

        // if (event?.state === "Not Published") {
        return (
          <div key={i}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="d-flex p-3 mb-4 shadow-sm bg-white card-brd myEvent-card-container">
                <div
                  className="d-flex align-items-center"
                  style={{ flex: 0.4 }}
                >
                  <div className="MyEvent_Parent_div">
                    <div className="MyEvent_image_div">
                      <img
                        src={
                          event?.bannerImage
                            ? event?.bannerImage
                            : ImagePlaceHolder
                        }
                        width="100%"
                        height="100%"
                        className="Image_Fit card-brd"
                        alt="event"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 mx-2">
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    {event?.eventName}
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      fontSize: 13,
                    }}
                  >
                    {event?.eventDescription &&
                    event?.eventDescription.length > 35
                      ? `${event?.eventDescription?.substring(0, 200)}...`
                      : null}
                    {event?.eventDescription &&
                    event?.eventDescription.length <= 35
                      ? event?.eventDescription
                      : null}
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    {formattedDate} |{" "}
                    {`${event.startTime} - ${event.endTime} SAST`}
                  </div>

                  <div
                    className="mt-2"
                    style={{
                      fontSize: 13,
                    }}
                  >
                    <ButtonSubmit
                      Title="Visit Event"
                      ColorText="#F68A20"
                      BorderColor="#F68A20"
                      BackgroundColor="white"
                      borderRadius="25px"
                      handleOnclick={() =>
                        handleAttendeeEnterEvent(
                          event,
                          event?.eventName,
                          event?._id
                        )
                      }
                    />
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    {canEditEvent() ? (
                      <div
                        style={{
                          fontSize: 14,
                          textDecoration: "underline",
                          color: "#0e35dd",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleEditEvent(event?.eventName, event?._id)
                        }
                      >
                        Edit Event
                      </div>
                    ) : (
                      <div> </div>
                    )}

                    {/* danger:#FF0000 // Not Published
                      green:"#0ac074" // Published */}
                    <div className="d-flex align-items-center">
                      <FaRegDotCircle
                        size={13}
                        color={
                          event?.visibility === "Public" ? "#0ac074" : "#FF0000"
                        }
                      />
                      <span
                        className="mx-1"
                        style={{
                          fontWeight: "600",
                          color:
                            event?.visibility === "Public"
                              ? "#0ac074"
                              : "#FF0000",
                        }}
                      >
                        {event?.visibility}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        );
        // }
      })}
    </>
  );
}

export default AllEventsCommunity;

import React from "react";
const Divider = () => {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div
        style={{
          width: "5%",
          height: "0.2em",
          backgroundColor: "#f68a20",
        }}
      ></div>
    </div>
  );
};

export default Divider;

import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import TicketingTab from "./TicketingTab/TicketingTab";
import RegistrationTab from "./RegistrationTab/RegistrationTab";
import WithdrawalTab from "./WithdrawalTab/Withdrawal";

// import Attendees from "./Attendees/Attendee";
// import Sponsors from "./Sponsors/Sponsors";
// import Speakers from "./Speakers/Speakers";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});
// Helper function to customize Tab label
const formatLabel = (label) =>
  `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

const Registration = ({ userID, token, eventDetails }) => {
  const [selectedTab, setSelectedTab] = useState("1");
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="set-event-rightbar-content">
        <Box
          sx={{ backgroundColor: "white", width: "100%" }}
          className="set-event-rightbar-content-hearder"
        >
          <div className="w-100 d-flex justify-content-center">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="community navigation tabs"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab value="1" label={formatLabel("Registration Form")} />
              {eventDetails?.ticketsSold === true ? (
                <Tab value="2" label={formatLabel("Ticketing")} />
              ) : null}

              {/* <Tab value="3" label={formatLabel("Withdrawal")} /> */}
            </Tabs>
          </div>
        </Box>

        {selectedTab === "1" && (
          <RegistrationTab
            userID={userID}
            token={token}
            eventID={eventDetails?._id}
            eventDetails={eventDetails}
          />
        )}
        {selectedTab === "2" && (
          <TicketingTab
            userID={userID}
            token={token}
            eventID={eventDetails?._id}
            eventDetails={eventDetails}
          />
        )}
        {selectedTab === "3" && (
          <WithdrawalTab
            userID={userID}
            token={token}
            eventID={eventDetails?._id}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default Registration;

import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Row, Col, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import formatDate from "../../../../utils/formatDate";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const EditTicket = ({
  ticketDetails,
  token,
  eventDetails,
  setIsOffcanvasOpen,
}) => {
  const [inputs, setInputs] = useState({
    tier: "",
    price: "",
    quantity: "",
    discount: "",
    earlyBirdStartDate: "",
    earlyBirdEndDate: "",
    closingDate: "",
    ticketDescription: "",
    ticketTerms: "",
  });

  const [isEarlyBird, setIsEarlyBird] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const validateDates = () => {
    const errors = [];
    const { earlyBirdStartDate, earlyBirdEndDate, closingDate } = inputs;

    if (isEarlyBird) {
      if (new Date(earlyBirdStartDate) >= new Date(earlyBirdEndDate)) {
        errors.push("Early bird start date must be before the end date.");
      }
      if (new Date(earlyBirdStartDate) >= new Date(closingDate)) {
        errors.push("Early bird start date must be before the closing date.");
      }
      if (new Date(earlyBirdEndDate) > new Date(closingDate)) {
        errors.push("Early bird end date must not be after the closing date.");
      }
    }

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    if (!validateDates()) {
      return; // Don't submit if validation fails
    }

    const url = `ticket-settings/${ticketDetails?._id}`;
    const raw = JSON.stringify({
      tier: inputs.tier,
      price: parseInt(inputs.price, 10),
      quantity: parseInt(inputs.quantity, 10),
      discount: parseInt(inputs.discount, 10),
      allowEarlyBird: isEarlyBird,
      earlyBirdStartDate: formatDate(inputs.earlyBirdStartDate),
      earlyBirdEndDate: formatDate(inputs.earlyBirdEndDate),
      closingDate: formatDate(inputs.closingDate),
      ticketDescription: inputs.ticketDescription,
      ticketTerms: inputs.ticketTerms,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: "PATCH",
      displayMessage: "Ticket Successfully Updated",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsEarlyBird((prev) => !prev);
  };

  useEffect(() => {
    if (ticketDetails) {
      const parseDate = (date) => {
        const parsedDate = new Date(date);
        return isNaN(parsedDate)
          ? ""
          : parsedDate.toISOString().substring(0, 10);
      };

      setInputs({
        tier: ticketDetails.tier || "",
        price: ticketDetails.price || "",
        quantity: ticketDetails.quantity || "",
        discount: ticketDetails.discount || "",
        earlyBirdStartDate: parseDate(ticketDetails.earlyBirdStartDate),
        earlyBirdEndDate: parseDate(ticketDetails.earlyBirdEndDate),
        closingDate: parseDate(ticketDetails.closingDate),
        ticketDescription: ticketDetails.ticketDescription || "",
        ticketTerms: ticketDetails.ticketTerms || "",
      });
      setIsEarlyBird(ticketDetails.allowEarlyBird || false);
    }
  }, [ticketDetails]);

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess, setIsOffcanvasOpen]);

  useEffect(() => {
    if (isEarlyBird) {
      validateDates();
    } else {
      setValidationErrors([]);
    }
  }, [
    inputs.earlyBirdStartDate,
    inputs.earlyBirdEndDate,
    inputs.closingDate,
    isEarlyBird,
  ]);

  const renderInput = (label, name, type = "text", required = false) => (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Input
        type={type}
        name={name}
        id={name}
        value={inputs[name]}
        onChange={handleChange}
        required={required}
      />
    </FormGroup>
  );

  return (
    <Form onSubmit={handleSubmitForm}>
      <Row>
        <Col md={6}>{renderInput("Tier", "tier")}</Col>
        <Col md={6}>{renderInput("Price", "price", "number")}</Col>
        <Col md={6}>{renderInput("Quantity", "quantity", "number")}</Col>
        <Col md={6}>{renderInput("Discount", "discount", "number")}</Col>
        <Col md={12}>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={isEarlyBird}
                onChange={handleCheckboxChange}
              />{" "}
              Allow Early Bird
            </Label>
          </FormGroup>
        </Col>
        {validationErrors.length > 0 && (
          <Alert color="warning" className="mt-3">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}

        <Row className="mt-3">
          {isEarlyBird && (
            <>
              <Col md={4}>
                {renderInput(
                  "Early Bird Start Date",
                  "earlyBirdStartDate",
                  "date"
                )}
              </Col>
              <Col md={4}>
                {renderInput("Early Bird End Date", "earlyBirdEndDate", "date")}
              </Col>
            </>
          )}
          <Col md={4}>
            {renderInput("Closing Date", "closingDate", "date", true)}
          </Col>
        </Row>

        {renderInput("Ticket description", "ticketDescription", "textarea")}

        {renderInput("Ticket Terms", "ticketTerms", "textarea")}

        <div className="w-100 d-flex justify-content-center mt-3">
          <ButtonSubmit
            Title="Update Ticket"
            BackgroundColor="#f68a20"
            ColorText="#fff"
            BorderColor="#f68a20"
            borderRadius={25}
            handleOnclick={handleSubmitForm}
            pending={mutation.isLoading}
            disabled={validationErrors.length > 0}
          />
        </div>
      </Row>
    </Form>
  );
};

export default EditTicket;

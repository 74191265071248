import React from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonCard = () => (
  <Box
    sx={{
      backgroundColor: "#fff",
      borderRadius: 2,
      mb: 2,
      overflow: "hidden",
      width: 250,
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Skeleton
        variant="rectangular"
        width="99%"
        height={140}
        sx={{
          mt: 0.3,
          mb: 1.5,
          borderRadius: 2,
        }}
      />
    </Box>

    <Skeleton variant="text" width="80%" height={24} sx={{ ml: 2, mb: 1 }} />

    <Skeleton variant="text" width="60%" height={24} sx={{ ml: 2, mb: 1 }} />

    <Skeleton variant="text" width="40%" height={24} sx={{ ml: 2, mb: 2 }} />
  </Box>
);

const LoaderExploreEvent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 2,
        width: "100%",
      }}
    >
      {[...Array(4)].map((_, index) => (
        <SkeletonCard key={index} />
      ))}
    </Box>
  );
};

export default LoaderExploreEvent;

import { isIOS, isMobile, isTablet, isBrowser } from "react-device-detect";

// Function to identify the device type
export const detectDeviceType = () => {
  const userAgent = navigator.userAgent;

  // Detect iOS devices
  if (isIOS) {
    if (isMobile && !isTablet) {
      return "iOS Phone";
    } else if (isTablet) {
      return "iOS Tablet";
    }
  }

  // Detect Huawei devices
  if (userAgent.includes("Build/Huawei")) {
    if (isMobile) {
      return "Huawei Phone";
    } else if (isTablet) {
      return "Huawei Tablet";
    }
  }

  // Detect other Android devices
  if (!isIOS && isMobile) {
    return "Android Phone";
  } else if (!isIOS && isTablet) {
    return "Android Tablet";
  }

  // Assume any non-mobile, non-tablet device is a desktop
  if (isBrowser) {
    return "Desktop";
  }

  // Default to desktop if nothing else matches
  return "Desktop";
};

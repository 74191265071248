import React, { useRef } from "react";
import Lottie from "lottie-react";
import sentMail from "../../assets/animations/sentMail.json";

function SentMail({ title }) {
  const sentMailRef = useRef();
  return (
    <div className="w-100 d-flex justify-content-center">
      {/* backgroundColor: "white" */}

      <div className="rounded-4 w-100">
        <div
          className="w-100 d-flex justify-content-center"
          style={{ width: 70, height: 70 }}
        >
          <Lottie lottieRef={sentMailRef} animationData={sentMail} />
        </div>
        <div
          className="w-100 d-flex justify-content-center"
          style={{
            userSelect: "none",
            color: "black",
            fontSize: 16,
            fontWeight: "600",
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
}

export default SentMail;

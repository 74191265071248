import React, { useState, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Button } from "reactstrap";
import "./css/Summary.scss";

// import { useHistory, useParams } from "react-router-dom";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import booth1 from "../../../../assets/images/booths/booth1.png";
import booth2 from "../../../../assets/images/booths/booth2.png";
import booth3 from "../../../../assets/images/booths/booth3.png";
import { BiEdit } from "react-icons/bi";
import "./css/booth.scss";

const AddBooth = ({ userId, token, eventID, setSlideInOpenBooth }) => {
  const key = ["exhibtitor-BoothDetails", eventID];
  const queryClient = useQueryClient();
  const mutation = useCustomMutation(key);
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  const [inputs, setInputs] = useState({});

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);
  const [selectedBoothImage, setSelectedBoothImage] = useState(null);

  const selectBoothImage = (imageSrc) => {
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "boothImage.png", { type: "image/png" });
        setLogoServer(file); // Set the file as logoServer
        setProfile(imageSrc); // Update the profile to show the selected image
        setSelectedBoothImage(imageSrc); // Set the selected image URL
      });
  };

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (event.target.type === "file") {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        setInputs((values) => ({ ...values, [name]: e.target.result }));
      };

      reader.readAsDataURL(file);
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  // console.log("token....", token);
  const handleSubmitForm = (event) => {
    event.preventDefault();

    const Method = "POST",
      url = `booths/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("boothLogo", logoServer);
    formdata.append("boothName", inputs?.boothName);
    formdata.append("boothTags", inputs?.boothTags);
    formdata.append("boothExhibitor", inputs?.email);
    // formdata.append("organization", inputs?.organization);
    formdata.append("chat", true);
    formdata.append("boothDescription", inputs?.sessionDescription);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Booth Successfully Set",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  useEffect(() => {
    if (mutation?.isSuccess) {
      queryClient.invalidateQueries(["my-BoothDetails", eventID]);
      setSlideInOpenBooth(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          enctype="multipart/form-data"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <p className="input-description">
              Please upload an image representing your booth for public viewing.
              Options include your company's official profile image or a
              selection from our curated default gallery.
            </p>
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={!logoServer ? ImgSession : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                {/* <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button> */}
                {/* <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                /> */}
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-16px" }}
            >
              <p
                color="ambience.6"
                fontSize="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 900x400px | JPG, PNG | Up to 5 MB
              </p>
            </div>
            <div className="d-flex justify-content-center images-container">
              {[booth1, booth2, booth3].map((boothImage, index) => (
                <img
                  key={index}
                  src={boothImage}
                  alt={`Booth ${index + 1}`}
                  onClick={() => selectBoothImage(boothImage)}
                  style={{
                    width: "80px",
                    height: "80px",

                    cursor: "pointer",
                    border:
                      selectedBoothImage === boothImage
                        ? "2px solid red"
                        : "none",
                    borderRadius: "8px",
                    marginRight: "10px",
                  }}
                />
              ))}
            </div>
            <div className="booth__body--form-input-container mt-3">
              <label
                htmlFor="boothName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Booth Name*</p>
                <p className="input-description">
                  Please provide your company or business name. This information
                  will be available to attendees searching for your booth.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="boothName"
                  name="boothName"
                  type="textarea"
                  rows={5}
                  onChange={handleChange}
                  required
                  placeholder="Enter Booth Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <label
              htmlFor="boothName"
              className="booth__body--form-input-container__label"
            >
              <p className="input-heading">Booth Description*</p>
              <p className="input-description">
                Provide a brief overview of your booth, including the services
                or products offered. This summary will be accessible to
                attendees viewing your booth
              </p>
            </label>
            <textarea
              name="sessionDescription"
              id="sessionDescription"
              placeholder="Add session description"
              onChange={handleChange}
              style={{
                minHeight: "120px",
                width: "95%",
                marginBottom: "16px",
                position: "relative",
                border: "1px solid rgb(154, 151, 170)",
                borderRadius: "8px",
                background: "transparent",
                padding: "12px",
                fontSize: 16,
              }}
            ></textarea>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="boothTags"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Booth Tag </p>
                <p className="input-description">
                  Tags are concise labels that help attendees quickly identify
                  and locate your booth, such as 'tech', 'marketing', etc.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="boothTags"
                  name="boothTags"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter Your tag name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="email"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Exhibitor Email</p>
                <p className="input-description">
                  Provide an email address to be displayed on your booth for
                  attendee inquiries, such as your company or business email.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="email"
                  name="email"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter exhibitor last name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            {/* <div className="booth__body--form-input-container">
              <label
                htmlFor="organization"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Exhibitor Organization</p>
                <p className="input-description">
                  Create a tag or select from the ones available.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="organization"
                  name="organization"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter exhibitor last name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div> */}
          </div>

          {/* ------------------ Features ---------------------- */}

          <div className="next-container" style={{ width: 250 }}>
            <button className="btn btn-success" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? (
                  <span className="text-light">Create</span>
                ) : null}
                {!mutation.isLoading ? null : (
                  <div className="d-flex">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="text-light"
                    />
                    <span className="text-light">Loading</span>
                  </div>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddBooth;

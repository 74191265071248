// Import necessary dependencies and components
import React from "react";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import Communities from "./Communities";
import AllEventPersonnelItems from "./AllEventPersonnelItems";
import AllAttendeeEvents from "./AllAttendeeEvents";
import Nothing from "../../../components/Loader/Contacts";

function AllEvents({ search, userId, userEmail, token, t, isSwitchOn }) {
  const {
    data: getEvents,
    error: errorGetEvents,
    isLoading: isLoadingGetEvents,
  } = useCustomQuery(
    ["MyEvents", userEmail],
    `${process.env.REACT_APP_API}events/personnel?email=${userEmail}`,
    token
  );

  const {
    data: getEventsAsAttendee,
    error: errorGetEventsAsAttendee,
    isLoading: isLoadingGetEventsAsAttendee,
  } = useCustomQuery(
    ["MyEventsAsAttendee", userEmail],
    `${process.env.REACT_APP_API}events/attendee-events?email=${userEmail}`,
    token
  );

  const filterEventsByName = (events, searchQuery, isSwitchOnQuery) => {
    if (isSwitchOnQuery) {
      return events?.filter((event) =>
        event?.eventName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      if (!searchQuery) {
        const myEvent = [];
        return myEvent;
      } else {
        return events?.filter((event) =>
          event?.eventName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    }
  };

  const filteredEvents = filterEventsByName(
    getEvents?.data,
    search,
    isSwitchOn
  );

  const filteredAttendeEvents = filterEventsByName(
    getEventsAsAttendee?.data,
    search,
    isSwitchOn
  );

  const isViewEvent = isSwitchOn || search;

  const isEmptyEvent =
    getEvents?.data?.length === 0 &&
    getEvents?.status === "ok" &&
    getEventsAsAttendee?.data?.length === 0 &&
    getEventsAsAttendee?.status === "ok";

  return (
    <>
      {!isViewEvent ? (
        <Communities token={token} userEmail={userEmail} />
      ) : (
        <>
          {isEmptyEvent ? (
            <div className="h1">
              <Nothing title="You have no event at the moment" />
            </div>
          ) : (
            <>
              <AllAttendeeEvents
                userEmail={userEmail}
                filteredAttendeEvents={filteredAttendeEvents}
                isLoadingGetEvents={isLoadingGetEvents}
              />
              <AllEventPersonnelItems
                userEmail={userEmail}
                filteredEvents={filteredEvents}
                isLoadingGetEvents={isLoadingGetEvents}
                isPersonnelEmpty={isEmptyEvent}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default AllEvents;

import React, { useState, useEffect } from "react";
import useCustomMutation from "../../Hooks/useCustomMutation";
import ButtonSubmit from "../Buttons/ButtonSubmit";

function AddCommunity({ userId, userEmail, token, setModal }) {
  const [inputs, setInputs] = useState({});
  const key = ["MyCommunities", userEmail];
  const mutation = useCustomMutation(key);

  // console.log("userId...", userId);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `communities/${userId}`;
    const raw = JSON.stringify({
      communityName: inputs?.communityName,
      communityDescription: inputs?.communityDescription,
    });
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Community successfully created",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setModal(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <div style={{ width: 500 }}>
          <div style={{ fontSize: 18, fontWeight: "600" }}>
            Create Your Community
          </div>
          <p>
            Your community houses all your events and keeps your audience
            engaged throughout the year..
          </p>
          <label>
            <span className="text-danger">*</span>Community name
          </label>
          <input
            type="text"
            name="communityName"
            value={inputs?.communityName}
            placeholder="Enter a community name"
            onChange={handleChange}
            style={{ width: 500, borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
          <label>
            <span className="text-danger">*</span>Community Description
          </label>
          <div style={{ textAlign: "justify" }}>
            Give a brief and short descriptuion of your organization.
          </div>
          <textarea
            name="communityDescription"
            value={inputs?.communityDescription}
            onChange={handleChange}
            placeholder="Describe here..."
            style={{ width: 500, borderRadius: 7 }}
            className="mb-3 mt-1 form-control fromInput"
            rows={5}
            required
          ></textarea>
          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Create"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddCommunity;

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ButtonStyle from "../../components/Buttons/ButtonSubmit";
import Nothing from "../../components/Loader/Nothing";
import QRCode from "react-qr-code";
import { LiaUser } from "react-icons/lia";
// import { MdEmail } from "react-icons/md";
// import { FaPhoneAlt } from "react-icons/fa";
function ShowQRCode() {
  const Navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  //   console.log("getMyCard....", getMyCard);
  // console.log("id....", id);

  return (
    <div className="qrCode-page-container">
      {id ? (
        <div className="w-100 d-flex justify-content-center">
          <div
            style={{
              width: 280,
              height: 280,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <QRCode value={id} size={250} />
          </div>
        </div>
      ) : (
        <div className="w-100">
          <div className="w-100 d-flex justify-content-center">
            <Nothing title="No Business Card" />
          </div>
          <div className="w-100 p-2 d-flex justify-content-center">
            <ButtonStyle
              Title="OK"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={() => {
                Navigate("/login");
              }}
              pending={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowQRCode;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { IoWarningOutline } from "react-icons/io5";
import Loading from "../../components/Loader/Loading";
import Failed from "../../components/Loader/Failed";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

// import {
//   dangerMessage,
//   successMessage,
//   warningMessage,
// } from "../../components/Toast/Toast";

import { Button } from "reactstrap";
import "./verify.scss";

function Index() {
  document.title = "Kago Events | Verify";
  const Navigate = useNavigate();
  let params = useParams();
  const Id = params.ID;
  const token = params.Token;
  const [btnLoad, setBtnLoad] = useState(false);
  const [message, setMessage] = useState(null);

  const verifyRequest = () => {
    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API}auth/verify/${Id}/${token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === "success") {
          setBtnLoad(false);
          Navigate("/login");
        }
        if (res?.status === "fail") {
          setMessage(res?.message);
          setBtnLoad(false);
        }
      })
      .catch((error) => {
        setBtnLoad(false);
      });
  };
  useEffect(() => {
    verifyRequest();
  }, [token]);

  return (
    <>
      {message && (
        <div className="verify---container">
          <div className="verify---card">
            {btnLoad ? (
              <Loading size={400} />
            ) : (
              <div>
                <div className="d-flex justify-content-center">
                  <Failed />
                  {/* <IoWarningOutline size={70} color="red" /> */}
                </div>

                <p className="mt-2 text-danger h3">{message}</p>
                <div className="d-flex justify-content-center">
                  <ButtonSubmit
                    Title="OK"
                    BackgroundColor="black"
                    ColorText="white"
                    BorderColor="black"
                    borderRadius={15}
                    handleOnclick={() => Navigate("/login")}
                    pending={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Index;

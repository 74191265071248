import React from "react";
import { useNavigate } from "react-router-dom";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import Nothing from "../../../components/Loader/Contacts";
import { FaChevronCircleRight } from "react-icons/fa";
import communityImg from "../../../assets/images/community/Community.png";
import LoaderCommunityItem from "../../../components/Loader/LoaderCommunityItem";

const Communities = ({ token, userEmail }) => {
  const Navigate = useNavigate();

  const {
    data: getCommunities,
    error: errorGetCommunities,
    isLoading: isLoadingGetCommunities,
  } = useCustomFecthQuery(
    ["MyCommunities", userEmail],
    `${process.env.REACT_APP_API}communities/user/${userEmail}`,
    token,
    { enabled: !!userEmail }
  );

  if (isLoadingGetCommunities) {
    return <LoaderCommunityItem />;
  }

  if (getCommunities?.data?.length === 0 && getCommunities?.status === "ok") {
    return (
      <div className="h1">
        <Nothing title="You have no community at the moment" />
      </div>
    );
  }

  /**
   * Renders the list of communities the user is part of.
   * Each community card shows a banner image, the community name, and an icon to navigate.
   */
  return (
    <div className="w-100">
      {getCommunities?.data?.map((community, i) => {
        console.log("community...#1......", JSON.stringify(community, null, 2));
        return (
          <div
            className="w-100 mb-2 p-2 rounded-3 bg-white"
            onClick={() => {
              // Navigate to the selected community's page
              Navigate(
                `/community/${community?.communityName?.replace(/ /g, "-")}/${
                  community?._id
                }`
              );
            }}
            key={i}
          >
            <div className="w-100">
              <img
                alt="community"
                src={community?.banner ? community?.banner : communityImg}
                className="w-100 h-100 rounded-3"
              />
            </div>
            <div className="w-100">
              <div className="w-100 mt-2 d-flex justify-content-between align-items-center">
                <div className="h4">{community?.communityName}</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaChevronCircleRight size={30} color="#F68A20" />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Communities;

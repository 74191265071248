import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
// import "./css/AttachSessionDocument.scss";

const AttachSessionDocument = ({
  token,
  eventID,
  sessionID,
  setShowAttachDocument,
}) => {
  const [inputs, setInputs] = useState({});
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);
  const [isAttachmentDocument, setIsAttachmentDocument] = useState([]);

  const [imagePreviews, setImagePreviews] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `session-document/${sessionID}`,
      isJSON = true;
    const formdata = new FormData();

    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    if (isAttachmentDocument) {
      isAttachmentDocument?.map((item) => {
        formdata.append("document", item);
      });
    }

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Document Successfully Uploaded",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  //attach document function
  const handleAttachment = (event) => {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setIsAttachmentDocument(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  //define file icon
  const getFileIcon = (fileName) => {
    if (fileName.endsWith(".pdf")) {
      return "far fa-file-pdf";
    } else if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      return "far fa-file-excel";
    } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
      return "far fa-file-word";
    } else if (fileName.endsWith(".JPG") || fileName.endsWith(".jpg")) {
      return "fas fa-file-image";
    } else if (fileName.endsWith(".PNG") || fileName.endsWith(".png")) {
      return "fas fa-file-image";
    } else if (fileName.endsWith(".JPEG") || fileName.endsWith(".jpeg")) {
      return "fas fa-file-image";
    }
    // Add more conditions for other file types if needed
    return "far fa-file"; // Default icon
  };
  useEffect(() => {
    if (mutation.isSuccess) {
      setShowAttachDocument(false);
    }
  }, [mutation.isSuccess]);

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        className="booth__body--form"
        onSubmit={handleSubmitForm}
      >
        <Row>
          <Col md={12}>
            <label>
              <span className="text-danger">*</span>Document Name
            </label>
            <input
              type="text"
              name="name"
              value={inputs?.name}
              placeholder="Document Name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={12}>
            <label className="event__input__form__Creation">Description</label>
            <textarea
              name="description"
              value={inputs?.description}
              onChange={handleChange}
              placeholder="Write here..."
              className="mb-3 form-control fromInput"
              rows={5}
              required
            ></textarea>
          </Col>
          <Col md={12}>
            <div className="mt-0">
              <div className="booth__body--form-input-container">
                <div className="file-previews-doc">
                  {isAttachmentDocument?.map((document, index) => {
                    // console.log("file...", document);
                    const isImage =
                      getFileIcon(document.name) === "fas fa-file-image";
                    const imageUrl = isImage
                      ? URL.createObjectURL(document)
                      : null;
                    return (
                      <>
                        {isImage ? (
                          <div key={index} className="file-preview-doc-img">
                            <img
                              src={imageUrl}
                              alt={document.name}
                              style={{
                                width: "70px",
                                height: "60px",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        ) : (
                          <div key={index} className="file-preview-doc">
                            <i
                              className={`${getFileIcon(
                                document.name
                              )} file-icon-doc`}
                            ></i>
                            <div className="file-details-doc">
                              <div className="file-name-doc">
                                {document.name}
                              </div>
                              <div className="file-size-doc">
                                {(document.size / 1024).toFixed(2)} KB
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>

                <div className="mb-3 drop-zone">
                  <label htmlFor="attachment" className="drop-zone-label">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <div className="drop-zone-text">Attach file(s)</div>
                  </label>
                  <input
                    type="file"
                    id="attachment"
                    className="form-control drop-zone-input"
                    multiple
                    accept=".pdf, .xls, .xlsx, .doc, .docx,image/*"
                    onChange={handleAttachment}
                  />
                </div>
                <div
                  className="sponsor-form-body__sponsor-logo"
                  style={{ marginRight: "12px", marginBottom: "-16px" }}
                >
                  <p
                    color="ambience.6"
                    // font-size="1"
                    className="sponsor-form-body--input-description"
                  >
                    <span className="h6">Recommended format: </span>
                    PDF, WORD, EXCEL, POWERPOINT, PICTURE(400x200px) | Up to 10
                    MB
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Submit"
                BackgroundColor="#f68a20"
                ColorText="white"
                BorderColor="#f68a20"
                borderRadius={25}
                handleOnclick={handleSubmitForm}
                pending={mutation.isLoading}
              />
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};
export default AttachSessionDocument;

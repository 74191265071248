import React, { useState, useEffect } from "react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import Nothing from "../../Loader/Nothing";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

function AssignSpeaker({ sessionID, eventID, token, setIsOffcanvasOpen }) {
  const [inputs, setInputs] = useState({ speaker: "" });
  const keySpeaker = ["mySpeakers", eventID];
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);

  const {
    data: getSpeakers,
    error: errorGetSpeakers,
    isLoading: isLoadingGetSpeakers,
  } = useCustomFecthQuery(
    keySpeaker,
    `${process.env.REACT_APP_API}speakers/event/${eventID}`,
    token,
    {
      enabled: !!eventID,
    }
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `speakers/assign/${sessionID}?speaker=${
        inputs?.speaker ? inputs?.speaker : null
      }`,
      isJSON = true;
    var formdata = null;
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Speaker successfully assigned",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  if (getSpeakers?.data?.length === 0 && getSpeakers?.status === "ok") {
    return (
      <Nothing title="You have no speaker at the moment please create speaker under participants" />
    );
  }

  return (
    <div className="w-100 mt-4 d-flex justify-content-center">
      <div style={{ width: 400 }}>
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          onSubmit={toggleSaveChanges}
        >
          <Row>
            <Col md={12}>
              <label>
                Assign Speaker <span className="text-danger">*</span>
              </label>
              <select
                name="speaker"
                value={inputs.speaker}
                onChange={handleChange}
                className="mb-3 form-control"
                required
              >
                <option value={null}>Select... </option>
                {getSpeakers?.data?.map((speaker, i) => (
                  <option value={speaker?.email} key={i}>
                    {speaker?.title} {speaker?.firstName} {speaker?.lastName}
                  </option>
                ))}
              </select>
            </Col>

            <div className="mb-3 mt-4"></div>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Assign speaker"
                ColorText="white"
                BorderColor="#F68A20"
                BackgroundColor="#F68A20"
                borderRadius="25px"
                handleOnclick={toggleSaveChanges}
                pending={mutation?.isLoading}
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default AssignSpeaker;

import * as XLSX from "xlsx";

export const exportRegisteredAttendee = (data) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(
    data?.map(({ firstName, lastName, email, phoneNumber, roles }) => ({
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      PhoneNumber: phoneNumber,
      JobTitle: roles[0],
    }))
  );

  XLSX.utils.book_append_sheet(workbook, worksheet, "RegisterAttendee");
  XLSX.writeFile(workbook, "RegisterAttendee.xlsx");
};
